<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="result-body__answer--wrapper"
     *ngFor="let answer of answersToDisplay"
     tabindex="0"
     [attr.aria-label]="('translations.global.ariaLabels.option' | transloco: {text: getTranslatedValue(answer.value)}) + ', ' +  answer.correct && answer.selected? ('translations.global.ariaLabels.correct' | transloco) : answer.correct && !answer.selected? ('translations.global.ariaLabels.not-selected' | transloco) : !answer.correct && !answer.selected? ('translations.global.ariaLabels.no-border' | transloco) : ('translations.global.ariaLabels.incorrect' | transloco)">
  <button
    tabindex="-1"
    mat-mini-fab color="warn"
    class="result-body__answer--wrapper-button"
    [ngClass]="answer.correct && answer.selected? 'button--correct' : answer.correct && !answer.selected || !answer.correct && !answer.selected? 'button--not-selected' : 'button--incorrect'">
    <mat-icon
      class="result-body__answer--wrapper-icon"
      aria-hidden="true"
      *ngIf="!answer.correct && answer.selected || !answer.correct && !answer.selected ">
      clear
    </mat-icon>
    <mat-icon
      class="result-body__answer--wrapper-icon"
      aria-hidden="true"
      *ngIf="answer.correct && answer.selected || answer.correct && !answer.selected">
      check
    </mat-icon>
  </button>
  <p
    class="result-body__answer"
    [ngClass]="answer.correct && answer.selected? 'correct' : answer.correct && !answer.selected? 'not-selected' : !answer.correct && !answer.selected? 'no-border' : 'incorrect'">
    {{getTranslatedValue(answer.value)}}
  </p>
</div>
