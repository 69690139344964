import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  BooleanFilter,
  BooleanQueryType,
  ResourcesStatusRequest
} from '../../../../../../../shared/models/admin/boolean-filters.model';
import { TranslationService } from '../../../../../../../shared/services/translation/translation.service';
import { MatSelectChange } from '@angular/material/select';
import { FormHeaders } from '../../../../../../../shared/models/admin/members.model';

@Component({
  selector: 'ptl-form-statuses-member-filter',
  templateUrl: './form-statuses-member-filter.component.html',
  styleUrls: ['./form-statuses-member-filter.component.scss'],
})
export class FormStatusesMemberFilterComponent implements OnChanges, OnInit {

  @Input() excludedFormStatuses: string[];
  @Input() formHeaders: FormHeaders[];
  @Output() setFormFilter = new EventEmitter<BooleanFilter>();
  @Output() cancelFormFilter = new EventEmitter<void>();


  selectedFormStatus: 'COMPLETED' | 'STARTED' = 'COMPLETED';
  allFormStatuses: { value: 'COMPLETED' | 'STARTED'; title: string }[] = [];
  formStatuses: { value: 'COMPLETED' | 'STARTED'; title: string }[] = [];

  selectedAmountOperator: 'AT_MOST' | 'AT_LEAST' = 'AT_LEAST';
  amountOperators: { value: string; title: string }[] = [];

  formTotalNumber = 0;
  formsNumber = 0;

  noNumber = false;

  constructor(
    private translationService: TranslationService,
  ) {
  }

  ngOnInit() {
    if (this.formHeaders) {
      this.formTotalNumber = this.formHeaders.length;
    }

    this.allFormStatuses = [
      { value: 'COMPLETED', title: this.translationService.getTranslation('members.filter.resources.completed') },
      { value: 'STARTED', title: this.translationService.getTranslation('members.filter.resources.started') }
    ];
    this.updateStatuses();

    this.amountOperators = [
      { value: 'AT_MOST', title: this.translationService.getTranslation('members.filter.resources.atMost') },
      { value: 'AT_LEAST', title: this.translationService.getTranslation('members.filter.resources.atLeast') }
    ];

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.excludedResourceStatuses) {
      this.updateStatuses();
    }
  }

  onFormStatusChange(event: MatSelectChange) {
    this.selectedFormStatus = event.value;
  }

  onAmountOperatorChange(event: MatSelectChange) {
    this.selectedAmountOperator = event.value;
  }

  onSave() {
    if (isNaN(Number(this.formsNumber))) {
      this.noNumber = true;
      return;
    }
    this.noNumber = false;
    const result = {
      request: this.getRequest(),
      message: this.getMessage()
    };

    this.setFormFilter.emit(result);
  }

  onCancel() {
    this.cancelFormFilter.emit();
  }

  private getMessage() {
    let message = '';

    if (this.selectedFormStatus === 'COMPLETED') {
      message += this.translationService.getTranslation('members.filter.summary.completed');
    } else {
      message += this.translationService.getTranslation('members.filter.summary.started');
    }

    switch (this.selectedAmountOperator) {
      case 'AT_LEAST':
        message += ' ' + this.translationService.getTranslation('members.filter.summary.atLeast');
        break;
      case 'AT_MOST':
        message += ' ' + this.translationService.getTranslation('members.filter.summary.atMost');
        break;
      default:
        break;
    }

    message += ' ' + this.formsNumber + '/' + this.formTotalNumber
      + ' ' + this.translationService.getTranslation('members.filter.summary.forms');

    return message;
  }

  private getRequest(): ResourcesStatusRequest {
    return {
      type: BooleanQueryType.RESOURCES_STATUS,
      resourcesType: 'FORMS',
      operator: this.selectedAmountOperator,
      amount: this.formsNumber,
      status: this.selectedFormStatus,
      required: true
    };
  }

  private updateStatuses() {
    this.formStatuses = this.allFormStatuses.filter(
      status => !this.excludedFormStatuses.includes(status.value)
    );
    if (this.formStatuses.length) {
      this.selectedFormStatus = this.formStatuses[0].value;
    }
  }
}
