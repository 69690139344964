<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="side-nav" #sideNav>
  <ng-container *ngFor="let navSection of displayedSideNavSections; let i = index">
    <ptl-side-nav-section
      *ngIf="!navSection.hideIfEmpty || navSection.navItems?.length"
      class="side-nav-section"
      [sideNavSection]="navSection"
      (itemLinkClick)="onLinkClick($event)"
      (expandToggled)="onExpandToggle($event, i)"
      (loadMore)="onLoadMore(i)">
    </ptl-side-nav-section>
    <ptl-side-nav-btn [isPage]="showAddNewPage" *ngIf="showAddNewPage && showAddNewPageIndex === i"></ptl-side-nav-btn>
    <ptl-side-nav-btn [isPublisher]="showAddNewPackage" *ngIf="showAddNewPackage && showAddNewPackageIndex === i"></ptl-side-nav-btn>
  </ng-container>
</div>
