/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import { Location } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  NgZone,
  OnDestroy,
  OnInit, Renderer2
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ShareBadgeUrlComponent } from '@app/app/shared/components/share-badge-url/share-badge-url.component';
import { ContentHelper } from '@app/app/shared/helpers/content-helper';
import { LanguageCodeHelper } from '@app/app/shared/helpers/language-code-helper';
import { LocalTimeHelper } from '@app/app/shared/helpers/local-time-helper';
import { SnackbarHelper } from '@app/app/shared/helpers/snackbar-helper';
import { FOLIO_BADGE_PREFIX } from '@app/app/shared/paths/folio/folio-paths';
import { ColorThiefService } from '@soarlin/angular-color-thief';
import { EMPTY, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { FOLIO_DATA_SERVICE, FolioDataService } from '../../../services/data.service';
import { PublicFolioBadge } from '../../../store/folio.state.model';
import * as moment from 'moment/moment';
import { ShareService } from '@app/app/shared/services/share/share.service';


@Component({
  selector: 'ptl-public-badge-content',
  templateUrl: './public-badge-content-new.component.html',
  styleUrls: ['./public-badge-content-new.component.scss'],
})
export class PublicBadgeContentComponent implements OnInit, AfterViewInit, OnDestroy {

  skeletonViewActive = true;
  badgeData: PublicFolioBadge;
  awardedDate: string;
  avgColor: string;
  isIframeMode: boolean;

  private subscriptionEnd$ = new EventEmitter<void>();

  constructor(
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private ngZone: NgZone,
    private colorThiefService: ColorThiefService,
    private dialog: MatDialog,
    private renderer: Renderer2,
    private shareService: ShareService,
    @Inject(FOLIO_DATA_SERVICE) private folioDataService: FolioDataService
  ) {
    this.isIframeMode = ContentHelper.isFrameMode();
  }

  ngOnInit() {
    this.loadUserBadgeData();
    document.documentElement.style.setProperty('--dynamic-color', null);
  }

  ngAfterViewInit() {
    this.overrideBody();
  }

  ngOnDestroy() {
    this.subscriptionEnd$.emit();
  }

  overrideBody() {
    if (this.isIframeMode) {
      const body = document.querySelector('body');

      this.renderer.setStyle(body, 'background-color', 'transparent');
    }
  }

  backToPreviousState() {
    this.location.back();
  }

  getLocalDateTime(date: Date): string {
    return LocalTimeHelper.getLocalDateTime(date).toString();
  }

  shareBadge() {
    const badgeId = this.activatedRoute.snapshot.paramMap.get('userBadgeId');
    const shareUrl = `${FOLIO_BADGE_PREFIX}${badgeId}`;

    this.dialog.open(ShareBadgeUrlComponent, {
      width: '30rem',
      maxHeight: '80vh',
      position: {
        top: '25vh',
      },
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      panelClass: ['ptl-mat-dialog'],
      backdropClass: 'dialog-backdrop',
      data: {
        shareUrl: shareUrl
      },
    });
  }

  shareLinkedInCertificate(): void {
    const {title, publisher, issuedOn, uid} = this.badgeData;
    const issueMonth = moment(issuedOn).format('MM');
    const issueYear = moment(issuedOn).format('YYYY');
    const badgeId = this.activatedRoute.snapshot.paramMap.get('userBadgeId');
    const shareUrl = `${FOLIO_BADGE_PREFIX}${badgeId}`;

    this.shareService.shareCertificateToLinkedin(title, publisher, issueMonth, issueYear, uid, shareUrl);
  }

  private loadUserBadgeData() {
    const badgeId = this.activatedRoute.snapshot.paramMap.get('userBadgeId');
    this.folioDataService.getFolioUserPublicBadge(badgeId).pipe(
      switchMap(({ isSuccess, value, error }) => {
        if (isSuccess) {
          const badgeData = value;
          this.awardedDate = this.getLocalDateTime(badgeData.issuedOn);
          if (badgeData?.imageUrl) {
            return this.setAvgColor(badgeData.imageUrl).pipe(
              take(1),
              map(() => ({ isSuccess, value }))
            );
          } else {
            return of({ isSuccess, value });
          }
        } else {
          SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
          this.skeletonViewActive = false;
          return EMPTY;
        }
      })
    ).subscribe(({ isSuccess, value }) => {
      if (isSuccess) {
        this.badgeData = value;
        this.skeletonViewActive = false;
      }
    });
  }

  private setAvgColor(imgUrl: string): Observable<boolean> {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    const timestamp = imgUrl.includes('base64') ? '' : '?' + (new Date()).getTime();
    image.src = imgUrl + timestamp;

    return new Observable<boolean>((observer) => {
      image.onload = () => {
        const color = this.colorThiefService.getColor(image);
        this.avgColor = this.rgbToHex(color[0], color[1], color[2]);
        document.documentElement.style.setProperty('--dynamic-color', this.avgColor);

        observer.next(true);
        observer.complete();
      };

      image.onerror = () => {
        observer.next(true);
        observer.complete();
      };
    });
  }

  private rgbToHex(r: number, g: number, b: number) {
    return '#' + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
  }

  private componentToHex(c: number) {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  }
}
