/*
 * Copyright (C) 2018 - present by Potentially
 *
 * Please see distribution for license.
 */

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { ChartConfiguration, ChartType } from 'chart.js';
import {
  AnalyticsFilterData,
  StatsData
} from '../../../models/analytics/analytics.model';


@Component({
  selector: 'ptl-analytics-charts-new',
  templateUrl: './analytics-charts-new.component.html',
  styleUrls: ['./analytics-charts-new.component.scss'],
})
export class AnalyticsChartsNewComponent implements OnChanges {

  @Input() chartTitle: string;
  @Input() statsData: StatsData[] = [];

  @Output() filterUpdated = new EventEmitter<AnalyticsFilterData>();

  chartType: ChartType | 'horizontalBar' = 'line';
  chartOptions: ChartConfiguration['options'] = this.getChartOptionsByType(this.chartType);
  chartLabels: string[] = [];
  chartData: number[] = [];

  chartDatasets;

  updateChartView = false;
  loadingInProgress = true;


  ngOnChanges(changes: SimpleChanges) {

    if (changes.statsData?.currentValue) {
      this.updateChartDataByRange(this.statsData);
      this.loadingInProgress = false;
      this.updateChartView = true;
    }
  }

  changeChart(type: ChartType | 'horizontalBar') {
    if (type === 'horizontalBar') {
      this.chartType = 'bar';
    } else {
      this.chartType = type;
    }
    this.chartOptions = this.getChartOptionsByType(type);
    this.updateChartDataByRange(this.statsData);
    this.updateChartRenderView();
  }

  private updateChartRenderView() {
    this.updateChartView = false;
    setTimeout(() => {
      this.updateChartView = true;
    }, 0);
  }

  private updateChartDataByRange(stats: StatsData[]) {
    if (!stats) {
      return;
    }
    const labels = []
    const data = [];

    for (const stat of stats) {
      const key = stat.data;
      labels.push(key);
      data.push(stat.count);
    }
    this.chartLabels = labels;
    this.chartData = data;

    this.chartDatasets = {
      labels: this.chartLabels,
      datasets: [
        {
          data: this.chartData,
        }
      ]
    }
    if (this.chartType === 'line' || this.chartType === 'bar') {
      this.chartDatasets.datasets[0].borderWidth = 1;
      this.chartDatasets.datasets[0].backgroundColor = 'rgba(106, 53, 255, 0.4)';
      this.chartDatasets.datasets[0].borderColor = 'rgba(106, 53, 255, 0.7)';
      this.chartDatasets.datasets[0].hoverBackgroundColor = 'rgba(106, 53, 255, 0.7)';
      this.chartDatasets.datasets[0].hoverBorderColor = 'rgba(106, 53, 255, 0.7)';
      this.chartDatasets.datasets[0].pointBackgroundColor = 'rgba(106, 53, 255, 1)';
      this.chartDatasets.datasets[0].fill = 'origin';
    }
  }

  private getChartOptionsByType(type: ChartType | 'horizontalBar'): ChartConfiguration['options'] {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 5,
          bottom: 5,
        }
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          // Disable the on-canvas tooltip
          enabled: false,
          external: function(context) {
            // Tooltip Element
            let tooltipEl = document.getElementById('chartTooltip');

            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartTooltip';
              tooltipEl.className = 'chart-tooltip';
              tooltipEl.innerHTML = '<div class="tooltip-content"></div>';
              document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            const tooltipModel = context.tooltip;
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = '0';
              return;
            }

            function getBody(body) {
              return body;
            }

            // Set Text
            if (tooltipModel.body) {
              let innerHtml = '<div class="tooltip-content-inner">';
              const body = tooltipModel.body.map(getBody);
              body.forEach((line, i) => {
                innerHtml += '<div class="tooltip-title">' + line.lines[0] + '</div>';
              });
              innerHtml += '</div>';
              const tableRoot = tooltipEl.querySelector('div');
              tableRoot.innerHTML = innerHtml;
            }

            const position = context.chart.canvas.getBoundingClientRect();

            // Display, position, and set styles for font
            tooltipEl.style.opacity = '1';
            tooltipEl.style.left = ((position.left + window.pageXOffset +
              tooltipModel.caretX - (type === 'horizontalBar' ? 150 : 50)) / 16) + 'rem';
            tooltipEl.style.top = ((position.top + window.pageYOffset +
              tooltipModel.caretY + (type === 'horizontalBar' ? -10 : 25)) / 16) + 'rem';
          },
          callbacks: {
            label: (context) => {
              return `${context.label}: ${context.formattedValue}`;
            },
          },
        },
      },
    };
    if (type === 'horizontalBar') {
      options['indexAxis'] = 'y';
    }
    if (this.chartType === 'line' || this.chartType === 'bar') {
      options['elements'] = {
        line: {
          tension: 0.5
        }
      }
      options['scales'] = {
        y: {
          min: 0,
          ticks: {
            stepSize: 1,
          }
        },
        x: {
          min: 0,
          ticks: {
            stepSize: 1,
          }
        },
      }
    }

    if (type === 'polarArea') {
      options['scale'] = {
        ticks: {
          stepSize: 1,
          min: 0,
        },
      };
    }
    return options as ChartConfiguration['options'];
  }
}
