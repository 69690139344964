/*
 * Copyright (C) 2018 - present by Potentially
 *
 * Please see distribution for license.
 */

import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AdminMembersRoles,
  FilterTypes,
  MembersActiveFilter,
  MembersGroupsFilter,
  MembersPlaylistsFilter,
  MembersTagsFilter
} from '../../../../../shared/models/admin/members.model';
import { FormControl } from '@angular/forms';
import {
  BooleanFilter,
  BooleanQueryRequest,
  BooleanQueryType,
  ExternalIdRequest,
  FirstNameRequest,
  LastNameRequest
} from '../../../../../shared/models/admin/boolean-filters.model';
import { filter } from 'rxjs/operators';
import { Select, Store } from '@ngxs/store';
import { MembersState } from '../store/members.state';
import { Observable, Subscription } from 'rxjs';
import { CardFilter, PlaylistFilter } from '../store/members.state.model';
import {
  UpdateCardFilters,
  UpdateMemberFilters,
  UpdatePlaylistFilters,
  UpdatePlaylistReviewFilters
} from '../store/members.action';

@Component({
  selector: 'ptl-find-people',
  templateUrl: './find-people.component.html',
  styleUrls: ['./find-people.component.scss'],
})
export class FindPeopleComponent implements OnInit, OnDestroy {

  @Input() isPlaylistPage: boolean;
  @Input() isCardPage: boolean;
  @Input() isPlaylistReviewsPage: boolean;
  @Input() playlistUid: string;
  @Input() cardUid: string;

  @Select(MembersState.memberFilters)
  private filters$: Observable<BooleanFilter[]>;
  @Select(MembersState.cardFilters)
  private cardFilters$: Observable<CardFilter[]>;
  @Select(MembersState.playlistFilters)
  private playlistFilters$: Observable<PlaylistFilter[]>;
  @Select(MembersState.playlistReviewFilters)
  private playlistReviewFilters$: Observable<PlaylistFilter[]>;
  private filtersSubscription: Subscription;
  filters: BooleanFilter[] = [];

  filterTypeSearch: FilterTypes = 'SEARCH';

  roles: AdminMembersRoles[] = [];
  activeFilters: MembersActiveFilter[] = [];
  groups: MembersGroupsFilter[] = [];
  tags: MembersTagsFilter[];
  playlists: MembersPlaylistsFilter[] = [];
  searchInProgress: boolean;
  searchString = new FormControl('');
  searchFilterActive: boolean;

  searchFilterBy = {
    firstName: '',
    lastName: '',
    id: '',
  };

  constructor(private store: Store) {}

  ngOnInit() {
    this.initFilters();
  }

  ngOnDestroy() {
    this.filtersSubscription?.unsubscribe();
  }

  openFilter() {
    this.searchFilterActive = true;
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    const targetElement = event.target as Element;
    if (!targetElement.closest('.f_filter-item')) {
      this.closeSearchFilter();
    }
  }

  onFilterApply() {
    this.updateSearchFilter();
    this.searchFilterActive = false;
    this.fireFilterAction();
    this.clearSearch();
  }


  deleteActiveFilter(index: number, filterData: MembersActiveFilter) {
    this.activeFilters.splice(index, 1);
    switch (filterData.filterType) {
      case this.filterTypeSearch:
        this.clearSearchByFilter(filterData);
        break;
    }
    this.fireFilterAction();
  }

  private clearSearch() {
    this.searchString.setValue('');
  }

  closeSearchFilter() {
    this.searchFilterActive = false;
  }

  private clearSearchByFilter(filterData: MembersActiveFilter) {
    this.searchFilterBy[filterData.content.type] = '';
  }

  private updateSearchFilter() {
    for (const key in this.searchFilterBy) {
      if (this.searchFilterBy.hasOwnProperty(key)) {
        this.updateSearchFilterBy(key, this.searchFilterBy[key]);
      }
    }
  }

  private updateSearchFilterBy(type: string, searchedValue: string) {
    const itemIndex = this.activeFilters.findIndex(item => item.content.type === type);
    if (searchedValue) {
      const data = {
        content: {
          _id: type,
          title: searchedValue,
          type: type,
        },
        filterType: this.filterTypeSearch,
      };
      if (itemIndex === -1) {
        this.activeFilters.push(data);
      } else {
        this.activeFilters[itemIndex] = data;
      }
    } else {
      if (itemIndex !== -1) {
        this.activeFilters.splice(itemIndex, 1);
      }
    }
  }

  private fireFilterAction() {
    const firstName = this.searchFilterBy.firstName;
    const lastName = this.searchFilterBy.lastName;
    const externalId = this.searchFilterBy.id;
    this.filters = this.filters.filter(f => Array.isArray(f) ||
      !['FIRST_NAME', 'LAST_NAME', 'EXTERNAL_ID'].includes((f.request as BooleanQueryRequest).type))
    if (firstName) {
      const request = { type: BooleanQueryType.FIRST_NAME, firstName: firstName } as FirstNameRequest;
      this.filters.push({
        request: request,
        message: `FIRST NAME '${firstName}'`
      });
    }
    if (lastName) {
      this.filters = this.filters.filter(f => Array.isArray(f) || (f.request as BooleanQueryRequest).type !== 'LAST_NAME');
      const request = { type: BooleanQueryType.LAST_NAME, lastName: lastName } as LastNameRequest;
      this.filters.push({
        request: request,
        message: `LAST NAME '${lastName}'`
      });
    }
    if (externalId) {
      this.filters = this.filters.filter(f => Array.isArray(f) || (f.request as BooleanQueryRequest).type !== 'EXTERNAL_ID');
      const request = { type: BooleanQueryType.EXTERNAL_ID, externalId: externalId } as ExternalIdRequest;
      this.filters.push({
        request: request,
        message: `ID '${externalId}'`
      });
    }
    this.updateFilters();
  }

  private initFilters() {
    if (this.isCardPage) {
      this.filtersSubscription = this.cardFilters$.pipe(
        filter(data => !!data),
      ).subscribe( cardFilters => {
          this.filters = cardFilters.find(cardFilter => cardFilter.cardUid === this.cardUid)?.value ?? [];
          this.updateSearch();
        }
      )
    } else if (this.isPlaylistPage) {
      this.filtersSubscription = this.playlistFilters$.pipe(
        filter(data => !!data)
      ).subscribe( playlistFilters => {
          this.filters = playlistFilters.find(playlistFilter => playlistFilter.playlistUid === this.playlistUid)?.value ?? [];
          this.updateSearch();
        }
      )
    } else if (this.isPlaylistReviewsPage) {
      this.filtersSubscription = this.playlistReviewFilters$.pipe(
        filter(data => !!data)
      ).subscribe( playlistFilters => {
          this.filters = playlistFilters.find(playlistFilter => playlistFilter.playlistUid === this.playlistUid)?.value ?? [];
          this.updateSearch();
        }
      )
    } else {
      this.filtersSubscription = this.filters$.pipe(
        filter(data => !!data)
      ).subscribe( filters => {
          this.filters = filters;
          this.updateSearch();
        }
      )
    }
  }

  private updateFilters() {
    if (this.isCardPage) {
      this.store.dispatch(new UpdateCardFilters(this.cardUid, this.filters));
    } else if (this.isPlaylistPage) {
      this.store.dispatch(new UpdatePlaylistFilters(this.playlistUid, this.filters));
    } else if (this.isPlaylistReviewsPage) {
      this.store.dispatch(new UpdatePlaylistReviewFilters(this.playlistUid, this.filters));
    } else {
      this.store.dispatch(new UpdateMemberFilters(this.filters));
    }
  }

  private updateSearch() {
    this.updateFirstName();
    this.updateLastName();
    this.updateExternalId();
    this.updateSearchFilter();
  }

  private updateFirstName() {
    const firstNameFilter = this.filters.find(f =>
      !Array.isArray(f.request) && (f.request.type === BooleanQueryType.FIRST_NAME)
    )
    if (firstNameFilter) {
      this.searchFilterBy.firstName = (firstNameFilter.request as FirstNameRequest).firstName;
    }
  }

  private updateLastName() {
    const lastNameFilter = this.filters.find(f =>
      !Array.isArray(f.request) && (f.request.type === BooleanQueryType.LAST_NAME)
    )
    if (lastNameFilter) {
      this.searchFilterBy.lastName = (lastNameFilter.request as LastNameRequest).lastName;
    }
  }

  private updateExternalId() {
    const externalIdFilter = this.filters.find(f =>
      !Array.isArray(f.request) && (f.request.type === BooleanQueryType.EXTERNAL_ID)
    )
    if (externalIdFilter) {
      this.searchFilterBy.id = (externalIdFilter.request as ExternalIdRequest).externalId;
    }
  }
}
