/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */


import {
  CategoryHierarchyItem, Organization,
  PlaylistCardShort,
  ResourceHierarchyItem, ResourcePermission,
  ResourceSection, SideNavItem,
  SideNavItemIcon, SideNavSection
} from '../../../../../../shared/models';
import { ActivatedRoute } from '@angular/router';
import {
  STATIC_NAV_OPTIONS_SECTION
} from '../../../../../../shared/side-nav/side-nav-static-options';
import { Store } from '@ngxs/store';
import { cloneDeep } from 'lodash-es';
import { UserAuthState } from '../../../../../../user-auth/store/user-auth.state';
import { BreadcrumbsState } from '../../../../../../shared/breadcrumbs/store/breadcrumbs.state';
import { CategoryItem } from '../../../../../../shared/models/page/page.model';
import { PageState } from '../../../../../pages/store/pages.state';
import { SideNavState } from '../../../../../../shared/side-nav/store/side-nav.state';
import { developmentDomains } from '../../../../../../shared/helpers/development-domains.helper';
import { UserRole } from '../../../../../../user-auth/models';
import { ContentHelper } from '../../../../../../shared/helpers/content-helper';
import { RedirectHelper } from './redirect.helper';

export class SideBarHelpers {
  static getSidebarCardsList(
    cards: PlaylistCardShort[],
    cardUid: string,
    resources: ResourceSection[],
    isGroupCards: boolean,
    route: ActivatedRoute,
    store: Store,
    playlistTitle: string,
    playlistFormattedUri: string,
    homePageTitle: string,
    excludedUrls?: string[]
  ) {
    const hierarchy = store.selectSnapshot(BreadcrumbsState.resourceHierarchy);
    const playlistUri = route.snapshot.paramMap.get('playlistUri');
    const sidebarSection = STATIC_NAV_OPTIONS_SECTION;
    this.setOrganizationNameInSidebar(sidebarSection, store, homePageTitle);
    return [
      ...this.filterUrls(sidebarSection, store, excludedUrls),
      {
        displayBottomLineSeparator: true,
      },
      {
        title: hierarchy?.length ? hierarchy[0].breadcrumb.label : playlistTitle,
        url: hierarchy?.length ? hierarchy[0].breadcrumb.url :
          RedirectHelper.getRedirectUrl(route, {
            playlistUri: playlistUri,
            formattedUri: playlistFormattedUri,
          }, 'PLAYLIST'),
        sideNavSectionType: 'CARD',
        navItems: this.generateItems(
          cards,
          resources?.length > 1 ? resources : [],
          isGroupCards,
          hierarchy,
          route,
          cardUid),
      },
    ];
  }

  static getPageTitle(existingSection: ResourceSection, index: number) {
    for (const content of existingSection.dynamicContent) {
      if (content.type === 'PARAGRAPH') {
        const text = content.content.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, '');
        if (text !== '') {
          return text;
        }
      }
    }
    return 'Page ' + (index + 1);
  }

  static getCategoriesHierarchy(
    store: Store,
    categories: CategoryItem[],
    pageUrl: string,
    isV2: boolean,
    result: SideNavItem[] = [],
    hierarchyLevel = 0,
    hierarchy?: CategoryHierarchyItem[],
  ): SideNavItem[] {
    if (!hierarchy) {
      hierarchy = store.selectSnapshot(BreadcrumbsState.categoryHierarchy);

      if (!hierarchy) {
        hierarchy = [];
      }
    }
    const discoveryPageUri = ContentHelper.getOrgDiscoveryUrl();
    if (pageUrl === discoveryPageUri) {
      pageUrl = (store.selectSnapshot(SideNavState.isDiscoveryPage) && isV2) ? `/v2${discoveryPageUri}` : discoveryPageUri;
    }
    if (hierarchyLevel < hierarchy.length) {
      let nextUri: string;
      let nextFrameworkId: string;
      let nextTagId: string;

      if (hierarchyLevel + 1 < hierarchy.length) {
        nextUri = hierarchy[hierarchyLevel + 1].breadcrumb.queryParams?.uri;
        nextFrameworkId = hierarchy[hierarchyLevel + 1].breadcrumb.queryParams?.frameworkId;
        nextTagId = hierarchy[hierarchyLevel + 1].breadcrumb.queryParams?.tagId;
      } else {
        const currentPage = store.selectSnapshot(PageState.page);
        nextUri = currentPage.data?.uri;
        nextFrameworkId = currentPage.data?.frameworkId;
        nextTagId = currentPage.data?.tagId;
      }

      for (const category of hierarchy[hierarchyLevel].categories) {
        result.push({
          childMenuLevel: hierarchyLevel + 1,
          label: category.title,
          icon: 'no-icon' as SideNavItemIcon,
          url: pageUrl,
          queryParams: {
            uri: category.uri,
            frameworkId: category.frameworkId,
            tagId: category.tagId,
          },
        });

        if (nextUri === category.uri && nextFrameworkId === category.frameworkId && nextTagId === category.tagId) {
          this.getCategoriesHierarchy(
            store,
            categories,
            pageUrl,
            isV2,
            result,
            hierarchyLevel + 1,
            hierarchy);
        }
      }
    } else {
      for (const category of categories) {
        result.push({
          childMenuLevel: hierarchyLevel + 1,
          label: category.title,
          icon: 'no-icon' as SideNavItemIcon,
          url: pageUrl,
          queryParams: {
            uri: category.uri,
            frameworkId: category.frameworkId,
            tagId: category.tagId,
          },
        });
      }
    }

    return result;
  }

  static filterUrls(sidenavSections: SideNavSection[], store: Store, excludedStaticUrls?: string[]): SideNavSection[] {
    const resultingSections = cloneDeep(sidenavSections);
    const organizationDomain = store.selectSnapshot(UserAuthState.organizationDomain);
    if (excludedStaticUrls) {
      return resultingSections.filter(section => {
        const excludedUrl = excludedStaticUrls.find(url => section.url === url);
        if (excludedUrl) {
          section.hideIfEmpty = true;
          section.navItems = [];
        }
        return section;
      }).map(section => {
        return {
          ...section,
          navItems: section.navItems?.filter(item => {
            if (item.allowedRoles) {
              if (item.allowedRoles.includes('BETA') && developmentDomains(organizationDomain)) {
                return true;
              } else {
                return false;
              }
            }
            return !excludedStaticUrls.find(url => item.url === url);
          }),
        };
      });
    }

    return resultingSections;
  }

  static getCardUri(playlistUri: string, card: PlaylistCardShort, route: ActivatedRoute, ignoreGroupUri = false, pageIndex = 0): string {
    const groupUri = route.snapshot.paramMap.get('groupUri');
    return RedirectHelper.getRedirectUrl(route, {
      playlistUri: playlistUri,
      groupUri: groupUri,
      resourceUri: card.uri,
      formattedUri: card.formattedUri,
      pageNumberUri: pageIndex ? `/page/${pageIndex}` : ''
    }, card.type);
  }

  static getResourcePageUri(
    playlistUri: string,
    card: PlaylistCardShort,
    index: number,
    route: ActivatedRoute
  ): string {
    return this.getCardUri(playlistUri, card, route, false, index + 1);
  }

  static setOrganizationNameInSidebar(sideNavSections: SideNavSection[], store: Store, homePageTitle: string) {
    const organizationName = store.selectSnapshot(UserAuthState.organizationName);
    const firstSection = sideNavSections[0];
    if (firstSection.titleId === '') {
      firstSection.title = homePageTitle ? homePageTitle : (organizationName ? organizationName : 'Home');
    }
    return firstSection;
  }

  static setDiscoveryPageTitle(sideNavSections: SideNavSection[], organization: Organization, discoveryPageDefaultTitle: string) {
    for (const section of sideNavSections) {
      if (section.titleId === 'discovery') {
        section.title = organization.discoveryPage?.title ? organization.discoveryPage?.title : discoveryPageDefaultTitle;
      }
    }
  }

  static handleNextButtonClick(backClick: boolean) {
    const sideNav = document.querySelector('ptl-side-nav');
    const links = sideNav.querySelectorAll('.nav-section__item-link');
    let nextItemIndex = 0;
    for (let i = 0; i < links.length; i++) {
      const activeLink = links[i];
      if (activeLink.classList.contains('nav-section__item-link--active')) {
        nextItemIndex = i;
        break;
      }
    }

    if (backClick && links[nextItemIndex - 1]) {
      (links[nextItemIndex - 1] as HTMLElement).click();
      return;
    }
    if (links[nextItemIndex + 1]) {
      (links[nextItemIndex + 1] as HTMLElement).click();
    }
  }

  static excludeSectionsByPermissions(sections: SideNavSection[], permissions: ResourcePermission): SideNavSection[] {
    const newSections = cloneDeep(sections);

    return newSections.map(section => ({
      ...section,
      navItems: this.excludeSubSectionsByPermissions(section.navItems, permissions)?.filter(item => {
        if (item.allowedPermissions && !item.allowedPermissions.includes('canViewReviewerDashboard')) {
          const intersect = [];
          for (const allowedPermission of item.allowedPermissions) {
            if (permissions[allowedPermission]) {
              intersect.push(allowedPermission);
              break;
            }
          }
          return !!intersect.length;
        }
        return true;
      }),
    }));
  }

  static excludeSectionsByEmbeddedStatus(sections: SideNavSection[], embedded: boolean): SideNavSection[] {
    const newSections = cloneDeep(sections);

    return newSections.map(section => ({
      ...section,
      navItems: section.navItems?.filter(item => {
        if (embedded) {
          if (item.url === 'target-audience' || item.url === 'collaboration-settings') {
            return false;
          }
        }
        return true;
      }),
    }));
  }

  static excludeSubSectionsByPermissions(navItems: SideNavItem[], permissions: ResourcePermission): SideNavItem[] {
    return navItems?.map(navItem => ({
      ...navItem,
      subNavItems: navItem.subNavItems?.filter(item => {
        if (item.allowedPermissions && !item.allowedPermissions.includes('canViewReviewerDashboard')) {
          const intersect = [];
          for (const allowedPermission of item.allowedPermissions) {
            if (permissions[allowedPermission]) {
              intersect.push(allowedPermission);
              break;
            }
          }
          return !!intersect.length;
        }
        return true;
      }),
    }));
  }

  static excludeSectionsByRoles(sections: SideNavSection[], organization: Organization, roles: UserRole[]): SideNavSection[] {
    return sections.map(section => ({
      ...section,
      navItems: this.excludeSubSectionsByRoles(section.navItems, organization, roles)?.filter(item => {
        if (item.allowedRoles) {
          if (item.allowedRoles.includes('BETA') && developmentDomains(organization?.domain)) {
            return true;
          }
          const intersect = [];
          for (const allowedRole of item.allowedRoles) {
            if (roles.filter(role => allowedRole === role.type).length) {
              intersect.push(allowedRole);
              break;
            }
          }
          return !!intersect.length;
        }
        return true;
      }),
    }));
  }

  static excludeSubSectionsByRoles(navItems: SideNavItem[], organization: Organization, roles: UserRole[]): SideNavItem[] {
    return navItems?.map(navItem => ({
      ...navItem,
      subNavItems: navItem.subNavItems?.filter(item => {
        if (item.allowedRoles) {
          if (item.allowedRoles.includes('BETA') && developmentDomains(organization?.domain)) {
            return true;
          }
          const intersect = [];
          for (const allowedRole of item.allowedRoles) {
            if (roles.filter(role => allowedRole === role.type).length) {
              intersect.push(allowedRole);
              break;
            }
          }
          return !!intersect.length;
        }
        return true;
      }),
    }));
  }

  static excludeReviewerDashboard(sections: SideNavSection[], permissions: ResourcePermission, roles: UserRole[]): SideNavSection[] {
    return sections.map(section => ({
        ...section,
        navItems: section.navItems.filter(item => {
          if (item.allowedPermissions.includes('canViewReviewerDashboard') && !roles.map(role => role.type).includes('SUPER_ADMIN')) {
            return !!permissions.reviewerDashboardPermission?.accessForGroups?.length;
          } else {
            return true;
          }
        })
      })
    );
  }


  private static generateItems(
    cards: PlaylistCardShort[],
    resources: ResourceSection[],
    isGroupCards: boolean,
    hierarchy: ResourceHierarchyItem[],
    route: ActivatedRoute,
    cardUid: string,
    hierarchyLevel = 2,
    result: SideNavItem[] = []
  ): SideNavItem[] {

    if (!hierarchy) {
      hierarchy = [];
    }

    const playlistUri = route.snapshot.paramMap.get('playlistUri');
    const groupUri = route.snapshot.paramMap.get('groupUri');

    const currentPlaylistUrl = RedirectHelper.getRedirectUrl(route, { playlistUri: playlistUri }, 'PLAYLIST');
    const currentGroupUrl = isGroupCards ?
      RedirectHelper.getRedirectUrl(route, { playlistUri: playlistUri, resourceUri: groupUri }, 'GROUP') : '';

    if (hierarchyLevel < hierarchy.length &&
      (isGroupCards || hierarchy[hierarchyLevel].breadcrumb.url !== currentPlaylistUrl)) {

      const childResourceUrl = (hierarchyLevel + 1 < hierarchy.length &&
        (isGroupCards || hierarchy[hierarchyLevel + 1].breadcrumb.url !== currentPlaylistUrl)) ?
        hierarchy[hierarchyLevel + 1].breadcrumb.url :
        (isGroupCards ? currentGroupUrl : currentPlaylistUrl);

      const hierarchyPlaylistUri = hierarchy[hierarchyLevel].breadcrumb.url.split('/')[2];
      const currentHierarchyLevelCards = hierarchy[hierarchyLevel].cards.filter((item, elemIndex) =>
        item.type !== 'PERSONALITY_INDICATOR' &&
        hierarchy[hierarchyLevel].cards.findIndex(duplicate =>
          duplicate._id === item._id &&
          duplicate.type === item.type) === elemIndex);

      for (const card of currentHierarchyLevelCards) {
        const childCardUrl = this.getCardUri(hierarchyPlaylistUri, card, route, true);
        result.push({
          childMenuLevel: hierarchyLevel,
          label: card.header,
          url: childCardUrl,
          icon: 'no-icon' as SideNavItemIcon,
          webLinkCardSummary: card.type === 'WEBLINK' ? card : undefined,
        });

        if (childResourceUrl === childCardUrl) {
          this.generateItems(
            cards,
            resources,
            isGroupCards,
            hierarchy,
            route,
            cardUid,
            hierarchyLevel + 1,
            result);
        }
      }
    } else {
      const items: SideNavItem[] = cards
        ? cards
          .filter((item, elemIndex) =>
            item.type !== 'PERSONALITY_INDICATOR' &&
            cards.findIndex(duplicate =>
              duplicate._id === item._id &&
              duplicate.type === item.type) === elemIndex)
          .map(card => ({
            childMenuLevel: hierarchyLevel,
            label: card.header,
            url: this.getResourcePageUri(playlistUri, card, 0, route),
            icon: 'no-icon' as SideNavItemIcon,
            webLinkCardSummary: card.type === 'WEBLINK' ? card : undefined,
            resourcePages: (cardUid === card._id && resources && card.type !== 'PLAYLIST')
              ? resources.map((existingSection, index) => ({
                label: this.getPageTitle(existingSection, index),
                url: this.getResourcePageUri(playlistUri, card, index, route),
                queryParams: card.type === 'ASSESSMENT' ? { navigate: 1 } : {},
              })) : [],
          })) : [];

      result.push(...items);
    }

    return result;
  }
}
