import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslationService } from '../../../services/translation/translation.service';
import {
  BooleanFilter,
  BooleanQueryRequest,
  BooleanQueryType,
  GroupsRequest,
  NotRequest
} from '../../../models/admin/boolean-filters.model';
import { UserGroups } from '../../../models/admin/group/user-groups.model';
import {
  USER_GROUPS_DATA_SERVICE,
  UserGroupsDataService
} from '../../../../page-modules/admin/services/groups/groups-data.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GroupSearchAutocompleteComponent } from '../../group-search-autocomplete/group-search-autocomplete.component';

@Component({
  selector: 'ptl-group-filter',
  templateUrl: './group-filter.component.html',
  styleUrls: ['./group-filter.component.scss']
})
export class GroupFilterComponent implements OnInit, OnDestroy {

  @Input() selectedFilters: BooleanFilter[];
  @Input() playlistUid: string;
  @Output() filterDeleted = new EventEmitter<void>();
  @Output() filterSaved = new EventEmitter<BooleanFilter>();

  @ViewChild('groupSearchAutocomplete') private groupSearchAutocomplete: GroupSearchAutocompleteComponent;

  activeGroups: UserGroups[] = [];
  foundGroups: UserGroups[] = [];
  expanded = true;
  groupIs = true;

  private groupTerm = '';
  private searchInputSubject$ = new Subject<void>();
  private searchInputSubscription: Subscription;
  loadingGroups = false;
  private page = 0;


  constructor(
    @Inject(USER_GROUPS_DATA_SERVICE) private userGroupsService: UserGroupsDataService,
    private translationService: TranslationService
  ) {
    this.searchInputSubscription = this.searchInputSubject$
      .pipe(debounceTime(500)).subscribe(() => this.fireSearch(true));
  }

  ngOnInit() {
    this.getSettingsFromFilters();
  }

  ngOnDestroy() {
    this.searchInputSubscription?.unsubscribe();
  }

  setGroupIs() {
    this.groupIs = true;
  }

  setGroupIsNot() {
    this.groupIs = false;
  }


  deleteFilter() {
    this.filterDeleted.emit();
  }

  applyFilter() {
    const request = this.getRequest(this.activeGroups.map(group => group._id));
    const message = this.getMessage(this.activeGroups.map(group => group.title));
    this.filterSaved.emit({ request: request, message: message, type: 'GROUP' });
  }

  private getRequest(groups: string[]): BooleanQueryRequest {
    const request: GroupsRequest = { type: BooleanQueryType.GROUPS, groups:  groups}
    if (this.groupIs) {
      return request as BooleanQueryRequest;
    } else {
      return { type: BooleanQueryType.NOT, arg: request } as BooleanQueryRequest;
    }
  }

  private getMessage(labels: string[]): string {
    if (this.groupIs) {
      return this.translationService.getTranslation('reviews.filter.group') + ' '
        + this.translationService.getTranslation('reviews.filter.is') + ' '
        + labels.join(' ' + this.translationService.getTranslation('reviews.filter.or') + ' ');
    } else {
      return this.translationService.getTranslation('reviews.filter.group') + ' '
        + this.translationService.getTranslation('reviews.filter.isNot') + ' '
        + labels.join(' ' + this.translationService.getTranslation('reviews.filter.andNot') + ' ');
    }
  }

  private getSettingsFromFilters() {
    if (this.selectedFilters) {
      const statusFilter = this.selectedFilters.find(f => f.type === 'GROUP');
      if (statusFilter) {
        const booleanRequest = (statusFilter.request as BooleanQueryRequest);
        let groups: string[] = [];
        if (booleanRequest.type === BooleanQueryType.NOT) {
          this.groupIs = false;
          groups = ((booleanRequest as NotRequest).arg as GroupsRequest).groups;
        } else {
          this.groupIs = true;
          groups = (booleanRequest as GroupsRequest).groups;
        }
        groups.forEach(group =>
          this.userGroupsService.loadUserGroup(group, this.playlistUid).subscribe(({isSuccess, value}) => {
            if(isSuccess) {
              this.activeGroups.push(value);
            }
          })
        )
      }
    }
  }

  onSearchInputChange(term: string) {
    this.groupTerm = term;
    this.loadingGroups = true;
    this.searchInputSubject$.next();
  }

  onGroupSelected(group: UserGroups) {
    if (!this.activeGroups.includes(group)) {
      this.activeGroups.push(group);
    }
  }

  onGroupRemoved(i: number) {
    this.activeGroups = this.activeGroups.filter((_, index) => index !== i);
  }

  onGroupScroll() {
    this.fireSearch(false);
  }

  private fireSearch(override: boolean) {
    this.page = override ? 0 : this.page + 1;
    this.loadingGroups = true;
    if (!this.groupTerm) {
      this.loadingGroups = false;
      return;
    }
    this.userGroupsService.searchUserGroups(this.page, 10, this.groupTerm, this.playlistUid)
      .subscribe(({ isSuccess, value }) => {
      this.loadingGroups = false;
      if (isSuccess) {
        if (override) {
          this.foundGroups = value.content;
        } else {
          this.foundGroups = this.foundGroups.concat(value.content);
        }
        this.groupSearchAutocomplete.canLoadMore = value.totalNumberOfElement > this.foundGroups.length;
        this.groupSearchAutocomplete.isLoadingMore = false;
      }
    });
  }
}

