<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="achievements-section__badges-container" #content (scroll)="onBadgesContainerScroll($event)">
  <div class="achievements-section__badges" *ngIf="cards?.length">
    <mat-card
      *ngFor="let card of cards"
      class="achievement-badge"
      [class.pointer]="canOpenPublicBadge"
      role="article"
      tabindex="0"
      (click)="canOpenPublicBadge ? onBadgeClicked(card.userBadgeUid) : null"
      (keydown.enter)="canOpenPublicBadge ? onBadgeClicked(card.userBadgeUid) : null"
      [attr.aria-label]="getTitle(card.title)"
      [ngClass]="{'show-only-first-row' : showOnlyFirstRow}">
      <div class="achievement-badge__card-center">
        <div class="achievement-badge__card-center-bg" [style.background-color]="card.color"></div>
        <div
          class="achievement-badge__upload"
          [ngStyle]="{'background-image': card.imageUrl ? 'url(&quot;' +  card.imageUrl + '&quot;)' : ''}">
        </div>
        <span
          class="icon-container"
          [ngStyle]="{'background-image': card.orgLogo ? 'url(&quot;' +  card.orgLogo + '&quot;)' : ''}">
       </span>
      </div>
      <div class="achievement-badge__card-footer">
        <div class="achievement-badge__card-text title"
             role="heading"
             aria-level="2"
             [attr.aria-label]="getTitle(card.title)">
          {{getTitle(card.title)}}</div>
        <br>
        <span
          class="achievement-badge__card-text description"
          role="note"
          [attr.aria-label]="'translations.global.ariaLabels.awardedBy' | transloco">
          {{ 'translations.playlist.goals.label.awardedBy' | transloco }}</span>
        <div class="achievement-badge__card-text description bold"
             role="note"
             [attr.aria-label]="card.issuedBy">
          {{ card.issuedBy }}</div>
        <span
          class="achievement-badge__card-text description"
          role="note"
          [attr.aria-label]="getLocalDateTime(card.issuedOn)  | date: 'd MMMM y, HH:mm'">
          {{ getLocalDateTime(card.issuedOn)  | date: 'd MMMM y, HH:mm'}}</span>
      </div>
    </mat-card>
  </div>
</div>

<div class="row-header__button" *ngIf="!editMode">
  <button mat-flat-button class="see-less-btn" *ngIf="loadedMore" (click)="onLoadLess()">
    {{ 'translations.global.button.seeLess' | transloco }}
  </button>
  <button
    mat-flat-button
    class="see-more-btn"
    *ngIf="(badges && cards?.length < badges?.length) || canLoadMore"
    (click)="onShowMore()">
    <mat-spinner *ngIf="loadingMore" [diameter]="20"></mat-spinner>
    <span class="see-more-btn-title">{{ 'translations.global.button.seeMore' | transloco }}</span>
  </button>
</div>
