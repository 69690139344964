<!--
  ~ Copyright (C) 2018 - present by Potentially
  ~
  ~ Please see distribution for license.
  -->

<div class="side-nav__section-container {{ sideNavSection?.className }}">
  <h3
    class="side-nav__section-title"
    [class.edit-mode]="sideNavSection?.editMode"
    [class.cursor]="sideNavSection?.expandable && sideNavSection?.hideExpandIcon"
    *ngIf="sideNavSection?.title && !sideNavSection?.url"
    (click)="sideNavSection?.expandable ? onExpandToggle() : null"
    (keydown.enter)="sideNavSection?.expandable ? onExpandToggle() : null">
    <i
      *ngIf="sideNavSection?.expandable && !sideNavSection?.hideExpandIcon"
      class="material-icons side-nav__section-expand-btn"
      tabindex="0"
      [attr.aria-label]="(sideNavSection?.expanded ? ('translations.global.ariaLabels.collapse' | transloco: {title: sideNavSection.title}) : ('translations.global.ariaLabels.expand' | transloco: {title: sideNavSection.title}))"
      [ogAppTooltip]="sideNavSection?.expanded ? 'Hide' : 'Show'">{{sideNavSection?.expanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}}
    </i>
    <span
      class="title-block"
      [attr.aria-label]="('translations.global.ariaLabels.section' | transloco: {title: sideNavSection.title} )"
    >{{ sideNavSection.title }}</span>
  </h3>
  <div class="side-nav__section-link-box" *ngIf="sideNavSection?.title && sideNavSection?.url">
    <a
      class="side-nav__section-link"
      [routerLink]="sideNavSection.url"
      [ngStyle]="{ 'padding-left': languageDirection === 'ltr' ? getMainMenuPadding(sideNavSection.menuLevel) : '',
        'padding-right': languageDirection === 'rtl' ? getMainMenuPadding(sideNavSection.menuLevel) : '' }"
      [class.nav-section__item-link--active]="isActiveOrExpanded(sideNavSection.url, sideNavSection?.parentPath)"
      [attr.aria-label]="sideNavSection.icon && sideNavSection.icon.name ? ('translations.global.ariaLabels.open' | transloco: {title: sideNavSection.title}) : sideNavSection.title"
      [ogAppTooltip]="sideNavSection.title"
      (click)="onTitleLinkClick(sideNavSection.url, sideNavSection?.shouldToggle);">
      <i
        class="material-icons item__icon" aria-hidden="true"
        *ngIf="sideNavSection.icon && sideNavSection.icon.name"
        [ngClass]="sideNavSection.icon.color ? 'item__icon--' + sideNavSection.icon.color : 'no-icon'">
        {{ sideNavSection.icon.name }}
      </i>
      <i
        class="{{sideNavSection.icon.class}} item__icon" aria-hidden="true"
        *ngIf="sideNavSection.icon && sideNavSection.icon.class"
        [ngClass]="sideNavSection.icon.color ? 'item__icon--' + sideNavSection.icon.color : 'no-icon'">
        <span *ngFor="let extraClass of sideNavSection.icon.extraClasses" [ngClass]="extraClass"></span>
      </i>
      <span
        class="link"
        [class.edit-mode]="sideNavSection?.editMode">
      {{ sideNavSection.title }}
    </span>
    </a>
  </div>
  <ng-container *ngIf="sideNavSection && sideNavSection?.navItems?.length">
    <ul
      class="nav-section"
      role="list"
      [class.edit-mode]="sideNavSection?.editMode"
      *ngIf="!sideNavSection?.expandable || sideNavSection?.expanded">
      <li class="nav-section__item" *ngFor="let navItem of sideNavSection.navItems" role="listitem">
        <ng-container *ogConditionalBetaFeature="navItem.isBetaFeature">
          <ng-container *ngIf="navItem && navItem.url; else withoutUrl">
              <a
                *ngIf="navItem.webLinkCardSummary"
                class="nav-section__item-link nav-section__item-level-{{ navItem.childMenuLevel }} {{ navItem.class }}"
                [ogAppTooltip]="navItem.label"
                [ngStyle]="{ 'padding-left': languageDirection === 'ltr' ? getPadding(navItem.childMenuLevel) : '',
                'padding-right': languageDirection === 'rtl' ? getPadding(navItem.childMenuLevel) : '' }"
                [class.nav-section__item-link--active]="isActive(navItem.url, navItem.queryParams)"
                [attr.aria-label]="navItem.childMenuLevel > 0 ? 'child ' + navItem.label : navItem.label"
                [href]="navItem.url"
                (click)="$event.preventDefault(); $event.stopPropagation(); onWebLinkOpen(navItem.webLinkCardSummary);"
              >
                <i
                  class="material-icons item__icon" aria-hidden="true"
                  *ngIf="navItem.icon && navItem.icon.name"
                  [ngClass]="navItem.icon.color ? 'item__icon--' + navItem.icon.color : 'no-icon'">
                  {{ navItem.icon.name }}
                </i>
                <i
                  class="{{navItem.icon.class}} item__icon" aria-hidden="true"
                  *ngIf="navItem.icon && navItem.icon.class"
                  [ngClass]="navItem.icon.color ? 'item__icon--' + navItem.icon.color : 'no-icon'">
                  <span *ngFor="let extraClass of navItem.icon.extraClasses" [ngClass]="extraClass"></span>
                </i>
                <span>{{ navItem.label }}</span>
              </a>

              <a
                *ngIf="!navItem.webLinkCardSummary"
                role="button"
                class="nav-section__item-link nav-section__item-level-{{ navItem.childMenuLevel }} {{ navItem.class }}"
                routerLinkActive="{{ !navItem.preventRedirection ? 'nav-section__item-link--active' : null }}"
                [ogAppTooltip]="navItem.label"
                [ngStyle]="{ 'padding-left': languageDirection === 'ltr' ? getPadding(navItem.childMenuLevel) : '',
                'padding-right': languageDirection === 'rtl' ? getPadding(navItem.childMenuLevel) : '' }"
                [class.nav-section__item-link--active]="isActive(navItem.url, navItem.queryParams, navItem.childHighlight)"
                [class.content-loading]="navItem.loading"
                [routerLink]="!navItem.preventRedirection ? navItem.url : null"
                tabindex="0"
                [attr.aria-label]="navItem.childMenuLevel > 0 ? 'child ' + navItem.label : navItem.label"
                [queryParams]="navItem.queryParams"
                [routerLinkActiveOptions]="{exact: true}"
                (click)="navItem.subNavItems ? onLinkWithSubChildClick(navItem.url, navItem) : onLinkClick(navItem.url)">
                <i
                  class="material-icons item__icon" aria-hidden="true"
                  *ngIf="navItem.icon && navItem.icon.name"
                  [ngClass]="navItem.icon.color ? 'item__icon--' + navItem.icon.color : 'no-icon'">
                  {{ navItem.icon.name }}
                </i>
                <i
                  class="{{navItem.icon.class}} item__icon" aria-hidden="true"
                  *ngIf="navItem.icon && navItem.icon.class"
                  [ngClass]="navItem.icon.color ? 'item__icon--' + navItem.icon.color : 'no-icon'">
                  <span *ngFor="let extraClass of navItem.icon.extraClasses" [ngClass]="extraClass"></span>
                </i>
                <div class="nav-section-link-container">
                  <span>{{ navItem.label }}</span>
                </div>
                <mat-spinner
                  class="nav-section__loader"
                  *ngIf="navItem.loading"
                  [diameter]="20">
                </mat-spinner>
              </a>
            </ng-container>
          <ul class="nav-section__resource" *ngIf="navItem.resourcePages && navItem.resourcePages.length" role="list">
            <li class="nav-section__resource-item" *ngFor="let resourcePage of navItem.resourcePages" role="listitem">
              <a class="nav-section__item-link-resource nav-section__item-resource-hover"
                 [ngStyle]="{ 'padding-left': languageDirection === 'ltr' ? getPadding(navItem.childMenuLevel) : '',
                'padding-right': languageDirection === 'rtl' ? getPadding(navItem.childMenuLevel) : '' }"
                 [routerLink]="resourcePage.url"
                 [class.nav-section__item-link--active-resource]="isResourcePageActive(resourcePage.url)"
                 [queryParams]="resourcePage.queryParams">
                <span>{{ resourcePage.label }}</span>
              </a>
            </li>
          </ul>

          <ul
            class="nav-section"
            role="list"
            [class.edit-mode]="sideNavSection?.editMode"
            *ngIf="navItem?.subNavItems?.length && !navItem?.childExpandable || navItem?.childExpanded">
            <li class="nav-section__item" *ngFor="let subNavItem of navItem.subNavItems"  role="listitem">
              <a
                class="nav-section__item-link nav-section__item-level-{{ subNavItem.childMenuLevel }} {{ subNavItem.class }}"
                routerLinkActive="{{ !subNavItem.preventRedirection ? 'nav-section__item-link--active' : null }}"
                [ogAppTooltip]="subNavItem.label"
                [ngStyle]="{ 'padding-left': languageDirection === 'ltr' ? getPadding(subNavItem.childMenuLevel) : '',
                'padding-right': languageDirection === 'rtl' ? getPadding(subNavItem.childMenuLevel) : '' }"
                [class.nav-section__item-link--active]="isActive(subNavItem.url, subNavItem.queryParams)"
                [class.content-loading]="subNavItem.loading"
                [routerLink]="!subNavItem.preventRedirection ? subNavItem.url : null"
                [attr.aria-label]="subNavItem.childMenuLevel > 0 ? 'child ' + subNavItem.label : subNavItem.label"
                [queryParams]="subNavItem.queryParams"
                [routerLinkActiveOptions]="{exact: true}"
                (click)="onLinkClick(subNavItem.url)">
                <i
                  class="material-icons item__icon" aria-hidden="true"
                  *ngIf="subNavItem.icon && subNavItem.icon.name"
                  [ngClass]="subNavItem.icon.color ? 'item__icon--' + subNavItem.icon.color : 'no-icon'">
                  {{ subNavItem.icon.name }}
                </i>
                <i
                  class="{{subNavItem.icon.class}} item__icon" aria-hidden="true"
                  *ngIf="subNavItem.icon && subNavItem.icon.class"
                  [ngClass]="subNavItem.icon.color ? 'item__icon--' + subNavItem.icon.color : 'no-icon'">
                  <span *ngFor="let subExtraClass of subNavItem.icon.extraClasses" [ngClass]="subExtraClass"></span>
                </i>
                <span>{{ subNavItem.label }}</span>
              </a>
            </li>
          </ul>

          <ng-template #withoutUrl>
            <div
              class="nav-section__item-link"
              [ngStyle]="{ 'padding-left': languageDirection === 'ltr' ? ((navItem.childMenuLevel ? navItem.childMenuLevel : 0) + (((sideNavSection?.expandable ? 2 : 1)) * 20) / 16) + 'rem' : '',
                'padding-right': languageDirection === 'rtl' ? ((navItem.childMenuLevel ? navItem.childMenuLevel : 0) + (((sideNavSection?.expandable ? 2 : 1)) * 20) / 16) + 'rem' : '' }"
              [class.content-loading]="navItem.loading">
              <i
                class="material-icons item__icon" aria-hidden="true"
                *ngIf="navItem.icon && navItem.icon.name"
                [ngClass]="navItem.icon.color ? 'item__icon--' + navItem.icon.color : 'no-icon'">
                {{ navItem.icon.name }}
              </i>
              <i
                class="{{navItem.icon.class}} item__icon" aria-hidden="true"
                *ngIf="navItem.icon && navItem.icon.class"
                [ngClass]="navItem.icon.color ? 'item__icon--' + navItem.icon.color : 'no-icon'">
                <span *ngFor="let extraClass of navItem.icon.extraClasses" [ngClass]="extraClass"></span>
              </i>
              <span>{{ navItem.label }}</span>
              <mat-spinner
                class="nav-section__loader"
                *ngIf="navItem.loading"
                [diameter]="20">
              </mat-spinner>
            </div>

          </ng-template>
        </ng-container>
      </li>
      <li
        role="listitem"
        class="nav-section__item nav-section__empty-placeholder"
        [style.paddingLeft]="sideNavSection?.expandable ? '3.063rem' : '1.813rem'"
        *ngIf="sideNavSection?.emptyPlaceholder && !sideNavSection?.navItems.length">
        <span class="placeholder-text">{{ sideNavSection.emptyPlaceholder }}</span>
      </li>
      <li
        role="listitem"
        class="nav-section__item nav-section__load-more"
        [style.paddingLeft]="sideNavSection?.expandable ? '3.063rem' : '1.813rem'"
        *ngIf="sideNavSection?.notAllLoaded">
        <button
          class="nav-section__load-more-btn"
          [attr.aria-label]="('translations.global.ariaLabels.loadMore' | transloco)"
          (click)="onLoadMore()">
          <span>{{ 'translations.sideNav.button.more' | transloco }}</span>
          <i class="material-icons" aria-hidden="true">keyboard_arrow_down</i>
        </button>
      </li>
    </ul>
  </ng-container>
  <div class="divider" *ngIf="sideNavSection?.displayBottomLineSeparator"></div>
</div>

