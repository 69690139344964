import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  NgZone, OnChanges,
  OnDestroy,
  OnInit, SimpleChanges
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddFromMomentsDialogComponent } from './add-from-moments-dialog/add-from-moments-dialog.component';
import { CollectorFormContent, EditorContent, Form, Organization, UserPlaylistSubmissionSummary } from '../../../../models';
import { Select, Store } from '@ngxs/store';
import { COLLECTOR_DATA_SERVICE, CollectorDataService } from '../../../../services/collector/collector-data.service';
import {
  CollectorConfig,
  LearnerCollectorSummary,
  LearnerLoggedItem,
  LearnerLoggedItemRequest,
  LearnerLoggedItemRequestTimeLogPeriod
} from '../../../../models/editor/collector-content.model';
import { UserAuthState } from '../../../../../user-auth/store/user-auth.state';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { RefreshLearnerViewCardStatus } from '../../../../../page-modules/resource/store/learner-view.actions';
import { formatDate } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogService } from '../../../../helpers/dialog/dialog.service';
import { SnackbarHelper } from '../../../../helpers/snackbar-helper';
import { TranslationService } from '../../../../services/translation/translation.service';
import { LanguageCodeHelper } from '../../../../helpers/language-code-helper';
import { LearnerViewState } from '../../../../../page-modules/resource/store/learner-view.state';
import { takeUntil, take, filter } from 'rxjs/operators';
import * as PlaylistViewActions from '../../../../../page-modules/playlist/store/view/playlist-view.state.actions';
import { PlaylistViewState } from '../../../../../page-modules/playlist/store/view/playlist-view.state';
import { LocalTimeHelper } from '../../../../helpers/local-time-helper';

@Component({
  selector: 'ptl-collector-preview',
  templateUrl: './form-collector-preview.component.html',
  styleUrls: ['./form-collector-preview.component.scss'],
})
export class FormCollectorPreviewComponent implements OnInit, OnChanges, OnDestroy {

  @Input() formData: Form;
  @Input() userPlaylistSubmissionSummary: UserPlaylistSubmissionSummary;
  @Input() playlistUri: string;
  @Input() resourceUri: string;
  @Input() groupUid: string;
  @Input() publisherUri: string;
  @Input() packageUri: string;
  @Input() pageUri: string;
  @Input() languageCode: string;

  learnerCollectorSummary: LearnerCollectorSummary;
  title: string;
  instruction: EditorContent[] = [];
  expanded = false;
  organization: Organization;
  currentLanguage: string;

  @Select(UserAuthState.organizationDetails)
  private organization$: Observable<Organization>;

  @Select(LearnerViewState.currentLanguage)
  private currentLanguage$: Observable<string>;

  @Select(PlaylistViewState.playlistId)
  private playlistId$: Observable<string>;

  requiredTable = {
    totalCount: undefined,
    completedCount: undefined,
    remainingCount: undefined,
    type: undefined,
  };

  private collectorId: string;
  private playlistUid: string;
  private playlistIdSubscribtion: Subscription;
  private subscriptionEnd$ = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    private store: Store,
    private snackBar: MatSnackBar,
    @Inject(COLLECTOR_DATA_SERVICE) private collectorDataService: CollectorDataService,
    private translationService: TranslationService,
    private cd: ChangeDetectorRef,
    private ngZone: NgZone,
    private dialogService: DialogService
  ) {
  }

  ngOnInit() {
    this.organization$.pipe(take(1)).subscribe(org => this.organization = org);
    this.playlistIdSubscribtion = this.playlistId$
      .pipe(filter(playlistId => !!playlistId))
      .subscribe((playlistId: string) => {
        this.playlistUid = playlistId
        if (this.formData && this.formData.content) {
          const collectorContent = this.formData.content as CollectorFormContent;
          this.collectorId = collectorContent.collectorId;
          this.getCollectorSummary();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.userPlaylistSubmissionSummary && changes.userPlaylistSubmissionSummary.currentValue) {
      this.checkSubmissionMode();
    }
  }

  ngOnDestroy() {
    this.playlistIdSubscribtion?.unsubscribe();
    this.subscriptionEnd$.next();
  }

  addEditLogItem(loggedItem?: LearnerLoggedItem) {
    const dialogRef = this.dialog.open(AddFromMomentsDialogComponent, {
      width: '75rem',
      maxHeight: '80vh',
      position: {
        top: '10vh',
      },
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      panelClass: 'ptl-mat-dialog',
      backdropClass: 'dialog-backdrop',
      data: {
        config: this.learnerCollectorSummary.config,
        summary: this.learnerCollectorSummary,
        loggedItem: loggedItem ? loggedItem : undefined,
      },
    });

    dialogRef.afterClosed().subscribe((data: LearnerLoggedItemRequest) => {
      if (data) {
        if (loggedItem) {
          this.updateExistingLogItem(data, loggedItem);
        } else {
          this.addNewLogItem(data);
        }
      }
    });
  }

  editLoggedItem(loggedItem: LearnerLoggedItem) {
    this.addEditLogItem(loggedItem);
  }

  openDeleteLogItemPopUp(loggedItem: LearnerLoggedItem) {
    this.dialogService.showConfirmDialog(
      this.translationService.getTranslation('dialog.title.removeLog'),
      this.translationService
    ).then(confirmed => {
      if (confirmed) {
        this.deleteLoggedItem(loggedItem);
      }
    });
  }

  deleteLoggedItem(loggedItem: LearnerLoggedItem) {
    this.collectorDataService.deleteLogItem(this.learnerCollectorSummary.learnerCollectorUid, loggedItem.uid)
      .subscribe(({ isSuccess }) => {
        if (isSuccess) {
          this.learnerCollectorSummary.loggedItems = this.learnerCollectorSummary.loggedItems.filter(item => item.uid !== loggedItem.uid);
          this.updateLearnerCollectorSummary();
        }
      });
  }

  readyToSubmit() {
    this.dialogService.showConfirmDialog(
      this.translationService.getTranslation('dialog.title.confirmFormSubmit'),
      this.translationService
    ).then(confirmed => {
      if (confirmed) {
        this.learnerCollectorSummary.isReadyToSubmit = false;
        this.checkSubmissionMode();
        this.collectorDataService.submitLearnerCollectorSummary(this.playlistUid, this.learnerCollectorSummary.learnerCollectorUid)
          .subscribe(({ isSuccess }) => {
              this.learnerCollectorSummary.isReadyToSubmit = true;
              if (isSuccess) {
                this.expanded = false;
                this.learnerCollectorSummary.status = 'COMPLETED';
                this.cd.detectChanges();
                timer(1000).subscribe(() => {
                  this.store.dispatch(new PlaylistViewActions.RefreshUserPlaylistSubmissionSummary(this.playlistUid));
                  this.store.dispatch(
                    new RefreshLearnerViewCardStatus(
                      this.playlistUri,
                      this.resourceUri,
                      this.groupUid,
                      this.publisherUri,
                      this.packageUri,
                      this.pageUri,
                      this.languageCode,
                    )
                  );
                });
              }
            }
          );
      }
    });
  }

  expandCollectorInfo(newValue: boolean) {
    this.expanded = newValue;
  }

  getLocalDateTime(date: Date): string {
    return LocalTimeHelper.getLocalDateTime(date).toString();
  }

  private getDateDisplayString(submittedTimeLogPeriod: LearnerLoggedItemRequestTimeLogPeriod) {
    let result = '-- to --';

    if (submittedTimeLogPeriod && submittedTimeLogPeriod.from && submittedTimeLogPeriod.to) {
      result = formatDate(submittedTimeLogPeriod.from, 'yyyy MMMM dd', 'en') + ' to ' +
        formatDate(submittedTimeLogPeriod.to, 'yyyy MMMM dd', 'en');
    }

    return result;
  }

  private addNewLogItem(data: LearnerLoggedItemRequest) {
    this.collectorDataService.addLogItem(this.learnerCollectorSummary.learnerCollectorUid, data)
      .subscribe(({ isSuccess, value, error }) => {
        if (isSuccess) {
          this.learnerCollectorSummary.loggedItems.push(this.getSummaryFormData(value));
          this.updateLearnerCollectorSummary();
        } else {
          SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
        }
      });
  }

  private updateExistingLogItem(data: LearnerLoggedItemRequest, loggedItem: LearnerLoggedItem) {
    this.collectorDataService.updateLogItem(this.learnerCollectorSummary.learnerCollectorUid, loggedItem.uid, data)
      .subscribe(({ isSuccess, value, error }) => {
        if (isSuccess) {
          this.learnerCollectorSummary.loggedItems = this.learnerCollectorSummary.loggedItems.map(item => {
            if (item.uid === loggedItem.uid) {
              return this.getSummaryFormData(value);
            }
            return item;
          });
          this.updateLearnerCollectorSummary();
        } else {
          SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
        }
      });
  }

  private getCollectorSummary() {
    this.currentLanguage$.pipe(takeUntil(this.subscriptionEnd$)).subscribe(lang => {
      this.currentLanguage = lang;
      this.collectorDataService.getLearnerCollectorSummary(this.playlistUid, this.collectorId, this.currentLanguage)
        .subscribe(({ isSuccess, value }) => {
          if (isSuccess) {
            this.title = LanguageCodeHelper.getDataByUserLanguageCode(value.title, this.organization, this.currentLanguage).value;
            this.instruction = value.instructions;
            this.learnerCollectorSummary = this.getSummaryFormattedData(value);
            if (!this.learnerCollectorSummary.loggedItems.length) {
              this.expanded = true;
            }
            this.updateRequiredCountsData();
            this.checkSubmissionMode();
          }
        });
    });
  }

  private updateLearnerCollectorSummary() {
    this.collectorDataService.getLearnerCollectorSummary(this.playlistUid, this.collectorId, this.currentLanguage)
      .subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          this.learnerCollectorSummary = this.getSummaryFormattedData(value);
          this.updateRequiredCountsData();
          if (this.learnerCollectorSummary.status === 'IN_PROGRESS') {
            this.expanded = true;
          }
          this.cd.detectChanges();
        }
      });
  }

  private getSummaryFormattedData(data: LearnerCollectorSummary) {
    for (const logItem of data.loggedItems) {

      if (logItem.submittedTimeLogPeriod) {
        logItem.submittedTimeLogPeriod.formattedDate = this.getDateDisplayString(logItem.submittedTimeLogPeriod);
      }
    }
    return data;
  }

  private getSummaryFormData(loggedItem: LearnerLoggedItem) {

    if (loggedItem.submittedTimeLogPeriod) {
      loggedItem.submittedTimeLogPeriod.formattedDate = this.getDateDisplayString(loggedItem.submittedTimeLogPeriod);
    }

    return loggedItem;
  }

  private updateRequiredCountsData() {
    const collectorConfig = this.learnerCollectorSummary.config;
    const logType = collectorConfig.type;
    const completedCount = logType === 'TIME_LOG' ? this.completedHours() : this.completedCounts();
    const remainingCount = logType === 'TIME_LOG' ? this.remainingHours(collectorConfig, completedCount) :
      this.remainingMoments(collectorConfig, completedCount);

    this.requiredTable = {
      totalCount: collectorConfig.requiredNumberOfLogs,
      completedCount: completedCount,
      remainingCount: remainingCount,
      type: logType,
    };
  }

  private completedHours(): number {
    let completedHours = 0;
    for (const item of this.learnerCollectorSummary.loggedItems) {
      if (item.submittedTimeLogPeriod) {
        completedHours += (item.submittedTimeLogPeriod.time ? item.submittedTimeLogPeriod.time : 0);
      }
    }
    return completedHours;
  }

  private completedCounts(): number {
    let completedCounts = 0;
    for (const item of this.learnerCollectorSummary.loggedItems) {
      if (item.moments && item.moments.length) {
        completedCounts += item.moments.length;
      }
      if (item.answers && item.answers.length) {
        completedCounts++;
      }
    }
    return completedCounts;
  }

  private remainingHours(collectorConfig: CollectorConfig, completedHours: number): number {
    if (completedHours) {
      const hours = collectorConfig.requiredNumberOfLogs - completedHours;
      return hours < 0 ? 0 : hours;
    }
    return collectorConfig.requiredNumberOfLogs ? collectorConfig.requiredNumberOfLogs : 0;
  }

  private remainingMoments(collectorConfig: CollectorConfig, completedCount: number): number {
    if (completedCount) {
      const count = collectorConfig.requiredNumberOfLogs - completedCount;
      return count < 0 ? 0 : count;
    }
    return collectorConfig.requiredNumberOfLogs ? collectorConfig.requiredNumberOfLogs : 0;
  }

  private checkSubmissionMode(): void {
    this.cd.detectChanges();
  }

}
