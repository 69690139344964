<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div
  class="form-preview"
  tabindex="0" [attr.aria-label]="('translations.global.ariaLabels.checkboxForm' | transloco)"
  [class.form-preview--saved]="submitted">
  <div class="form-preview__top">
    <div class="form-preview__top-left">
      <div class="form-preview__top-icon">
        <mat-icon aria-hidden="true">{{ submitted ? 'lock' : 'create' }}</mat-icon>
      </div>
      <div class="form-preview__top-title" tabindex="0">
        <div class="title">{{ formData?.content?.title }}</div>
      </div>
    </div>
    <div class="form-preview__top-right">
      <mat-checkbox color="primary" [aria-label]="('translations.global.ariaLabels.checkForm' | transloco)" [(ngModel)]="isChecked" (change)="onCheckboxChange()"></mat-checkbox>
      <div class="printable" [class.checked]="isChecked"></div>
    </div>
  </div>
  <div class="form-preview-notes" *ngIf="formData?.content?.notes?.length">
    <div class="note-item" *ngFor="let note of formData?.content?.notes" tabindex="0">
      <div class="note-added-by">
        <span class="note-label">{{ 'translations.cards.review.label.note' | transloco }}</span> {{note.addedBy}}, {{ getLocalDateTime(note.addedOn) | date: 'dd MMM yyyy' }}
      </div>
      <div class="note-message">
        <ptl-resource-preview class="thin-preview" [previewContent]="note.message">
        </ptl-resource-preview>
      </div>
    </div>
  </div>
  <div class="form-preview__footer" *ngIf="!submitted">
    <button
      mat-flat-button
      class="form-preview__footer-save uppercase"
      title="Submit form"
      type="button"
      (click)="submit()"
      [disabled]="isSubmissionDisabled">
      <span class="no-pointer">{{ 'translations.global.button.submit' | transloco }}</span>
    </button>
  </div>
</div>
