/*
 * Copyright (C) 2020 - Potentially Ltd
 *
 * Please see distribution for license.
 */


import { SidenavSettings, SidenavTreeSettingUpdatedItemOptions } from '../../models';

export class SaveSidenavSettings {
  static readonly type = '[Sidenav] Save sidenav settings';
  constructor(public sidenavSettings: SidenavSettings) {}
}

export class EnableDiscoveryPage {
  static readonly type = '[Sidenav] Enable Discovery page';
}

export class DisableDiscoveryPage {
  static readonly type = '[Sidenav] Disable Discovery page';
}

export class EnableContentStorePage {
  static readonly type = '[Sidenav] Enable Content store page';
}

export class DisableContentStorePage {
  static readonly type = '[Sidenav] Disable Content store page';
}

export class EnableFolioPage {
  static readonly type = '[Sidenav] Enable Folio page';
}

export class DisableFolioPage {
  static readonly type = '[Sidenav] Disable Folio page';
}

export class ShowHideSidebarTree {
  static readonly type = '[Sidenav] Show/Hide sidebar tree';

  constructor(public show: boolean) {
  }
}

export class ShowHideSidebarWorkspaceNav {
  static readonly type = '[Sidenav] Show/Hide sidebar workspace nav';

  constructor(public show: boolean) {
  }
}

export class ResetSidebarTree {
  static readonly type = '[Sidenav] Reset sidebar tree';

  constructor(public reset: boolean) {
  }
}

export class UpdateSidebarItemData {
  static readonly type = '[Sidenav] Update sidebar item data';

  constructor(
    public uid: string,
    public updatedOptions: SidenavTreeSettingUpdatedItemOptions,
  ) {
  }
}

export class ResetSidebarItemUpdateData {
  static readonly type = '[Sidenav] Reset sidebar item update data';
}

export class FindItemInSidebarTree {
  static readonly type = '[Sidenav] Find item in sidebar tree';

  constructor(public uid: string) {
  }
}

export class ResetFindItemInSidebarTree {
  static readonly type = '[Sidenav] Reset find item in sidebar tree';

}

export class UpdateSidebarItemChildren {
  static readonly type = '[Sidenav] Update sidebar item children';

  constructor(public uid: string, public updateParent: boolean) {
  }
}

export class ResetSidebarItemChildrenUpdate {
  static readonly type = '[Sidenav] Reset sidebar item children update';
}


export class RemoveSidebarViewStackAfterId {
  static readonly type = '[Sidenav] Remove item uid from stack view';

  constructor(public uid: string) {
  }
}

export class ResetRemoveSidebarViewStackAfterId {
  static readonly type = '[Sidenav] Reset Remove item uid from stack view';

}
