import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserSearch } from '../../../../../../../shared/models/admin/members.model';
import { QuestionFormRootList, Title } from 'src/app/shared/models/lists/list.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { of, switchMap } from 'rxjs';
import { Result } from '../../../../../../../shared/store';
import { ReviewSettingsDetails } from '../../../../../../playlist/models';
import { REVIEWS_DATA_SERVICE, ReviewsDataService } from '../../../../../services/data.service';
import {
  SHARED_LISTS_DATA_SERVICE,
  SharedListsDataService
} from '../../../../../../../shared/services/lists/lists-data.service';


@Component({
  selector: 'ptl-close-reviews-modal-component',
  templateUrl: './close-reviews-modal.component.html',
  styleUrls: ['./close-reviews-modal.component.scss']
})
export class CloseReviewsModalComponent implements OnInit {
  loadingMembers: boolean;
  members: UserSearch[];
  acceptSelected = false;
  reasonList: QuestionFormRootList[];
  allowFreeText: boolean;
  reasonFormGroup: FormGroup;
  reasonListUid: string;
  selectedReasonText: Title[];

  constructor(
    @Inject(REVIEWS_DATA_SERVICE) private reviewsDataService: ReviewsDataService,
    @Inject(SHARED_LISTS_DATA_SERVICE) private sharedListsDataService: SharedListsDataService,
    @Inject(MAT_DIALOG_DATA) public data: {
      playlistSubmissionMode?:string;
      playlistId?: string;
    },
    private dialogRef: MatDialogRef<CloseReviewsModalComponent>,
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {
    const data = this.dialogRef?.componentInstance?.data;

    if (data) {
      this.getPlaylistSettings(data.playlistId);
    }
  }

  getPlaylistSettings(playlistId: string) {
    this.reviewsDataService.getReviewSettingsDetail(playlistId)
      .pipe(
        switchMap((res: Result<ReviewSettingsDetails, string, string[]>) => {
          this.allowFreeText = res?.value?.reviewCloseReasonSettings?.allowFreeText;
          this.reasonListUid = res?.value?.reviewCloseReasonSettings?.reasonListUid;

          if (this.reasonListUid) {
            return this.sharedListsDataService.getFormListByOptionUid(this.reasonListUid);
          }

          return of({ isSuccess: true, value: '' });
        })
      )
      .subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          if (typeof value !== 'string') {
            this.reasonList = (value.root.sublist as unknown as QuestionFormRootList[]);
          }

          this.initializeForm(this.reasonListUid);
        }
      });
  }

  getSelectedReasonUid(reason: QuestionFormRootList) {
    const listItemUidControl = this.reasonFormGroup.get('reason.listItemUid');
    listItemUidControl.setValue(reason.uid);
    this.selectedReasonText = reason.value;
  }

  onConfirmButton() {
    this.dialogRef.close({
      confirmed: true,
      withAcceptance: this.acceptSelected,
      reviewCloseReasonSettings: this.reasonFormGroup.value
    });
  }

  closeDialog() {
    this.dialogRef.close({ confirmed: false });
  }

  getFreeText(event: Event) {
    const input = event.target as HTMLInputElement;
    const freeTextControl = this.reasonFormGroup.get('reason.freeText');
    freeTextControl.setValue(input.value);
  }

  private initializeForm(reasonListUid: string | null) {
    this.reasonFormGroup = this.formBuilder.group({
      reviewUids: ['', Validators.required],
      reason: this.formBuilder.group({
        listUid: [reasonListUid, Validators.required],
        listItemUid: ['', Validators.required],
        freeText: ['', Validators.required]
      })
    });
  }
}
