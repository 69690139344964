<div class="member-filter__controls">
  <div class="member-filter__controls__filler"></div>
  <button
    mat-button
    class="member-filter__controls__cancel"
    (click)="onCancel()"
    (keydown.enter)="onCancel()"
    [attr.aria-label]="('translations.global.ariaLabels.cancel' | transloco)">
    {{'translations.global.button.cancel' | transloco}}
  </button>
  <button
    mat-stroked-button
    class="member-filter__controls__save"
    (click)="onSave()"
    (keydown.enter)="onSave()"
    [attr.aria-label]="('translations.global.ariaLabels.apply' | transloco)">
    {{'translations.global.button.apply' | transloco }}
  </button>
</div>
