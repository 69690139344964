<div class="word-limit-dialog">
  <header class="header separator">
    <h3>{{ 'translations.cards.messages.error.textExceedsLimit' | transloco }}</h3>
  </header>

  <p class="exceed-text">{{ data.aiData }}</p>

  <footer class="footer">
    <div class="controls">
      <button
        (click)="cancel()" class="mat-button-base" mat-stroked-button>Cancel
      </button>
      <button
        (click)="replace()"
        class="mat-button-base mat-focus-indicator mat-flat-button mat-primary" mat-stroked-button>
        Replace
      </button>
    </div>
  </footer>
</div>


