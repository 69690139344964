<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div
  class="form-preview"
  tabindex="0" [attr.aria-label]="('translations.global.ariaLabels.textBoxForm' | transloco)"
  [class.form-preview--active]="inputFocused"
  [class.form-preview--saved]="submitted">
  <div class="form-preview__top">
    <div class="form-preview__top-icon">
      <mat-icon aria-hidden="true">{{ submitted ? 'lock' : 'create' }}</mat-icon>
    </div>
    <div class="form-preview__top-title" tabindex="0">
      <div class="title">{{ formData?.content?.title }}</div>
      <div class="form-preview__top-limits">
        {{ 'translations.wordLimit' | transloco }} {{ formData?.content?.wordLimit }}
      </div>
    </div>
  </div>
  <div class="form-preview__input">
    <ptl-tiny-medium-editor
      *ngIf="!submitted"
      tabindex="0"
      [wordLimit]="formData?.content?.wordLimit"
      [content]="existingUserAnswer"
      [inputType]="formData?.content?.answerType"
      (inputFocus)="onInputFocus()"
      (inputBlur)="deactivateForm()"
      (textLength)="onTextLengthUpdate($event)"
      (mediumEditorUpdate)="onMediumEditorUpdate($event)"
    ></ptl-tiny-medium-editor>
    <ptl-resource-html-preview *ngIf="submitted" [content]="submittedAnswerContent"></ptl-resource-html-preview>
  </div>
  <div class="form-preview-notes" *ngIf="formData?.content?.notes?.length">
    <div class="note-item" *ngFor="let note of formData?.content?.notes" tabindex="0">
      <div class="note-added-by">
        <span class="note-label"> {{ 'translations.cards.review.label.note' | transloco }}</span>
        {{note.addedBy}}, {{getLocalDateTime(note.addedOn) | date: 'dd MMM yyyy' }}
      </div>
      <div class="note-message">
        <ptl-resource-preview class="thin-preview" [previewContent]="note.message">
        </ptl-resource-preview>
      </div>
    </div>
  </div>
  <div class="form-preview__footer" *ngIf="!submitted">
    <div class="form-preview__footer-limit">{{ updateWords() }}</div>

    <div class="form-preview__footer-buttons">
      <span class="autosave-label saving" *ngIf="autoSaveInProgress">{{ 'translations.global.label.autoSaving' | transloco }}</span>
      <span class="autosave-label" *ngIf="autoSaved">{{ 'translations.global.label.autoSaved' | transloco }}</span>

      <button
        mat-flat-button
        class="form-preview__footer-submit uppercase"
        title="Submit form"
        type="button"
        (click)="submit()"
        *ngIf="!hideTextFormButtons"
        [disabled]="isSubmissionDisabled">
        <span class="no-pointer">{{ 'translations.global.button.submit' | transloco }}</span>
      </button>
    </div>
  </div>
</div>
