/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input, OnChanges } from '@angular/core';
import { DiagnosticQuestionType, Organization } from '../../../../../../../../shared/models';
import { LanguageCodeHelper } from '../../../../../../../../shared/helpers/language-code-helper';
import { QuizReportOption } from '../../../../../../models';

@Component({
  selector: 'ptl-app-summary-report',
  templateUrl: './summary-report.component.html',
  styleUrls: ['./summary-report.component.scss'],
})
export class SummaryReportComponent implements  OnChanges {

  @Input() answers: QuizReportOption[];
  @Input() questionType: DiagnosticQuestionType;
  @Input() currentLanguage: string;
  @Input() organization: Organization;

  answersToDisplay: QuizReportOption[];

  ngOnChanges() {
    if (this.questionType && this.answers) {
      this.answersToDisplay = [ ...this.answers ];

      if (this.questionType === 'DRAGDROP_QUESTION') {
        this.answersToDisplay.sort((a, b) => a.userScore - b.userScore);
      }
    }
  }

  getTranslatedValue(instructions) {
    return LanguageCodeHelper.getDataByUserLanguageCode(instructions, this.organization, this.currentLanguage).value;
  }

}
