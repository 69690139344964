/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import {
  EditorContent,
  MediaContent,
  QuizFormContent,
  Form,
  EmbedCode
} from '../../models';
import {
  FILE_UPLOAD_DEFAULT_SIZE, FILE_UPLOAD_SUPPORTED_TYPES,
  TEXT_FORM_WORD_LIMIT
} from '../../../shared/constants/constants';
import { TranslationService } from '../../../shared/services/translation/translation.service';

@Component({
  selector: 'ptl-editor-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class EditorToolbarComponent implements OnInit {

  /** Enables url toolbar button */
  @Input() link = false;
  /** Enables upload toolbar button */
  @Input() upload = false;
  /** Enables form toolbar button */
  @Input() form = false;
  /** Enables embed code toolbar button */
  @Input() embed = false;
  /** Enables text form toolbar button */
  @Input() textFormOnly = false;
  /** Enables separator toolbar button */
  @Input() separator = false;
  /** Enables quiz toolbar button */
  @Input() quiz = false;
  /** Enables chart toolbar button */
  @Input() chart = false;
  /** Enables insert learnerCollectorSummary toolbar button */
  @Input() collector = false;

  /** Emits data on new content from child components */
  @Output() contentCreated = new EventEmitter<EditorContent>();
  /** Emits data on new regular link insertion from child components */
  @Output() regularLinkCreated = new EventEmitter<string>();
  /** Emits data on toolbar open */
  @Output() toolbarOpened = new EventEmitter<boolean>();

  toolbarVisible = false;
  activeToolbarItem = 'ALL';
  showInteractiveContent = false;
  showMediaContent = false;
  subItemsExpanded = false;
  windowWidth = window.innerWidth;

  constructor(
    private translationService: TranslationService,
  ) {}


  @HostListener('window:resize', ['$event'])
  onResize( event ) {
    this.windowWidth = event.target.innerWidth;
  }

  ngOnInit() {
    if ( this.windowWidth > 900 ) {
      this.showMediaContent = true;
    }
  }

  toggleToolbar() {
    this.toolbarVisible = !this.toolbarVisible;
    this.toolbarOpened.emit(this.toolbarVisible);
    this.subItemsExpanded = false;
    if ( this.windowWidth <= 900 ) {
      this.showMediaContent = false;
    }
    this.showInteractiveContent = false;

    if ( this.activeToolbarItem !== 'ALL' ) {
      this.activeToolbarItem = 'ALL';
    }
  }

  onShowInteractiveContent() {
    this.showInteractiveContent = !this.showInteractiveContent;
    this.subItemsExpanded = true;
    if ( this.windowWidth <= 900 ) {
      this.showMediaContent = false;
    }
  }

  onShowMediaContent() {
    this.showMediaContent = !this.showMediaContent;
    this.subItemsExpanded = true;
    if ( this.windowWidth <= 900 ) {
      this.showInteractiveContent = false;
    }
  }

  onMouseEnter() {
    document.getElementById('toolbarButton')?.classList.add('is_toolbar-over');
  }

  onMouseLeave() {
    document.getElementById('toolbarButton')?.classList.remove('is_toolbar-over');
  }

  onMediaContentAdded( mediaContentData: MediaContent ) {
    this.toggleToolbar();
    this.contentCreated.emit(mediaContentData);
  }

  onRegularLinkAdded( link: string ) {
    this.toggleToolbar();
    this.regularLinkCreated.emit(link);
  }

  selectToolbarItem( buttonType: string ) {
    if ( this.activeToolbarItem === 'ALL' ) {
      this.activeToolbarItem = buttonType;
    }
  }

  addEmptyForm() {
    this.toggleToolbar();
    this.contentCreated.emit({
      type: 'FORM',
      newAddedForm: true,
      content: {
        type: 'TEXTBOX',
        title: this.translationService.getTranslation('editor.toolbarNew.option.defaultFormTitle'),
        wordLimit: TEXT_FORM_WORD_LIMIT,
        answerType: 'RICH'
      },
    } as EditorContent);
  }

  addUploadForm() {
    this.toggleToolbar();
    this.contentCreated.emit({
      type: 'FORM',
      newAddedForm: true,
      content: {
        type: 'USER_FILE_UPLOAD',
        title: this.translationService.getTranslation('editor.toolbarNew.option.defaultFormTitle'),
        maxFileSizeMb: FILE_UPLOAD_DEFAULT_SIZE,
        supportedFileTypes: FILE_UPLOAD_SUPPORTED_TYPES
      },
    } as EditorContent);
  }

  addCheckboxForm() {
    this.toggleToolbar();
    this.contentCreated.emit({
      type: 'FORM',
      newAddedForm: true,
      content: {
        type: 'CHECKBOX',
        title: this.translationService.getTranslation('editor.toolbarNew.option.defaultFormTitle')
      },
    } as EditorContent);
  }


  onAddSeparator() {
    const separator = '<div class="separator-container"><hr class="separator"/></div>';
    this.toggleToolbar();
    this.contentCreated.emit({ type: 'SEPARATOR', content: separator } as EditorContent);
  }

  onAddQuiz() {
    this.toggleToolbar();
    const quizContent = {
      type: 'FORM',
      newAddedForm: true,
      content: {
        type: 'QUIZ',
        title: this.translationService.getTranslation('editor.toolbarNew.option.quizForm.defaultTitle')
      },
    } as Form<QuizFormContent>;
    this.contentCreated.emit(quizContent);
  }

  onAddChart() {
    this.toggleToolbar();
    this.contentCreated.emit({ type: 'CHART' } as EditorContent);
  }

  onInsertCollector() {
    this.toggleToolbar();
    this.contentCreated.emit({
      type: 'FORM',
      newAddedForm: true,
      content: {
        type: 'COLLECTOR',
        title: this.translationService.getTranslation('editor.toolbarNew.option.collectorForm.defaultTitle')
      },
    } as EditorContent);
  }

  onInsertEmbedCode() {
    this.toggleToolbar();
    this.contentCreated.emit({ type: 'EMBED_CODE', code: '' } as EmbedCode);
  }

  setToolbarHidden() {
    this.toolbarVisible = false;
    this.toolbarOpened.emit(this.toolbarVisible);
  }
}
