/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  ApplicationRef, ComponentFactoryResolver, ComponentRef,
  Directive,
  ElementRef, EmbeddedViewRef, EventEmitter,
  Injector,
  Input,
  OnChanges, Output,
  SimpleChanges
} from '@angular/core';
import { MomentCard } from '../../page-modules/folio/models';
import {
  MasonryCardComponent
} from '../../page-modules/folio/components/view/shared/masonry-section/masonry-card/masonry-card.component';
import {
  MasonryTextCardComponent
} from '../../page-modules/folio/components/view/shared/masonry-section/masonry-text-card/masonry-text-card.component';
import { VersionHelper } from '../helpers/version.helper';
import {
  MasonryCardNewComponent
} from '../../page-modules/folio/components/view/shared/masonry-section/mansory-card-new/masonry-card-new.component';
/* eslint-disable @typescript-eslint/no-explicit-any */

declare const Bricklayer;


@Directive({
  selector: '[ogMasonry]',
})
export class MasonryDirective implements OnChanges {
  /** Card data. */
  @Input() cards: MomentCard[] = [];
  @Input() removedItemsCount: number;
  @Input() archiveMoment: boolean;
  @Input() shouldAnimateOnLoadMore: boolean;
  @Input() disabled = false;
  @Input() uid: string;
  @Input() editMode = false;
  @Input() isFolioSection = false;

  @Output() removeCard = new EventEmitter<string>()

  private brickLayer: any;

  constructor(
    private el: ElementRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector,
  ) {
  }

  private initBrick() {
    this.brickLayer = new Bricklayer(this.el.nativeElement);
    this.appendCards(this.cards, false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.brickLayer && this.cards) {
      // using timeout so the elements are placed in the DOM first during route changes
      setTimeout(() => this.initBrick());
    }

    if (!changes.cards.firstChange) {
      // If new card added but length the same
      if (this.isFolioSection && changes.cards.previousValue.length === changes.cards.currentValue.length
        && changes.cards.previousValue.length > 0) {
        const isFirstItemChanged = !this.areCardsEqual(changes.cards.previousValue[0], changes.cards.currentValue[0]);

        if (isFirstItemChanged) {
          setTimeout(() => this.initBrick());
        }
      } else if (changes.cards.currentValue.length > changes.cards.previousValue.length) {
        // if cards were added, append to masonry
        const newMomentsToAppend = changes.cards.currentValue.filter(card =>
          !changes.cards.previousValue.map(c => c._id).includes(card._id));
        this.appendCards(newMomentsToAppend, this.shouldAnimateOnLoadMore);
        // if cards were removed, remove from masonry
      } else {
        if (this.removedItemsCount && this.removedItemsCount === 1) {
          if (changes.cards.previousValue.length > changes.cards.currentValue.length) {
            const momentsToRemove = changes.cards.previousValue.filter(card =>
              !changes.cards.currentValue.map(c => c._id).includes(card._id));

            momentsToRemove.forEach(moment => this.removeSingleMoment(changes.cards.previousValue, moment));
          }
        } else {
          if (changes.cards.previousValue.length >= changes.cards.currentValue.length) {
            this.removeBulkMoments(changes.cards.previousValue);
          }
        }
      }
    }
  }

  appendCards(cards: MomentCard[], animation: boolean) {
    cards.forEach((card, index) => {
      const momentCardHTML = this.getMomentCardHTML(card);

      if (animation) {
        setTimeout(() => this.brickLayer.append(momentCardHTML), index * 100);
      } else {
        this.brickLayer.append(momentCardHTML);
      }
    });
  }

  private areCardsEqual(card1: MomentCard, card2: MomentCard): boolean {
    return card1?._id === card2?._id;
  }

  private getMomentCardHTML(momentData: MomentCard): HTMLElement {
    // select text or normal moment component
    let component;

    if (VersionHelper.newVersionEnabled()) {
      component = MasonryCardNewComponent
    } else {
      component = momentData.textOnly ? MasonryTextCardComponent : MasonryCardComponent;
    }

    // create a moment card component reference
    const momentCardRef: ComponentRef<any> = this.componentFactoryResolver
      .resolveComponentFactory(component as any)
      .create(this.injector);

    // attach component to the appRef so that it will be dirty checked
    this.applicationRef.attachView(momentCardRef.hostView);

    // we need to get access to Input() of injected component data
    const instance = momentCardRef.instance;
    (instance as MasonryCardComponent | MasonryTextCardComponent).card = momentData;
    (instance as MasonryCardComponent | MasonryTextCardComponent).card.isDisabled = this.disabled;
    (instance as MasonryCardComponent | MasonryTextCardComponent).archiveMoment = this.archiveMoment;
    (instance as MasonryCardComponent | MasonryTextCardComponent).removeCard = this.removeCard;

    if (VersionHelper.newVersionEnabled()) {
      (instance as MasonryCardNewComponent).uid = this.uid;
      (instance as MasonryCardNewComponent).editMode = this.editMode;
      (instance as MasonryCardNewComponent).isFolioSection = this.isFolioSection;
    }

    // return DOM element from component
    return (momentCardRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
  }

  private removeSingleMoment(moments: MomentCard[], momentToRemove: MomentCard) {
    const indexToRemove = moments.indexOf(momentToRemove);
    this.brickLayer.elements.splice(indexToRemove, 1);
    this.brickLayer.redraw();
  }


  private removeBulkMoments(moments: MomentCard[]) {
    if (this.brickLayer) {
      const removeCount = this.removedItemsCount ? this.removedItemsCount : 8;
      moments.length = moments.length - removeCount;
      this.brickLayer.elements.length = this.brickLayer.elements.length - removeCount;
      this.brickLayer.redraw();
    }
  }

}
