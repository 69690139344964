/*
 * Copyright (C) 2018 - present by Potentially
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Organization, PlaylistCardShort, SideNavItem, SideNavItemIconDetails, SideNavSection } from '../../models';
import { Params, Router } from '@angular/router';
import { TranslationService } from '../../services/translation/translation.service';
import { ContentHelper } from '../../helpers/content-helper';
import { UserAuthState } from '../../../user-auth/store/user-auth.state';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { LanguageCodeHelper } from '../../helpers/language-code-helper';

@Component({
  selector: 'ptl-side-nav-item',
  templateUrl: './side-nav-item.component.html',
  styleUrls: ['./side-nav-item.component.scss'],
})
export class SideNavItemComponent implements OnInit, OnDestroy {
  /** The side nav section to display. */
  @Input() sideNavSection: SideNavSection;

  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  private organizationDataSubscription: Subscription;

  @Output() itemLinkClick = new EventEmitter<string>();
  @Output() loadMore = new EventEmitter<void>();
  @Output() expandToggled = new EventEmitter<boolean>();
  @Output() openWebLink = new EventEmitter<PlaylistCardShort>();

  organizationDataAuthType: string
  languageDirection = LanguageCodeHelper.getBodyLanguageDir();

  /** handle correct toggle action. */
  private toggleTitleIsClicked: boolean;

  constructor(public router: Router, private translationService: TranslationService) {
  }

  ngOnInit() {
    if (this.sideNavSection) {
      this.organizationDataSubscription = this.organizationData$.subscribe(data => this.organizationDataAuthType = data.authType)
      this.translateSideNavSection(this.sideNavSection);
    }
  }

  ngOnDestroy() {
    this.organizationDataSubscription?.unsubscribe();
  }

  getPadding(childMenuLevel: number): string {
    const paddingUnit = this.sideNavSection?.expandable ? 20 : 10;
    return ((15 + ((childMenuLevel ? childMenuLevel : 0) + 2) * paddingUnit) / 16) + 'rem';
  }

  getMainMenuPadding(menuLevel: number): string {
    const paddingUnit = 20;
    return menuLevel ? ((15 + menuLevel * paddingUnit) / 16) + 'rem' : '';
  }

  isActive(url: string, params: Params, highlight?: boolean) {
    if (highlight) {
      return this.router.isActive(url, false);
    }
    const exact = this.sideNavSection.sideNavSectionType !== 'CARD' ||
      (this.sideNavSection.sideNavSectionType === 'CARD' && url?.split('/').length === 3);

    if (this.sideNavSection.sideNavSectionType !== 'CATEGORY') {
      return this.router.isActive(url, exact);
    } else {
      const routeQueryParams = this.router.parseUrl(this.router.url).queryParamMap;
      return params?.uri === routeQueryParams.get('uri') &&
        params?.frameworkId === routeQueryParams.get('frameworkId') &&
        params?.tagId === routeQueryParams.get('tagId');
    }
  }

  isActiveOrExpanded(url: string, parentPath: string) {
    let isActive = (this.router.url === url &&
      !this.router.url.includes(`/v2${ContentHelper.getOrgDiscoveryUrl()}`));

    if (this.sideNavSection.sideNavSectionType === 'CATEGORY') {
      const routeQueryParams = this.router.parseUrl(this.router.url).queryParamMap;
      isActive = !routeQueryParams.get('frameworkId') && this.router.url.startsWith(url);
    }

    if (!this.toggleTitleIsClicked) {
      this.sideNavSection.expanded = this.router.url.indexOf(parentPath) !== -1;
      this.toggleTitleIsClicked = false;
    }
    return isActive;
  }

  isResourcePageActive(url: string) {
    const pagePath = url?.split('?')[0];

    return this.router.isActive(pagePath, false);
  }

  onLinkClick(url: string) {
    if (this.sideNavSection.sideNavSectionType !== 'CATEGORY') {
      this.itemLinkClick.emit(url);
    }
  }


  onLinkWithSubChildClick(url: string, navItem) {
    navItem.childExpanded = !navItem.childExpanded;
    this.onLinkClick(url);
  }

  onWebLinkOpen(card: PlaylistCardShort) {
    this.openWebLink.emit(card);
  }

  onTitleLinkClick(url: string, shouldToggle: boolean) {
    if (shouldToggle) {
      this.sideNavSection.expanded = !this.sideNavSection.expanded;
    } else {
      this.sideNavSection.expanded = true;
    }
    this.toggleTitleIsClicked = true;
    this.itemLinkClick.emit(url);
  }

  onExpandToggle() {
    if (this.sideNavSection) {
      this.sideNavSection.expanded = !this.sideNavSection.expanded;
    }
    this.expandToggled.emit(this.sideNavSection.expanded);
  }

  onLoadMore() {
    this.loadMore.emit();
  }

  private translateSideNavSection(section: SideNavSection) {
    if (section.titleId && section.titleId !== 'discovery') {
      section.title = this.translationService.getSideNavSectionTranslation(section.titleId, section.title);
    }
    if (section.navItems) {
      section.navItems.map(item => {
        if (item.labelId) {
          item.label = this.translationService.getSideNavSectionTranslation(item.labelId, item.label);
        }
      });
    }
  }
}
