/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../store';
import { InjectionToken } from '@angular/core';
import { ReviewNoteUpdateRequest } from '../../models/admin/members.model';
import { Review } from '../../models/review/review.model';

/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
export const SHARED_REVIEW_DATA_SERVICE =
  new InjectionToken<SharedReviewDataService>('[resource] SharedReviewDataService');

/** The service handling tag CRUD operations. */
export interface SharedReviewDataService {

  reviewFormReSubmit(reviewId: string, cardUid: string, formUid: string): ObservableResult<void>;

  reviewApprove(reviewId: string, cardUid: string, itemId: string): ObservableResult<void>;

  unlockForm(cardUid: string, formId: string, learnerCardUid: string): ObservableResult<void>;

  reviewApproveAll(reviewId: string): ObservableResult<void>;

  reviewAddNote(reviewId: string, cardUid: string, itemUid: string, request: ReviewNoteUpdateRequest): ObservableResult<void>;

  fetchReview(reviewUid: string): ObservableResult<Review>;

  remindToSubmitForm(reviewUid: string, cardUid: string, formUid: string, message: string): ObservableResult<void>;
}
