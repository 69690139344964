import { Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  UserSearchAutocompleteComponent
} from '../../../../../../shared/components/user-search-autocomplete/user-search-autocomplete.component';
import { UserSearch } from '../../../../../../shared/models/admin/members.model';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MEMBERS_DATA_SERVICE, MembersDataService } from '../../../../../admin/services/members/members-data.service';
import { REVIEWS_DATA_SERVICE, ReviewsDataService } from '../../../../services/data.service';
import {
  TOAST_NOTIFICATION_SERVICE,
  ToastService
} from '../../../../../../shared/services/toast-notifications/toast-service';
import { TranslationService } from '../../../../../../shared/services/translation/translation.service';
import { ReviewerSummary } from '../../../../../../shared/models/review/review.model';

@Component({
  selector: 'ptl-admin-reviewers-management-component',
  templateUrl: './admin-reviewers-management.component.html',
  styleUrls: ['./admin-reviewers-management.component.scss']
})
export class AdminReviewersManagementComponent implements OnDestroy {
  @ViewChild('userSearchAutocomplete') private userSearchAutocomplete: UserSearchAutocompleteComponent
  loadingMembers: boolean;
  members: UserSearch[];
  reviewers: ReviewerSummary[];
  private page = 0;
  private size = 20;
  private searchInputSubject$ = new Subject<string>();
  private searchInputSubscription: Subscription;
  private assignmentSubscription: Subscription;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
                reviewUid: string;
              },
              @Inject(MEMBERS_DATA_SERVICE) private membersDataService: MembersDataService,
              @Inject(REVIEWS_DATA_SERVICE) private reviewsService: ReviewsDataService,
              private dialogRef: MatDialogRef<AdminReviewersManagementComponent>,
              @Inject(TOAST_NOTIFICATION_SERVICE) private toastService: ToastService,
              private translationService: TranslationService
  ) {
    this.searchInputSubscription = this.searchInputSubject$
      .pipe(debounceTime(500))
      .subscribe((searchValue) => this.fireSearch(searchValue, true));
    if (this.data !== null && this.data?.reviewUid !== null) {
      this.reviewsService.retrieveReviewers(this.data.reviewUid)
        .subscribe((result) => {
          this.reviewers = result.value
        })
    }
  }

  ngOnDestroy() {
    this.searchInputSubscription.unsubscribe();
  }

  onSearchLoadingMore(searchValue: string) {
    this.fireSearch(searchValue, false);
  }

  onSearchInputChange(searchValue: string) {
    this.searchInputSubject$.next(searchValue);
  }

  onMemberSelected(data: UserSearch) {
    this.reviewers.push({
      uid: data.uid,
      firstName: data.firstName,
      lastName: data.lastName,
      imageUrl: data.image
    });
  }

  onRemove(data: ReviewerSummary) {
    this.reviewers = this.reviewers.filter(reviewer => reviewer.uid !== data.uid);
  }

  onUpdateButton() {
    const request = { reviewerUids: this.reviewers.map(reviewer => reviewer.uid) }
    this.assignmentSubscription = this.reviewsService
      .updateReviewers(this.data.reviewUid, request).subscribe(isSuccess => {
        if (isSuccess) {
          this.toastService.showSuccess(
            this.translationService.getTranslation('translations.reviews.adminActions.reviewersManagement.success')
          );
        } else {
          this.translationService.getTranslation('translations.reviews.adminActions.reviewersManagement.error')

        }
        this.dialogRef.close();
      });
  }

  private fireSearch(searchValue: string, override: boolean) {
    this.page = override ? 0 : this.page + 1;
    this.loadingMembers = true;
    if (!searchValue) {
      this.loadingMembers = false;
      return;
    }
    this.membersDataService.searchUsers(this.page, this.size, searchValue).subscribe(({ isSuccess, value }) => {
      if (isSuccess) {
        if (override) {
          this.members = value.content;
        } else {
          this.members = this.members.concat(value.content);
        }
      }
      this.userSearchAutocomplete.canLoadMore = value.totalNumberOfElement > this.members.length;
      this.userSearchAutocomplete.isLoadingMore = false;
      this.loadingMembers = false;
    });
  }


}
