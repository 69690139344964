import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ptl-word-limit-dialog',
  templateUrl: './word-limit-dialog.component.html',
  styleUrls: ['./word-limit-dialog.component.scss']
})
export class WordLimitDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<WordLimitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { aiData: string }
  ) {
  }

  cancel() {
    this.dialogRef.close(null);
  }

  replace() {
    this.dialogRef.close(this.data.aiData);
  }
}
