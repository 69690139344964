/*
 * Copyright (C) 2023 - present by Potentially
 *
 * Please see distribution for license.
 */

import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { CheckedMembersData, Members, MembersSearchOrder } from '@app/app/shared/models/admin/members.model';
import { AdminMembersAction, MembersListColumns } from '../model/members.model';
import { AdminMemberTableComponent } from './admin-member-table/admin-member-table.component';
import { ActivatedRoute, Router } from '@angular/router';
import { RedirectHelper } from '@app/app/page-modules/resource/store/editor/content/helpers/redirect.helper';

@Component({
  selector: 'ptl-admin-member-list-new',
  templateUrl: './admin-member-list-new.component.html',
  styleUrls: ['./admin-member-list-new.component.scss'],
})
export class AdminMemberListNewComponent implements OnDestroy {

  @Input() columns: MembersListColumns[];
  @Input() isPlaylistPage: boolean;
  @Input() isCardPage: boolean;
  @Input() isEvent: boolean;
  @Input() checkedMembers: CheckedMembersData[];
  @Input() members: Members[];
  @Input() membersTotalCount: number;
  @Input() isWaitingListEnabled: boolean;
  @Input() isWaitingListOperationDisabled: boolean;
  @Input() orderInput: MembersSearchOrder;
  @Input() paginationLength = 0;

  @Output() orderChanged = new EventEmitter<{ order: MembersSearchOrder; page: number; pageSize: number }>();
  @Output() membersChecked = new EventEmitter<CheckedMembersData[]>();
  @Output() memberActionTriggered = new EventEmitter<AdminMembersAction>();
  @Output() refreshList = new EventEmitter<number>();

  @ViewChild('defaultTable') defaultTable: AdminMemberTableComponent;

  currentPage = 0;
  pageSize = 10;
  isLoaderActive = false;

  private subscriptionEnd$ = new EventEmitter<void>();

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnDestroy(): void {
    this.subscriptionEnd$?.emit();
  }

  setIsLoaderActive(active: boolean) {
    this.isLoaderActive = active;
  }

  setPageSize(size: number) {
    this.pageSize = size;
  }

  setCurrentPage(page: number) {
    this.currentPage = page;
  }

  openUserRecord(userUid: string) {
    const url = this.router.createUrlTree([userUid, 'user-record'], {relativeTo: this.activatedRoute});

    if (this.isPlaylistPage || this.isCardPage) {
      RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, url.toString(), null);
    } else {
      window.open(url.toString(), '_blank');
    }
  }

  orderChangedTrigger(event) {
    this.orderChanged.emit(event);
  }

  membersCheckedTrigger(event) {
    this.membersChecked.emit(event);
  }

  memberActionTrigger(event) {
    this.memberActionTriggered.emit(event);
  }
}
