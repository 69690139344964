<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="control-panel-container">
  <div class="control-panel__description">
    <div class="title-users-count-box">
      <h2 class="title large"
          [attr.aria-label]="('translations.global.ariaLabels.usersPage' | transloco)">
        {{ 'translations.membersNew.controlPanel.usersLabel' | transloco }}
      </h2>
      <div class="users-count" *ngIf="!isIframeMode">
        <span *ngIf="totalMembersCount !== allMembersCount" class="large bold primary">
          {{totalMembersCount}} /
        </span>
        <span class="small">{{allMembersCount}} {{ 'translations.membersNew.controlPanel.users' | transloco }}</span>
      </div>
    </div>
    <div class="description-remaining-tickets-box">
      <span *ngIf="isEvent" class="description small">
        {{ 'translations.membersNew.controlPanel.pageDescriptionEvent' | transloco }}
      </span>
      <div *ngIf="(isCardPage || isPlaylistPage) && !isEvent" class="description">
        <span class="small">
          {{ 'translations.membersNew.controlPanel.pageDescriptionCardPlaylist.first' | transloco }}
        </span>
        <span class="small bold">
          {{ 'translations.membersNew.controlPanel.pageDescriptionCardPlaylist.second' | transloco }}
        </span>
        <span class="small">
          {{ 'translations.membersNew.controlPanel.pageDescriptionCardPlaylist.third' | transloco }}
        </span>
        <span class="small bold">
          {{ 'translations.membersNew.controlPanel.pageDescriptionCardPlaylist.fourth' | transloco }}
        </span>
        <span class="small">
          {{ 'translations.membersNew.controlPanel.pageDescriptionCardPlaylist.fifth' | transloco }}
        </span>
        <span class="small bold">
          {{ 'translations.membersNew.controlPanel.pageDescriptionCardPlaylist.sixth' | transloco }}
        </span>
        <span class="small">
          {{ 'translations.membersNew.controlPanel.pageDescriptionCardPlaylist.seventh' | transloco }}
        </span>
      </div>
      <span *ngIf="!(isCardPage || isPlaylistPage)" class="description small">
        {{ 'translations.membersNew.controlPanel.pageDescriptionAdminPage' | transloco }}
      </span>
      <div *ngIf="limitedTickets" class="remaining-tickets-count">
        <span class="large bold"
              [class]="remainingTicketsInfoClass">
          {{ getRemainingTickets() }} /
        </span>
        <span class="small">
          {{ 'translations.membersNew.controlPanel.ticketsLeft' | transloco:{ticketsQuantity: ticketsQuantity} }}
        </span>
      </div>
    </div>
  </div>

  <div class="control-panel__first-row">
    <ptl-name-filter class="name-filter" (nameChanged)="nameUpdated($event)"></ptl-name-filter>
    <mat-menu #exportMenu>
      <button
        mat-menu-item
        [attr.aria-label]="('translations.global.ariaLabels.exportMembersCsvFile' | transloco)"
        (click)="exportUsersButtonClicked()">
        <span class="small">{{ 'translations.members.button.exportCsv' | transloco }}</span>
      </button>
      <mat-divider class="export-divider"></mat-divider>
      <button
        mat-menu-item
        (click)="openAdvancedExport()">
        <span class="small">{{ 'translations.advancedExport.title.main' | transloco }}</span>
      </button>
    </mat-menu>
    <mat-menu #manageReviewersMenu>
      <button
        mat-menu-item
        [attr.aria-label]="('translations.global.ariaLabels.tapToCloseSubmissions' | transloco)"
        [class.is_disabled]="!checkedMembers.length"
        (click)="reviewAction(reviewActions.CLOSE)">
        <span
          class="small">{{ 'translations.reviews.submissionsControls.buttons.closeSelectedReviews' | transloco }}</span>
      </button>
      <mat-divider class="export-divider"></mat-divider>
      <button
        mat-menu-item
        [attr.aria-label]="('translations.global.ariaLabels.tapToReOpenSubmissions' | transloco)"
        [class.is_disabled]="!checkedMembers.length"
        (click)="reviewAction(reviewActions.REOPEN)">
        <span
          class="small">{{ 'translations.reviews.submissionsControls.buttons.reopenSelectedReviews' | transloco }}</span>
      </button>
    </mat-menu>

    <mat-menu #attendanceMenu class="attendance-menu">
      <button
        mat-menu-item
        class="attendance-menu-item"
        [attr.aria-label]="('translations.global.ariaLabels.willNotAttend' | transloco)"
        (click)="invokeEventAction('WILL_NOT_ATTEND')">
        <span class="small">{{ 'translations.membersNew.actionBar.willNotAttend' | transloco }}</span>
      </button>
      <mat-divider class="export-divider"></mat-divider>
      <button
        mat-menu-item
        class="attendance-menu-item"
        [attr.aria-label]="('translations.global.ariaLabels.willAttend' | transloco)"
        (click)="invokeEventAction('WILL_ATTEND')">
        <span class="small">{{ 'translations.membersNew.actionBar.willAttend' | transloco }}</span>
      </button>
      <mat-divider class="export-divider"></mat-divider>
      <button *ngIf="isWaitingListEnabled"
              mat-menu-item
              class="attendance-menu-item"
              [class.is_disabled]="isWaitingListOperationDisabled"
              [disabled]="isWaitingListOperationDisabled"
              [attr.aria-label]="('translations.global.ariaLabels.waitingList' | transloco)"
              (click)="invokeEventAction('WAITING_LIST')">
        <span class="small">{{ 'translations.membersNew.actionBar.waitingList' | transloco }}</span>
      </button>
      <mat-divider class="export-divider"></mat-divider>
      <button
        mat-menu-item
        class="attendance-menu-item"
        [attr.aria-label]="('translations.global.ariaLabels.haveAttended' | transloco)"
        (click)="invokeEventAction('HAVE_ATTENDED')">
        <span class="small">{{ 'translations.membersNew.actionBar.haveAttended' | transloco }}</span>
      </button>
    </mat-menu>
    <ptl-filter-btn [labels]="filterLabels" (filterSelected)="filterSelected($event)"></ptl-filter-btn>

    <button mat-stroked-button
            class="more-btn"
            type="button"
            *ngIf="!isIframeMode"
            [class.is-selected]="actionButtonsVisible"
            (click)="actionButtonsVisible = !actionButtonsVisible"
            [attr.aria-label]="('translations.global.ariaLabels.seeMoreActionButtons' | transloco)">
      <span class="more-btn-text small bold">{{  'translations.membersNew.actionBar.seeMore' | transloco }}</span>
    </button>
    <button
      *ngIf="exportEnabled && isIframeMode"
      mat-stroked-button
      class="export-btn"
      type="button"
      [attr.aria-label]="('translations.global.ariaLabels.exportUsers' | transloco)"
      (click)="exportUsersFilteredByGroup()">
      <div class="button-content">
        <svg class="svg" role="img" [attr.aria-label]="('translations.global.ariaLabels.exportUsersIcon' | transloco)">
          <use ogSvgUnify="assets/sprite/sprite.svg#download"></use>
        </svg>
        <span class="small bold">{{ 'translations.membersNew.controlPanel.export' | transloco }}</span>
      </div>
    </button>
  </div>

  <div class="control-panel-action-buttons" *ngIf="actionButtonsVisible">

    <ng-container *ngIf="isPlaylistPage && canManageReviews">
      <button
        mat-stroked-button
        [matMenuTriggerFor]="checkedMembers.length ? manageReviewersMenu : null"
        type="button"
        [class.is_disabled_tooltip]="!checkedMembers.length"
        matTooltipClass="medium-tooltip"
        [matTooltip]="checkedMembers.length ? '' : ('translations.membersNew.actionBar.manageReviewStatus' | transloco)"
        [attr.aria-label]="('translations.global.ariaLabels.manageReviewStatus' | transloco)">
        <span class="small bold">{{ 'translations.membersNew.actionBar.manageReviewStatus' | transloco }}</span>
      </button>
    </ng-container>

    <button mat-stroked-button *ngIf="isAdminPage"
            type="button"
            [class.is_disabled_tooltip]="!checkedMembers.length"
            matTooltipClass="medium-tooltip"
            [matTooltip]="checkedMembers.length > 0 ? '' : ('translations.membersNew.actionBar.noMemberSelect' | transloco)"
            (click)="openEditRoleDialog()"
            [attr.aria-label]="('translations.global.ariaLabels.editRole' | transloco)">
      <span class="small bold">{{ 'translations.membersNew.actionBar.editRole' | transloco }}</span>
    </button>
    <button
      mat-stroked-button
      type="button"
      *ngIf="!isIframeMode"
      [class.is_disabled_tooltip]="!checkedMembers.length"
      matTooltipClass="medium-tooltip"
      [matTooltip]="checkedMembers.length > 0 ? '' : ('translations.membersNew.actionBar.noMemberSelect' | transloco)"
      (click)="openEditGroupDialog()"
      [attr.aria-label]="('translations.global.ariaLabels.editGroup' | transloco)">
      <span class="small bold">{{ 'translations.membersNew.actionBar.editGroup' | transloco }}</span>
    </button>
    <button
      mat-stroked-button
      type="button"
      (click)="openSendMessageDialog()"
      [attr.aria-label]="('translations.global.ariaLabels.sendMessage' | transloco)">
      <div class="button-content">
        <svg class="svg" role="img" [attr.aria-label]="('translations.global.ariaLabels.icons.mail' | transloco)">
          <use ogSvgUnify="assets/sprite/sprite.svg#mail"></use>
        </svg>
        <span class="small bold">{{ 'translations.membersNew.controlPanel.message' | transloco }}</span>
      </div>
    </button>
    <button
      *ngIf="exportEnabled && !advancedExportAvailable"
      mat-stroked-button
      class="export-btn"
      type="button"
      [attr.aria-label]="('translations.global.ariaLabels.exportUsers' | transloco)"
      (click)="exportUsersButtonClicked()">
      <div class="button-content">
        <svg class="svg" role="img" [attr.aria-label]="('translations.global.ariaLabels.exportUsersIcon' | transloco)">
          <use ogSvgUnify="assets/sprite/sprite.svg#download"></use>
        </svg>
        <span class="small bold">{{ 'translations.membersNew.controlPanel.export' | transloco }}</span>
      </div>
    </button>
    <button
      *ngIf="exportEnabled && advancedExportAvailable"
      mat-stroked-button
      [matMenuTriggerFor]="exportMenu"
      [attr.aria-label]="('translations.global.ariaLabels.export' | transloco)"
      class="export-btn"
      type="button">
      <div class="button-content">
        <svg class="svg" role="img" [attr.aria-label]="('translations.global.ariaLabels.exportUsersIcon' | transloco)">
          <use ogSvgUnify="assets/sprite/sprite.svg#download"></use>
        </svg>
        <span class="small bold">{{ 'translations.membersNew.controlPanel.export' | transloco }}</span>
      </div>
    </button>
    <button
      *ngIf="isEvent || isCardPage || isPlaylistPage"
      mat-stroked-button
      class="import-users-btn"
      [class.is_disabled_tooltip]="(isCardPage && !cardPublished) || (isPlaylistPage && !playlistPublished)"
      matTooltipClass="medium-tooltip"
      [matTooltip]="importDisabledTooltipText"
      type="button"
      (click)="importUsersCsv()">
      <div class="button-content">
        <svg class="svg" role="img" [attr.aria-label]="('translations.global.ariaLabels.icons.upload' | transloco)">
          <use ogSvgUnify="assets/sprite/sprite.svg#upload"></use>
        </svg>
        <span class="small bold">{{ 'translations.membersNew.controlPanel.import.name' | transloco }}</span>
      </div>
    </button>
    <button
      *ngIf="isEvent"
      mat-stroked-button
      class="qr-code-btn"
      type="button"
      (click)="openCheckInDialog()">
      <div class="button-content">
        <svg class="svg" role="img" [attr.aria-label]="('translations.global.ariaLabels.icons.qrCode' | transloco)">
          <use ogSvgUnify="assets/sprite/sprite.svg#qr-code"></use>
        </svg>
        <span class="small bold">{{'translations.qrcode.checkIn' | transloco }}</span>
      </div>
    </button>
    <button
      *ngIf="!isCardPage && !isPlaylistPage && !isEvent && (canManageUserImportPrivileges$ | async) === true"
      mat-stroked-button
      class="upload-users-btn"
      [attr.aria-label]="('translations.global.ariaLabels.usersUpload' | transloco)"
      (click)="openUsersUploadCsv()"
      (keydown.enter)="openUsersUploadCsv()">
      <div class="button-content">
        <svg class="svg" role="img" [attr.aria-label]="('translations.global.ariaLabels.icons.upload' | transloco)">
          <use ogSvgUnify="assets/sprite/sprite.svg#upload"></use>
        </svg>
        <span class="small bold">{{ 'translations.membersNew.controlPanel.usersUpload.name' | transloco }}</span>
      </div>
    </button>
    <button
      *ngIf="!isCardPage && !isPlaylistPage && (organizationData$ | async)?.type !== 'FOLIO'"
      mat-stroked-button
      class="alumni-upload-btn"
      [attr.aria-label]="('translations.global.ariaLabels.userAlumniUpload' | transloco)"
      (click)="openAlumniUploadCsv()"
      (keydown.enter)="openAlumniUploadCsv()">
      <div class="button-content">
        <svg class="svg" role="img" [attr.aria-label]="('translations.global.ariaLabels.icons.upload' | transloco)">
          <use ogSvgUnify="assets/sprite/sprite.svg#upload"></use>
        </svg>
        <span class="small bold">{{ 'translations.membersNew.controlPanel.alumniUpload.name' | transloco }}</span>
      </div>
    </button>

    <ng-container *ngIf="isEvent">
      <button
        mat-stroked-button
        [matMenuTriggerFor]="checkedMembers.length >= 1 ? attendanceMenu : null"
        type="button"
        [class.is_disabled_tooltip]="!checkedMembers.length"
        matTooltipClass="medium-tooltip"
        [matTooltip]="checkedMembers.length > 0 ? '' : ('translations.membersNew.actionBar.noMemberSelect' | transloco)"
        [attr.aria-label]="('translations.global.ariaLabels.attendance' | transloco)">
        <span class="small bold">{{ 'translations.membersNew.actionBar.attendance' | transloco }}</span>
      </button>
    </ng-container>
  </div>

  <div class="control-panel-filter-chips chips-list">
    <ng-container *ngFor="let filter of filters">
      <div class="chip-item has-action-btn chip-interactive chip-filter menu-selector"
           (click)="filterSelected(filter.type)"
           (keydown.enter)="filterSelected(filter.type)">
        <span class="chip-text" [matTooltip]="filter.message" matTooltipClass="medium-tooltip">
          {{ filter.message }}
        </span>
        <span class="chip-item-icon">
          <svg class="svg" role="img" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#chevron-down"></use>
          </svg>
        </span>
        <ng-container *ngIf="currentFilter === filter.type" [ngSwitch]="filter.type">
          <ptl-review-status-filter
            class="filter-settings"
            *ngSwitchCase="'REVIEW_STATUS'"
            (filterDeleted)="onRemoveFilter('REVIEW_STATUS')"
            (filterSaved)="onSaveFilter($event)"
            [selectedFilters]="filters">
          </ptl-review-status-filter>
          <ptl-group-filter
            class="filter-settings"
            *ngSwitchCase="'GROUP'"
            (filterDeleted)="onRemoveFilter('GROUP')"
            (filterSaved)="onSaveFilter($event)"
            [selectedFilters]="filters"
            [playlistUid]="playlistUid"
          >
          </ptl-group-filter>
          <ptl-role-filter
            class="filter-settings"
            *ngSwitchCase="'ROLE'"
            (filterDeleted)="onRemoveFilter('ROLE')"
            (filterSaved)="onSaveFilter($event)"
            [selectedFilters]="filters"
          >
          </ptl-role-filter>
          <ptl-attendance-filter
            class="filter-settings"
            *ngSwitchCase="'ATTENDANCE'"
            (filterDeleted)="onRemoveFilter('ATTENDANCE')"
            (filterSaved)="onSaveFilter($event)"
            [selectedFilters]="filters"
            [isWaitingListEnabled]="isWaitingListEnabled"
          >
          </ptl-attendance-filter>
          <ptl-activity-date-filter
            class="filter-settings"
            *ngSwitchCase="'ACTIVITY'"
            (filterDeleted)="onRemoveFilter('ACTIVITY')"
            (filterSaved)="onSaveFilter($event)"
            [selectedFilters]="filters"
          >
          </ptl-activity-date-filter>
          <ptl-completion-filter
            class="filter-settings"
            *ngSwitchCase="'COMPLETION'"
            (filterDeleted)="onRemoveFilter('COMPLETION')"
            (filterSaved)="onSaveFilter($event)"
            [selectedFilters]="filters"
            [isPlaylistPage]="isPlaylistPage"
            [playlistUid]="playlistUid"
            [isCardPage]="isCardPage"
            [cardUid]="cardUid"
            [cardHeaders]="cardHeaders"
            [formHeaders]="formHeaders"
          >
          </ptl-completion-filter>
        </ng-container>
      </div>

    </ng-container>
    <div class="chip-item has-action-btn chip-interactive menu-selector"
         *ngIf="newFilter === 'GROUP'">
      <span class="chip-text">
        {{ ('translations.reviews.filter.group' | transloco) + ' ' + ('translations.reviews.filter.is' | transloco) + '...' }}
      </span>
      <span class="chip-item-icon">
        <svg class="svg" role="img" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#chevron-down"></use>
        </svg>
      </span>
      <ptl-group-filter
        class="filter-settings"
        *ngIf="!isIframeMode"
        (filterDeleted)="onRemoveFilter('GROUP')"
        (filterSaved)="onSaveFilter($event)"
        [selectedFilters]="filters"
        [playlistUid]="playlistUid"
      >
      </ptl-group-filter>
    </div>
    <div class="chip-item has-action-btn chip-interactive menu-selector" *ngIf="newFilter === 'ROLE'">
      <span class="chip-text">
        {{ ('translations.filter.role.name' | transloco) + ' ' + ('translations.filter.is' | transloco) + '...' }}
      </span>
      <span class="chip-item-icon">
        <svg class="svg" role="img" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#chevron-down"></use>
        </svg>
      </span>
      <ptl-role-filter
        class="filter-settings"
        (filterDeleted)="onRemoveFilter('ROLE')"
        (filterSaved)="onSaveFilter($event)"
        [selectedFilters]="filters"
      >
      </ptl-role-filter>
    </div>
    <div class="chip-item has-action-btn chip-interactive menu-selector" *ngIf="newFilter === 'ATTENDANCE'">
      <span class="chip-text">
        {{ ('translations.filter.attendance.name' | transloco) + ' ' + ('translations.filter.is' | transloco) + '...' }}
      </span>
      <span class="chip-item-icon">
        <svg class="svg" role="img" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#chevron-down"></use>
        </svg>
      </span>
      <ptl-attendance-filter
        class="filter-settings"
        (filterDeleted)="onRemoveFilter('ATTENDANCE')"
        (filterSaved)="onSaveFilter($event)"
        [selectedFilters]="filters"
      >
      </ptl-attendance-filter>
    </div>
    <div class="chip-item has-action-btn chip-interactive menu-selector" *ngIf="newFilter === 'ACTIVITY'">
      <span class="chip-text">
        {{ ('translations.filter.has' | transloco) + ' ' + ('translations.filter.activity.beenActive' | transloco) + ' ' + ('translations.filter.activity.since' | transloco) + '...' }}
      </span>
      <span class="chip-item-icon">
        <svg class="svg" role="img" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#chevron-down"></use>
        </svg>
      </span>
      <ptl-activity-date-filter
        class="filter-settings"
        (filterDeleted)="onRemoveFilter('ACTIVITY')"
        (filterSaved)="onSaveFilter($event)"
        [selectedFilters]="filters"
      >
      </ptl-activity-date-filter>
    </div>
    <div class="chip-item has-action-btn chip-interactive menu-selector" *ngIf="newFilter === 'COMPLETION'">
      <span class="chip-text">
        {{ ('translations.filter.has' | transloco) + ' ' + ('translations.filter.completion.type.completed' | transloco) + '...' }}
      </span>
      <span class="chip-item-icon">
        <svg class="svg" role="img" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#chevron-down"></use>
        </svg>
      </span>
      <ptl-completion-filter
        class="filter-settings"
        (filterDeleted)="onRemoveFilter('COMPLETION')"
        (filterSaved)="onSaveFilter($event)"
        [selectedFilters]="filters"
        [isPlaylistPage]="isPlaylistPage"
        [playlistUid]="playlistUid"
        [isCardPage]="isCardPage"
        [cardUid]="cardUid"
        [cardHeaders]="cardHeaders"
        [formHeaders]="formHeaders"
      >
      </ptl-completion-filter>
    </div>
    <div class="chip-item has-action-btn chip-interactive menu-selector" *ngIf="newFilter === 'REVIEW_STATUS'">
      <span class="chip-text">
          {{ ('translations.filter.review.name' | transloco) + ' ' + ('translations.filter.is' | transloco) + '...' }}
      </span>
      <span class="chip-item-icon">
        <svg class="svg" role="img" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#chevron-down"></use>
        </svg>
      </span>
      <ptl-review-status-filter
        class="filter-settings"
        (filterDeleted)="onRemoveFilter('REVIEW_STATUS')"
        (filterSaved)="onSaveFilter($event)"
        [selectedFilters]="filters">
      </ptl-review-status-filter>
    </div>
  </div>
</div>
