<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="result" tabindex="0" role="group" [attr.aria-label]="('translations.global.ariaLabels.thisIsQuestionResult' | transloco)">
  <div class="result-body" *ngFor="let question of filteredResponses">
    <div class="result-body__question">
      <span class="result-body__question__icon-close" [attr.aria-label]="('translations.global.ariaLabels.question' | transloco: {text: ': ' + getTranslatedValue(question?.instructions)}) + ', ' +  question?.answeredCorrectly ? ' ' + ('translations.global.ariaLabels.answeredCorrect' | transloco) : ' ' + ('translations.global.ariaLabels.answeredIncorrect' | transloco)">
        <button tabindex="-1" mat-mini-fab color="warn" [ngClass]="question?.answeredCorrectly ? 'button--correct' : 'button--incorrect'">
          <mat-icon  aria-hidden="true" *ngIf="!question?.answeredCorrectly">clear</mat-icon>
          <mat-icon  aria-hidden="true" *ngIf="question?.answeredCorrectly">check</mat-icon>
        </button>
      </span>
      <div class="result-body__question__name" [innerHTML]="getTranslatedValue(question?.instructions)"></div>
    </div>
    <hr class="question-divider">
    <div class="result-body__answers">

      <ptl-app-summary-report
        *ngIf="question.type !== 'SLIDER_QUESTION'"
        [answers]="question?.options"
        [questionType]="question?.type"
        [currentLanguage]="currentLanguage"
        [organization]="organization">
      </ptl-app-summary-report>

      <ptl-app-summary-report-slider
        *ngIf="question.type === 'SLIDER_QUESTION'"
        [question]="question"
        [currentLanguage]="currentLanguage"
        [organization]="organization"
        [quiz]="quiz">
      </ptl-app-summary-report-slider>
    </div>
    <div class="result-body__feedback" *ngIf="question.feedback.length">
      <ptl-content-section-view [content]="question.feedback"></ptl-content-section-view>
    </div>
  </div>
</div>
