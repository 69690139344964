/* eslint-disable max-len */

// defines relative url where user should redirect after login or session reconnect
export const REDIRECT_AFTER_LOGIN = 'redirectAfterLogin';

// defines password regexp that we should have minimum 8 characters with 1 lowercase letter, 1 uppercase letter, 1 number and one symbol
export const DEFAULT_PASSWORD_REGEXP = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[\\/@$.\\-!:~\\\\|;`#()_<>"\'{}=\\[\\],^%*?&])[A-Za-z\\d\\/@$.\\-!:~\\\\|;`#()_<>"\'{}=\\[\\],^+%*?&]{8,}$';

export const DEFAULT_URL_VALIDATION_PATTERN = '^(https?:\\/\\/)?([\\w-]|%[A-F0-9]{2})+(\\.([\\w-]|%[A-F0-9]{2})+)+(\\/([\\w-,.:;~!@+()=]|%[A-F0-9]{2})+)*\\/?(\\?[\\w]+=([\\w-,.:;~!@+()]|%[A-F0-9]{2})*(&[\\w]+=([\\w-,.:;~!@+()]|%[A-F0-9]{2})*)*)?(#[^\\s]+)?$';

// defines translation data that we are using in case of transloco asynchronous issue
/* eslint-disable */
export const TRANSLATIONS_DATA: { [key: string]: any } = {
    en: undefined,
    de: undefined,
    cy: undefined,
}

export const SIDEBAR_SECTIONS = 'sidebarSections';

export const MY_PAGES_SIDEBAR_SECTIONS = 'myPagesSidebarSections';

// defines relative url where user should redirect after login or session reconnect
export const SKILLS_WALLET_TAG_ID = 'skillsWalletTagId';

// defines organization authorised client cookie
export const AUTHORISED_CLIENT = 'f_authorised_client';

// defines if organization should show new version UI
export const NEW_VERSION_ENABLED = 'newVersionEnabled';

export const HELPHERO_ID = 'nD9H88Yqod';

export const TEXT_FORM_WORD_LIMIT = 200;

export const FILE_UPLOAD_DEFAULT_SIZE = 25;

export const FILE_UPLOAD_SUPPORTED_TYPES = ['.pdf', '.ppt', '.pptx', '.doc', '.docx', '.jpg', '.jpeg', '.png'];

export const MAINTENANCE_MODE_FLAG = 'canary';

export const DEFAULT_THEME = {
    primaryThemeColor: '#5A27E7',
    primaryThemeLiteColor: '#DDD7F5',
    primaryColour: '#3B126F',
    primaryLiteColor: '#E6DEF0',
    interactiveColor: '#373677',
    interactiveLightColor: '#E7EAF6',
    adminColor: '#231A37',
    adminLightColor: '#FFEB7B',
}

export const HEADLINE_CHARACTERS_LIMIT = 92;
