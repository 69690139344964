/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  Component,
  EventEmitter,
  Input, OnChanges,
  OnDestroy,
  Output,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import { SideNavSection, SidenavSettings } from '../models';
import { Store } from '@ngxs/store';
import { SideNavState } from './store/side-nav.state';
import { SaveSidenavSettings } from './store/side-nav.actions';

@Component({
  selector: 'ptl-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements AfterViewInit, OnChanges, OnDestroy {

  /** The side nav sections to display. */
  @Input() sideNavSections: SideNavSection[];
  @Input() showAddNewPage: boolean;
  @Input() showAddNewPageIndex: number;
  @Input() showAddNewPackage: boolean;
  @Input() showAddNewPackageIndex: number;
  @Input() saveState = false;

  @Output() itemLinkClick = new EventEmitter<string>();
  @Output() loadMore = new EventEmitter<number>();
  @Output() expandToggled = new EventEmitter<{ toggle: boolean; index: number }>();

  @ViewChild('sideNav') public sideNavElement: ElementRef<HTMLElement>;

  displayedSideNavSections: SideNavSection[];

  private settings: SidenavSettings;
  private swipeOffsetY: number;

  constructor(private store: Store) {
    this.settings = this.store.selectSnapshot(SideNavState.settings);
    this.displayedSideNavSections = this.settings?.savedSidenavSections;
  }

  ngAfterViewInit() {
    if (this.settings && this.sideNavElement?.nativeElement) {
      const element = this.sideNavElement.nativeElement.closest('.mat-drawer-inner-container') as HTMLElement;

      if (element) {
        element.scroll(0, this.settings.scrollPosition);

        element.ontouchstart = (event: TouchEvent) => {
          this.swipeOffsetY = element.scrollTop + event.touches[0].screenY;
        };

        element.ontouchmove = (event: TouchEvent) => {
          element.scroll(0, this.swipeOffsetY - event.touches[0].screenY);
          event.preventDefault();
        };
      }
    }
  }

  ngOnChanges() {
    if (this.sideNavSections) {
      this.displayedSideNavSections = this.sideNavSections;
    }
  }

  ngOnDestroy() {
    let scrollPosition = 0;

    if (this.sideNavElement?.nativeElement) {
      const element = this.sideNavElement.nativeElement.closest('.mat-drawer-inner-container');

      if (element) {
        scrollPosition = element.scrollTop;
      }
    }

    this.store.dispatch(new SaveSidenavSettings({
      savedSidenavSections: this.saveState ? this.sideNavSections : [],
      scrollPosition: scrollPosition,
    }));
  }


  onLinkClick(event: string) {
    this.itemLinkClick.emit(event);
  }

  onLoadMore(event: number) {
    this.loadMore.emit(event);
  }

  onExpandToggle($event: boolean, index: number) {
    this.expandToggled.emit({ toggle: $event, index: index });
  }

}
