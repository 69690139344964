<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<div class="dialog-header">
  <div class="dialog-header__top">
    <img src="{{(organizationData$| async)?.logo}}" class="dialog-header__logo" alt="" width="88" height="67"/>
    <h1 class="dialog-header-title"
        tabindex="0"
        [attr.aria-label]="'translations.global.ariaLabels.prepareForPagePublication' | transloco">
      {{ 'translations.pages.title.publish' | transloco }}</h1>
    <i class="material-icons dialog-header__close-icon"
       [attr.aria-label]="('translations.global.ariaLabels.closePublishPage' | transloco)"
       (click)="onCloseDialog()"
       (keydown.enter)="onCloseDialog()"
       tabindex="0">close</i>
  </div>

  <div class="dialog-header-active-tabs">
    <div class="dialog-header-tab-item"
         [class.is_active]="activePage === 0"
         tabindex="0"
         (click)="activePage === 1 ? setPage(0) : null"
         (keydown.enter)="activePage === 1 ? setPage(0) : null">
      {{ 'translations.pages.label.audience' | transloco }}
    </div>
    <div class="dialog-header-tab-item">
      <mat-icon aria-hidden="true">east</mat-icon>
    </div>
    <div class="dialog-header-tab-item" [class.is_active]="activePage === 1"
         tabindex="0"
         (click)="activePage === 0 ? setPage(1) : null"
         (keydown.enter)="activePage === 0 ? setPage(1) : null">
      {{ 'translations.pages.label.publish' | transloco }}
    </div>
  </div>

</div>
<div class="content">

  <ptl-pages-audience [isDialog]="true" *ngIf="activePage === 0"></ptl-pages-audience>

  <ptl-pages-publish [isDialog]="true" *ngIf="activePage === 1"></ptl-pages-publish>
</div>

<div class="footer-buttons">

  <button mat-flat-button (click)="onCloseDialog()" class="cancel-button uppercase" type="button"
          [attr.aria-label]="('translations.global.ariaLabels.cancel' | transloco)">
    {{ 'translations.global.button.exit' | transloco }}
  </button>

  <button mat-stroked-button class="uppercase publish-now" *ngIf="activePage === 1" type="button"
          [attr.aria-label]="('translations.global.ariaLabels.publishPage' | transloco)"
          (click)="onPublishClick()">
    <span class="publish-card__footer-btn-text">
    {{ 'translations.pages.publish.button.publishNow' | transloco }}
      <mat-spinner class="publish-card__footer-btn-spinner" *ngIf="updateInProgress"
                   [color]="'primary'" [diameter]="20"></mat-spinner>
          </span>
  </button>

  <button mat-stroked-button (click)="setPage(1)" *ngIf="activePage === 0" class="next-button uppercase" type="button"
          [attr.aria-label]="('translations.global.ariaLabels.next' | transloco)" color="primary">
    {{ 'translations.global.button.next' | transloco }}
  </button>

</div>
