import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslationService } from '../../../../../../../shared/services/translation/translation.service';
import { MatSelectChange } from '@angular/material/select';
import { ActivityRequest, BooleanQueryType } from '../../../../../../../shared/models/admin/boolean-filters.model';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Select } from '@ngxs/store';
import { UserAuthState } from '../../../../../../../user-auth/store/user-auth.state';
import { Organization } from '../../../../../../../shared/models';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FormControl, FormGroup, NgModel, Validators } from '@angular/forms';

@Component({
  selector: 'ptl-activity-member-filter',
  templateUrl: './activity-member-filter.component.html',
  styleUrls: ['./activity-member-filter.component.scss'],
})
export class ActivityMemberFilterComponent implements OnChanges, OnInit, OnDestroy {

  @Output() setActivityFilter = new EventEmitter<{ request: ActivityRequest; message: string }>();
  @Output() cancelActivityFilter = new EventEmitter<void>();
  @Input() excludedActivityStatuses: string[];

  @Select(UserAuthState.organizationDetails)
  private organizationData$: Observable<Organization>;
  private organizationDataSubscription: Subscription;
  private language: string;

  selectedActivityStatus = 'ACTIVE';
  allActivityStatuses = [];
  activityStatuses = [];

  selectedTimeType: 'SINCE' | 'PERIOD' = 'SINCE';
  timeTypes = [];

  selectedDay: number;
  days = [...Array(31).keys()].map(i => i+1);
  selectedMonth: number;
  months: { name: string; value: number }[] = moment.localeData().monthsShort().map(
    // in js months are in range 0-11
    (month, index) => { return { name: month, value: index } }
  );
  selectedYear: number;
  years = [];
  invalidDate = false;

  selectedPeriod: 'DAYS' | 'WEEKS' | 'MONTHS' | 'YEARS' = 'DAYS';
  periods = [];

  periodForm: FormGroup;
  selectedPeriodLength = 1;
  noNumber = false;

  constructor(
    private translationService: TranslationService,
  ) {
  }

  ngOnInit() {
    this.allActivityStatuses = [
      { value: 'ACTIVE', title: this.translationService.getTranslation('members.filter.activity.active')},
      { value: 'INACTIVE', title: this.translationService.getTranslation('members.filter.activity.inactive')}
    ];
    this.updateStatuses();
    this.timeTypes = [
      { value: 'SINCE', title: this.translationService.getTranslation('members.filter.activity.since') },
      { value: 'PERIOD', title: this.translationService.getTranslation('members.filter.activity.period') },
    ];
    const currentYear = new Date().getFullYear();
    this.years = [...Array(10).keys()].map(i => currentYear - i);
    this.periods = [
      { value: 'DAYS', title: this.translationService.getTranslation('members.filter.activity.days') },
      { value: 'WEEKS', title: this.translationService.getTranslation('members.filter.activity.weeks') },
      { value: 'MONTHS', title: this.translationService.getTranslation('members.filter.activity.months') },
      { value: 'YEARS', title: this.translationService.getTranslation('members.filter.activity.years') },
    ];
    this.periodForm = new FormGroup({
        periodLength: new FormControl(
          this.selectedPeriodLength,
          [Validators.required, Validators.pattern('[0-9]+')]
        )
      }
    )
    this.organizationDataSubscription = this.organizationData$.pipe(
      filter(data => !!data)
    ).subscribe(data => {
      this.language = data.defaultLanguage;
      this.months = moment.localeData(this.language).monthsShort().map(
        // in js months are in range 0-11
        (month, index) => { return { name: month, value: index } }
      );
    });
  }

  ngOnDestroy() {
    this.organizationDataSubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.excludedActivityStatuses) {
      this.updateStatuses();
    }
  }

  onActivityStatusChange(event: MatSelectChange) {
    this.selectedActivityStatus = event.value;
  }

  onTimeTypeChange(event: MatSelectChange) {
    this.selectedTimeType = event.value;
    this.noNumber = false;
  }

  onDayChange(event: MatSelectChange) {
    this.selectedDay = event.value;
  }

  onMonthChange(event: MatSelectChange) {
    this.selectedMonth = event.value;
  }

  onYearChange(event: MatSelectChange) {
    this.selectedYear = event.value;
  }

  onPeriodChange(event: MatSelectChange) {
    this.selectedPeriod = event.value;
  }

  onSave() {
    if (this.selectedTimeType === 'PERIOD' && this.periodForm.get('periodLength').errors !== null) {
      this.noNumber = true;
      return;
    } else {
      this.selectedPeriodLength = this.periodForm.get('periodLength').value;
      this.noNumber = false;
    }

    const time = this.getTime();
    if (!time.isValid()) {
      this.invalidDate = true;
      return;
    } else {
      this.invalidDate = false;
    }
    const activityFilter = {
      message: this.getMessage(moment(time)),
      request: this.getRequest(moment(time))
    }
    this.setActivityFilter.emit(activityFilter);
  }

  private getTime(): Moment {
    switch(this.selectedTimeType) {
      case 'SINCE':
        return moment([this.selectedYear, this.selectedMonth, this.selectedDay]);
      case 'PERIOD':
        return moment().startOf('day').subtract(this.selectedPeriodLength, this.getPeriodDuration());
    }
  }

  private getPeriodDuration(): 'days' | 'weeks' | 'months' | 'years' {
    return this.selectedPeriod.toLowerCase() as 'days' | 'weeks' | 'months' | 'years';
  }

  private getMessage(time: Moment): string {
    let message = '';

    if (this.selectedActivityStatus === 'ACTIVE') {
      message += this.translationService.getTranslation('members.filter.summary.active');
    } else if (this.selectedActivityStatus === 'INACTIVE') {
      message += this.translationService.getTranslation('members.filter.summary.inactive');
    }

    if (this.selectedTimeType === 'SINCE') {
      if (this.language) {
        time.locale(this.language);
      }
      const date = time.format('LL');
      message += ' ' + this.translationService.getTranslation('members.filter.summary.since') + ' ' + date;
    } else if (this.selectedTimeType === 'PERIOD') {
      const period = this.selectedPeriodLength
        + ' ' + this.translatePeriod(this.selectedPeriod, this.selectedPeriodLength);
      message += ' ' + this.translationService.getTranslation('members.filter.summary.last') + ' ' + period;
    }

    return message;
  }

  private translatePeriod(period: string, length: number): string {
    if (length > 1) {
      switch (period) {
        case 'DAYS':
          return this.translationService.getTranslation('members.filter.summary.days');
        case 'WEEKS':
          return this.translationService.getTranslation('members.filter.summary.weeks');
        case 'MONTHS':
          return this.translationService.getTranslation('members.filter.summary.months');
        case 'YEARS':
          return this.translationService.getTranslation('members.filter.summary.years');
        default:
          return '';
      }
    } else {
      switch (period) {
        case 'DAYS':
          return this.translationService.getTranslation('members.filter.summary.day');
        case 'WEEKS':
          return this.translationService.getTranslation('members.filter.summary.week');
        case 'MONTHS':
          return this.translationService.getTranslation('members.filter.summary.month');
        case 'YEARS':
          return this.translationService.getTranslation('members.filter.summary.year');
        default:
          return '';
      }
    }
  }

  private getRequest(time: Moment): ActivityRequest {

    if (this.language) {
      time.locale(this.language);
    }

    return {
      type: BooleanQueryType.ACTIVITY,
      operator: this.selectedActivityStatus === 'ACTIVE' ? 'AFTER' : 'BEFORE',
      time: time.format()
    }
  }

  private updateStatuses() {
    this.activityStatuses = this.allActivityStatuses.filter(
      status => !this.excludedActivityStatuses.includes(status.value)
    );
    if (this.activityStatuses.length) {
      this.selectedActivityStatus = this.activityStatuses[0].value;
    }
  }

  onCancel() {
    this.cancelActivityFilter.emit();
  }
}
