<!--
  ~ Copyright (C) 2022 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ng-container>
  <div class="member-filter__wrapper">
    <div class="member-filter__dropdowns">
      <mat-select
        [(value)]="selectedGroupMembership"
        (selectionChange)="onGroupMembershipChange($event)"
        class="member-filter__dropdown">
        <mat-option *ngFor="let groupMembership of groupMemberships" [value]="groupMembership.value">{{ groupMembership.title }}</mat-option>
      </mat-select>
      <div class="member-group__container" *ngIf="true">
        <div class="member-group__container-inner">
          <div class="member-group__search-container">
            <form class="member-group__search-form" (ngSubmit)="onInputSubmit()">
              <input
                type="text"
                class="member-group__search-form-input"
                (ngModelChange)="onInputChange()"
                [formControl]="searchString"
                [attr.aria-label]="('translations.members.filter.group.search' | transloco)"
                [placeholder]="('translations.members.filter.group.search' | transloco)">
              <mat-spinner
                class="member-group__search-spinner"
                *ngIf="searchInProgress"
                [diameter]="18">
              </mat-spinner>
            </form>
            <div class="member-group__search-result" *ngIf="showSearchResult">
              <ul class="member-group__search-list" *ngIf="groups?.length">
                <li tabindex="0"
                    (click)="onGroupSelect(group)"
                    (keydown.enter)="onGroupSelect(group)"
                    *ngFor="let group of groups">
                  {{ group.title }}
                </li>
              </ul>
              <div class="member-group__search-empty" *ngIf="!groups?.length">
                {{ 'translations.global.search.label.noData' | transloco }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="selected-groups">
        <div class="selected-group" *ngFor="let selectedGroup of selectedGroups;">
          <span>{{ selectedGroup.title }}</span>
          <span
            class="selected-group__remove"
            [matTooltip]="('translations.members.filter.group.remove' | transloco)"
            [attr.aria-label]="('translations.global.ariaLabels.clear' | transloco: {title: selectedGroup.title})"
            (click)="onGroupRemove(selectedGroup)"
            (keydown.enter)="onGroupRemove(selectedGroup)"
            tabindex="0">
            <mat-icon aria-hidden="true" [inline]="true">clear</mat-icon>
          </span>
        </div>
      </div>
    </div>
    <p *ngIf="noGroupsSelected" class="member-filter__validation">
      {{ 'translations.members.filter.group.no' | transloco }}
    </p>
  </div>
  <ptl-member-filter-controls (cancel)="onCancel()" (save)="onSave()"></ptl-member-filter-controls>
</ng-container>
