<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="members-list__container-outer">
  <span class="members-list__update-message" *ngIf="members?.length"
        [attr.aria-label]="'translations.global.ariaLabels.updatedEvery30Minutes' | transloco">
    {{ 'translations.members.label.updatedEvery30Minutes' | transloco }}</span>
  <div class="members-list__container"
       [class.members-playlist-list__container]="isPlaylistPage"
       *ngIf="members?.length else emptyList">
    <table
      class="members-list__main" [class.members-playlist-list__main]="isPlaylistPage"
      [attr.aria-label]="('translations.global.ariaLabels.membersList' | transloco)">
      <thead>
      <tr class="header-row">
        <th class="checkbox-cell">
          <mat-checkbox [(ngModel)]="allUsersChecked"
                        [tabIndex]="0"
                        color="primary"
                        [aria-label]="allUsersChecked ? ('translations.global.ariaLabels.uncheckAllMembers' | transloco) : ('translations.global.ariaLabels.checkAllMembers' | transloco)"
                        (change)="onAllCheckboxChange()"
                        class="members-list__checkbox"></mat-checkbox>
        </th>
        <th class="name-cell first-name pointer"
            [attr.aria-label]="('translations.global.ariaLabels.orderByFirstName' | transloco)"
            tabindex="0"
            role="button"
            (keydown.enter)="changeListOrdering('firstName')"
            (click)="changeListOrdering('firstName')">
          <span class="column-name">{{ 'translations.global.label.firstName' | transloco }}</span>
          <i class="material-icons" aria-hidden="true" *ngIf="defaultOrderBy === 'firstName'">
            {{ (defaultOrderBy === 'firstName' && order === 'ASC') ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </i>
        </th>
        <th class="name-cell last-name pointer"
            [tabIndex]="0"
            [attr.aria-label]="('translations.global.ariaLabels.orderByLastName' | transloco)"
            tabindex="0"
            role="button"
            (keydown.enter)="changeListOrdering('lastName')"
            (click)="changeListOrdering('lastName')">
          <span class="column-name">{{ 'translations.global.label.lastName' | transloco }}</span>
          <i class="material-icons" aria-hidden="true" *ngIf="defaultOrderBy === 'lastName'">
            {{ (defaultOrderBy === 'lastName' && order === 'ASC') ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </i>
        </th>
        <th class="role-cell"
            [attr.aria-label]="'translations.global.ariaLabels.role' | transloco">
          {{ 'translations.global.label.role' | transloco }}</th>
        <th class="group-cell"
            [attr.aria-label]="'translations.global.ariaLabels.group' | transloco">
          {{ 'translations.global.label.group' | transloco }}</th>
        <th class="email-cell"
            [attr.aria-label]="'translations.global.ariaLabels.email' | transloco">
          {{ 'translations.global.label.email' | transloco }}</th>
        <th class="membership-cell pointer"
            *ngIf="!isCardPage && !isPlaylistPage"
            [attr.aria-label]="('translations.global.ariaLabels.orderByMembershipStatus' | transloco)"
            tabindex="0"
            role="button"
            (keydown.enter)="changeListOrdering('membershipStatus')"
            (click)="changeListOrdering('membershipStatus')">
          <span class="column-name">
            {{ 'translations.members.list.label.membershipStatus' | transloco }}</span>
          <i class="material-icons" aria-hidden="true" *ngIf="defaultOrderBy === 'membershipStatus'">
            {{ (defaultOrderBy === 'membershipStatus' && order === 'ASC') ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </i>
        </th>
        <th class="date-cell pointer"
            [attr.aria-label]="('translations.global.ariaLabels.orderByLastActiveOn' | transloco)"
            tabindex="0"
            role="button"
            (keydown.enter)="changeListOrdering('lastActiveOn')"
            (click)="changeListOrdering('lastActiveOn')">
          <span class="column-name">
            {{ 'translations.members.list.label.lastActiveDate' | transloco }}</span>
          <i class="material-icons" aria-hidden="true" *ngIf="defaultOrderBy === 'lastActiveOn'">
            {{ (defaultOrderBy === 'lastActiveOn' && order === 'ASC') ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </i>
        </th>
        <th class="date-cell pointer"
            [attr.aria-label]="('translations.global.ariaLabels.orderByJoinDate' | transloco)"
            tabindex="0"
            role="button"
            (keydown.enter)="changeListOrdering('createdAt')"
            (click)="changeListOrdering('createdAt')">
          <span class="column-name">{{ 'translations.members.list.label.joinedDate' | transloco }}</span>
          <i class="material-icons" aria-hidden="true" *ngIf="defaultOrderBy === 'createdAt'">
            {{ (defaultOrderBy === 'createdAt' && order === 'ASC') ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </i>
        </th>
        <ng-container *ngIf="isCardPage || isPlaylistPage">
          <th class="date-cell pointer"
              [attr.aria-label]="('translations.global.ariaLabels.orderByStartedOn' | transloco)"
              tabindex="0"
              role="button"
              (keydown.enter)="changeListOrdering('startedOn')"
              (click)="changeListOrdering('startedOn')">
            <span class="column-name">{{ 'translations.members.list.label.startedOn' | transloco }}</span>
            <i class="material-icons" aria-hidden="true" *ngIf="defaultOrderBy === 'startedOn'">
              {{ (defaultOrderBy === 'startedOn' && order === 'ASC') ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
            </i>
          </th>
          <th class="date-cell pointer"
              [attr.aria-label]="('translations.global.ariaLabels.orderByCompletedOn' | transloco)"
              tabindex="0"
              role="button"
              (keydown.enter)="changeListOrdering('completedOn')"
              (click)="changeListOrdering('completedOn')">
            <span class="column-name">{{ 'translations.members.list.label.completedOn' | transloco }}</span>
            <i class="material-icons" aria-hidden="true" *ngIf="defaultOrderBy === 'completedOn'">
              {{ (defaultOrderBy === 'completedOn' && order === 'ASC') ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
            </i>
          </th>
          <th class="status-cell pointer"
              [attr.aria-label]="('translations.global.ariaLabels.orderByStatus' | transloco)"
              tabindex="0"
              role="button"
              (keydown.enter)="changeListOrdering(isCardPage ? 'cardStatus' : 'playlistStatus')"
              (click)="changeListOrdering(isCardPage ? 'cardStatus' : 'playlistStatus')">
            <span class="column-name">{{ 'translations.global.label.status' | transloco }}</span>
            <i class="material-icons" aria-hidden="true"
               *ngIf="defaultOrderBy === 'cardStatus' || defaultOrderBy === 'playlistStatus'">
              {{ ((defaultOrderBy === 'cardStatus' || defaultOrderBy === 'playlistStatus') && order === 'ASC') ?
              'keyboard_arrow_up' : 'keyboard_arrow_down' }}
            </i>
          </th>
        </ng-container>
        <ng-container *ngIf="isPlaylistPage">
          <ng-container *ngFor="let card of members[0].cards; let j = index;">
            <th class="card-cell">
              <div class="card-cell-inner">
                <div class="card-type"
                     [attr.aria-label]="card.cardType">
                  {{card.cardType}}</div>
                <span class="card-name" [title]="card.header"
                      [attr.aria-label]="card.header">
                  {{card.header}}</span>
                <i class="material-icons card-expand-icon"
                   [attr.aria-label]="('translations.global.ariaLabels.expandCards' | transloco)"
                   (click)="expandNestedCards(j)"
                   (keydown.enter)="expandNestedCards(j)"
                   tabindex="0"
                   *ngIf="card.groupCards && card.groupCards.length">double_arrow</i>
              </div>
            </th>
            <th class="card-nested-cell"
                [class.is_active]="j === nestedCardIndex"
                *ngFor="let childCard of card.groupCards">
              <span title="{{childCard.header}}"
                    [attr.aria-label]="childCard.header">
                {{childCard.header}}</span>
            </th>
          </ng-container>
        </ng-container>
        <th class="expand-cell" *ngIf="!isPlaylistPage"></th>
      </tr>
      </thead>
      <tbody>
      <ng-container
        *ngFor="let member of members; let i = index">
        <tr class="item-row"
            tabindex="0"
            role="button"
            [attr.aria-label]="('translations.global.ariaLabels.openUserRecord' | transloco: {title: member.firstName + ' ' + member.lastName})"
            (keydown.enter)="openUserRecordByUid(member._id)"
            (click)="openUserRecordByUid(member._id)">
          <td class="checkbox-cell">
            <mat-checkbox
              color="primary"
              [checked]="member.checked"
              [aria-label]="member.checked ? ('translations.global.ariaLabels.uncheck' | transloco: {name: (member.firstName + ' ' + member.lastName)}) : ('translations.global.ariaLabels.check' | transloco: {name: member.firstName + ' ' + member.lastName})"
              (change)="onCheckboxChange(member._id)"
              (click)="$event.stopPropagation()"
            ></mat-checkbox>
          </td>
          <td class="name-cell first-name"
              [attr.aria-label]="member.firstName">
            {{ member.firstName }}</td>
          <td class="name-cell last-name"
              [attr.aria-label]="member.lastName">
            {{ member.lastName }}</td>

          <td class="role-cell">
            <span *ngFor="let role of member.roles"
                  [attr.aria-label]="role">
              {{ role }}</span>
          </td>

          <td class="group-cell">
            <span *ngFor="let group of member.groups"
                  [attr.aria-label]="group.title">
              {{ group.title }}</span>
          </td>

          <td class="email-cell"><span matTooltip="{{member.email}}"
                                       [attr.aria-label]="member.email">
            {{ member.email }}</span></td>

          <td class="membership-cell"
              *ngIf="!isCardPage && !isPlaylistPage"
              [attr.aria-label]="member.membershipStatus | titlecase">
            {{ member.membershipStatus | titlecase }}</td>

          <td class="date-cell"
              [attr.aria-label]="member.lastActiveOn | date: 'mediumDate'">
            {{ member.lastActiveOn | date: 'mediumDate' }}</td>
          <td class="date-cell"
              [attr.aria-label]="member.createdAt | date: 'mediumDate'">
            {{ member.createdAt | date: 'mediumDate' }}</td>

          <ng-container *ngIf="isCardPage || isPlaylistPage">
            <td class="date-cell"
                [attr.aria-label]="member.startedOn | date: 'mediumDate'"
            >{{ member.startedOn | date: 'mediumDate' }}</td>
            <td class="date-cell"
                [attr.aria-label]="member.completedOn | date: 'mediumDate'">
              {{ member.completedOn | date: 'mediumDate' }}</td>
            <td class="status-cell">
            <span
              class="status-cell-indicator"
              [title]="getMemberStatusTitle(member)"
              tabindex="0"
              [attr.aria-label]="getMemberStatusTitle(member)"
              [ngClass]="{
                'completed': member.cardStatus === 'COMPLETED' || member.playlistStatus === 'COMPLETED',
                'in-progress': member.cardStatus === 'IN_PROGRESS' || member.playlistStatus === 'IN_PROGRESS',
                'not-started': member.cardStatus === 'NOT_STARTED' || member.playlistStatus === 'NOT_STARTED'
              }"
            ></span>
            </td>
          </ng-container>

          <ng-container *ngIf="isPlaylistPage">
            <ng-container *ngFor="let card of member.cards; let j = index">
              <td class="card-cell card-status">
                <ng-container *ngIf="!card.underReview">
                  <span *ngIf="card.startedOn">
                    <mat-icon aria-hidden="true" class="mat-icon" [class.completed]="card.completed">check</mat-icon>
                  </span>
                  <span *ngIf="!card.startedOn && !card.underReview">
                    ---
                  </span>
                </ng-container>
                <a *ngIf="card.underReview"
                   href="javascript:void(0)"
                   tabindex="0"
                   [attr.aria-label]="'translations.global.ariaLabels.review' | transloco">
                  {{ 'translations.global.label.review' | transloco }}</a>
              </td>
              <td class="card-nested-cell card-status"
                  [class.is_active]="j === nestedCardIndex"
                  *ngFor="let childCard of card.groupCards">
                <ng-container *ngIf="!childCard.underReview">
                  <span *ngIf="childCard.startedOn">
                    <mat-icon aria-hidden="true" class="mat-icon"
                              [class.completed]="childCard.completed">check</mat-icon>
                  </span>
                  <span *ngIf="!childCard.startedOn && !childCard.underReview">
                    ---
                  </span>
                </ng-container>
                <a *ngIf="childCard.underReview"
                   href="javascript:void(0)"
                   tabindex="0"
                   [attr.aria-label]="'translations.global.ariaLabels.review' | transloco">
                  {{ 'translations.global.label.review' | transloco }}</a>
              </td>
            </ng-container>
          </ng-container>


          <td class="expand-cell"
              *ngIf="!isPlaylistPage"
              (click)="onOpenMobileMenu($event, i); $event.stopPropagation();"
              (keydown.enter)="onOpenMobileMenu($event, i); $event.stopPropagation();">
            <i class="material-icons active f_expand-more-icon" aria-hidden="true">expand_more</i>
            <i class="material-icons f_expand-less-icon" aria-hidden="true">expand_less</i>
          </td>
        </tr>
        <tr class="mobile-row" id="{{i}}" *ngIf="!isPlaylistPage">
          <td class="mobile-cell" colspan="5">
            <table class="mobile-table">
              <tr>
                <td class="first-cell"
                    tabindex="0"
                    [attr.aria-label]="'translations.global.ariaLabels.role' | transloco">
                  {{ 'translations.global.label.role' | transloco }}</td>
                <td class="second-cell"><span *ngFor="let role of member.roles"
                                              tabindex="0"
                                              [attr.aria-label]="role">
                  {{ role }}</span></td>
              </tr>
              <tr>
                <td class="first-cell"
                    tabindex="0"
                    [attr.aria-label]="'translations.global.ariaLabels.group' | transloco">
                  {{ 'translations.global.label.group' | transloco }}</td>
                <td class="second-cell"><span *ngFor="let group of member.groups"
                                              tabindex="0"
                                              [attr.aria-label]="group.title">
                  {{ group.title }}</span></td>
              </tr>
              <tr *ngIf="!isCardPage && !isPlaylistPage">
                <td class="first-cell"
                    tabindex="0"
                    [attr.aria-label]="'translations.global.ariaLabels.membershipStatus' | transloco">
                  {{ 'translations.members.list.label.membershipStatus' | transloco }}</td>
                <td class="second-cell"
                    tabindex="0"
                    [attr.aria-label]="member.membershipStatus | titlecase">
                  {{ member.membershipStatus | titlecase }}</td>
              </tr>
              <tr>
                <td class="first-cell">
                  <span
                    tabindex="0"
                    [attr.aria-label]="'translations.global.ariaLabels.lastActiveDate' | transloco">
                    {{ 'translations.members.list.label.lastActiveDate' | transloco }}</span>
                </td>
                <td class="second-cell"
                    tabindex="0"
                    [attr.aria-label]="member.lastActiveOn | date: 'mediumDate'">
                  {{ member.lastActiveOn | date: 'mediumDate' }}</td>
              </tr>
              <tr>
                <td class="first-cell">
                  <span
                    tabindex="0"
                    [attr.aria-label]="'translations.global.ariaLabels.joinedDate' | transloco">
                    {{ 'translations.members.list.label.joinedDate' | transloco }}</span>
                </td>
                <td class="second-cell"
                    tabindex="0"
                    [attr.aria-label]="member.createdAt | date: 'mediumDate'">
                  {{ member.createdAt | date: 'mediumDate' }}</td>
              </tr>
              <ng-container *ngIf="isCardPage || isPlaylistPage">
                <tr>
                  <td class="first-cell">
                    <span
                      tabindex="0"
                      [attr.aria-label]="'translations.global.ariaLabels.startedOn' | transloco">
                      {{ 'translations.members.list.label.startedOn' | transloco }}</span>
                  </td>
                  <td class="second-cell"
                      tabindex="0"
                      [attr.aria-label]="member.startedOn | date: 'mediumDate'">
                    {{ member.startedOn | date: 'mediumDate' }}</td>
                </tr>
                <tr>
                  <td class="first-cell">
                    <span
                      tabindex="0"
                      [attr.aria-label]="'translations.global.ariaLabels.completedOn' | transloco">
                      {{ 'translations.members.list.label.completedOn' | transloco }}</span>
                  </td>
                  <td class="second-cell"
                      tabindex="0"
                      [attr.aria-label]="member.completedOn | date: 'mediumDate'">
                    {{ member.completedOn | date: 'mediumDate' }}</td>
                </tr>
                <tr>
                  <td class="first-cell">
                    <span
                      tabindex="0"
                      [attr.aria-label]="'translations.global.ariaLabels.status' | transloco">
                      {{ 'translations.global.label.status' | transloco }}</span>
                  </td>
                  <td class="second-cell">
                  <span
                    class="status-cell-indicator"
                    [title]="getMemberStatusTitle(member)"
                    [ngClass]="{
                      'completed': member.cardStatus === 'COMPLETED' || member.playlistStatus === 'COMPLETED',
                      'in-progress': member.cardStatus === 'IN_PROGRESS' || member.playlistStatus === 'IN_PROGRESS',
                      'not-started': member.cardStatus === 'NOT_STARTED' || member.playlistStatus === 'NOT_STARTED'
                    }"
                  ></span>
                  </td>
                </tr>
              </ng-container>
            </table>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>

    <div class="admin-member__list-loader" *ngIf="isLoaderActive">
      <div class="admin-member__list-loader-inner"></div>
    </div>
  </div>
</div>

<ng-template #emptyList>
  <h3 class="members-list__empty"
      tabindex="0"
      [attr.aria-label]="'translations.global.ariaLabels.noMembersFound' | transloco">
    {{ 'translations.members.label.noMembersFound' | transloco }}</h3>
</ng-template>

<div class="admin-member__list-footer" *ngIf="members?.length">
  <ptl-pagination-block
    [pageSize]="pageSize"
    [totalNumberOfElement]="membersTotalCount"
    [currentPage]="currentPage"
    [showPageSize]="true"
    [startedFromZero]="true"
    (sizeChanged)="onSizeChange($event)"
    (pageChanged)="onPageChange($event)"></ptl-pagination-block>
</div>
