<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="quiz" [ngClass]="{'orange': !quizTaken, 'green': quizTaken}">
  <div class="quiz__header">
    <mat-icon class="quiz__header__icon" aria-hidden="true"
              [ngClass]="{
              'orange': !quizTaken,
              'green': quizTaken,
              'locked' : (quizTaken && !canRetake)
              }">
      {{ (quizTaken && !canRetake) ? 'lock' : 'linear_scale' }}
    </mat-icon>
    <div class="quiz__header__inline" tabindex="0" *ngIf="summary">
      <div class="quiz__header__title">{{ title }}</div>
      <div class="quiz__header__text"
           tabindex="0"
           [attr.aria-label]="('translations.answerQuestionsToPass' | transloco)">
        {{ 'translations.answerQuestionsToPass' | transloco }}</div>
    </div>
  </div>

  <ng-container *ngIf="!quizTaken">
    <div class="quiz__body quiz-not-taken">
      <div tabindex="0" class="quiz-answered-text">
        {{ 'translations.quizYouAnsweredQuestions' | transloco: {diff: summary?.answeredQuestions + '/' + summary?.totalQuestions} }}
      </div>
      <div>
        <button
          *ngIf="!resume"
          class="quiz__body__orange-btn uppercase"
          mat-flat-button
          [attr.aria-label]="('translations.global.ariaLabels.startTakingQuiz' | transloco)"
          (click)="showDialog()">
          {{ 'translations.start' | transloco }}
        </button>
        <button
          *ngIf="resume"
          class="quiz__body__orange-btn"
          mat-flat-button
          [attr.aria-label]="('translations.global.ariaLabels.continueTakingQuiz' | transloco)"
          (click)="showDialog()">
          {{ 'translations.resume' | transloco }}
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="quizTaken">
    <div class="quiz__body quiz-taken">
      <div *ngIf="summary" tabindex="0" class="quiz-answered-text"
           [attr.aria-label]="('translations.global.ariaLabels.youAnswered' | transloco: {answeredQuestions: summary?.answeredQuestions}) + ' ' + ('translations.global.ariaLabels.outOf' | transloco: {totalQuestions: summary?.totalQuestions}) + ' ' + ('translations.global.ariaLabels.questions' | transloco)">
        {{ 'translations.quizYouAnsweredQuestions' | transloco: {diff: summary?.answeredQuestions + '/' + summary?.totalQuestions} }}
      </div>
      <div>
        <button
          *ngIf="canRetake && !resume"
          class="quiz__body__green-btn uppercase"
          mat-stroked-button
          [attr.aria-label]="('translations.global.ariaLabels.retakeQuiz' | transloco)"
          (click)="retake()">
          {{ 'translations.retake' | transloco }}
        </button>
        <button
          *ngIf="quizTaken"
          class="quiz__body__green-btn uppercase"
          mat-stroked-button
          [attr.aria-label]="('translations.global.ariaLabels.viewQuizResult' | transloco)"
          (click)="showDialog()">
          {{ 'translations.viewResults' | transloco }}
        </button>
      </div>
    </div>
  </ng-container>
  <div class="form-preview-notes" *ngIf="content?.content?.notes?.length">
    <div class="note-item" *ngFor="let note of content?.content?.notes" tabindex="0">
      <div class="note-added-by">
        <span class="note-label" [ngClass]="{'orange': !quizTaken, 'green': quizTaken}">
          {{ 'translations.cards.review.label.note' | transloco }}</span> {{note.addedBy}}
        , {{ getLocalDateTime(note.addedOn) | date: 'dd MMM yyyy' }}
      </div>
      <div class="note-message">
        <ptl-resource-preview class="thin-preview" [previewContent]="note.message">
        </ptl-resource-preview>
      </div>
    </div>
  </div>
</div>
