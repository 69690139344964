<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<h1 hidden="true" *ngIf="!isInDialogMode">
  {{ 'translations.adminUsersPageLabel' | transloco }}
</h1>

<ptl-skeleton-view *ngIf="skeletonViewActive; else elseTemplate"
                   [state]="{ loading: true }"
                   [countInput]="13"
                   [viewType]="'table'">
</ptl-skeleton-view>

<ng-template #elseTemplate>

  <div class="admin-member__error-container" *ngIf="showNotPermittedMessage">
    {{ 'translations.members.label.noPermittedToViewMembers' | transloco:{organization: (organizationData$ | async)?.name} }}
  </div>

  <div class="admin-member-container-outer"
       (keydown.enter)="onCloseDialog($event)"
       (click)="onCloseDialog($event)" [class.dialog-mode]="isInDialogMode"
    [class.page-has-offset]="isCardPage || isPlaylistPage || isEvent">
    <div class="admin-member-container" [class.dialog-mode]="isInDialogMode"
         [class.small-container]="isCardPage || isPlaylistPage || isEvent" *ngIf="!showNotPermittedMessage">

      <ptl-alert *ngIf="showAlert" [type]="alertType" [message]="alertMessage"
                 [textClickable]="alertTextClickable"
                 (actionClicked)="onActionClicked()" (closeClicked)="hideAlertMessage()"></ptl-alert>


      <div class="admin-member__table-container" id="adminMemberTableContainer" [class.dialog-mode]="isInDialogMode">
        <mat-card class="admin-member__control-panel-container" [class.dialog-mode]="isInDialogMode">
          <div class="close-btn-container" *ngIf="isInDialogMode">
            <button class="close-dialog-btn"
                    mat-icon-button color="primary"
                    (click)="disableDialogMode()"
                    [attr.aria-label]="('translations.global.ariaLabels.closeDialog' | transloco)">
              <svg class="svg" role="img" aria-hidden="true">
                <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
              </svg>
            </button>
          </div>

          <ptl-admin-member-control-panel
            #membersControlPanel
            [columns]="columns"
            [isAdminPage]="!isCardPage && !isPlaylistPage"
            [isPlaylistPage]="isPlaylistPage"
            [isCardPage]="isCardPage"
            [isWaitingListOperationDisabled]="isWaitingListOperationDisabled"
            [isAssessment]="isAssessment"
            [isEvent]="isEvent"
            [checkedMembers]="checkedMembers"
            [totalMembersCount]="membersTotalCount"
            [allMembersCount]="membersAllCount"
            [booleanSearchRequest]="{query: queryRequest}"
            [playlistUid]="playlistUid"
            [cardUid]="cardUid"
            [courseUid]="courseUid"
            [exportEnabled]="exportEnabled || (!isCardPage && !isPlaylistPage)"
            [exportName]="resourceUri"
            [isWaitingListEnabled]="isWaitingListEnabled"
            [cardHeaders]="cardHeaders"
            [formHeaders]="formHeaders"
            [eventFinished]="eventFinished"
            (reviewersChanged)="onReviewersUpdate()"
            (memberActionTriggered)="handleMemberAction($event)"
            (refreshList)="refreshList($event)"
            (triggerUserImport)="onTriggerUserImport($event)"
            (filterChanged)="onFilterChanged($event)"
            (csvFileUploadFinished)="refreshTableWithDelay($event, true)"></ptl-admin-member-control-panel>
        </mat-card>

        <ng-container *ngIf="!isInDialogMode">
          <button *ngIf="members?.length"
                  class="members-list__expand-table-btn"
                  [attr.aria-label]="('translations.global.ariaLabels.expandTable' | transloco)"
                  (click)="expandTable()">
            <span class="small">{{ 'translations.membersNew.list.expandTable' | transloco }}</span>
            <svg class="svg" role="img" [attr.aria-label]="('translations.global.ariaLabels.icons.expand' | transloco)">
              <use ogSvgUnify="assets/sprite/sprite.svg#expand_content"></use>
            </svg>
          </button>
        </ng-container>
        <ptl-admin-member-list-new class="admin-member-list-container f_admin-member-list-container"
                                   #membersList
                                   *ngIf="membersListLoaded"
                                   [columns]="columns"
                                   [isPlaylistPage]="isPlaylistPage"
                                   [isCardPage]="isCardPage"
                                   [isEvent]="isEvent"
                                   [checkedMembers]="checkedMembers"
                                   [members]="members"
                                   [membersTotalCount]="membersTotalCount"
                                   [isWaitingListEnabled]="isWaitingListEnabled"
                                   [isWaitingListOperationDisabled]="isWaitingListOperationDisabled"
                                   [orderInput]="order"
                                   [paginationLength]="paginationLength"
                                   (orderChanged)="changeListOrderingByBooleanFilter($event)"
                                   (membersChecked)="onMembersChecked($event)"
                                   (memberActionTriggered)="handleMemberAction($event)"
                                   (refreshList)="refreshList($event)"></ptl-admin-member-list-new>
      </div>
    </div>
  </div>
</ng-template>
