<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="question-card__slider-wrapper">
  <div class="question-card__slider-ranges">
    <div class="question-card__slider-range-item"></div>
    <div class="question-card__slider-range-item"></div>
    <div class="question-card__slider-range-item"></div>
    <div
      tabindex="0"
      class="question-card__slider-runner"
      [attr.aria-label]="('translations.global.ariaLabels.sliderOption' | transloco) + ': ' + (runnerPosition === 0 ? ariaLeftEnd.innerText
       : runnerPosition === 50 ? ariaMiddle.innerText : runnerPosition === 100 ? ariaRightEnd.innerText :
       runnerPosition < 50 ? ariaLeft.innerText : ariaRight.innerText) +  question?.answeredCorrectly ? ' answered correctly' : ' answered incorrect'"
      [class.wrong-answer]="!this.question?.answeredCorrectly"
      [style.left]="runnerPosition + '%'">
      <mat-icon aria-hidden="true" >{{this.question?.answeredCorrectly ? 'check' : 'clear'}}</mat-icon>
    </div>
  </div>
  <div class="question-card__slider-texts">
    <div class="question-card__slider-text left-text">{{ leftTitle }}</div>
    <div class="question-card__slider-text right-text">{{ rightTitle }}</div>
  </div>
</div>

<span #ariaMiddle class="question-aria">{{ 'translations.slideSummaryAriaMiddle' | transloco }}</span>
<span #ariaLeft class="question-aria">{{ 'translations.sliderSummaryAriaLeft' | transloco }}</span>
<span #ariaRight class="question-aria">{{ 'translations.sliderSummaryAriaRight' | transloco }}</span>
<span #ariaLeftEnd class="question-aria">{{ 'translations.sliderSummaryAriaLeftEnd' | transloco }}</span>
<span #ariaRightEnd class="question-aria">{{ 'translations.sliderSummaryAriaRightEnd' | transloco }}</span>

