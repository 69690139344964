import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ptl-member-filter-controls',
  templateUrl: './member-filter-controls.component.html',
  styleUrls: ['./member-filter-controls.component.scss'],
})
export class MemberFilterControlsComponent {

  @Output() cancel = new EventEmitter<void>();
  @Output() save = new EventEmitter<void>();

  onSave() {
    this.save.emit();
  }

  onCancel() {
    this.cancel.emit();
  }
}
