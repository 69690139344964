import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AttendanceRequest,
  AttendanceType,
  BooleanFilter, BooleanQueryRequest,
  BooleanQueryType, NotRequest, OrRequest
} from '../../../models/admin/boolean-filters.model';
import { TranslationService } from '../../../services/translation/translation.service';

@Component({
  selector: 'ptl-attendance-filter',
  templateUrl: './attendance-filter.component.html',
  styleUrls: ['./attendance-filter.component.scss']
})
export class AttendanceFilterComponent implements OnInit {

  @Input() selectedFilters: BooleanFilter[];
  @Output() filterDeleted = new EventEmitter<void>();
  @Output() filterSaved = new EventEmitter<BooleanFilter>();

  selectedAttendance: AttendanceType[] = []
  expanded = true;
  attendanceIs = true;
  attendances: { type: AttendanceType; value: boolean; label: string }[] = [];

  constructor(private translationService: TranslationService) {
  }

  ngOnInit() {
    this.getSettingsFromFilters();
    this.initAttendances();
  }

  setAttendanceIs() {
    this.attendanceIs = true;
  }

  setAttendanceIsNot() {
    this.attendanceIs = false;
  }

  changeAttendance(attendanceType: AttendanceType) {
    this.attendances = this.attendances.map(attendance => {
      if (attendanceType === attendance.type) {
        attendance.value = !attendance.value;
      }
      return attendance;
    });
  }

  deleteFilter() {
    this.filterDeleted.emit();
  }

  applyFilter() {
    const selectedAttendances = this.attendances.filter(attendance => attendance.value);
    const request = this.getRequest(selectedAttendances.map(attendance => attendance.type));
    const message = this.getMessage(selectedAttendances.map(status => status.label));
    this.filterSaved.emit({ request: request, message: message, type: 'ATTENDANCE' });
  }

  private getRequest(attendances: AttendanceType[]): BooleanQueryRequest {
    const attendanceRequest: AttendanceRequest[] = attendances.flatMap(
      attendance => {
        const request: AttendanceRequest = { type: BooleanQueryType.ATTENDANCE, attendanceType: attendance }
        return request;
      }
    );
    if (this.attendanceIs) {
      return { type: BooleanQueryType.OR, args: attendanceRequest } as BooleanQueryRequest;
    } else {
      return { type: BooleanQueryType.NOT, arg: { type: BooleanQueryType.OR, args: attendanceRequest }} as BooleanQueryRequest;
    }
  }

  private getMessage(labels: string[]): string {
    if (this.attendanceIs) {
      return this.translationService.getTranslation('filter.attendance.name') + ' '
        + this.translationService.getTranslation('filter.is') + ' '
        + labels.join(' ' + this.translationService.getTranslation('filter.or') + ' ');
    } else {
      return this.translationService.getTranslation('filter.attendance.name') + ' '
        + this.translationService.getTranslation('filter.isNot') + ' '
        + labels.join(' ' + this.translationService.getTranslation('filter.andNot') + ' ');
    }
  }

  private getSettingsFromFilters() {
    if (this.selectedFilters) {
      const statusFilter = this.selectedFilters.find(f => f.type === 'ATTENDANCE');
      if (statusFilter) {
        const booleanRequest = (statusFilter.request as BooleanQueryRequest);
        if (booleanRequest.type === BooleanQueryType.NOT) {
          this.attendanceIs = false;
          this.selectedAttendance = ((booleanRequest as NotRequest).arg as OrRequest).args
            .map(r => (r as AttendanceRequest).attendanceType);
        } else {
          this.attendanceIs = true;
          this.selectedAttendance = (booleanRequest as OrRequest).args
            .map(r => (r as AttendanceRequest).attendanceType);
        }
      }
    }
  }

  private initAttendances() {
    this.attendances = [
      {
        type: 'REGISTERED',
        value: this.selectedAttendance.includes('REGISTERED'),
        label: this.translationService.getTranslation('filter.attendance.registered')
      },
      {
        type: 'WAITING_LIST',
        value: this.selectedAttendance.includes('WAITING_LIST'),
        label: this.translationService.getTranslation('filter.attendance.waitingList')
      },
      {
        type: 'PARTICIPATED',
        value: this.selectedAttendance.includes('PARTICIPATED'),
        label: this.translationService.getTranslation('filter.attendance.participated')
      }
    ];
  }
}

