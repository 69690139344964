/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  SubmitPlaylistReviewDialogComponent
} from 'src/app/page-modules/playlist/shared/view/playlist-action-bar/submit-dialog/submit-dialog.component';
import {
  RefreshUserPlaylistSubmissionSummary
} from 'src/app/page-modules/playlist/store/view/playlist-view.state.actions';
import { DialogService } from 'src/app/shared/helpers/dialog/dialog.service';
import {
  LearnerFormAnswer,
  LearnerFormAnswerRequest,
  LearnerResourceProgress
} from '../../../../../../page-modules/resource/models';
import {
  LEARNER_VIEW_DATA_SERVICE,
  LearnerViewDataService
} from '../../../../../../page-modules/resource/services/data.service';
import { RefreshStateAfterFormSubmission } from '../../../../../../page-modules/resource/store/learner-view.actions';
import { LearnerViewState } from '../../../../../../page-modules/resource/store/learner-view.state';
import { ContentHelper } from '../../../../../../shared/helpers/content-helper';
import { FileUploadHelper } from '../../../../../../shared/helpers/file-upload-helper';
import { LocalTimeHelper } from '../../../../../../shared/helpers/local-time-helper';
import { SnackbarHelper } from '../../../../../../shared/helpers/snackbar-helper';
import {
  FileAnswer,
  FileUploadBoxFormContent,
  Form,
  Organization,
  Resource,
  UserPlaylistSubmissionSummary
} from '../../../../../../shared/models';
import {
  FileUploadService,
  RESOURCES_FILE_UPLOAD_DATA_SERVICE
} from '../../../../../../shared/services/file-upload/file-upload.service';
import { TranslationService } from '../../../../../../shared/services/translation/translation.service';
import { Result } from '../../../../../../shared/store';
import { UserDetails } from '../../../../../../user-auth/models';
import { UserAuthState } from '../../../../../../user-auth/store/user-auth.state';
import { LanguageCodeHelper } from '../../../../../../shared/helpers/language-code-helper';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'ptl-form-preview-file-upload-new',
  templateUrl: './form-preview-file-upload-new.component.html',
  styleUrls: ['./form-preview-file-upload-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class FormPreviewFileUploadNewComponent implements OnInit, OnChanges, OnDestroy {

  /** Receives the file upload type object */
  @Input() formData: Form<FileUploadBoxFormContent>;
  @Input() learnerFormAnswer: LearnerFormAnswer;
  @Input() userPlaylistSubmissionSummary: UserPlaylistSubmissionSummary;
  @Input() isProjectResource: boolean;
  @Input() hideTextFormButtons: boolean;
  @Input() playlistUri: string;
  @Input() playlistUid: string;
  @Input() playlistTitle: string;
  @Input() resourceUri: string;
  @Input() resourceUid: string;
  @Input() groupUid: string;
  @Input() publisherUri: string;
  @Input() packageUri: string;
  @Input() pageUri: string;
  @Input() languageCode: string;

  @Select(UserAuthState.userDetailsData)
  private userDetails$: Observable<UserDetails>;

  @Select(UserAuthState.organizationDetails)
  private organizationData$: Observable<Organization>;

  @Select(LearnerViewState.resourceData)
  private resourceData$: Observable<Resource>;

  filesHovered = false;
  fileUploaded = false;
  fileUploadInProgress: boolean;
  fileRemoveInProgress: boolean;
  submitted = false;
  canEdit = false;
  canNotEditReason: string;
  supportedFileTypes = [];
  requiresResubmission = false;
  submissionInProgress = false;
  validationInProgress = false;
  validationProcessed = false;
  answersStructure: FileAnswer[] = [];

  private organization: Organization;
  private cardUid = '';
  private oneMegabyte = 1048576;
  private userData: UserDetails;
  private userDetailsSubscription: Subscription;
  private organizationDataSubscription: Subscription;
  private resourceSubscription: Subscription;
  private fileInput: ElementRef;

  @ViewChild('fileInput', { static: false }) set fileInputContent(content: ElementRef) {
    if (content) {
      this.fileInput = content;
    }
  }

  constructor(
    private store: Store,
    private ref: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private ngZone: NgZone,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private dialogService: DialogService,
    private translationService: TranslationService,
    @Inject(LEARNER_VIEW_DATA_SERVICE) private dataService: LearnerViewDataService,
    @Inject(RESOURCES_FILE_UPLOAD_DATA_SERVICE) private fileUploadService: FileUploadService
  ) {
  }

  ngOnInit() {
    this.userDetailsSubscription = this.userDetails$
      .pipe(filter(userData => !!userData))
      .subscribe((userData: UserDetails) => this.userData = userData);

    this.saveOrganizationDataToBeUsedForFileUpload();

    this.resourceSubscription = this.resourceData$.pipe(filter(resourceData => !!resourceData))
      .subscribe((resourceData) => {
        this.cardUid = resourceData._id;
      });
    if (this.formData && this.formData.content) {
      const content = this.formData.content as FileUploadBoxFormContent;
      if (content.userAnswer) {
        this.submitted = content.submitted;
        this.fileUploaded = true;
        this.answersStructure = FileUploadHelper.formatAnswersStructure(content.userAnswer);
      }
      if (content.supportedFileTypes) {
        this.supportedFileTypes = content.supportedFileTypes;
      }
    }

    this.checkSubmissionMode();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.playlistUid && this.resourceUid && this.formData?.uid) {
      this.checkSubmissionMode();
    } else {
      if (changes.learnerFormAnswer || changes.userPlaylistSubmissionSummary) {
        this.checkSubmissionMode();
      }
    }
  }

  ngOnDestroy() {
    this.userDetailsSubscription?.unsubscribe();
    this.organizationDataSubscription?.unsubscribe();
    this.resourceSubscription?.unsubscribe();
  }

  submit() {
    if (ContentHelper.isProgressiveSubmissionMode(this.userPlaylistSubmissionSummary)) {
      const dialogMessage =
        this.userPlaylistSubmissionSummary?.userReviewSummary?.reviewType &&
        this.userPlaylistSubmissionSummary.userReviewSummary.reviewType !== 'AUTOMATIC' ?
          this.translationService.getTranslation('dialog.title.formSubmissionReviewEnabled') :
          this.translationService.getTranslation('dialog.title.formSubmission')
      this.dialogService.showConfirmDialog(
        dialogMessage,
        this.translationService
      ).then(confirmed => {
          if (confirmed) {
            this.processSubmit();
          }
        }
      );
    } else {
      this.processSubmit();
    }
  }

  processSubmit() {
    if (ContentHelper.shouldDisplayReviewersDialogOnFormSubmission(this.userPlaylistSubmissionSummary)) {
      this.openReviewersDialog();
    } else {
      this.submitFileUpload();
    }
  }

  submitFileUpload(): void {
    this.submissionInProgress = true;
    this.dataService.submitForm(this.playlistUid, this.resourceUid, this.formData.uid).subscribe(({ isSuccess }) => {
      if (isSuccess) {
        this.refreshStateAndFinishSubmission();
      }
    });
  }

  private refreshStateAndFinishSubmission() {
    this.store.dispatch(new RefreshUserPlaylistSubmissionSummary(this.playlistUid));
    this.dataService.getCardWithProgress(
      this.isProjectResource,
      this.playlistUri,
      this.resourceUri,
      this.groupUid,
      this.publisherUri,
      this.packageUri,
      this.pageUri,
      this.languageCode
    ).subscribe(
      ({ isSuccess, value }) => {
        if (isSuccess) {
          this.store.dispatch(new RefreshStateAfterFormSubmission(value));
          this.finishSubmission();
        }
      });
  }

  private openReviewersDialog() {
    const dialogRef = this.dialog.open(SubmitPlaylistReviewDialogComponent, {
      width: '90vw',
      minWidth: '15.625rem',
      maxWidth: '46.875rem',
      maxHeight: '33.75rem',
      restoreFocus: true,
      position: {
        top: '10vh',
      },
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      panelClass: 'ptl-mat-dialog',
      backdropClass: 'dialog-backdrop',
      data: {
        isProjectResource: this.isProjectResource,
        playlistUid: this.playlistUid,
        playlistTitle: this.playlistTitle,
        userPlaylistSubmissionSummary: this.userPlaylistSubmissionSummary,
        mode: 'FORM',
        resourceUid: this.resourceUid,
        formUid: this.formData.uid,
        playlistUri: this.playlistUri,
        resourceUri: this.resourceUri,
        groupUid: this.groupUid,
        publisherUri: this.publisherUri,
        packageUri: this.packageUri,
        pageUri: this.pageUri,
        languageCode: this.languageCode
      }
    });
    dialogRef.afterClosed().subscribe((data => {
      if (data) {
        this.finishSubmission();
      }
    }))
  }

  private finishSubmission() {
    this.submissionInProgress = false;
    this.submitted = true;
    this.checkSubmissionMode();
  }

  removeFile(event: Event, filePath: string, index: number): void {
    event.preventDefault();
    event.stopPropagation();

    this.fileUploadInProgress = true;
    this.fileRemoveInProgress = true;
    this.dataService.removeFileFromForm(this.playlistUid, this.cardUid, this.formData.uid, filePath)
      .subscribe(({ isSuccess, error }) => {
        if (isSuccess) {
          (this.fileInput.nativeElement as HTMLInputElement).value = ''
          this.submitted = false;
          this.answersStructure.splice(index, 1);

          if (this.answersStructure.length === 0) {
            this.fileUploaded = false;
          }

          SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'fileSuccessfullyRemoved');
        } else {
          SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
        }
        this.fileUploadInProgress = false;
        this.fileRemoveInProgress = false;
        this.cd.detectChanges();
      });
  }


  get isSubmissionDisabled(): boolean {
    return this.answersStructure.length < 1 || this.submissionInProgress;
  }

  onFilesDropped(fileList: FileList): void {
    this.filesHovered = false;
    if (fileList.length === 1) {
      this.tryToSaveAnswer(fileList.item(0));
    } else {
      SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'uploadFileCountLimitError');
    }
  }

  onFileChanged(eventData: Event): void {
    this.tryToSaveAnswer((eventData.target as HTMLInputElement).files[0]);
  }

  getSupportedFileTypes(): string {
    return this.supportedFileTypes.join(', ').replace(/\./g, '');
  }

  getLocalDateTime(date: Date): string {
    return LocalTimeHelper.getLocalDateTime(date).toString();
  }

  triggerUpload() {
    if (this.fileInput?.nativeElement) {
      this.fileInput.nativeElement.click();
    }
  }

  private saveOrganizationDataToBeUsedForFileUpload() {
    this.organizationDataSubscription = this.organizationData$.subscribe(data => {
      this.organization = data;
    });
  }

  private tryToSaveAnswer(file: File): void {
    if (file) { // falsy when cancel is clicked
      if (file.size > (this.oneMegabyte * this.formData.content.maxFileSizeMb)) {
        const translation = this.translationService.getTranslation('uploadFileLimitError');
        const message = translation.replace('{maxFileSize}', this.formData.content.maxFileSizeMb.toString());
        SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, message);
        (this.fileInput.nativeElement as HTMLInputElement).value = ''
        return
      }
      const fileExtension = file.name.split('.').splice(-1);
      if (!this.supportedFileTypes.includes(`.${fileExtension[0].toLowerCase()}`)) {
        SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'uploadFileTypeError');
        (this.fileInput.nativeElement as HTMLInputElement).value = ''
        return;
      }
      this.uploadFileAndSaveAnswer(file);
    }
  }

  private uploadFileAndSaveAnswer(file: File): void {
    this.fileUploaded = true;
    this.fileUploadInProgress = true;
    const learnerAnswer: LearnerFormAnswerRequest = {
      formUid: this.formData.uid,
      answer: FileUploadHelper.formatFileNameForUpload(file.name),
    };
    this.dataService.saveFormAnswer(this.playlistUid, this.cardUid, learnerAnswer).subscribe(({ isSuccess, value }) => {
      if (isSuccess) {
        this.uploadFile(file, value).then(() => this.handleSaveAnswerSuccess(value));
      }
    });
  }

  private uploadFile(file: File, progressFromBackend: LearnerResourceProgress): Promise<Result<void>> {
    const uuidv4Id = uuidv4();
    this.formData.content['uuid4'] = uuidv4Id;
    const currentForm = progressFromBackend.learnerFormAnswers.find(form => form.formUid === this.formData.uid);
    const fullUrl = currentForm.answer.split(';');
    const filePath = FileUploadHelper.filePath(fullUrl[fullUrl.length - 1]);
    return this.fileUploadService.uploadFile(filePath, file, uuidv4Id).toPromise();
  }

  private handleSaveAnswerSuccess(progress: LearnerResourceProgress) {
    const currentForm = progress.learnerFormAnswers.find(form => form.formUid === this.formData.uid);
    this.answersStructure = FileUploadHelper.formatAnswersStructure(currentForm.answer)
    this.fileUploadInProgress = false;
    this.ref.detectChanges();
  }

  private checkSubmissionMode(): void {
    if (!this.learnerFormAnswer) {
      if (!this.playlistUid || !this.resourceUid || !this.formData?.uid) {
        return;
      }
      if (!this.validationInProgress && !this.validationProcessed) {
        this.validationInProgress = true;
        this.dataService.validateFormUpdate(this.playlistUid, this.resourceUid, this.formData?.uid)
          .subscribe(({ isSuccess, value }) => {
            if (isSuccess) {
              this.canEdit = value.canBeUpdated;
              this.canNotEditReason = ContentHelper.formCanNotBeEditedReason(this.translationService, value);
              this.requiresResubmission = false;
              this.validationProcessed = true;
            }
            this.checkSubmissionLocked();
            this.cd.detectChanges();
            this.validationInProgress = false;
          });
      }
    } else {
      this.canEdit = this.learnerFormAnswer?.updatePermission?.canBeUpdated;
      this.canNotEditReason = ContentHelper.formCanNotBeEditedReason(this.translationService, this.learnerFormAnswer?.updatePermission);
      this.requiresResubmission = this.learnerFormAnswer?.requiresResubmission;
      this.checkSubmissionLocked();
      this.cd.detectChanges();
    }
  }

  private checkSubmissionLocked() {
    if (this.userPlaylistSubmissionSummary?.submissionsLocked) {
      this.canEdit = false;
      this.canNotEditReason = this.translationService.getTranslation('formUpdateDisabledReasons.submissionClosed')
      this.requiresResubmission = false;
    }
  }

}
