<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div
  [class.is_active]="searchFilterActive"
  class="members-filter__search-cell-container f_filter-item">
  <button
    class="search-button"
    mat-button
    [attr.aria-label]="('translations.global.ariaLabels.findPeople' | transloco)"
    (click)="openFilter()">
    <i class="material-icons search-icon" aria-hidden="true">search</i>
    <span class="members-filter__search-label">{{ 'translations.members.filter.button.find' | transloco }}</span>
  </button>
  <div class="members-filter__filter-item-container">
    <div class="members-filter__filter-item-container-inner">
      <form class="members-filter__filter-item-form">
        <div class="input-box">
          <label for="firstNameFilter" class="label">{{ 'translations.members.filter.label.firstName' | transloco }}</label>
          <input
            id="firstNameFilter"
            type="text"
            [attr.aria-label]="('translations.global.ariaLabels.firstName' | transloco)"
            class="members-filter__filter-item-form-input"
            [ngModelOptions]="{standalone: true}"
            (keydown.enter)="onFilterApply()"
            [(ngModel)]="searchFilterBy.firstName">
        </div>
        <div class="input-box">
          <label for="lastNameFilter" class="label">{{ 'translations.members.filter.label.lastName' | transloco }}</label>
          <input
            id="lastNameFilter"
            type="text"
            class="members-filter__filter-item-form-input"
            [attr.aria-label]="('translations.global.ariaLabels.lastName' | transloco)"
            [ngModelOptions]="{standalone: true}"
            (keydown.enter)="onFilterApply()"
            [(ngModel)]="searchFilterBy.lastName">
        </div>
        <div class="input-box">
          <label for="idFilter" class="label">{{ 'translations.members.filter.label.id' | transloco }}</label>
          <input
            id="idFilter"
            type="text"
            class="members-filter__filter-item-form-input"
            [ngModelOptions]="{standalone: true}"
            [attr.aria-label]="('translations.global.ariaLabels.externalId' | transloco)"
            (keydown.enter)="onFilterApply()"
            [(ngModel)]="searchFilterBy.id">
        </div>
      </form>
      <div class="members-filter__filter-item-actions">
        <button
          mat-stroked-button
          class="cancel-btn uppercase"
          [attr.aria-label]="('translations.global.ariaLabels.closeFindPeople' | transloco)"
          (click)="closeSearchFilter()">
          {{ 'translations.global.button.cancel' | transloco }}
        </button>
        <button
          mat-stroked-button
          class="apply-btn uppercase"
          [attr.aria-label]="('translations.global.ariaLabels.applyFindPeople' | transloco)"
          (click)="onFilterApply()">
          {{ 'translations.global.button.apply' | transloco }}
        </button>
      </div>
    </div>
  </div>
<div class="members-filter__active-filters">
  <div class="members-filter__active-filters-item" *ngFor="let filter of activeFilters; let i = index;">
    <div class="bold">{{ filter.content.title }}</div>
    <span
      class="members-filter__active-filters-item-remove"
      matTooltip="Remove filter"
      [attr.aria-label]="('translations.global.ariaLabels.clear' | transloco: {title: filter.content.title}) + ' ' + ('translations.global.ariaLabels.fromFilter' | transloco)"
      (click)="deleteActiveFilter(i, filter)"
      (keydown.enter)="deleteActiveFilter(i, filter)"
      tabindex="0">
        <mat-icon aria-hidden="true" [inline]="true">clear</mat-icon>
      </span>
  </div>
</div>
</div>
