<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ng-container *ngIf="!newVersionEnabled">

  <div class="framework-nested-tags__container" *ngIf="frameworkTags || frameworkNestedTags">
    <button mat-icon-button
            type="button"
            class="back-button"
            [attr.aria-label]="('translations.global.ariaLabels.back' | transloco)"
            (click)="closeNestedTags()"
            *ngIf="frameworkNestedTags">
      <mat-icon aria-hidden="true">close</mat-icon>
    </button>
    <ul class="framework-tags__tags-list" role="list">
      <li
        class="framework-tags__tags-list-item f_tags-list-item"
        [class.selected]="tag.checked"
        [class.disabled]="tag._id | disableTag:selectedTagIds:isTagDisabled"
        [title]="tag.title"
        *ngFor="let tag of tags"
        role="listitem"
        tabindex="0"
        [attr.aria-label]="tag.title"
        (click)="onTagItemClick(tag)"
        (keydown.enter)="onTagItemClick(tag)">
        <span class="framework-tags__tag-title">
          {{ tag.title }}
        </span>

        <span
          *ngIf="tag.descendantsCount"
          class="framework-tags__tags-list-item-button"
          title="Show descendant tags"
          tabindex="0"
          role="button"
          [attr.aria-label]="tag.descendantsCount + ' ' + ('translations.global.ariaLabels.showDescendantTags' | transloco)"
          (click)="loadNestedFramework($event, tag._id)"
          (keydown.enter)="loadNestedFramework($event, tag._id); $event.stopPropagation()">
          {{ tag.descendantsCount }} {{ 'translations.global.button.more' | transloco }}
        </span>
      </li>
    </ul>
  </div>
</ng-container>

<ng-container *ngIf="newVersionEnabled">

  <div class="tags-container" *ngIf="frameworkTags || frameworkNestedTags">
    <ng-container *ngIf="(isUserAdmin$ | async) ||
                          (isUserSuperAdmin$ | async) ||
                          ((userDetailsData$ | async)?.uid === selectedFramework?.creatorUid)">
      <div class="empty-tags-container" *ngIf="!tags || tags.length < 1">
        <span class="small" [innerHTML]="(frameworkType === 'STANDARD' ? ('translations.framework.label.emptyFrameworkTags' | transloco) :  ('translations.framework.label.emptyCategories' | transloco))"></span>
      </div>
    </ng-container>

    <div class="tags-list" role="list">
      <ng-container *ngTemplateOutlet="tagsTree; context: {$implicit: tags}"></ng-container>
    </div>
  </div>

  <ng-template #tagsTree let-tags>
    <ng-container *ngFor="let tag of tags">
      <div class="tags-list-item small"
           [class.selected]="tag.checked"
           [class.disabled]="tag._id | disableTag:selectedTagIds:isTagDisabled"
           [class.checkbox-enabled]="type === 'DEFAULT'"
           [title]="tag.title"
           role="listitem"
           tabindex="0"
           [attr.aria-label]="tag.title"
           (click)="onTagItemClick(tag)"
           (keydown.enter)="onTagItemClick(tag)">
        <span class="tag-title">
        {{ tag.title }}
        </span>

        <button
          *ngIf="tag.descendantsCount && !tag.childTags"
          class="more-button"
          title="Show descendant tags"
          tabindex="0"
          role="button"
          [attr.aria-label]="tag.descendantsCount + ' ' + ('translations.global.ariaLabels.showDescendantTags' | transloco)"
          (click)="loadNestedFramework($event, tag._id)">
          {{ tag.descendantsCount }} {{ 'translations.global.button.more' | transloco }}
        </button>
      </div>

      <div class="tags-nested-list" *ngIf="tag.childTags">
        <ng-container *ngTemplateOutlet="tagsTree; context: {$implicit: tag.childTags}"></ng-container>
      </div>
    </ng-container>
  </ng-template>
</ng-container>
