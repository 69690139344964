/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngxs/store';
import { switchMap, tap } from 'rxjs/operators';
import { FileUploadHelper } from '../../../../../../shared/helpers/file-upload-helper';
import { EditorTransformer } from '../../../../../../shared/helpers/editor-transformer';
import { SnackbarHelper } from '../../../../../../shared/helpers/snackbar-helper';
import {
	EditorContent,
	EditorJsContent,
	EditorJsDeletedContent,
	MediaUploadData,
	Resource,
	ResourceCardType,
	ResourceSection
} from '../../../../../../shared/models';
import { FileUploadService } from '../../../../../../shared/services/file-upload/file-upload.service';
import { SubheaderDataService } from '../../../../../../shared/services/subheader/subheader-data.service';
import { TranslationService } from '../../../../../../shared/services/translation/translation.service';
import { SaveNewCardDataToPlaylistSection } from '../../../../../playlist/store/create/playlist-creation.actions';
import { PlaylistCreationState } from '../../../../../playlist/store/create/playlist-creation.state';
import { ResourceSectionRequest } from '../../../../models/editor';
import { ResourceDataService } from '../../../../services/editor/core/data.service';
import { DynamicContentUploadHelper } from './dynamic-content-upload.helper';
import { ResourceAdminStateModel } from '../../../admin/resource-admin.state.model';
import { from, of } from 'rxjs';

export class SaveHelper {

	static saveUpdates(
		state: ResourceAdminStateModel,
		patchState: ( p: Partial<ResourceAdminStateModel> ) => Partial<ResourceAdminStateModel>,
		subheaderService: SubheaderDataService,
		store: Store,
		snackBar: MatSnackBar,
		ngZone: NgZone,
		translationService: TranslationService,
		groupUri: string,
		preventRedirection: boolean,
		languageCode?: string
	) {

		if ( !state.resource ) {
			return;
		}

		const resourceData = state.resource;
		const cardType = state.resource.cardType;
		const resource = {
			...state.resource,
			settings: state.resource.settings ? {
				...state.resource.settings,
				publication: {
					...state.resource.settings.publication
				}
			} : null
		};

		if ( !resourceData.content.headline ) {
			return this.resourceHeadlineEmptyValidationHandler(state, patchState);
		}

		if ( !this.formFieldsAreValid(resourceData) ) {
			return this.resourceFormValidationHandler(state, patchState, snackBar, ngZone, translationService);
		}

		this.setStateInLoadedMode(patchState);

		if ( resourceData.cardType === 'WEBLINK' && resourceData.subHeader?.length ) {
			subheaderService.updateCardSubheader(resourceData._id,
				{
					subHeader: resourceData.subHeader
				}, languageCode).subscribe();
		}

		return this.processResourceUpdateResponse(
			patchState,
			store,
			resource,
			resource.settings?.publication.uri,
			groupUri,
			preventRedirection,
			cardType
		);

	}

	private static processResourceUpdateResponse(
		patchState: ( p: Partial<ResourceAdminStateModel> ) => Partial<ResourceAdminStateModel>,
		store: Store,
		resource: Resource,
		resourceUri: string,
		groupUri: string,
		preventRedirection: boolean,
		cardType: ResourceCardType
	) {
		const parentPlaylistHasNewCard = store.selectSnapshot(PlaylistCreationState.hasNewCard);
		// save card to playlist section if newly created
		if ( preventRedirection ) {
			if ( parentPlaylistHasNewCard ) {
				store.dispatch(new SaveNewCardDataToPlaylistSection(resource));
			}
			this.setStateInLoadedMode(patchState);
			return;
		}
		// save card to playlist section if newly created and go back
		if ( parentPlaylistHasNewCard ) {
			store.dispatch(new SaveNewCardDataToPlaylistSection(resource)).toPromise().then(() => {
				DynamicContentUploadHelper.goBack(patchState, store, resourceUri, groupUri, cardType);
			});
		} else {
			DynamicContentUploadHelper.goBack(patchState, store, resourceUri, groupUri, cardType);
		}
	}

	private static resourceHeadlineEmptyValidationHandler(
		state: ResourceAdminStateModel,
		patchState: ( p: Partial<ResourceAdminStateModel> ) => Partial<ResourceAdminStateModel>
	) {
		return patchState({
			contentChanged: false,
			saveStatus: {
				autoSaveInProgress: false
			}
		});
	}

	private static resourceFormValidationHandler(
		state: ResourceAdminStateModel,
		patchState: ( p: Partial<ResourceAdminStateModel> ) => Partial<ResourceAdminStateModel>,
		snackBar: MatSnackBar,
		ngZone: NgZone,
		translationService: TranslationService
	) {
		SnackbarHelper.showTranslatableSnackBar(ngZone, snackBar, translationService, 'completeFormData');
		return patchState({
			contentChanged: false,
			saveStatus: {
				autoSaveInProgress: false
			}
		});
	}

	static saveBlockItem(
		cardUid: string,
		sectionUid: string,
		resourceData: EditorJsContent,
		languageCode: string,
		resourceDataService: ResourceDataService,
		fileUploadService: FileUploadService,
		getState: () => ResourceAdminStateModel,
		patchState: ( p: Partial<ResourceAdminStateModel> ) => Partial<ResourceAdminStateModel>
	) {
		const appendToBlock = resourceData.appendToBlock;
		const state = getState();
		const file = resourceData['file'];

		delete resourceData['file'];

		const resourceDataForRequest = {
		  ...resourceData
		};

		return resourceDataService.createEditorBlock(cardUid, sectionUid, resourceDataForRequest, languageCode)
		  .pipe(
			switchMap(({ isSuccess, value }) => {
			  if (isSuccess) {
				const blockFromResponse = value.item;
				blockFromResponse['isPaste'] = resourceData['isPaste'];
				blockFromResponse['needToAddIntoFirstIndex'] = resourceData['needToAddIntoFirstIndex'];
				blockFromResponse['hasFile'] = true;

				if (blockFromResponse['mediaType'] === 'IMAGE' && file) {
				  return from(this.extractImageDimensions(file)).pipe(
					switchMap(({ width, height }) => {
					  blockFromResponse['imageDimensions'] = { imageWidth: width, imageHeight: height };

					  return this.uploadAndPatchState(file, fileUploadService, value.uploadUrl, blockFromResponse,
						 patchState, state, sectionUid, resourceData.index || 0, appendToBlock);
					})
				  );
				} else {
				  return this.uploadAndPatchState(file, fileUploadService, value.uploadUrl, blockFromResponse,
					patchState, state, sectionUid, resourceData.index || 0, appendToBlock);
				}
			  } else {
				return of(null).pipe(
				  tap(() => {
					patchState({
					  contentChanged: false,
					  saveStatus: {
						autoSaveInProgress: false
					  }
					});
				  })
				);
			  }
			})
		  );
	  }

	  private static uploadAndPatchState(
		file: File,
		fileUploadService: FileUploadService,
		uploadUrl: string,
		blockFromResponse: ResourceSection,
		patchState: (p: Partial<ResourceAdminStateModel>) => Partial<ResourceAdminStateModel>,
		state: ResourceAdminStateModel,
		sectionUid: string,
		index: number,
		appendToBlock: string
	  ) {
		return FileUploadHelper.uploadBlockObservable(file, fileUploadService, uploadUrl, blockFromResponse.uid).pipe(
		  tap(() => {
			this.patchStateWithUpdatedBlock(
			  blockFromResponse,
			  patchState,
			  state,
			  sectionUid,
			  index,
			  false,
			  appendToBlock
			);
		  })
		);
	  }

	  static extractImageDimensions(file: File): Promise<{ width: number; height: number }> {
		return new Promise((resolve) => {
		  const img = new Image();
		  const objectUrl = URL.createObjectURL(file);

		  img.onload = () => {
			const container = document.createElement('div');
			container.style.width = '100%';
			container.style.maxWidth = '1000px';
			container.style.height = 'auto';
			container.style.position = 'absolute';
			container.style.top = '-9999px';
			container.style.visibility = 'hidden';
			document.body.appendChild(container);

			container.appendChild(img);

			setTimeout(() => {
			  const renderedWidth = img.clientWidth;
			  const renderedHeight = img.clientHeight;
			  URL.revokeObjectURL(objectUrl);
			  document.body.removeChild(container);
			  resolve({ width: renderedWidth, height: renderedHeight });
			}, 100);
		  };
		  img.src = objectUrl;
		});
	  }

	static updateBlockItem(
		cardUid: string,
		sectionUid: string,
		resourceData: EditorContent,
		languageCode: string,
		resourceDataService: ResourceDataService,
		fileUploadService: FileUploadService,
		getState: () => ResourceAdminStateModel,
		patchState: (p: Partial<ResourceAdminStateModel>) => Partial<ResourceAdminStateModel>
	) {
		const { appendToBlock, replace, file } = this.extractContentInfo(resourceData);
		const state = getState();
		const data: EditorContent & { replace: boolean } = resourceData.content;

		return resourceDataService.updateEditorBlock(cardUid, sectionUid, resourceData.content.uid, data, languageCode).pipe(
			switchMap(({ isSuccess, value }) => {
				patchState({ contentChanged: true });

				if (!isSuccess) {
					return this.handleFailure(patchState);
				}


				const blockFromResponse = this.prepareBlockResponse(value.item, file);

				if (file && blockFromResponse['mediaType'] === 'IMAGE') {
					return this.handleImageUpload(blockFromResponse, file, fileUploadService, value.uploadUrl, state,
						patchState, sectionUid, appendToBlock, replace, resourceData);
				} else {
					return this.handleFileUpload(blockFromResponse, file, fileUploadService, value.uploadUrl, state,
						patchState, sectionUid, appendToBlock, replace, resourceData);
				}
			})
		);
	}

	private static extractContentInfo(resourceData: EditorContent) {
		const appendToBlock = resourceData['content']['appendToBlock'] || resourceData['appendToBlock'];
		const replace = resourceData['content']['replace'] || resourceData['replace'];
		const file = resourceData['content']['file'];
		delete resourceData['content']['file'];
		return { appendToBlock, replace, file };
	}

	private static prepareBlockResponse(block: ResourceSection, file: File | undefined) {
		block['hasFile'] = !!file;
		return block;
	}

	private static handleFailure(patchState: (p: Partial<ResourceAdminStateModel>) => Partial<ResourceAdminStateModel>) {
		patchState({
			contentChanged: false,
			saveStatus: {
				autoSaveInProgress: false
			}
		});
		return of(null); // Handle failure case
	}

	private static handleImageUpload(
		blockFromResponse: ResourceSection,
		file: File,
		fileUploadService: FileUploadService,
		uploadUrl: string,
		state: ResourceAdminStateModel,
		patchState: (p: Partial<ResourceAdminStateModel>) => Partial<ResourceAdminStateModel>,
		sectionUid: string,
		appendToBlock: string,
		replace: boolean,
		resourceData: EditorContent
	) {
		return from(this.extractImageDimensions(file)).pipe(
			switchMap(({ width, height }) => {
				blockFromResponse['imageDimensions'] = { imageWidth: width, imageHeight: height };
				return this.uploadFile(blockFromResponse, file, fileUploadService, uploadUrl, state,
					patchState, sectionUid, appendToBlock, replace, resourceData);
			})
		);
	}

	private static handleFileUpload(
		blockFromResponse: ResourceSection,
		file: File | undefined,
		fileUploadService: FileUploadService,
		uploadUrl: string,
		state: ResourceAdminStateModel,
		patchState: (p: Partial<ResourceAdminStateModel>) => Partial<ResourceAdminStateModel>,
		sectionUid: string,
		appendToBlock: string,
		replace: boolean,
		resourceData: EditorContent
	) {
		if (!file) return of(null).pipe(
			tap(() => {
				this.patchStateWithUpdatedBlock(
					blockFromResponse,
					patchState,
					state,
					sectionUid,
					resourceData.index || resourceData?.content?.currentIndex || 0,
					false,
					appendToBlock,
					replace
				  );
			})
		);

		return this.uploadFile(blockFromResponse, file, fileUploadService, uploadUrl, state,
			patchState, sectionUid, appendToBlock, replace, resourceData);
	}

	  private static uploadFile(
		blockFromResponse: ResourceSection,
		file: File,
		fileUploadService: FileUploadService,
		uploadUrl: string,
		state: ResourceAdminStateModel,
		patchState: (p: Partial<ResourceAdminStateModel>) => Partial<ResourceAdminStateModel>,
		sectionUid: string,
		appendToBlock: string,
		replace: boolean,
		resourceData: EditorContent
	  ) {
		return FileUploadHelper.uploadBlockObservable(file, fileUploadService, uploadUrl, blockFromResponse.uid).pipe(
		  tap(() => {
			this.patchStateWithUpdatedBlock(
			  blockFromResponse,
			  patchState,
			  state,
			  sectionUid,
			  resourceData.index || resourceData?.content?.currentIndex || 0,
			  false,
			  appendToBlock,
			  replace
			);
		  })
		);
	  }

	static deleteBlockItem(
		cardUid: string,
		sectionUid: string,
		blockUid: string,
		withoutStoreUpdate: boolean,
		resourceData: EditorJsDeletedContent,
		resourceDataService: ResourceDataService,
		getState: () => ResourceAdminStateModel,
		patchState: ( p: Partial<ResourceAdminStateModel> ) => Partial<ResourceAdminStateModel>
	) {
		return resourceDataService.deleteEditorBlock(cardUid, sectionUid, blockUid)
		.pipe(
			tap(( res ) => {
				// if ( !withoutStoreUpdate ) {
				this.patchStateWithUpdatedBlock(null, patchState, getState(), sectionUid, resourceData.index, true);
				// }
			})
		);
	}

	static saveSectionItem(
		snackBar: MatSnackBar,
		ngZone: NgZone,
		translationService: TranslationService,
		sectionUid: string,
		getState: () => ResourceAdminStateModel,
		patchState: ( p: Partial<ResourceAdminStateModel> ) => Partial<ResourceAdminStateModel>,
		dataService: ResourceDataService,
		resourceData: Resource,
		fileUploadService: FileUploadService,
		languageCode: string
	) {
		const resource = resourceData;
		if ( !this.formFieldsAreValid(resource) ) {
			this.setStateInLoadedMode(patchState);
			SnackbarHelper.showTranslatableSnackBar(ngZone, snackBar, translationService, 'completeFormData');
			return undefined;
		}

		const resourceUid = resource._id;
		const sectionToSave = resource.content.sections.find(section => section.uid === sectionUid);

		if ( !sectionToSave ) {
			this.setStateInLoadedMode(patchState);
			SnackbarHelper.showTranslatableSnackBar(ngZone, snackBar, translationService, 'noSectionFoundToSave');
			return undefined;
		}

		const sectionData = {
			...sectionToSave,
			dynamicContent: sectionToSave.dynamicContent
			.map(item => EditorTransformer.transformEditorContentToMatchApiExpectations(item)),
			languageCode: languageCode
		} as ResourceSectionRequest;

		return dataService.updateSection(resourceUid,
			sectionUid,
			sectionData,
			languageCode).pipe(
			tap(( { isSuccess, value } ) => {
				if ( isSuccess ) {
					const sectionFromResponse = value.item;
          			const hasFile =
            		sectionToSave.dynamicContent.find(content => content.type === 'MEDIA_UPLOAD' && !!(content as MediaUploadData).file);
          			if (hasFile) {
            			FileUploadHelper.uploadObservable(sectionToSave, fileUploadService, value.uploadUrl)
              			.subscribe(() => {
                			this.patchStateWithUpdatedSection(sectionFromResponse, patchState, getState(), sectionUid);
              			});
          			} else {
            			this.patchStateWithUpdatedSection(sectionFromResponse, patchState, getState(), sectionUid);
          			}
				}
			})
		);
	}

	private static patchStateWithUpdatedBlock(
		newSection,
		patchState: ( p: Partial<ResourceAdminStateModel> ) => Partial<ResourceAdminStateModel>,
		state: ResourceAdminStateModel,
		sectionUid: string,
		index: number,
		shouldRemove: boolean = false,
		appendToBlock: string = '',
		replace: boolean = false
	): void {
		const updatedDynamicContent: ResourceSection[] = [...state.resource.content.sections];
		updatedDynamicContent.map(section => {
			if ( section.uid === sectionUid ) {
				this.updateSectionInDynamicContent(updatedDynamicContent, index, newSection, shouldRemove, appendToBlock, replace);
			}
		});

		const uniqueDynamicContent = this.removeDuplicates(updatedDynamicContent[0].dynamicContent);
		updatedDynamicContent[0].dynamicContent = uniqueDynamicContent;

		patchState({
			resource: {
				...state.resource,
				content: {
					...state.resource.content,
					sections: updatedDynamicContent
				}
			},
			contentChanged: false,
			saveStatus: {
				autoSaveInProgress: false
			}
		});
	}

	private static removeDuplicates(dynamicContent: EditorContent[]): EditorContent[] {
		return dynamicContent = dynamicContent.filter((editorContent, editorContentIndex, self) =>
			editorContentIndex === self.findIndex(block => block.uid === editorContent.uid)
		);
	}

	private static updateSectionInDynamicContent(
		updatedDynamicContent: ResourceSection[],
		index: number,
		newSection: EditorContent,
		shouldRemove: boolean,
		appendToBlock: string,
		replace: boolean = false
	): void {
		if ( shouldRemove && !newSection ) {
			updatedDynamicContent[0].dynamicContent.splice(index, 1);
		} else {
			if ( index === -1 && !newSection['needToAddIntoFirstIndex']) {
				if ( replace ) {
					updatedDynamicContent[0].dynamicContent.splice(0, 1, newSection);
				} else {
					updatedDynamicContent[0].dynamicContent.push(newSection);
				}
			} else {
				if (newSection['isPaste'] && appendToBlock) {
					const appendToBlockIndex = updatedDynamicContent[0].dynamicContent.findIndex((item) => item.uid === appendToBlock)
					updatedDynamicContent[0].dynamicContent.splice(appendToBlockIndex + 1, 0, newSection);
				} else {
					if ( replace ) {
						updatedDynamicContent[0].dynamicContent.splice(index, 1, newSection);
					} else {
						updatedDynamicContent[0].dynamicContent.splice(index + 1, 0, newSection);
					}
				}
			}
		}
	}

	private static patchStateWithUpdatedSection(
		newSection: ResourceSection,
		patchState: ( p: Partial<ResourceAdminStateModel> ) => Partial<ResourceAdminStateModel>,
		state: ResourceAdminStateModel,
		sectionUid: string
	) {
		patchState({
			resource: {
				...state.resource,
				content: {
					...state.resource.content,
					sections: state.resource.content.sections.map(section =>
						this.getUpdatedSections(section, newSection, sectionUid))
				}
			},
			contentChanged: false,
			saveStatus: {
				autoSaveInProgress: false
			}
		});
		// setTimeout(() => {
		//   const el = document.querySelector('[data-medium-focused="true"]');
		//   if (el) {
		//     this.updateCursorPosition(el as HTMLElement);
		//   }
		// }, 0);

	}

	private static getUpdatedSections( section: ResourceSection, newSection: ResourceSection, sectionUid: string ) {
		if ( section.uid === sectionUid ) {
			const editorElements = document.getElementsByClassName('f_editor-element');
			for ( let i = 0; i < newSection.dynamicContent.length; i++ ) {
				const content = newSection.dynamicContent[i];
				if ( content.type === 'MEDIA_UPLOAD' ) {
					if ( section.dynamicContent[i].index !== content.index ) {
						section.dynamicContent[i] = content;
					}
					if ( section.dynamicContent[i].index === undefined && content.index === undefined ) {
						section.dynamicContent[i] = content;
					}
				} else if ( content.type === 'FORM' ) {
					if ( section.dynamicContent[i].index !== content.index ) {
						section.dynamicContent[i] = content;
					}
				} else if ( content.type === 'PARAGRAPH' ) {
					if ( editorElements.length && editorElements[i] ) {
						const editableContent = editorElements[i].querySelector('.me-editable');
						if ( editableContent ) {
							section.dynamicContent[i].content = editableContent.innerHTML;
						}
					}
					section.dynamicContent[i].uid = content.uid;
				} else if ( content.type === 'TABLE' ) {
					section.dynamicContent[i].uid = content.uid;
				} else {
					section.dynamicContent[i] = content;
				}
			}
		}
		return section;
	}

	private static formFieldsAreValid( resourceContent: Resource ): boolean {
		const someFormFieldIsInvalid = resourceContent.content.sections.some(section =>
			section.dynamicContent
			.filter(content => content.type === 'FORM')
			.some(data => !data['content'] ||
				(data['content'].type !== 'COLLECTOR' && !data['content'].title) ||
				(data['content'].type === 'TEXTBOX' && !data['content'].wordLimit))
		);
		return !someFormFieldIsInvalid;
	}

	private static setStateInLoadedMode(
		patchState: ( p: Partial<ResourceAdminStateModel> ) => Partial<ResourceAdminStateModel> ) {
		patchState({
			contentChanged: false,
			saveStatus: {
				autoSaveInProgress: false
			}
		});
	}

	private static updateCursorPosition( element: HTMLElement ) {
		const offset = this.getCharacterOffsetWithin(element);
		const isHighlighted = this.getSelectedText();
		if ( !isHighlighted ) {
			this.setCurrentCursorPosition(offset, element);
		}
	}

	private static getSelectedText() {
		let text = '';
		if ( typeof window.getSelection !== 'undefined' ) {
			text = window.getSelection().toString();
			// @ts-ignore
		} else if ( typeof document.selection !== 'undefined' && document.selection.type === 'Text' ) {
			// @ts-ignore
			text = document.selection.createRange().text;
		}
		return text;
	}


	private static getCharacterOffsetWithin( element ) {
		let position = 0;
		const isSupported = typeof window.getSelection !== 'undefined';
		if ( isSupported ) {
			const selection = window.getSelection();
			if ( selection.rangeCount !== 0 ) {
				const range = window.getSelection().getRangeAt(0);
				const preCaretRange = range.cloneRange();
				preCaretRange.selectNodeContents(element);
				preCaretRange.setEnd(range.endContainer, range.endOffset);
				position = preCaretRange.toString().length;
			}
		}
		return position;
	}

	private static setCurrentCursorPosition( chars, el ) {
		if ( chars > 0 ) {
			const selection = window.getSelection();

			const range = this.createRange(el.parentNode, { count: chars });

			if ( range ) {
				range.collapse(false);
				selection.removeAllRanges();
				selection.addRange(range);
			}
		}
	}

	private static createRange( node, chars, range? ) {
		if ( !range ) {
			range = document.createRange();
			range.selectNode(node);
			range.setStart(node, 0);
		}

		if ( chars.count === 0 ) {
			range.setEnd(node, chars.count);
		} else if ( node && chars.count > 0 ) {
			if ( node.nodeType === Node.TEXT_NODE ) {
				if ( node.textContent.length < chars.count ) {
					chars.count -= node.textContent.length;
				} else {
					range.setEnd(node, chars.count);
					chars.count = 0;
				}
			} else {
				/* eslint-disable-next-line @typescript-eslint/prefer-for-of */
				for ( let lp = 0; lp < node.childNodes.length; lp++ ) {
					range = this.createRange(node.childNodes[lp], chars, range);

					if ( chars.count === 0 ) {
						break;
					}
				}
			}
		}
		return range;
	}
}
