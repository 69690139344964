import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { AppFrameState } from '../../../app-frame/store/app-frame.state';
import {
  LoadFavoritePlaylists,
  LoadRecentPlaylists,
  RefreshFavoritePlaylists,
  RefreshRecentPlaylists
} from '../../../app-frame/store/summaries.actions';
import { SummariesState } from '../../../app-frame/store/summaries.state';
import { LoadableState, ObservableResult } from '../../store';
import { Page as Pagination } from '../../models/page';
import { CategoryItem, PageList, SearchPage } from '../../models/page/page.model';
import { LearnerPlaylistSummary } from '../../models/playlist/learner-playlist-summary.model';
import {
  FavoritePlaylistSummary, Organization, Playlist,
  SideNavItem,
  SideNavItemIcon,
  SideNavSection, SidenavSettings
} from '../../models';
import {
  STATIC_NAV_CONTENT_DISCOVERY,
  STATIC_NAV_CONTENT_STORE, STATIC_NAV_FOLIO_PAGE,
  STATIC_NAV_OPTIONS_SECTION,
  STATIC_NAV_OPTIONS_SECTION_DISCOVERY_V2
} from '../side-nav-static-options';
import { ClearAllBreadcrumbs } from '../../breadcrumbs/store/breadcrumbs.actions';
import { PageState } from '../../../page-modules/pages/store/pages.state';
import {
  LoadMorePagesSection,
  LoadSubscriptionSectionPlaylists,
  LoadYourSectionPlaylists, RefreshSubscriptionSectionPlaylists,
  RefreshYourSectionPlaylists
} from '../../../page-modules/pages/store/pages.actions';
import { SideBarHelpers } from '../../../page-modules/resource/store/editor/content/helpers/side-bar.helpers';
import { UserAuthState } from '../../../user-auth/store/user-auth.state';
import { SideNavState } from '../store/side-nav.state';
import { SaveSidenavSettings } from '../store/side-nav.actions';
import { CloseMobileNavMenu } from '../../../app-frame/store/app-frame.actions';
import { SHARED_UPCOMING_DATA_SERVICE, SharedUpcomingDataService } from '../../services/upcoming/upcoming-data.service';
import { developmentDomains } from '../../helpers/development-domains.helper';
import {
  CONTENT_PACKAGE_DATA_SERVICE,
  ContentPackageDataService
} from '../../services/content-package/content-package-data.service';
import {
  CORE_MOMENT_DATA_SERVICE,
  MomentDataService
} from '../../../page-modules/folio/shared-moment-module/services/data.service';
import { Moment } from '../../../page-modules/folio/models';
import { ContentHelper } from '../../helpers/content-helper';
import { TranslationService } from '../../services/translation/translation.service';
import { RedirectHelper } from '../../../page-modules/resource/store/editor/content/helpers/redirect.helper';
import { ActivatedRoute } from '@angular/router';
import { VersionHelper } from '../../helpers/version.helper';
import { ReviewsDataService, REVIEWS_DATA_SERVICE } from '../../../page-modules/reviews/services/data.service';
import { ReviewItem } from '../../../page-modules/reviews/models';

@Component({
  selector: 'ptl-main-side-nav',
  templateUrl: './main-side-nav.component.html',
  styleUrls: ['./main-side-nav.component.scss'],
})
export class MainSideNavComponent implements OnInit, OnDestroy {
  @Select(AppFrameState.isMobileMenuExpanded)
  mobileMenuExpanded$: Observable<boolean>;

  @Select(AppFrameState.isMobile)
  isMobile$: Observable<boolean>;

  @Select(SummariesState.playlistSummaries)
  private playlistSummaries$: Observable<LoadableState<Pagination<LearnerPlaylistSummary>>>;

  @Select(SummariesState.favoritePlaylists)
  private favoritePlaylists$: Observable<LoadableState<Pagination<FavoritePlaylistSummary>>>;

  @Select(SideNavState.settings)
  private sidenavSettings$: Observable<SidenavSettings>;

  @Select(PageState.allPages)
  private allPages$: Observable<PageList[]>;

  @Select(UserAuthState.getHomePageTitle)
  private homePageTitle$: Observable<string>;

  @Select(PageState.yourSectionPlaylists)
  private yourSectionPlaylists$: Observable<LoadableState<Pagination<LearnerPlaylistSummary>>>;

  @Select(PageState.subscriptionSectionPlaylists)
  private subscriptionSectionPlaylists$: Observable<LoadableState<Pagination<LearnerPlaylistSummary>>>;

  @Select(PageState.categories)
  private discoveryPageCategories$: Observable<CategoryItem[]>;

  @Select(UserAuthState.isDiscoveryPageAvailable)
  isDiscoveryPageAvailable$: Observable<boolean>;

  @Select(UserAuthState.userHasAdminRole)
  private isUserAdmin$: Observable<boolean>;

  @Select(UserAuthState.userHasSuperAdminRole)
  private isUserSuperAdmin$: Observable<boolean>;

  @Select(UserAuthState.organizationDetails)
  private organizationData$: Observable<Organization>;

  @Select(UserAuthState.userDetailsName)
  private userName$: Observable<string>;

  sideNavSections: SideNavSection[];
  isDevelopmentDomains: boolean;
  isContentStorePage = false;
  isFolioPage = false;

  fixedTopGap = VersionHelper.newVersionEnabled() ? (48 / 16) : (66 / 16);

  private nextSubscriptionSectionPage = 0;
  private nextRecentsPage = 0;
  private nextFavoritesPage = 0;
  private nextYourSectionPage = 0;
  private nextPagesSectionPage = 0;

  private sideNavSectionsSubscription: Subscription;
  private isDiscoveryPageAvailable = false;
  private isContentStoreAvailable = false;
  private isDiscoveryPage = false;

  private PAGES_SECTION_INDEX = -1;
  private SUBSCRIPTION_SECTION_INDEX = -1;
  private FAVORITE_SECTION_INDEX = -1;
  private RECENT_SECTION_INDEX = -1;
  private YOUR_SECTION_INDEX = -1;

  private myPlaylistsExpanded = true;
  private newVersionEnabled = VersionHelper.newVersionEnabled();

  constructor(
    @Inject(SHARED_UPCOMING_DATA_SERVICE) private upcomingDataService: SharedUpcomingDataService,
    @Inject(CORE_MOMENT_DATA_SERVICE) private momentDataService: MomentDataService,
    @Inject(CONTENT_PACKAGE_DATA_SERVICE) private contentPackageService: ContentPackageDataService,
    @Inject(REVIEWS_DATA_SERVICE) private reviewsDataService: ReviewsDataService,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private translationService: TranslationService,
  ) {
  }

  onLinkClick(url: string) {
    this.store.dispatch(new ClearAllBreadcrumbs());
  }

  ngOnInit(): void {
    this.loadSidebar();
    this.store.dispatch(new LoadMorePagesSection(this.nextPagesSectionPage));
    this.store.dispatch(new RefreshSubscriptionSectionPlaylists());
    this.store.dispatch(new RefreshFavoritePlaylists());
    this.store.dispatch(new RefreshRecentPlaylists());
    this.store.dispatch(new RefreshYourSectionPlaylists());
  }

  ngOnDestroy() {
    this.sideNavSectionsSubscription?.unsubscribe();
    this.saveSidenavSettings();
  }

  onLoadMore(sectionIndex: number) {
    this.myPlaylistsExpanded = true;
    switch (sectionIndex) {
      case this.SUBSCRIPTION_SECTION_INDEX:
        this.store.dispatch(new LoadSubscriptionSectionPlaylists(this.nextSubscriptionSectionPage));
        break;
      case this.RECENT_SECTION_INDEX:
        this.store.dispatch(new LoadRecentPlaylists(this.nextRecentsPage));
        break;
      case this.FAVORITE_SECTION_INDEX:
        this.store.dispatch(new LoadFavoritePlaylists(this.nextFavoritesPage));
        break;
      case this.YOUR_SECTION_INDEX:
        this.store.dispatch(new LoadYourSectionPlaylists(this.nextYourSectionPage));
        break;
      case this.PAGES_SECTION_INDEX:
        this.nextPagesSectionPage += 1;
        this.store.dispatch(new LoadMorePagesSection(this.nextPagesSectionPage));
        break;
    }
  }

  onExpandToggle(data: { toggle: boolean; index: number }) {
    const sidenavItem = this.sideNavSections[data.index];
    if (sidenavItem && sidenavItem.className === 'f_playlist-sidenav') {
      this.sideNavSections.map(sideNavItem => {
        if (sideNavItem.className) {
          if (sideNavItem.className.includes('f_playlist-sidenav-item')) {
            sideNavItem.className = data.toggle ? 'f_playlist-sidenav-item' : 'f_playlist-sidenav-item is_hidden';
          }
        }
      });
    }
  }

  closeSidebarMenu() {
    this.store.dispatch(new CloseMobileNavMenu());
  }

  private loadSidebar() {

    const discoveryPageDefaultTitle = this.translationService.getTranslation('sideNav.title.discovery');

    const playlistsObservable = combineLatest([
      this.subscriptionSectionPlaylists$,
      this.playlistSummaries$,
      this.favoritePlaylists$,
      this.yourSectionPlaylists$,
      this.allPages$,
      this.homePageTitle$,
    ]);
    const userObservable = combineLatest([this.isUserAdmin$, this.isUserSuperAdmin$, this.organizationData$, this.userName$]);
    const apiObservableList: (ObservableResult<boolean> | ObservableResult<Pagination<Moment>> |
      ObservableResult<Pagination<Playlist>> | ObservableResult<Pagination<SearchPage>> | ObservableResult<ReviewItem[]>)[]
      = [
      this.upcomingDataService.hasUpcomingEvents(),
      this.momentDataService.getArchivedMoments('RECENT', '', 0, 1) as ObservableResult<Pagination<Moment>>,
      this.contentPackageService.getSubmittedPlaylists(0, 1) as ObservableResult<Pagination<Playlist>>,
      this.contentPackageService.getSubmittedPages(0, 1) as ObservableResult<Pagination<SearchPage>>,
      this.reviewsDataService.getReviews(),
    ];
    const apiObservable = combineLatest(apiObservableList);

    this.sideNavSectionsSubscription = combineLatest([
      playlistsObservable,
      this.sidenavSettings$,
      this.discoveryPageCategories$,
      this.isDiscoveryPageAvailable$,
      apiObservable,
      userObservable])
      .subscribe(data => {
        const subscriptionPlaylists = data[0][0];
        const recentPlaylists = data[0][1];
        const favoritePlaylists = data[0][2];
        const yourPlaylists = data[0][3];
        const allPages = data[0][4];
        const homePageTitle = data[0][5];
        const sidenavSettings = data[1];
        const categories = data[2];
        const isDiscoveryPageAvailable = data[3];
        const hasUpcomingEvents = data[4][0].value;
        const hasArchivedMoments = (data[4][1]?.value as Pagination<Moment>)?.content || [];
        const hasSubmittedPlaylists = (data[4][2]?.value as Pagination<Playlist>)?.content || [];
        const hasSubmittedPages = (data[4][3]?.value as Pagination<SearchPage>)?.content || [];
        const reviews = data[4][4].value as ReviewItem[];
        const isUserAdmin = data[5][0] || data[5][1];
        const organization = data[5][2];
        const userName = data[5][3];
        const contentStoreHasSubmittedItems = (!!hasSubmittedPages.length || !!hasSubmittedPlaylists.length);


        const formattedAllPages: SideNavItem[] = allPages?.map(page => {
          return {
            label: page.title,
            url: RedirectHelper.getRedirectUrl(this.activatedRoute, {
              pageUri: page.uri,
              formattedUri: page.contentPackageLinks[0]
            }, 'PAGE'),
            icon: !page.showOnLeftNav ? {
              name: 'visibility_off',
              color: 'cod-gray'
            } : 'no-icon',
          };
        });

        this.isDevelopmentDomains = developmentDomains(organization?.domain);
        this.isContentStoreAvailable = organization?.contentStoreEnabled;

        if (this.isDiscoveryPageAvailable !== isDiscoveryPageAvailable ||
          this.isDiscoveryPage !== sidenavSettings.isDiscoveryPage) {

          this.saveSidenavSettings();
          this.isDiscoveryPageAvailable = isDiscoveryPageAvailable;
          this.isDiscoveryPage = sidenavSettings.isDiscoveryPage;
          this.sideNavSections = undefined;
        }

        this.isContentStorePage = sidenavSettings.isContentStorePage;
        this.isFolioPage = sidenavSettings.isFolioPage;

        const isV2 = location.href.includes('/v2/');

        const sideNavSections = this.getSideNavSections(sidenavSettings, isV2);

        SideBarHelpers.setOrganizationNameInSidebar(sideNavSections, this.store, homePageTitle);
        SideBarHelpers.setDiscoveryPageTitle(sideNavSections, organization, discoveryPageDefaultTitle);
        const filteredSideNavSections = SideBarHelpers.filterUrls(
          sideNavSections,
          this.store,
          this.getSectionUrlsToFilter(sidenavSettings, isUserAdmin, contentStoreHasSubmittedItems)
        );

        filteredSideNavSections.forEach(item => {
          // hide upcoming if empty
          if (item.url === '/upcoming') {
            item.hideIfEmpty = !hasUpcomingEvents;
          }
          // hide archived moments if empty
          if (item.url === '/folio' && !hasArchivedMoments.length) {
            if (item.navItems !== undefined) {
              for (const navItem of item.navItems) {
                if (navItem.labelId === 'moments') {
                  navItem.subNavItems = navItem.subNavItems?.filter(subItem => subItem.labelId !== 'archivedMoments');
                  break;
                }
              }
            }
          }
        });

        const generatedSidenavSections = this.generateHomePageSidebarSections(
          sidenavSettings, subscriptionPlaylists, recentPlaylists, favoritePlaylists, yourPlaylists, reviews
        );

        const discoveryPageUrl = ContentHelper.getOrgDiscoveryUrl();
        if (this.isDiscoveryPageAvailable) {
          filteredSideNavSections.forEach(section => {
            if (section.titleId === 'discovery') {
              section.url = discoveryPageUrl;
              section.parentPath = discoveryPageUrl.replace('/', '');
            }
          });
        }

        if (this.isDiscoveryPageAvailable && categories) {
          filteredSideNavSections.forEach(section => {
            if (section.titleId === 'discovery') {
              section.url = discoveryPageUrl;
              section.parentPath = discoveryPageUrl.replace('/', '');
            }
            if (!!section.titleId && section.url.includes(discoveryPageUrl) && !section.url.includes('/admin/')) {
              section.navItems = SideBarHelpers.getCategoriesHierarchy(this.store, categories, discoveryPageUrl, isV2);
            }
          });
        }

        if (isUserAdmin && categories && this.isContentStoreAvailable) {
          filteredSideNavSections.forEach(section => {
            if (!!section.titleId && section.url.includes('/content-store') && !section.url.includes('/admin/')) {
              section.navItems = SideBarHelpers.getCategoriesHierarchy(this.store, categories, '/content-store', isV2);
            }
          });
        }

        if (userName && this.isFolioPage) {
          filteredSideNavSections[1].titleId = '';
          filteredSideNavSections[1].title = userName;
        }

        filteredSideNavSections.forEach(section => {
          if (section.url === `/v2${ContentHelper.getOrgDiscoveryUrl()}`) {
            section.displayBottomLineSeparator = isUserAdmin;
          }
        });

        if (filteredSideNavSections[1].url.includes('/pages')) {
          if (formattedAllPages && formattedAllPages.length) {
            // filteredSideNavSections[1].notAllLoaded = true;
            filteredSideNavSections[1].expanded = true;
            filteredSideNavSections[1].expandable = true;
            filteredSideNavSections[1].navItems = formattedAllPages;
          } else {
            filteredSideNavSections[1].hideIfEmpty = true;
          }
        }

        this.sideNavSections = [
          ...filteredSideNavSections,
          {
            displayBottomLineSeparator:
              !sidenavSettings.isDiscoveryPage && !sidenavSettings.isContentStorePage && !sidenavSettings.isFolioPage,
          },
          ...generatedSidenavSections,
        ];

        this.sideNavSections = SideBarHelpers.excludeSectionsByRoles(this.sideNavSections, organization, []);

        this.updateStaticContentIndex();

        if (this.myPlaylistsExpanded) {
          this.onExpandToggle({ toggle: true, index: this.SUBSCRIPTION_SECTION_INDEX });
        }

      });
  }

  private getSideNavSections(sidenavSettings: SidenavSettings, isV2: boolean): SideNavSection[] {
    if (sidenavSettings.isDiscoveryPage) {
      if (isV2) {
        return STATIC_NAV_OPTIONS_SECTION_DISCOVERY_V2;
      }
      return STATIC_NAV_CONTENT_DISCOVERY;
    }
    if (sidenavSettings.isContentStorePage) {
      return STATIC_NAV_CONTENT_STORE;
    }
    if (sidenavSettings.isFolioPage) {
      return STATIC_NAV_FOLIO_PAGE;
    }
    return STATIC_NAV_OPTIONS_SECTION;
  }

  private saveSidenavSettings(): void {
    if (this.sideNavSections?.length > this.YOUR_SECTION_INDEX && !this.isDiscoveryPage && !this.isContentStorePage) {
      this.store.dispatch(new SaveSidenavSettings({
        expandRecentPlaylists: this.sideNavSections[this.RECENT_SECTION_INDEX]?.expanded,
        expandFavoritePlaylists: this.sideNavSections[this.FAVORITE_SECTION_INDEX]?.expanded,
        expandYourPlaylists: this.sideNavSections[this.YOUR_SECTION_INDEX]?.expanded,
      }));
    }
  }

  private getSectionUrlsToFilter(sidenavSettings: SidenavSettings, isAdmin: boolean, contentStoreHasSubmittedItems): string[] {
    const sectionUrls: string[] = [];

    const discoveryPageUri = ContentHelper.getOrgDiscoveryUrl();
    if (!this.isDiscoveryPageAvailable) {
      sectionUrls.push(discoveryPageUri);
      sectionUrls.push(`/v2${discoveryPageUri}`);
    } else if (sidenavSettings.isDiscoveryPage) {
      if (!isAdmin) {
        sectionUrls.push(`/admin/v2${discoveryPageUri}`);
      }
    }
    if (!isAdmin || !this.isContentStoreAvailable) {
      sectionUrls.push('/content-store');
    }
    if (!isAdmin && this.isContentStorePage) {
      sectionUrls.push('/content-store/submitted');
      sectionUrls.push('/admin/content-store-layout');
    }
    if (!contentStoreHasSubmittedItems) {
      sectionUrls.push('/content-store/submitted');
    }

    return sectionUrls;
  }

  private updateStaticContentIndex() {
    this.PAGES_SECTION_INDEX = 1;
    this.SUBSCRIPTION_SECTION_INDEX = this.sideNavSections?.findIndex(item => item.titleId === 'myPlaylists');
    this.FAVORITE_SECTION_INDEX = this.sideNavSections?.findIndex(item => item.titleId === 'favourites');
    this.RECENT_SECTION_INDEX = this.sideNavSections?.findIndex(item => item.titleId === 'recentPlaylists');
    this.YOUR_SECTION_INDEX = this.sideNavSections?.findIndex(item => item.titleId === 'playlistAdmin');
  }

  private generateHomePageSidebarSections(
    sidenavSettings: SidenavSettings,
    subscriptionPlaylists: LoadableState<Pagination<LearnerPlaylistSummary>>,
    recentPlaylists: LoadableState<Pagination<LearnerPlaylistSummary>>,
    favoritePlaylists: LoadableState<Pagination<FavoritePlaylistSummary>>,
    yourPlaylists: LoadableState<Pagination<LearnerPlaylistSummary>>,
    reviews: ReviewItem[]
  ): SideNavSection[] {
    if (sidenavSettings.isDiscoveryPage || sidenavSettings.isContentStorePage  || sidenavSettings.isFolioPage) {
      return [];
    }

    let subscriptionPlaylistsNavItems: SideNavItem[] = [];
    let recentPlaylistsNavItems: SideNavItem[] = [];
    let favoritePlaylistsNavItems: SideNavItem[] = [];
    let yourPlaylistsNavItems: SideNavItem[] = [];

    let notAllSubscriptionsLoaded: boolean;
    let notAllFavoritesLoaded: boolean;
    let notAllRecentsLoaded: boolean;
    let notAllYourPlaylistsLoaded: boolean;

    let expandFavorites = true;
    let expandRecents = sidenavSettings.expandRecentPlaylists;
    let expandYourPlaylists = sidenavSettings.expandYourPlaylists;

    // Subscription playlists

    if (subscriptionPlaylists && subscriptionPlaylists.data && !subscriptionPlaylists.loading) {
      subscriptionPlaylistsNavItems = subscriptionPlaylists.data.content.map(playlist => ({
        label: playlist.title,
        icon: 'no-icon' as SideNavItemIcon,
        url: RedirectHelper.getRedirectUrl(this.activatedRoute, {
          playlistUri: playlist.uri,
          formattedUri: playlist.formattedUri
        }, 'PLAYLIST'),
      }));

      this.nextSubscriptionSectionPage = subscriptionPlaylists.data.currentPageNumber + 1;
      notAllSubscriptionsLoaded = subscriptionPlaylists.data.hasContent &&
        subscriptionPlaylists.data.totalNumberOfElement >
        subscriptionPlaylists.data.content.length;
    } else if (this.sideNavSections) {
      subscriptionPlaylistsNavItems = this.sideNavSections[this.SUBSCRIPTION_SECTION_INDEX]?.navItems;
      notAllSubscriptionsLoaded = false;
    }

    // Recent playlists

    if (recentPlaylists && recentPlaylists.data && !recentPlaylists.loading) {
      recentPlaylistsNavItems = recentPlaylists.data.content.map(playlist => ({
        label: playlist.title,
        icon: 'no-icon' as SideNavItemIcon,
        url: RedirectHelper.getRedirectUrl(this.activatedRoute, {
          playlistUri: playlist.uri,
          formattedUri: playlist.formattedUri
        }, 'PLAYLIST'),
      }));

      this.nextRecentsPage = recentPlaylists.data.currentPageNumber + 1;
      notAllRecentsLoaded = recentPlaylists.data.hasContent &&
        recentPlaylists.data.totalNumberOfElement > recentPlaylists.data.content.length;
    } else if (this.sideNavSections) {
      recentPlaylistsNavItems = this.sideNavSections[this.RECENT_SECTION_INDEX]?.navItems;
      notAllRecentsLoaded = false;
    }

    // Favorite playlists

    if (favoritePlaylists && favoritePlaylists.data && !favoritePlaylists.loading) {
      favoritePlaylistsNavItems = favoritePlaylists.data.content.map(playlist => ({
        label: playlist.title,
        icon: 'no-icon' as SideNavItemIcon,
        url: RedirectHelper.getRedirectUrl(this.activatedRoute, {
          playlistUri: playlist.uri,
          formattedUri: playlist.formattedUri
        }, 'PLAYLIST'),
      }));

      this.nextFavoritesPage = favoritePlaylists.data.currentPageNumber + 1;
      notAllFavoritesLoaded = favoritePlaylists.data.hasContent &&
        favoritePlaylists.data.totalNumberOfElement > favoritePlaylists.data.content.length;
    } else if (this.sideNavSections) {
      favoritePlaylistsNavItems = this.sideNavSections[this.FAVORITE_SECTION_INDEX]?.navItems;
      notAllFavoritesLoaded = false;
    }

    // Admin playlists

    if (yourPlaylists && yourPlaylists.data && !yourPlaylists.loading) {
      yourPlaylistsNavItems = yourPlaylists.data.content.map(playlist => ({
        label: playlist.title,
        icon: 'no-icon' as SideNavItemIcon,
        url: RedirectHelper.getRedirectUrl(this.activatedRoute, {
          playlistUri: playlist.uri,
          formattedUri: playlist.formattedUri
        }, 'PLAYLIST'),
      }));

      this.nextYourSectionPage = yourPlaylists.data.currentPageNumber + 1;
      notAllYourPlaylistsLoaded = yourPlaylists.data.hasContent &&
        yourPlaylists.data.totalNumberOfElement > yourPlaylists.data.content.length;
    } else if (this.sideNavSections) {
      yourPlaylistsNavItems = this.sideNavSections[this.YOUR_SECTION_INDEX]?.navItems;
      notAllYourPlaylistsLoaded = false;
    }

    expandFavorites = this.sideNavSections ? this.sideNavSections[this.FAVORITE_SECTION_INDEX]?.expanded
      : expandFavorites;
    expandRecents = this.sideNavSections ? this.sideNavSections[this.RECENT_SECTION_INDEX]?.expanded
      : expandRecents;
    expandYourPlaylists = this.sideNavSections ?
      (this.sideNavSections[this.YOUR_SECTION_INDEX]?.expanded !== undefined
        ? this.sideNavSections[this.YOUR_SECTION_INDEX]?.expanded : expandYourPlaylists)
      : expandYourPlaylists;

    const navItems = [
      {
        navItems: subscriptionPlaylistsNavItems,
        notAllLoaded: notAllSubscriptionsLoaded,
        displayBottomLineSeparator: true,
        hideIfEmpty: true,
      },
      {
        title: 'Favourites',
        titleId: 'favourites',
        navItems: favoritePlaylistsNavItems,
        expandable: true,
        expanded: expandFavorites,
        notAllLoaded: notAllFavoritesLoaded,
        emptyPlaceholder: favoritePlaylists?.loading ? 'Loading...' : 'No favourites added',
        hideIfEmpty: sidenavSettings.isDiscoveryPage,
      },
      {
        title: 'Recent playlists',
        titleId: 'recentPlaylists',
        navItems: recentPlaylistsNavItems,
        expandable: true,
        expanded: expandRecents,
        notAllLoaded: notAllRecentsLoaded,
        emptyPlaceholder: recentPlaylists?.loading ? 'Loading...' : 'No recent playlists',
        hideIfEmpty: sidenavSettings.isDiscoveryPage,
      },
    ];

    if (yourPlaylistsNavItems?.length) {
      navItems.push(
        {
          title: 'Playlist admin',
          titleId: 'playlistAdmin',
          navItems: yourPlaylistsNavItems,
          expandable: true,
          expanded: expandYourPlaylists,
          notAllLoaded: notAllYourPlaylistsLoaded,
          emptyPlaceholder: yourPlaylists?.loading ? 'Loading...' : 'No playlists to manage',
          hideIfEmpty: sidenavSettings.isDiscoveryPage,
        },
      );
    }


    const data: SideNavSection[] = [
      {
        title: 'My Playlists',
        titleId: 'myPlaylists',
        navItems: subscriptionPlaylistsNavItems,
        expandable: true,
        hideExpandIcon: true,
        expanded: this.myPlaylistsExpanded,
        notAllLoaded: false,
        hideIfEmpty: false,
        className: 'f_playlist-sidenav',
      },
      {
        title: 'Favourites',
        titleId: 'favourites',
        navItems: favoritePlaylistsNavItems,
        expandable: true,
        expanded: expandFavorites,
        notAllLoaded: notAllFavoritesLoaded,
        emptyPlaceholder: favoritePlaylists?.loading ? 'Loading...' : 'No favourites added',
        hideIfEmpty: sidenavSettings.isDiscoveryPage,
        className: 'f_playlist-sidenav-item',
      },
      {
        title: 'Recent playlists',
        titleId: 'recentPlaylists',
        navItems: recentPlaylistsNavItems,
        expandable: true,
        expanded: expandRecents,
        notAllLoaded: notAllRecentsLoaded,
        emptyPlaceholder: recentPlaylists?.loading ? 'Loading...' : 'No recent playlists',
        hideIfEmpty: sidenavSettings.isDiscoveryPage,
        className: 'f_playlist-sidenav-item',
      },
    ];


    if (yourPlaylistsNavItems?.length) {
      data.push(
        {
          title: 'Playlist admin',
          titleId: 'playlistAdmin',
          navItems: yourPlaylistsNavItems,
          expandable: true,
          expanded: expandYourPlaylists,
          notAllLoaded: notAllYourPlaylistsLoaded,
          emptyPlaceholder: yourPlaylists?.loading ? 'Loading...' : 'No playlists to manage',
          hideIfEmpty: sidenavSettings.isDiscoveryPage,
          className: 'f_playlist-sidenav-item',
        },
      );
    }

    if (reviews?.length && this.newVersionEnabled) {
      data.push(
        {
          title: 'Reviews',
          titleId: 'reviews',
          className: 'reviews-sidenav',
          url: '/reviews'
        },
      );
    }

    return data;
  }

}
