/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { CheckboxFormContent, Form, TextBoxFormContent, UserPlaylistSubmissionSummary } from '../../../../models';
import { FormSavedEvent } from '../form-preview-event.model';
import {
  RefreshLearnerViewCardStatus,
  SaveFormAnswer,
  SubmitForm
} from '../../../../../page-modules/resource/store/learner-view.actions';
import { Store } from '@ngxs/store';
import { timer } from 'rxjs';
import { DialogService } from '../../../../helpers/dialog/dialog.service';
import { TranslationService } from '../../../../services/translation/translation.service';
import * as PlaylistViewActions from '../../../../../page-modules/playlist/store/view/playlist-view.state.actions';
import { ContentHelper } from '../../../../helpers/content-helper';
import { LocalTimeHelper } from '../../../../helpers/local-time-helper';

@Component({
  selector: 'ptl-form-preview-checkbox',
  templateUrl: './form-checkbox.component.html',
  styleUrls: ['./form-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormPreviewCheckboxComponent implements OnInit, OnChanges {

  /** Receives the checkbox type object */
  @Input() formData: Form<TextBoxFormContent>;
  @Input() userPlaylistSubmissionSummary: UserPlaylistSubmissionSummary;
  @Input() playlistUri: string;
  @Input() playlistUid: string;
  @Input() resourceUri: string;
  @Input() groupUid: string;
  @Input() publisherUri: string;
  @Input() packageUri: string;
  @Input() pageUri: string;
  @Input() languageCode: string;

  existingUserAnswer: boolean;
  submitted = false;
  userAnswer = 'false';
  isChecked = false;

  constructor(
    private store: Store,
    private dialogService: DialogService,
    private cd: ChangeDetectorRef,
    private translationService: TranslationService,
  ) {
  }

  ngOnInit() {
    if (this.formData && (this.formData.content as CheckboxFormContent).userAnswer) {
      const content = this.formData.content as CheckboxFormContent;
      this.userAnswer = content.userAnswer;
      this.submitted = content.submitted;
      if (content.userAnswer.length > 0) {
        this.existingUserAnswer = true;
      }
      if (this.userAnswer === 'true') {
        this.isChecked = true;
      }

      this.checkSubmissionMode();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.userPlaylistSubmissionSummary && changes.userPlaylistSubmissionSummary.currentValue) {
      this.checkSubmissionMode();
    }
  }

  onCheckboxChange() {
    const event: FormSavedEvent = {
      formUid: this.formData.uid,
      answer: this.isChecked ? 'true' : 'false',
    };
    this.existingUserAnswer = true;
    this.store.dispatch(new SaveFormAnswer(this.playlistUid, event));
  }

  submit(): void {
    this.dialogService.showConfirmDialog(
      this.translationService.getTranslation('dialog.title.confirmFormSubmit'),
      this.translationService
    ).then(confirmed => {
      if (confirmed) {
        this.submitted = true;
        this.checkSubmissionMode();
        this.store.dispatch(new SubmitForm(this.playlistUid, this.formData.uid)).toPromise().then(() => {
          timer(1000).subscribe(() => {
            this.store.dispatch(new PlaylistViewActions.RefreshUserPlaylistSubmissionSummary(this.playlistUid));
            this.store.dispatch(
              new RefreshLearnerViewCardStatus(
                this.playlistUri,
                this.resourceUri,
                this.groupUid,
                this.publisherUri,
                this.packageUri,
                this.pageUri,
                this.languageCode,
              )
            );
          });
        });
      }
    });
  }

  getLocalDateTime(date: Date): string {
    return LocalTimeHelper.getLocalDateTime(date).toString();
  }

  get isSubmissionDisabled(): boolean {
    return !this.existingUserAnswer;
  }

  private checkSubmissionMode(): void {
    if (ContentHelper.checkFormSubmissionStatus(this.userPlaylistSubmissionSummary)) {
      this.submitted = false;
    }
    this.cd.detectChanges();
  }

}
