import { LearnerFormUpdatePermission } from 'src/app/page-modules/resource/models';
import { UserPlaylistSubmissionSummary } from '../models';
import { TranslationService } from '../services/translation/translation.service';

export class ContentHelper {

  static isFrameMode(): boolean {
    return (window.self !== window.top);
  }

  static convertTimeEstimate(timeInMinutes: number): string {
    const hours = Math.floor(timeInMinutes / 60);
    const minutes = timeInMinutes % 60;
    if (hours === 0 && minutes === 0) {
      return '';
    }
    return hours ? `${hours}h ${minutes} min` : `${minutes} min`;
  }

  static getOrgDiscoveryUrl() {
    return localStorage.getItem('orgDiscoveryUrl') ?? 'discovery'
  }

  static setOrgDiscoveryUrl(url?: string) {
    url = url ? url : 'discovery';
    return localStorage.setItem('orgDiscoveryUrl', `/${url}`);
  }

  // TODO: replace with learnerFormAnswer.updatePermission when switching to new forms
  // return true if form can be submitted
  static checkFormSubmissionStatus(userPlaylistSubmissionSummary: UserPlaylistSubmissionSummary): boolean {
    let canReSubmit = false;
    if (userPlaylistSubmissionSummary && userPlaylistSubmissionSummary.submissionMode === 'SINGLE') {
      if (userPlaylistSubmissionSummary.submittedOn === null) {
        canReSubmit = true;
        return canReSubmit;
      }
      if (userPlaylistSubmissionSummary.submittedOn &&
        userPlaylistSubmissionSummary.userReviewSummary?.status?.currentReviewEvent?.type === 'REQUIRES_RESUBMISSION') {
        canReSubmit = true;
        return canReSubmit;
      }
    }
    return canReSubmit;
  }

  static formCanNotBeEditedReason(translationService: TranslationService, formUpdatePermission: LearnerFormUpdatePermission): string {
    if (formUpdatePermission?.canBeUpdated === false) {
      switch (formUpdatePermission.notPermittedReason) {
        case 'REVIEW_OPEN':
          return translationService.getTranslation('formUpdateDisabledReasons.reviewOpen');
        case 'REVIEW_CLOSED':
          return translationService.getTranslation('formUpdateDisabledReasons.reviewClosed');
        case 'DEADLINE_PASSED':
          return translationService.getTranslation('formUpdateDisabledReasons.deadlinePassed');
        case 'FORM_ALREADY_SUBMITTED':
          return translationService.getTranslation('formUpdateDisabledReasons.formAlreadySubmitted');
        case 'PLAYLIST_ALREADY_SUBMITTED':
          return translationService.getTranslation('formUpdateDisabledReasons.playlistAlreadySubmitted');
        default:
          return '';
      }
    } else {
      return '';
    }
  }

  static shouldDisplayReviewersDialogOnFormSubmission(userPlaylistSubmissionSummary: UserPlaylistSubmissionSummary): boolean {
    if (this.isProgressiveSubmissionMode(userPlaylistSubmissionSummary)) {
      const reviewSummary = userPlaylistSubmissionSummary?.userReviewSummary;
      if ( (reviewSummary && reviewSummary.reviewType !== 'AUTOMATIC' && !reviewSummary.areReviewersFixed) &&
        (!reviewSummary.status || !reviewSummary.status.open || reviewSummary.canSelectNewReviewers)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  static isProgressiveSubmissionMode(userPlaylistSubmissionSummary: UserPlaylistSubmissionSummary): boolean {
    return userPlaylistSubmissionSummary?.submissionMode === 'PROGRESSIVE';
  }

  static stripHtmlTags(htmlContent: string): string {
    return htmlContent.replace(/<[^>]*>?|\s+/gm, ' ');
  }

  static stripOnlyHtmlTags(htmlContent: string): string {
    return htmlContent.replace(/<[^>]*>/g, ' '); // Removes HTML tags
  }

  static escapeHtml(htmlString: string): string {
    return htmlString
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;');
  }

  static unescapeHtml(htmlString: string): string {
    return htmlString
      .replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&quot;/g, '"')
      .replace(/&#039;/g, '\'');
  }
}

export function canTriggerSearch(event: KeyboardEvent): boolean {
  /* eslint-disable */
  return (event.which < 9 || event.which > 40 || event.which === 32);
  /* eslint-enable */
}
