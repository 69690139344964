<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div
  role="heading"
  aria-level="2"
  [class.new-version]="newVersionEnabled"
  class="preview-element
    preview-element-type-{{ content?.type }}
    preview-element-position-{{ content?.position || 'CENTER' }}"
  #htmlEl>
</div>

