/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormContentType } from 'src/app/editor/models';
import { LearnerFormAnswer } from 'src/app/page-modules/resource/models';
import { LocalTimeHelper } from 'src/app/shared/helpers/local-time-helper';
import { TranslationService } from 'src/app/shared/services/translation/translation.service';

@Component({
  selector: 'ptl-form-update-footer',
  templateUrl: './form-update-footer.component.html',
  styleUrls: ['./form-update-footer.component.scss'],
})
export class FormUpdateFooterComponent implements OnInit, OnChanges {

  @Input() submitted: boolean;
  @Input() canEdit: boolean;
  @Input() learnerFormAnswer: LearnerFormAnswer;
  @Input() formType: FormContentType;
  @Input() playlistSubmissionDeadline: Date;

  updateAdviceText: string;

  constructor(
    private translationService: TranslationService
  ) {
  }

  ngOnInit(): void {
    this.updateAdviceText = this.getUpdateAdviceText();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateAdviceText = this.getUpdateAdviceText();
  }

  getSubmissionDate() {
    const date = this.learnerFormAnswer?.submittedOn === null ? new Date() : this.learnerFormAnswer.submittedOn
    return LocalTimeHelper.getLocalDateTime(date).toString();
  }

  private getUpdateAdviceText(): string {
    switch (this.formType) {
      case 'TEXTBOX':
      case 'PRIVATE_NOTE':
        return this.translationService.getTranslation('cards.form.footer.updateEntry.textbox');
      case 'USER_FILE_UPLOAD':
        return this.translationService.getTranslation('cards.form.footer.updateEntry.upload');
      case 'CHECKBOX':
        return this.translationService.getTranslation('cards.form.footer.updateEntry.checkbox');
      case 'QUIZ':
        if (this.submitted) {
          return this.translationService.getTranslation('cards.form.footer.updateEntry.quizRetake');
        }
        return this.translationService.getTranslation('cards.form.footer.updateEntry.quizStart');
      case 'COLLECTOR':
        return this.translationService.getTranslation('cards.form.footer.updateEntry.collector');
      case 'BOOKING':
        return this.translationService.getTranslation('cards.form.footer.updateEntry.booking');
      case 'QUESTION_FORM':
        return this.translationService.getTranslation('cards.form.footer.updateEntry.questionForm');
      default:
        console.error(`Form type ${this.formType} is not supported!`);
        return '';
    }
  }
}
