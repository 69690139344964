<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->


<ptl-skeleton-view *ngIf="skeletonViewActive; else elseTemplate"
                   [state]="{ loading: true }"
                   [countInput]="13"
                   [viewType]="'table'">
</ptl-skeleton-view>

<ng-template #elseTemplate>


  <div class="admin-member__error-container" *ngIf="showNotPermittedMessage">
    {{ 'translations.members.label.noPermittedToViewMembers' | transloco:{organization: (organizationData$ | async)?.name} }}
  </div>


  <h1 class="admin-member__title"
      *ngIf="!isPlaylistPage && !showNotPermittedMessage"
      [attr.aria-label]="('translations.global.ariaLabels.membersSection' | transloco)">
    {{ 'translations.members.title' | transloco }}
  </h1>

  <div class="admin-member__container" *ngIf="!showNotPermittedMessage">
    <div class="admin-member__title">

      <ptl-admin-member-buttons
        #membersButtons
        [exportEnabled]="exportEnabled || (!isCardPage && !isPlaylistPage)"
        [exportName]="resourceUri"
        [isPlaylistPage]="isPlaylistPage"
        [isCardPage]="isCardPage"
        [isAssessment]="isAssessment"
        [totalMembersCount]="membersTotalCount"
        [allMembersCount]="membersAllCount"
        [booleanSearchRequest]="{order: order, query: queryRequest}"
        [playlistUid]="playlistUid"
        [cardUid]="cardUid"
        (groupAddRemove)="onGroupAddRemove($event)"
        (roleAddRemove)="onRoleAddRemove($event)"
        (openMessage)="onOpenMessage()"
      >
      </ptl-admin-member-buttons>

      <ptl-boolean-member-filters
        [isCardPage]="isCardPage"
        [isPlaylistPage]="isPlaylistPage"
        [cardUid]="cardUid"
        [playlistUid]="playlistUid"
        [cardHeaders]="cardHeaders"
        [formHeaders]="formHeaders"
        (filterChanged)=onFilterChanged($event)
      ></ptl-boolean-member-filters>

      <ptl-admin-member-list
        #membersList
        [isPlaylistPage]="isPlaylistPage"
        [isCardPage]="isCardPage"
        *ngIf="membersListLoaded"
        [members]="members"
        [membersTotalCount]="membersTotalCount"
        (openUserRecord)="openUserRecord($event)"
        (orderChanged)="changeListOrderingByBooleanFilter($event)"
        (membersChecked)="onMembersChecked($event)"></ptl-admin-member-list>

    </div>
  </div>
</ng-template>
