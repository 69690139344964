/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input, OnInit } from '@angular/core';
import { QuizReportResponse } from '../../../../../../models';
import { Quiz } from '../../../../../../../../shared/models/editor/quiz-content.model';
import { LanguageCodeHelper } from '../../../../../../../../shared/helpers/language-code-helper';
import { Organization } from '../../../../../../../../shared/models';

@Component({
  selector: 'ptl-app-summary-report-slider',
  templateUrl: './summary-report-slider.component.html',
  styleUrls: ['./summary-report-slider.component.scss'],
})
export class SummaryReportSliderComponent implements OnInit {

  @Input() question: QuizReportResponse;
  @Input() quiz: Quiz;
  @Input() languageCode: string;
  @Input() currentLanguage: string;
  @Input() organization: Organization;

  leftTitle: string;
  rightTitle: string;
  runnerPosition: number;

  ngOnInit() {
    const currentQuestion = this.quiz.questions.filter(question => question.uid === this.question.questionUid);
    if (currentQuestion.length) {
      const correctQuestion = this.question.options.find(option => option.selected);
      this.leftTitle = LanguageCodeHelper.getDataByUserLanguageCode(
        currentQuestion[0].options[0].values, this.organization, this.currentLanguage).value;
      this.rightTitle = LanguageCodeHelper.getDataByUserLanguageCode(
        currentQuestion[0].options[1].values, this.organization, this.currentLanguage).value;
      this.runnerPosition = ((correctQuestion.userScore - currentQuestion[0].options[0].score) * 100) /
        (currentQuestion[0].options[1].score - currentQuestion[0].options[0].score);
    }
  }

}
