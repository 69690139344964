<!--
  ~ Copyright (C) 2021 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<mat-card class="main-container" [class.dialog-container]="isDialog">
  <div class="head" *ngIf="!isDialog">
    <h1>{{ 'translations.pages.target.title' | transloco }}</h1>
  </div>
  <div class="content">

    <div class="collapsible-row">
      <div class="row">
        <div class="cell toggle">
          <mat-slide-toggle class="toggle-slide" color="primary" [checked]="selectedOrganizationOnly"
                            [disabled]="!haveUserGroups"
                            (change)="onToggleChangeOrganizationVisibility($event)">
            <span class="layout__enable-text-on toggle-1">{{ 'translations.global.toggle.organization' | transloco }}</span>
          </mat-slide-toggle>
        </div>
        <div class="cell description">
          {{ 'translations.pages.target.label.onlyOrganizationMembers' | transloco: {orgName: (organizationData$ | async)?.name} }}
        </div>
      </div>
    </div>

    <div class="collapsible-row">

      <div class="row">
        <div class="cell toggle">
          <mat-slide-toggle class="toggle-slide" color="primary" [checked]="selectedGroupsOnly"
                            [disabled]="!haveUserGroups"
                            (change)="onToggleChangeGroupsVisibility($event)">
            <span class="layout__enable-text-on toggle-2">{{ 'translations.global.label.group' | transloco }}</span>
          </mat-slide-toggle>
        </div>
        <div class="cell description">
          {{ 'translations.pages.target.label.onlySelectedGroup' | transloco }}
        </div>
      </div>

      <div class="collapsible-row-item" *ngIf="selectedGroupsOnly">
        <div class="assigned-content">

          <div class="assigned-items-list" *ngIf="groups?.length">
            <div class="assigned-items-list-item" *ngFor="let group of groups">
              <div class="bold">{{ group.name }}</div>
              <span
                tabindex="0"
                class="assigned-items-list-item-remove"
                matTooltip="Remove member"
                [attr.aria-label]="('translations.global.ariaLabels.remove' | transloco: {name: group.name})"
                (click)="deleteGroup(group._id)"
                (keydown.enter)="deleteGroup(group._id)">
            <mat-icon aria-hidden="true" [inline]="true">clear</mat-icon>
          </span>
            </div>
          </div>

          <mat-form-field class="collapsible-row-item-field" appearance="outline">
            <mat-label>{{ 'translations.global.search.label.forGroups' | transloco }}</mat-label>
            <input
              matInput
              [matAutocomplete]="auto"
              (paste)="onTextPaste($event)"
              (keyup)="onGroupSearchInputChange($event)">

            <mat-autocomplete
              [panelWidth]="450"
              #auto="matAutocomplete"
              [displayWith]="autocompleteDisplayFn"
              (optionSelected)="onGroupSelected($event)">
              <mat-option
                *ngFor="let group of searchedGroups"
                [value]="group">
                <div>{{ group.title }}</div>
              </mat-option>
            </mat-autocomplete>

            <mat-icon aria-hidden="true" matSuffix class="flex">
              <mat-spinner *ngIf="loadingGroups" [diameter]="20"></mat-spinner>
            </mat-icon>

          </mat-form-field>

        </div>
      </div>
    </div>

    <div class="collapsible-row">

      <div class="row">
        <div class="cell toggle">
          <mat-slide-toggle class="toggle-slide" color="primary" [checked]="selectedMembersOnly"
                            (change)="onToggleChangeMembersVisibility($event)">
            <span class="layout__enable-text-on toggle-1">{{ 'translations.global.toggle.selected' | transloco }}</span>
          </mat-slide-toggle>
        </div>
        <div class="cell description">
          {{ 'translations.pages.target.label.onlySelectedMembers' | transloco }}
        </div>
      </div>

      <div class="collapsible-row-item" *ngIf="selectedMembersOnly">

        <div class="assigned-content">
          <div class="assigned-items-list" *ngIf="members?.length">
            <div class="assigned-items-list-item" *ngFor="let member of members">
              <div class="bold">{{ member.name }}</div>
              <span
                tabindex="0"
                class="assigned-items-list-item-remove"
                matTooltip="Remove member"
                [attr.aria-label]="('translations.global.ariaLabels.remove' | transloco: {name: member.name})"
                (click)="deleteMember(member._id)"
                (keydown.enter)="deleteMember(member._id)">
            <mat-icon aria-hidden="true" [inline]="true">clear</mat-icon>
          </span>
            </div>
          </div>

          <ptl-user-search-autocomplete
            class="user-search-autocomplete"
            #userSearchAutocomplete
            [loadingMembers]="loadingMembers"
            [label]="'translations.global.search.label.forMembers' | transloco"
            [members]="searchedMembers"
            (searchLoadedMore)="onSearchLoadingMore('MEMBER')"
            (searchInputChanged)="onUserSearchInputChange($event)"
            (memberSelected)="onMemberSelected($event)">
          </ptl-user-search-autocomplete>

        </div>
      </div>
    </div>

    <div class="collapsible-row">
      <div class="row">
        <div class="cell toggle">
          <mat-slide-toggle class="toggle-slide" color="primary" [checked]="selectedOnlyMe" (change)="onToggleChangeOnlyMeVisibility($event)">
            <span class="layout__enable-text-on toggle-3">{{ 'translations.global.toggle.private' | transloco }}</span>
          </mat-slide-toggle>
        </div>
        <div class="cell description">
          {{ 'translations.pages.target.label.onlyMe' | transloco }}
        </div>
      </div>
    </div>
  </div>
</mat-card>

<ptl-help-center *ngIf="newVersionEnabled" [viewsType]="'page_admin_targeting'"></ptl-help-center>
