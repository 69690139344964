<!--
  ~ Copyright (C) 2022 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ng-container>
  <div class="member-filter__wrapper">
    <div class="member-filter__dropdowns">
      <mat-select
        [(value)]="selectedCardStatus"
        (selectionChange)="onCardStatusChange($event)"
        class="member-filter__dropdown">
        <mat-option *ngFor="let cardStatus of cardStatuses" [value]="cardStatus.value">{{ cardStatus.title }}</mat-option>
      </mat-select>
      <div class="member-filter__dropdown member-filter__search" *ngIf="!isCardPage && !isPlaylistPage">
        <input
          matInput
          [matAutocomplete]="auto"
          [(ngModel)]="searchString"
          [placeholder]="('translations.members.filter.card.find'| transloco)"
          [ngModelOptions]="{standalone: true}"
          (paste)="onTextPaste($event)"
          (keyup)="onSearchInputChange($event)"/>
        <mat-autocomplete
          [panelWidth]="450"
          #auto="matAutocomplete"
          [displayWith]="searchAutocompleteFormat"
          (optionSelected)="onCardSelected($event)">
          <mat-option
            *ngFor="let card of searchedCards"
            [value]="card">
            <div>{{ card.header }}</div>
          </mat-option>
        </mat-autocomplete>

        <mat-icon aria-hidden="true" matSuffix class="flex">
          <mat-spinner *ngIf="loadingSearch" [diameter]="20">
          </mat-spinner>
        </mat-icon>
      </div>
      <mat-select
        *ngIf="isPlaylistPage"
        [placeholder]="('translations.members.filter.card.find' | transloco) +
          ', ' + ('translations.members.filter.playlist.find' | transloco)"
        (selectionChange)="onCardHeaderSelected($event)"
        class="member-filter__dropdown">
        <mat-option *ngFor="let cardHeader of cardHeaders" [value]="cardHeader">{{ cardHeader.header }}</mat-option>
      </mat-select>
      <div class="member-filter__filler">
        <div *ngFor="let selectedCard of selectedCards" class="selected-card">
          <span>{{ selectedCard.header }}</span>
          <span
            class="selected-card__remove"
            [matTooltip]="('translations.members.filter.card.remove' | transloco)"
            [attr.aria-label]="('translations.global.ariaLabels.clear' | transloco: {title: selectedCard.header})"
            (click)="onCardRemove(selectedCard.id)"
            (keydown.enter)="onCardRemove(selectedCard.id)"
            tabindex="0">
            <mat-icon aria-hidden="true" [inline]="true">clear</mat-icon>
          </span>
        </div>
      </div>
    </div>
    <p *ngIf="noCard" class="member-filter__validation">
      {{ 'translations.members.filter.card.noCard' | transloco }}
    </p>
  </div>
  <ptl-member-filter-controls (cancel)="onCancel()" (save)="onSave()"></ptl-member-filter-controls>
</ng-container>
