<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="form-preview__update-footer">
  <span
      *ngIf="submitted && learnerFormAnswer?.submittedOn && canEdit">{{'translations.cards.form.footer.updatedOn' | transloco}} {{ getSubmissionDate() | date: 'd MMMM y, HH:mm'}}
      . {{ updateAdviceText }}</span>
  <span
    *ngIf="submitted && learnerFormAnswer?.submittedOn && !canEdit">{{'translations.cards.form.footer.updatedOn' | transloco}} {{ getSubmissionDate() | date: 'd MMMM y, HH:mm'}}</span>
  <span
    *ngIf="(!submitted || !learnerFormAnswer?.submittedOn) && canEdit">{{ updateAdviceText }}</span>

  <ptl-form-review-events-list *ngIf="learnerFormAnswer?.progressiveReviewEnabled"
    [reviewEvents]="learnerFormAnswer.reviewEvents"
    [submitted]="submitted"
    [playlistSubmissionDeadline]="playlistSubmissionDeadline">
  </ptl-form-review-events-list>

</div>
