<!--
  ~ Copyright (C) 2022 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ng-container>
  <div class="member-filter__wrapper">
    <div class="member-filter__dropdowns">
      <mat-select
        [(value)]="selectedResourceStatus"
        (selectionChange)="onResourceStatusChange($event)"
        class="member-filter__dropdown">
        <mat-option *ngFor="let resourceStatus of resourceStatuses" [value]="resourceStatus.value">{{ resourceStatus.title }}</mat-option>
      </mat-select>
      <mat-select
        [(value)]="selectedAmountOperator"
        (selectionChange)="onAmountOperatorChange($event)"
        class="member-filter__dropdown">
        <mat-option *ngFor="let amountOperator of amountOperators" [value]="amountOperator.value">{{ amountOperator.title }}</mat-option>
      </mat-select>
      <div class="resources-filter__number">
        <input
          matInput
          type="number"
          class="resources-filter__number__input"
          pattern="^\d+$"
          min="0"
          [max]="goalTotalNumber"
          [(ngModel)]="resourcesNumber"/>
      </div>
      <div class="member-filter__goals">
        <span>{{ ('translations.members.filter.goal.of' | transloco)
        + ' ' + goalTotalNumber + ' ' + ('translations.members.filter.goal.title' | transloco)}}</span>
      </div>
    </div>
    <p *ngIf="noNumber" class="member-filter__validation">
      {{ 'translations.members.filter.resources.noNumber' | transloco }}
    </p>
  </div>
  <ptl-member-filter-controls (cancel)="onCancel()" (save)="onSave()"></ptl-member-filter-controls>
</ng-container>
