import { Component, Inject, NgZone, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserGroups } from '../../../../../shared/models/admin/group/user-groups.model';
import { ACCOUNT_DATA_SERVICE, AccountDataService } from '../../../../account/services/account-data.service';
import { USER_GROUPS_DATA_SERVICE, UserGroupsDataService } from '../../../services/groups/groups-data.service';
import { TranslationService } from '../../../../../shared/services/translation/translation.service';
import { SnackbarHelper } from '../../../../../shared/helpers/snackbar-helper';
import { MembersActiveFilterContent } from '../../../../../shared/models/admin/members.model';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { canTriggerSearch } from '../../../../../shared/helpers/content-helper';

@Component({
  templateUrl: './alumni-csv-upload-dialog.component.html',
  styleUrls: ['./alumni-csv-upload-dialog.component.scss'],
})
export class AlumniCsvUploadDialogComponent implements OnDestroy {

  groups: UserGroups[] = [];
  csvFile: File;
  filesHovered = false;
  fileUploaded = false;
  uploadedFileName: string;

  searchInProgress: boolean;
  selectedGroup: { id: string; name: string };
  searchString: string;
  groupInvalid = false;
  csvInvalid = false;
  isLoading = false;

  private searchInputSubject$ = new Subject<void>();
  private searchInputSubscription: Subscription;

  constructor(
    @Inject(ACCOUNT_DATA_SERVICE) private accountService: AccountDataService,
    @Inject(USER_GROUPS_DATA_SERVICE) public userGroupsService: UserGroupsDataService,
    private dialogRef: MatDialogRef<AlumniCsvUploadDialogComponent>,
    private snackBar: MatSnackBar,
    private ngZone: NgZone,
    private translationService: TranslationService
  ) {

    this.searchInputSubscription = this.searchInputSubject$
      .pipe(debounceTime(500)).subscribe(() => this.fireGroupSearch());
  }

  ngOnDestroy() {
    this.searchInputSubscription?.unsubscribe();
  }

  saveButtonAction() {
    this.groupInvalid = false;
    this.csvInvalid = false;
    if (!this.selectedGroup || !this.csvFile) {
      this.groupInvalid = !this.selectedGroup;
      this.csvInvalid = !this.csvFile;
      SnackbarHelper.showTranslatableSnackBar(
        this.ngZone, this.snackBar, this.translationService, 'members.alumni.message.error.csvFileMissing'
      );
      return;
    }
    if (!this.validCsvFile()) {
      this.csvInvalid = true;
      SnackbarHelper.showTranslatableSnackBar(
        this.ngZone, this.snackBar, this.translationService, 'members.alumni.message.error.csvFileInvalid'
      );
      return;
    }
    const request = { groupUid: this.selectedGroup.id, file: this.csvFile };
    this.isLoading = true;
    this.accountService.alumniUploadCsv(request).subscribe(({ isSuccess, value, error }) => {
      this.isLoading = false;
      if (isSuccess) {
        this.closeAction();
        const processed = value ? value.processed.toString() : '';
        const message = this.translationService.getTranslation('members.alumni.message.success.csvFileAdded')
          .replace('{number}', processed);
        SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, message);
      } else {
        SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
      }
    });
  }

  closeAction() {
    this.dialogRef.close();
  }

  deleteGroupItem() {
    this.selectedGroup = undefined;
  }

  chooseGroup(group: MembersActiveFilterContent) {
    this.groupInvalid = false;
    this.selectedGroup = { id: group._id, name: group.title };
  }

  searchAutocompleteFormat(): string {
    return '';
  }

  onTextPaste(event: ClipboardEvent) {
    if (event.type === 'paste') {
      setTimeout(() => {
        if (this.searchString) {
          this.searchInProgress = true;
          this.searchInputSubject$.next();
        }
      }, 0);
    }
  }

  onSearchInputChange(event: KeyboardEvent) {
    if (canTriggerSearch(event)) {
      if (this.searchString) {
        this.searchInProgress = true;
        this.searchInputSubject$.next();
      }
    }
  }

  onSearchResultSelected({ option }: MatAutocompleteSelectedEvent): void {
    const group: UserGroups = option.value;
    this.selectedGroup = {
      id: group._id,
      name: group.title,
    };
  }

  fireGroupSearch() {
    this.userGroupsService.searchUserGroups(0, 10, this.searchString).subscribe(({ value }) => {
      if (value) {
        this.groups = value.content;
      }
      this.searchInProgress = false;
    });
  }

  onFilesDropped(fileList: FileList): void {
    if (fileList.length === 1) {
      this.uploadFile(fileList.item(0));
    } else {
      SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'uploadFileCountLimitError');
    }
  }

  onFileChanged(eventData: Event): void {
    this.uploadFile((eventData.target as HTMLInputElement).files[0]);
  }

  private validCsvFile() {
    /* eslint-disable */
    const pattern = '^.+\.(xlsx|xls|csv)$';
    /* eslint-enable */
    const csvRegexp = RegExp(pattern);
    return csvRegexp.test(this.csvFile.name);
  }

  private uploadFile(file: File) {
    this.csvInvalid = false;
    this.fileUploaded = true;
    if (file) {
      this.fileUploaded = true;
      this.uploadedFileName = file.name;
    } else {
      this.fileUploaded = false;
      this.uploadedFileName = '';
    }
    this.csvFile = file;
  }

}
