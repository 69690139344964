<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="form-preview" tabindex="0" [attr.aria-label]="('translations.global.ariaLabels.fileUploadForm' | transloco)"
     [class.form-preview--saved]="submitted">
  <div class="form-preview__top">
    <div class="form-preview__top-icon">
      <mat-icon aria-hidden="true">{{ submitted ? 'lock' : 'create' }}</mat-icon>
    </div>
    <div class="form-preview__top-title" tabindex="0">
      <div class="title">{{ formData?.content?.title }}</div>
      <div class="form-preview__top-limits">
        {{ 'translations.uploadedFileSizeLimit' | transloco: {maxFileSizeMb: formData?.content?.maxFileSizeMb} }}<br/>
        {{ 'translations.supportedFileTypes' | transloco: {supportedFileTypes: getSupportedFileTypes()} }}
      </div>
    </div>
  </div>
  <div class="form-preview__upload-file-section" *ngIf="submitted">
    <a tabindex="0" [attr.aria-label]="('translations.global.ariaLabels.downloadFile' | transloco: {name: uploadedFileName})" href="{{uploadedFileLink}}" target="_blank" download>{{uploadedFileName}}</a>
  </div>
  <div
    tabindex="0"
    class="form-preview__upload-file-section"
    *ngIf="!submitted"
    ogFileDrop
    (click)="fileInput.click()"
    [attr.aria-label]="('translations.global.ariaLabels.dragFileOrBrowse' | transloco)"
    (keydown.enter)="fileInput.click()"
    (filesHovered)="filesHovered=$event"
    [class.form-preview__upload-file-section--active]="filesHovered"
    (filesDropped)="onFilesDropped($event)">
    <mat-icon aria-hidden="true" *ngIf="fileUploadInProgress" class="form-preview__upload-file-loader">autorenew</mat-icon>
    <div *ngIf="!fileUploaded" [innerHTML]=" 'translations.dragFileOrBrowse' | transloco"></div>
    <a *ngIf="fileUploaded && !fileUploadInProgress"
       href="{{uploadedFileLink}}"
       (click)="$event.stopPropagation()"
       target="_blank"
       download>{{uploadedFileName}}</a>
    <input
      type="file"
      [accept]="supportedFileTypes"
      (change)="onFileChanged($event)"
      hidden
      #fileInput>
  </div>
  <div class="form-preview-notes" *ngIf="formData?.content?.notes?.length">
    <div class="note-item" *ngFor="let note of formData?.content?.notes" tabindex="0">
      <div class="note-added-by">
        <span class="note-label">{{ 'translations.cards.review.label.note' | transloco }}</span> {{note.addedBy}}, {{ getLocalDateTime(note.addedOn) | date: 'dd MMM yyyy' }}
      </div>
      <div class="note-message">
        <ptl-resource-preview class="thin-preview" [previewContent]="note.message">
        </ptl-resource-preview>
      </div>
    </div>
  </div>
  <div class="form-preview__footer" *ngIf="!submitted">
    <button
      mat-flat-button
      class="form-preview__footer-save uppercase"
      title="Submit form"
      type="button"
      (click)="submit()"
      [disabled]="isSubmissionDisabled">
      <span class="no-pointer">{{ 'translations.global.button.submit' | transloco }}</span>
    </button>
  </div>
</div>
