/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, OnInit, Input } from '@angular/core';
import { QuizReportResponse } from '../../../../../models';
import { Quiz } from '../../../../../../../shared/models/editor/quiz-content.model';
import { EditorContent, Organization } from '../../../../../../../shared/models';
import { LanguageCodeHelper } from '../../../../../../../shared/helpers/language-code-helper';

@Component({
  selector: 'ptl-app-results-report',
  templateUrl: './results-report.component.html',
  styleUrls: ['./results-report.component.scss'],
})
export class ResultsReportComponent implements OnInit {

  @Input() reportType: string;
  @Input() responses: QuizReportResponse[] = [];
  @Input() quiz: Quiz;
  @Input() isReviewResult: boolean;
  @Input() currentLanguage: string;
  @Input() organization: Organization;

  filteredResponses: QuizReportResponse[] = [];

  ngOnInit() {
    this.responses.forEach(response => {
      this.filteredResponses.push({
        ...response,
        options: (!this.isReviewResult && this.reportType === 'BRIEF')
          ? response.options.filter(option => option.selected === true) : response.options,
        feedback: this.getFeedback(response.feedback),
      });
    });
  }

  getTranslatedValue(instructions) {
    return LanguageCodeHelper.getDataByUserLanguageCode(instructions, this.organization, this.currentLanguage).value;
  }

  private getFeedback(feedback: EditorContent[]) {
    if (!feedback || !feedback.length) {
      return [];
    }
    if (feedback.length === 1 && feedback[0].content === '') {
      return  [];
    }
    return feedback;
  }
}
