<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ng-container *ngFor="let card of sectionCards; let i = index">
<ng-container *ngIf="!newVersionEnabled">
  <ptl-playlist-featured-card
    *ngIf="card.type !== 'LINK'"
    class="cards"
    [inHomeLayoutEditor]="inHomeLayoutEditor"
    [card]="card"
    [type]="'VIEW'"
    [isAdmin]="isAdmin"
    [size]="size"
    [index]="i"
    (cardClick)="onCardClick($event)">
  </ptl-playlist-featured-card>
</ng-container>

  <ng-container *ngIf="newVersionEnabled">
    <ptl-playlist-featured-card-new
      *ngIf="card.type !== 'LINK'"
      class="cards"
      role="listitem"
      [inHomeLayoutEditor]="inHomeLayoutEditor"
      [card]="card"
      [type]="'VIEW'"
      [size]="size"
      [index]="i"
      (cardClick)="onCardClick($event)">
    </ptl-playlist-featured-card-new>
  </ng-container>

  <ptl-playlist-featured-link
    *ngIf="card.type === 'LINK' && (card.isOpenPage || card.hasTargetedAccess || isEditPage)"
    class="cards"
    [index]="i"
    [inHomeLayoutEditor]="inHomeLayoutEditor"
    [type]="'VIEW'"
    [size]="size"
    [linkData]="card">
  </ptl-playlist-featured-link>
</ng-container>
