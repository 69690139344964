<!--
  ~ Copyright (C) 2021 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="search-playlist-container">

  <div class="title">
    {{'translations.discoveryShareSelectPlaylistPrepareSubmit' | transloco}}
  </div>

  <mat-form-field class="search-playlist-field" appearance="outline">
    <input
      matInput
      [(ngModel)]="searchString"
      [ngModelOptions]="{standalone: true}"
      (paste)="onTextPaste($event)"
      (keyup)="onSearchInputChange($event)"/>

    <mat-icon aria-hidden="true" matSuffix class="flex">
      <mat-spinner *ngIf="loadingSearch" [diameter]="20">
      </mat-spinner>
    </mat-icon>
  </mat-form-field>
</div>


<div class="share-discovery-playlists-content" *ngIf="playlists.length">
  <table>
    <thead>
    <tr>
      <th class="status">{{ 'translations.managePlaylist.list.label.liveStatus' | transloco }}</th>
      <th class="name">{{ 'translations.selectLayoutPlaylistTitle' | transloco }}</th>
      <th class="target">{{ 'translations.managePlaylist.list.label.targetedAt' | transloco }}</th>
      <th class="mod-date"><a href="javascript:void(0)">{{ 'translations.managePlaylist.list.label.modifiedDate' | transloco }}</a></th>
      <th class="action">{{ 'translations.framework.list.label.action' | transloco }}</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let playlist of playlists;">
      <td class="status">
        <span class="indicator" [class.inactive]="playlist.status.type !== 'PUBLISHED'"></span>
      </td>
      <td class="name">
        {{playlist.title}}
      </td>
      <td class="target">
        {{ 'translations.members.title' | transloco }}
      </td>
      <td class="mod-date">
        {{ playlist.playlistLastUpdatedOn | date: 'EEEE, d MMMM, H:mm'  }}
      </td>
      <td class="action">
        <button mat-stroked-button (click)="selectPlaylist(playlist)">
        {{ 'translations.global.button.select' | transloco }}
        </button>
      </td>
    </tr>
    </tbody>
  </table>
</div>
