import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  AI_ASSISTANT_SERVICE,
  AIAssistantAction,
  AiAssistantService,
  LearnerAIAssistantSettings,
  OpenAiMessage
} from '../../../../../../editor/services/assistant/ai-assistant.service';
import {
  copyToClipboard
} from '../../../../../../page-modules/resource/store/editor/content/helpers/copy-clipboard.helpers';
import { ContentHelper } from '../../../../../../shared/helpers/content-helper';

@Component({
  selector: 'ptl-form-ai-assistant',
  templateUrl: './form-ai-assistant.component.html',
  styleUrls: ['./form-ai-assistant.component.scss']
})
export class FormAiAssistantComponent implements OnInit {

  @Input() formContent: string;

  @Input() aiAssistantSettingsUid: string;

  @Input() formContentWordLimit: number;

  @Output() assistantAnswerSelected = new EventEmitter<{ text: string; eventType: string }>();

  @Output() closeAiAssistantBox = new EventEmitter<boolean>();

  @ViewChild('conversation') private conversationContainer: ElementRef;

  actions: AIAssistantAction[];
  assistantResponse: OpenAiMessage = null;
  isLoadingResponse = false;
  errorLoadingActions = false;
  isLoadingActions = true;
  activatedAction: AIAssistantAction = null;
  aiAssistantSettings: LearnerAIAssistantSettings;

  constructor(
    @Inject(AI_ASSISTANT_SERVICE) private service: AiAssistantService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this.service.getAIAssistantSettingsLearnerView(this.aiAssistantSettingsUid).subscribe(response => {
      this.aiAssistantSettings = response.value;
      if (response.isSuccess === false || response.error) {
        this.errorLoadingActions = true;
        this.isLoadingActions = false;
        this.cdr.detectChanges();
        return;
      }
      this.actions = response.value.actions;
      if (this.actions.length > 0) {
        this.activatedAction = this.actions[0];
      }
      this.isLoadingActions = false;
      this.cdr.detectChanges();
    });
  }

  sendMessage(prompt: string) {
    const strippedPrompt = ContentHelper.stripOnlyHtmlTags(ContentHelper.unescapeHtml(prompt));
    if (this.isLoadingResponse === false) {
      const request = {
        assistantSettingsUid: this.aiAssistantSettingsUid,
        sentence: strippedPrompt,
        actionUid: this.activatedAction.uid,
        wordLimit: this.formContentWordLimit
      };
      this.isLoadingResponse = true;
      this.scrollConversationToBottom();
      this.service.exchange(request).subscribe((response) => {
        this.isLoadingResponse = false;
        this.assistantResponse = response.value;
        this.scrollConversationToBottom();
        this.cdr.detectChanges();
      });
    }
  }

  clearMessages() {
    if (this.isLoadingResponse === false) {
      this.assistantResponse = null;
      this.cdr.detectChanges();
    }
  }

  scrollConversationToBottom(): void {
    setTimeout(() => {
      this.conversationContainer.nativeElement.scrollTop = this.conversationContainer.nativeElement.scrollHeight + 30;
    }, 100);
  }

  combineAndSend() {
    this.clearMessages();
    return this.sendMessage(this.formContent);
  }

  chooseAction(action: AIAssistantAction) {
    this.activatedAction = action;
    this.cdr.detectChanges();
  }

  @HostListener('document:copy', ['$event'])
  onCopy(event: ClipboardEvent) {
    const copiedText = window.getSelection().toString();
    this.assistantAnswerSelected.emit({ text: copiedText, eventType: 'copy' });
    this.clearMessages();
  }

  handleMessageClick(message: OpenAiMessage) {
    if (message.role === 'assistant') {
      this.assistantAnswerSelected.emit({ text: message.content, eventType: 'insert' });
      this.clearMessages();
    }
  }

  copyMessage(response: OpenAiMessage) {
    copyToClipboard(ContentHelper.stripOnlyHtmlTags(response.content));
  }

  closeAiBox() {
    this.closeAiAssistantBox.emit(true);
  }
}
