/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { ReviewEvent } from 'src/app/shared/models';
import { TranslationService } from 'src/app/shared/services/translation/translation.service';
import { UserDetailsSummary } from 'src/app/user-auth/models';
import { UserAuthState } from 'src/app/user-auth/store/user-auth.state';

@Component({
  selector: 'ptl-form-review-events-list',
  templateUrl: './form-review-events-list.component.html',
  styleUrls: ['./form-review-events-list.component.scss'],
})
export class FormReviewEventsListComponent implements OnInit {

  @Input() reviewEvents: ReviewEvent[];
  @Input() submitted: boolean;
  @Input() playlistSubmissionDeadline: Date;

  @Select(UserAuthState.userDetailsData)
  organizationData$: Observable<UserDetailsSummary>;

  userUid: string;

  constructor(
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    this.organizationData$.subscribe(value => {
      this.userUid = value.uid;
    })
  }

  getText(reviewEvent: ReviewEvent): string {
    let firstName: string;
    let lastName: string;
    if (reviewEvent.reviewer.uid === this.userUid) {
      firstName = this.translationService.getTranslation('you');
      lastName = '';
    } else {
      firstName = reviewEvent.reviewer.firstName;
      lastName = reviewEvent.reviewer.lastName;
    }
    switch(reviewEvent.type) {
      case 'SUBMITTED_FOR_REVIEW':
        return this.translationService.getTranslation('cards.form.footer.reviewEvents.submittedForReview') + `${firstName} ${lastName}`;
      case 'ACCEPTED':
        return this.translationService.getTranslation('cards.form.footer.reviewEvents.approved') + `${firstName} ${lastName}`;
      case 'REQUIRES_RESUBMISSION':
        return this.translationService.getTranslation('cards.form.footer.reviewEvents.resubmissionRequested') + `${firstName} ${lastName}`;
      case 'RESUBMITTED':
        return this.translationService.getTranslation('cards.form.footer.reviewEvents.resubmitted') + `${firstName} ${lastName}`;
      case 'REJECTED_TO_REVIEW':
        return this.translationService.getTranslation('cards.form.footer.reviewEvents.rejectedToReview') + `${firstName} ${lastName}`;
      case 'REMINDED_TO_SUBMIT':
        return this.translationService.getTranslation('cards.form.footer.reviewEvents.remindedToSubmit') + `${firstName} ${lastName}`;
      default:
        return null
    }
  }

  getReviewEventDate(reviewEvent: ReviewEvent): string {
    return moment
    .utc(reviewEvent.createdAt)
    .local()
    .format('D MMMM YYYY, HH:mm');
  }

  isLateSubmission(reviewEvent: ReviewEvent): boolean {
    return (reviewEvent.type === 'SUBMITTED_FOR_REVIEW' || reviewEvent.type === 'RESUBMITTED') &&
      reviewEvent.createdAt > this.playlistSubmissionDeadline;
  }
}
