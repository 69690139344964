import { BooleanFilter } from '../../../../../shared/models/admin/boolean-filters.model';

export class UpdateSelectedMembers {
  static readonly type = '[Members] Update selected members';

  constructor(public selectedMembers: string[]) {
  }
}

export class ClearSelectedMembers {
  static readonly type = '[Members] Clear selected members';
}

export class UpdateMemberFilters {
  static readonly type = '[Members] Update member filters';

  constructor(public filters: BooleanFilter[]) {
  }
}

export class UpdateCardFilters {
  static readonly type = '[Members] Update card filters';

  constructor(public cardUid: string, public filters: BooleanFilter[]) {
  }
}

export class UpdatePlaylistFilters {
  static readonly type = '[Members] Update playlist filters';

  constructor(public playlistUid: string, public filters: BooleanFilter[]) {
  }
}

export class UpdatePlaylistReviewFilters {
  static readonly type = '[Members] Update playlist review filters';

  constructor(public playlistUid: string, public filters: BooleanFilter[]) {
  }
}
