<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div *ngIf="reviewEvents?.length > 0"
  class="chips-list"
  [matMenuTriggerFor]="reviewEventsListPopup">
  <div class="chip-item chip-interactive pointer no-margin">
    <span class="chip-text">{{ 'translations.cards.form.footer.reviewEvents.title' | transloco: { count: reviewEvents.length } }}</span>
  </div>
</div>
<mat-menu class="mat-menu" #reviewEventsListPopup="matMenu">
  <div class="review-event-list-content">
    <div class="review-event" *ngFor="let reviewEvent of reviewEvents" [ngSwitch]="reviewEvent.type">

      <div class="review-event-info small" *ngSwitchCase="'SUBMITTED_FOR_REVIEW'">
        <mat-icon aria-hidden="true" class="mat-icon">forward</mat-icon>
        {{ getText(reviewEvent) }}
      </div>

      <div class="review-event-info small" *ngSwitchCase="'ACCEPTED'">
        <svg class="svg positive" role="img"
            [attr.aria-label]="('translations.global.ariaLabels.icons.check' | transloco)">
          <use ogSvgUnify="assets/sprite/sprite.svg#check"></use>
        </svg>
        {{ getText(reviewEvent) }}
      </div>

      <div class="review-event-info small" *ngSwitchCase="'REQUIRES_RESUBMISSION'">
        <mat-icon aria-hidden="true" class="mat-icon warn">loop</mat-icon>
        {{ getText(reviewEvent) }}
      </div>

      <div class="review-event-info small" *ngSwitchCase="'RESUBMITTED'">
        <mat-icon aria-hidden="true" class="mat-icon highlight">replay</mat-icon>
        {{ getText(reviewEvent) }}
      </div>

      <div class="review-event-info small" *ngSwitchCase="'REJECTED_TO_REVIEW'">
        <mat-icon aria-hidden="true" class="mat-icon error">close</mat-icon>
        {{ getText(reviewEvent) }}
      </div>

      <div class="review-event-info small" *ngSwitchCase="'REMINDED_TO_SUBMIT'">
        <mat-icon aria-hidden="true" class="mat-icon highlight">flash_on</mat-icon>
        {{ getText(reviewEvent) }}
      </div>

      <div class="review-event-date">
        <div class="late-submission-indicator" *ngIf="isLateSubmission(reviewEvent)">
          ({{ 'translations.reviews.status.dateLabel.lateSubmission' | transloco }})
        </div>
        {{ getReviewEventDate(reviewEvent) }}
      </div>

    </div>
  </div>
</mat-menu>
