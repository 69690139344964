<!--
  ~ Copyright (C) 2022 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ng-container>
  <div class="member-filter__wrapper">
    <div class="member-filter__dropdowns">
      <mat-select
        [(value)]="selectedActivityStatus"
        (selectionChange)="onActivityStatusChange($event)"
        class="member-filter__dropdown member-filter__dropdown__activity">
        <mat-option *ngFor="let activityStatus of activityStatuses" [value]="activityStatus.value">{{ activityStatus.title }}</mat-option>
      </mat-select>
      <mat-select
        [(value)]="selectedTimeType"
        (selectionChange)="onTimeTypeChange($event)"
        class="member-filter__dropdown member-filter__dropdown__time">
        <mat-option *ngFor="let timeType of timeTypes" [value]="timeType.value">{{ timeType.title }}</mat-option>
      </mat-select>
      <div class="member-filter__date">
        <ng-container *ngIf="selectedTimeType === 'SINCE'">
          <mat-select
            [(value)]="selectedDay"
            (selectionChange)="onDayChange($event)"
            class="member-filter__dropdown member-filter__time__dropdown"
            [placeholder]="('translations.members.filter.activity.day' | transloco)">
            <mat-option *ngFor="let day of days" [value]="day">{{ day }}</mat-option>
          </mat-select>
          <mat-select
            [(value)]="selectedMonth"
            (selectionChange)="onMonthChange($event)"
            class="member-filter__dropdown member-filter__time__dropdown"
            [placeholder]="('translations.members.filter.activity.month' | transloco)">
            <mat-option *ngFor="let month of months" [value]="month.value">{{ month.name }}</mat-option>
          </mat-select>
          <mat-select
            [(value)]="selectedYear"
            (selectionChange)="onYearChange($event)"
            class="member-filter__dropdown member-filter__time__dropdown"
            [placeholder]="('translations.members.filter.activity.year' | transloco)">
            <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
          </mat-select>
        </ng-container>
        <ng-container *ngIf="selectedTimeType === 'PERIOD'">
          <form [formGroup]="periodForm" class="member-filter__number">
          <input
            matInput
            type="number"
            class="member-filter__number__input"
            pattern="^\d+$"
            min="0"
            formControlName="periodLength"
            />
          </form>
          <mat-select
            [(value)]="selectedPeriod"
            (selectionChange)="onPeriodChange($event)"
            class="member-filter__dropdown member-filter__time__dropdown"
            [placeholder]="('translations.members.filter.activity.period' | transloco)">
            <mat-option *ngFor="let period of periods" [value]="period.value">{{ period.title }}</mat-option>
          </mat-select>
          <div class="member-filter__time__dropdown"></div>
        </ng-container>
      </div>
    </div>
    <p *ngIf="invalidDate" class="member-filter__validation">
      {{ 'translations.members.filter.activity.invalid' | transloco }}
    </p>
    <p *ngIf="noNumber" class="member-filter__validation">
      {{ 'translations.members.filter.activity.noNumber' | transloco }}
    </p>
  </div>
  <ptl-member-filter-controls (cancel)="onCancel()" (save)="onSave()"></ptl-member-filter-controls>
</ng-container>
