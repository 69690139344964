/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable, Subscription, filter } from 'rxjs';
import { map } from 'rxjs/operators';
import { PlaylistViewState } from '../../../../page-modules/playlist/store/view/playlist-view.state';
import { LearnerViewState } from '../../../../page-modules/resource/store/learner-view.state';
import { LanguageCodeHelper } from '../../../helpers/language-code-helper';
import { VersionHelper } from '../../../helpers/version.helper';
import { FileUploadBoxFormContent, Form, TextBoxFormContent, UserPlaylistSubmissionSummary } from '../../../models';
import { ProjectHelper } from '../../../../page-modules/project/helpers/project.helper';
import { LearnerFormAnswer } from '../../../../page-modules/resource/models';

@Component({
  selector: 'ptl-resource-form-preview',
  templateUrl: './form-preview.component.html',
  styleUrls: ['./form-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceFormPreviewComponent implements OnInit, OnChanges, OnDestroy {

  /** Receives the Form object */
  @Input() formContent: Form<TextBoxFormContent> | Form<FileUploadBoxFormContent>;

  @Input() userPlaylistSubmissionSummary: UserPlaylistSubmissionSummary | undefined;

  @Input() hideTextFormButtons = false;

  @Select(PlaylistViewState.playlistId)
  private playlistId$: Observable<string>;

  @Select(LearnerViewState.resourceId)
  private resourceId$: Observable<string>;

  @Select(LearnerViewState.learnerFormAnswers)
  private learnerFormAnswers$: Observable<LearnerFormAnswer[]>;

  isProjectResource: boolean;
  playlistUri: string;
  playlistUid: string;
  resourceUri: string;
  resourceUid: string;
  groupUid: string;
  publisherUri: string;
  packageUri: string;
  pageUri: string;
  languageCode: string;
  learnerFormAnswer?: LearnerFormAnswer;

  newVersionEnabled = VersionHelper.newVersionEnabled();

  private playlistIdSubscription: Subscription;
  private resourceIdSubscription: Subscription;
  private learnerFormAnswersSubscription: Subscription;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private cd: ChangeDetectorRef,
  ) {
    this.isProjectResource = ProjectHelper.isProjectByUrl(this.router);
    this.playlistUri = this.activeRoute.snapshot.paramMap.get('playlistUri');
    this.groupUid = this.activeRoute.snapshot.paramMap.get('groupUri');
    this.resourceUri = this.activeRoute.snapshot.paramMap.get('resourceUri');
    this.publisherUri = this.activeRoute.snapshot.paramMap.get('publisherUri');
    this.packageUri = this.activeRoute.snapshot.paramMap.get('packageUri');
    this.pageUri = this.activeRoute.snapshot.paramMap.get('pagesUri');
    this.languageCode = LanguageCodeHelper.getContentLanguageCode();

    if (!this.resourceUri) {
      this.resourceUri = this.activeRoute.snapshot.paramMap.get('diagnosticUri');
    }
  }

  ngOnInit() {
    this.playlistIdSubscription = this.playlistId$
      .pipe(filter(playlistId => !!playlistId))
      .subscribe((playlistId: string) => this.playlistUid = playlistId);
    this.resourceIdSubscription = this.resourceId$
      .pipe(filter(resourceId => !!resourceId))
      .subscribe((resourceId: string) => this.resourceUid = resourceId);
    this.fetchFormAnswers();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.userPlaylistSubmissionSummary && changes.userPlaylistSubmissionSummary.currentValue) {
      this.cd.detectChanges();
    }
    this.fetchFormAnswers();
  }

  ngOnDestroy() {
    this.playlistIdSubscription?.unsubscribe();
    this.resourceIdSubscription?.unsubscribe();
    this.learnerFormAnswersSubscription?.unsubscribe();
  }

  private fetchFormAnswers() {
    this.learnerFormAnswersSubscription = this.learnerFormAnswers$
      .pipe(
        filter(formAnswers => !!formAnswers),
        map((formAnswers) =>
          formAnswers.find((formAnswer) => formAnswer.formUid === this.formContent.uid))
      ).subscribe((formAnswer) => {
        this.learnerFormAnswer = formAnswer
        this.cd.detectChanges();
      });
  }

}
