<!--
~ Copyright (C) 2019 - Potentially Ltd
~
~ Please see distribution for license.
-->

<ng-container *ngIf="!newVersionEnabled">
  <div class="form-section">
    <form
      class="form-section__form-expanded"
      *ngIf="expanded"
      #textBoxFormElement
      tabindex="0"
      [attr.aria-label]="('translations.global.ariaLabels.thisIsTextboxForm' | transloco)"
      [formGroup]="textboxForm"
      (ngSubmit)="saveForm()">
      <div class="form-section__box form-section__box--add">
        <mat-form-field class="form-section__form-field mat-no-border mat-full-width">
          <mat-label #instructionText>{{ 'translations.instruction' | transloco }}</mat-label>
          <input
            matInput
            type="text"
            required
            formControlName="title"
            [placeholder]="'translations.instruction' | transloco">
        </mat-form-field>
      </div>
      <div class="form-section__box form-section__form-group form-section__box--no-border">
        <label for="wordLimit">{{ 'translations.setWordLimit' | transloco }}</label>
        <mat-form-field class="form-section__form-field mat-outline-small" appearance="outline" style="width: 7.5rem">
          <mat-label>{{ 'translations.wordLimit' | transloco }}</mat-label>
          <input
            id="wordLimit"
            matInput
            type="number"
            min="1"
            required
            formControlName="wordLimit">
        </mat-form-field>
      </div>

      <div class="form-section__footer">
        <div class="form-section__footer-right">
          <button
            mat-button
            class="form-section__footer-btn"
            color="warn"
            type="button"
            title="Remove element"
            *ngIf="canRemoveItem"
            [attr.aria-label]="('translations.global.ariaLabels.deleteTextBoxForm' | transloco)"
            (click)="removeForm()">{{ 'translations.global.button.delete' | transloco }}
          </button>
          <button
            mat-flat-button
            class="form-section__footer-btn uppercase"
            color="primary"
            title="Save"
            type="submit"
            [attr.aria-label]="('translations.global.ariaLabels.addTextBoxForm' | transloco)">{{ 'translations.addToForm' | transloco }}
          </button>
        </div>
      </div>
    </form>

    <div class="form-section__form-contracted form-section__form-contracted-textbox" *ngIf="!expanded">
      <div class="form-section__form-contracted-text" tabindex="0">
        <span class="uppercase">{{ 'translations.form' | transloco }}</span>{{ textboxForm.value.title }}
      </div>
      <div class="form-section__form-contracted-actions">
        <button
          mat-button
          class="form-section__save-btn"
          title="Edit"
          color="primary"
          [attr.aria-label]="('translations.global.ariaLabels.addTextBoxForm' | transloco)"
          (click)="expandForm()">{{ 'translations.global.button.edit' | transloco }}
        </button>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="newVersionEnabled">
  <div class="form-section-new" (focusin)="focused=true" (focusout)="focused=false" (click)="expandForm()"
       (keydown.enter)="$event.stopPropagation()">
    <form
      class="form-block"
      #textBoxFormElement
      tabindex="0"
      [class.has-error]="hasError"
      [class.is_focused]="focused"
      [attr.aria-label]="('translations.global.ariaLabels.thisIsTextboxForm' | transloco)"
      [formGroup]="textboxForm"
      (ngSubmit)="saveForm()">
      <div class="form-block-inner">
        <div class="form-block-title-box">
          <input
            type="text"
            class="form-block-input-field no-border full-width no-padding medium bold"
            required
            (input)="hasError = false"
            formControlName="title"
            [placeholder]="'translations.instruction' | transloco">
        </div>
        <ng-container *ngIf="expanded">
          <div class="form-block-help-text-box extra-small"></div>
          <div class="form-section-box small">
            <input
              class="form-block-input-field count-field small"
              type="number"
              min="1"
              required
              formControlName="wordLimit">
            <label for="wordLimit">{{ 'translations.setWordLimit' | transloco }}</label>
          </div>
          <div class="form-ai-assistant" *ngIf="aiAssistantFeatureEnabled">
            <mat-slide-toggle
              class="mat-slide-toggle enable-ai-toggle mat-primary"
              color="primary"
              formControlName="aiAssistantEnabled"
              [checked]="aiAssistantEnabled"
              (change)="onAiAssistantToggle($event)">
              {{ 'translations.enableAiAssistant' | transloco }}
            </mat-slide-toggle>

            <div *ngIf="aiAssistantEnabled" class="template-type">
              <button
                mat-stroked-button
                class="small"
                [ngClass]="{'active': isDialogOpen}"
                [title]="'translations.global.dataForms.aiAssistant.settings.selectTemplate' | transloco"
                type="button"
                (click)="openAiTemplatesDialog()"
                *ngIf="canRemoveItem"
                [attr.aria-label]="'translations.global.dataForms.aiAssistant.settings.selectTemplate' | transloco">
                {{ 'translations.global.dataForms.aiAssistant.settings.selectTemplate' | transloco }}
              </button>

              <button
                mat-stroked-button
                [ngClass]="{'active': isTemplateSettingsEnabled}"
                class="small"
                [title]="'translations.global.dataForms.aiAssistant.settings.createYourOwn' | transloco"
                type="button"
                (click)="openAiSettings()"
                *ngIf="canRemoveItem"
                [attr.aria-label]="'translations.global.dataForms.aiAssistant.settings.createYourOwn' | transloco">
                {{ 'translations.global.dataForms.aiAssistant.settings.createYourOwn' | transloco }}
              </button>
            </div>
          </div>
          <button mat-icon-button type="button" class="form-edit-icon" [attr.aria-label]="('translations.global.ariaLabels.icons.edit' | transloco)">
            <svg class="svg" aria-hidden="true">
              <use ogSvgUnify="assets/sprite/sprite.svg#edit"></use>
            </svg>
          </button>
          <ptl-form-ai-assistant-settings
            *ngIf="aiAssistantEnabled && isTemplateSettingsEnabled"
            #formAiAssistantSettings
            [assistantId]="aiAssistantSettingsUid"
            [aiAssistantData]="aiAssistantData"
            [updatedAssistantSettings]="updatedAssistantSettings"
            (formChanged)="formChanged($event)"
            (saveAsTemplate)="saveAsTemplate($event)"
            [cardUid]="cardUid">
          </ptl-form-ai-assistant-settings>
        </ng-container>
        <span class="setting-text extra-small"
              *ngIf="!expanded">{{ 'translations.global.button.settings' | transloco }}</span>
      </div>
      <div class="form-block-buttons" *ngIf="expanded">
        <div class="form-controls">
          <button
            mat-flat-button
            class="form-button-remove small f_delete-block"
            type="button"
            color="warn"
            [title]=" 'translations.global.button.delete' | transloco"
            *ngIf="canRemoveItem"
            [attr.aria-label]="('translations.global.ariaLabels.deleteTextBoxForm' | transloco)"
            (click)="removeForm()">
            <svg class="svg svg-icon icon-left" aria-hidden="true">
              <use ogSvgUnify="assets/sprite/sprite.svg#delete"></use>
            </svg>
            <span>{{ 'translations.global.button.delete' | transloco }}</span>
          </button>
          <button
            mat-flat-button
            class="form-button-save small btn-with-loader f_update-block"
            [title]="!form?.uid ? ('translations.addToForm' | transloco) : ('translations.global.button.update' | transloco)"
            color="primary"
            type="submit"
            [attr.aria-label]="('translations.global.ariaLabels.addTextBoxForm' | transloco)">
            <svg class="svg svg-icon icon-left" aria-hidden="true">
              <use ogSvgUnify="assets/sprite/sprite.svg#check"></use>
            </svg>
            <span>{{ !form?.uid ? ('translations.addToForm' | transloco) : ('translations.global.button.update' | transloco) }}</span>
            <mat-spinner class="is-right" *ngIf="saveInProgress" [diameter]="20"></mat-spinner>
          </button>
          <button
            mat-flat-button
            class="form-button-save small f_cancel-block"
            [title]="'translations.global.button.cancel' | transloco"
            color="accent"
            type="button"
            (click)="collapseForm($event)"
            [attr.aria-label]="('translations.global.ariaLabels.collapseForm' | transloco)">
            <span>{{ 'translations.global.button.cancel' | transloco }}</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-container>