<div class="dialog-header">
  <div class="dialog-header__top">

    <h1 class="dialog-header-title"
        tabindex="0"
        [attr.aria-label]="'translations.global.ariaLabels.alumniTitle' | transloco">
      {{ 'translations.members.alumni.title' | transloco }}</h1>

    <i class="material-icons dialog-header__close-icon"
       [attr.aria-label]="('translations.global.ariaLabels.closePage' | transloco)"
       (click)="closeAction()"
       (keydown.enter)="closeAction()"
       tabindex="0">close</i>

  </div>
</div>


<div class="dialog__cropper-container">
  <div class="content-wrapper">
    <div
      tabindex="0"
      class="file-upload-content"
      ogFileDrop
      (click)="fileInput.click()"
      [attr.aria-label]="('translations.global.ariaLabels.dragFileOrBrowse' | transloco)"
      (keydown.enter)="fileInput.click()"
      (filesHovered)="filesHovered=$event"
      [class.file-upload-content--active]="filesHovered"
      [class.file-upload-content--error]="csvInvalid"
      (filesDropped)="onFilesDropped($event)">
      <div *ngIf="!fileUploaded" [innerHTML]=" 'translations.members.alumni.button.dragFileOrBrowse' | transloco"></div>
      <span *ngIf="fileUploaded">{{ uploadedFileName }}</span>
      <input
        type="file"
        [accept]="'.csv'"
        (change)="onFileChanged($event)"
        hidden
        #fileInput>
    </div>

    <div class="download-csv-content"
         [innerHTML]="'translations.members.alumni.description.csvDownload' | transloco | safeHtml"></div>

    <div class="members-filter__filter-item-container-inner">
      <div class="members-filter__search-field">
        <input
          matInput
          [matAutocomplete]="auto"
          [(ngModel)]="searchString"
          placeholder="{{ 'translations.global.search.label.group' | transloco }}"
          class="members-filter__filter-item-form-input"
          [class.members-filter__filter-item-form-input--error]="groupInvalid"
          [ngModelOptions]="{standalone: true}"
          (paste)="onTextPaste($event)"
          (keyup)="onSearchInputChange($event)"/>
        <mat-autocomplete
          [panelWidth]="450"
          class="members-filter__autocomplete"
          #auto="matAutocomplete"
          [displayWith]="searchAutocompleteFormat"
          (optionSelected)="onSearchResultSelected($event)">
          <mat-option
            *ngFor="let group of groups"
            [value]="group">
            <div>{{ group.title }}</div>
          </mat-option>
        </mat-autocomplete>

        <mat-icon aria-hidden="true" matSuffix class="flex">
          <mat-spinner *ngIf="searchInProgress" [diameter]="20">
          </mat-spinner>
        </mat-icon>
      </div>
    </div>

    <div class="file-uploaded-groups" *ngIf="selectedGroup">

      <div class="file-uploaded-groups-item">
        <div class="bold">{{ selectedGroup.name }}</div>
        <span
          tabindex="0"
          class="file-uploaded-groups-item-remove"
          matTooltip="Remove Group"
          [attr.aria-label]="('translations.global.ariaLabels.removeGroup' | transloco)"
          (click)="deleteGroupItem()"
          (keydown.enter)="deleteGroupItem()">
                <mat-icon aria-hidden="true" [inline]="true">clear</mat-icon>
              </span>
      </div>

    </div>
  </div>
  <button
    mat-flat-button
    class="okay-btn"
    (click)="saveButtonAction()">
    {{ 'translations.members.alumni.button.upload' | transloco }}
    <span class="submit--loading" *ngIf="isLoading">
            <mat-spinner [diameter]="20"></mat-spinner>
          </span>
  </button>
</div>
