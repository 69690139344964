<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="collector-preview__section">

  <div class="collector-preview__section-top">
    <div class="collector-preview__section-icon">
      <mat-icon aria-hidden="true">{{ (learnerCollectorSummary?.status === 'COMPLETED') ? 'lock' : 'card_travel' }}</mat-icon>
    </div>
    <div class="collector-preview__section-info" tabindex="0" [attr.aria-label]="('translations.global.ariaLabels.collectorForm' | transloco)">
      <div class="collector-preview__headline" tabindex="0">
        <div class="collector-preview__section-title">{{ title }}</div>
        <ptl-resource-preview [previewContent]="instruction"></ptl-resource-preview>
      </div>

      <div *ngIf="expanded" class="collector-preview__log-info">
        <div class="collector-preview__progress-box" tabindex="0" [attr.aria-label]="('translations.global.ariaLabels.completed' | transloco: {progress: learnerCollectorSummary?.progress + '%'})">
          <div class="collector-preview__progress-range">
            <div
              class="collector-preview__progress-range-inner"
              [ngStyle]="{'width': learnerCollectorSummary?.progress + '%'}"
            ></div>
          </div>
          <span class="collector-preview__progress">{{learnerCollectorSummary?.progress}}%</span>

        </div>
        <div class="collector-preview__log-info-table">
          <div class="log-info-table">
            <div class="log-info-table-row">
              <div class="log-info-table-cell header-name-cell"></div>
              <div class="log-info-table-cell header-name-time">
                <span *ngIf="requiredTable.type !== 'TIME_LOG'">{{ 'translations.collector.completionTable.moments' | transloco }}</span>
                <span *ngIf="requiredTable.type === 'TIME_LOG'">{{ 'translations.collector.completionTable.logs' | transloco }}</span>
              </div>
            </div>
            <div class="log-info-table-row" tabindex="0" [attr.aria-label]="('translations.global.ariaLabels.totalRequirementForComplete' | transloco: {totalCount: requiredTable.totalCount})">
              <div class="log-info-table-cell name-cell">{{ 'translations.collector.completionTable.totalRequired' | transloco }}</div>
              <div class="log-info-table-cell name-required">{{ requiredTable.totalCount }}</div>
            </div>
            <div class="log-info-table-row" tabindex="0" [attr.aria-label]="('translations.global.ariaLabels.alreadyLogged' | transloco: {completedCount: requiredTable.completedCount})">
              <div class="log-info-table-cell name-cell">{{ 'translations.collector.completionTable.logsLogged' | transloco }}</div>
              <div class="log-info-table-cell name-required green">{{ requiredTable.completedCount }}</div>
            </div>
            <div class="log-info-table-row" tabindex="0" [attr.aria-label]="('translations.global.ariaLabels.remainingToLog' | transloco: {remainingCount: requiredTable.remainingCount})">
              <div class="log-info-table-cell name-cell">{{ 'translations.collector.completionTable.logsRemaining' | transloco }}</div>
              <div class="log-info-table-cell name-required orange">{{ requiredTable.remainingCount }}</div>
            </div>
          </div>
        </div>
      </div>
      <span
        *ngIf="!expanded && learnerCollectorSummary?.status === 'COMPLETED'"
        tabindex="0"
        [attr.aria-label]="('translations.global.ariaLabels.collectorFormSubmitted' | transloco)"
        class="collector-preview__submit-status-label">{{ 'translations.collector.label.completedStatus' | transloco }}</span>


    </div>

  </div>
  <div *ngIf="expanded" class="collector-preview__section-list">
    <button
      mat-button
      tabindex="0"
      class="collector-preview__section-add-moment uppercase"
      color="primary"
      title="Add from existing moments"
      [attr.aria-label]="('translations.global.ariaLabels.logNewItem' | transloco)"
      (click)="addEditLogItem()">{{ 'translations.collector.button.logNewItem' | transloco }}
    </button>

    <ptl-collector-table
      [learnerCollectorSummary]="learnerCollectorSummary"
      (logItemEdit)="editLoggedItem($event)"
      (logItemDelete)="openDeleteLogItemPopUp($event)">
    </ptl-collector-table>

  </div>
  <div class="collector-preview__footer">
    <button
      *ngIf="!expanded"
      mat-button
      class="collector-preview__expand-btn uppercase"
      title="Show the collector evidences"
      color="primary"
      (click)="expandCollectorInfo(true)">{{ 'translations.global.button.seeMore' | transloco }}
    </button>
    <button
      *ngIf="expanded  && learnerCollectorSummary?.status !== 'COMPLETED'"
      mat-button
      [disabled]="!learnerCollectorSummary?.isReadyToSubmit"
      class="collector-preview__submit-btn uppercase"
      title="Submit the learner collector summary"
      color="primary"
      (click)="readyToSubmit()">{{ 'translations.collector.button.readyToSubmit' | transloco }}
    </button>
  </div>

  <div class="form-preview-notes" *ngIf="formData?.content?.notes?.length">
    <div class="note-item" *ngFor="let note of formData?.content?.notes" tabindex="0">
      <div class="note-added-by">
        <span class="note-label">{{ 'translations.cards.review.label.note' | transloco }}</span> {{note.addedBy}}, {{ getLocalDateTime(note.addedOn) | date: 'dd MMM yyyy' }}
      </div>
      <div class="note-message">
        <ptl-resource-preview class="thin-preview" [previewContent]="note.message">
        </ptl-resource-preview>
      </div>
    </div>
  </div>


  <button title="Collapse" *ngIf="expanded" class="collector-preview__collapse-btn" (click)="expandCollectorInfo(false)">
    <mat-icon aria-hidden="true">keyboard_arrow_up</mat-icon>
  </button>
</div>
