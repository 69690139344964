import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { TranslationService } from '../../../../../../../shared/services/translation/translation.service';
import {
  BooleanQueryRequest,
  BooleanQueryType,
  RolesRequest, NotRequest, BooleanFilter
} from '../../../../../../../shared/models/admin/boolean-filters.model';
import { AdminMembersRoles } from '../../../../../../../shared/models/admin/members.model';
import { ROLES_DATA_SERVICE, RolesDataService } from '../../../../../services/roles/roles-data.service';

@Component({
  selector: 'ptl-role-member-filter',
  templateUrl: './role-member-filter.component.html',
  styleUrls: ['./role-member-filter.component.scss'],
})
export class RoleMemberFilterComponent implements OnChanges, OnDestroy {

  @Output() setRoleFilter = new EventEmitter<BooleanFilter>();
  @Output() cancelRoleFilter = new EventEmitter<void>();
  @Input() excludedRoles: string[];
  @Input() excludedRoleStatuses: string[];

  selectedRoleStatus: 'HAS_ROLE' | 'WITHOUT_ROLE' = 'HAS_ROLE';
  allRoleStatuses = [];
  roleStatuses = [];
  selectedRoles: AdminMembersRoles[] = [];
  noRolesSelected = false;

  showSearchResult: boolean;
  roles: AdminMembersRoles[];
  searchString = new FormControl('');
  searchInProgress: boolean;

  private searchInputSubject$ = new Subject<void>();
  private searchInputSubscription: Subscription;
  private subscriptionEnd$ = new EventEmitter<void>();

  constructor(
    private translationService: TranslationService,
    @Inject(ROLES_DATA_SERVICE) private rolesDataService: RolesDataService,
  ) {
    this.allRoleStatuses = [
      { value: 'HAS_ROLE', title: this.translationService.getTranslation('members.filter.role.has') },
      { value: 'WITHOUT_ROLE', title: this.translationService.getTranslation('members.filter.role.without')  },
    ];
    this.roleStatuses = this.allRoleStatuses;
    this.searchInputSubscription = this.searchInputSubject$
      .pipe(debounceTime(500))
      .subscribe(() => this.fireSearch());
  }

  ngOnDestroy() {
    this.subscriptionEnd$.emit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.excludedRoleStatuses && this.roleStatuses) {
      this.roleStatuses = this.allRoleStatuses.filter(
        status => !this.excludedRoleStatuses.includes(status.value)
      );
      if (this.roleStatuses.length) {
        this.selectedRoleStatus = this.roleStatuses[0].value;
      }
    }
  }

  onRoleStatusChange(event: MatSelectChange) {
    this.selectedRoleStatus = event.value;
  }

  onSave() {
    if (this.selectedRoles.length === 0) {
      this.noRolesSelected = true;
      return;
    }
    const result = {
      request: this.getRequest(),
      message: this.getMessage()
    }
    this.setRoleFilter.emit(result);
  }

  onCancel() {
    this.cancelRoleFilter.emit();
  }

  onInputSubmit() {
    this.fireSearch();
  }

  onInputChange() {
    this.searchInputSubject$.next();
  }

  onRoleSelect(role: AdminMembersRoles) {
    this.showSearchResult = false;
    if (!this.selectedRoles.map(selectedRole => selectedRole._id).includes(role._id)) {
      this.selectedRoles.push(role);
      this.noRolesSelected = false;
    }
  }

  onRoleRemove(role: AdminMembersRoles) {
    this.selectedRoles = this.selectedRoles.filter(selectedRole => selectedRole._id !== role._id);
  }

  private fireSearch() {
    this.showSearchResult = false;
    this.searchInProgress = true;
    const searchString = this.searchString.value;
    if (!searchString) {
      this.searchInProgress = false;
      return;
    }
      this.rolesDataService.loadRolesByPageWithSearch(searchString, 0, 10).subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          this.roles = value.content
            .filter(role => !this.selectedRoles.map(selectedRole => selectedRole._id).includes(role._id))
            .filter(role => !this.excludedRoles.includes(role._id));
        }
        this.searchInProgress = false;
        this.showSearchResult = true;
      });
  }

  private getRequest(): BooleanQueryRequest {
    const rolesRequest: RolesRequest = {
      type: BooleanQueryType.ROLES,
      roles: this.selectedRoles.map(role => role._id)
    };
    if (this.selectedRoleStatus === 'HAS_ROLE') {
      return rolesRequest
    } else {
      return {
        type: BooleanQueryType.NOT,
        arg: rolesRequest
      } as NotRequest
    }
  }

  private getMessage(): string {
    if (this.selectedRoleStatus === 'HAS_ROLE') {
      if (this.selectedRoles.length === 1) {
        return this.translationService.getTranslation('members.filter.summary.hasRole')
          + ' ' + `'${this.selectedRoles[0].name[0].value}'`;
      } else {
        return this.translationService.getTranslation('members.filter.summary.hasRoles')
          + ' ' + this.selectedRoles.map(role => `'${role.name[0].value}'`).join(', ');
      }
    } else {
      if (this.selectedRoles.length === 1) {
        return this.translationService.getTranslation('members.filter.summary.withoutRole')
          + ' ' + `'${this.selectedRoles[0].name[0].value}'`;
      } else {
        return this.translationService.getTranslation('members.filter.summary.withoutRoles')
          + ' ' + this.selectedRoles.map(role => `'${role.name[0].value}'`).join(', ');
      }
    }
  }
}
