<!--
  ~ Copyright (C) 2022 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ng-container>
  <div class="member-filter__wrapper">
    <div class="member-filter__dropdowns">
      <mat-select
        [(value)]="selectedRoleStatus"
        (selectionChange)="onRoleStatusChange($event)"
        class="member-filter__dropdown">
        <mat-option *ngFor="let roleStatus of roleStatuses" [value]="roleStatus.value">{{ roleStatus.title }}</mat-option>
      </mat-select>
      <div class="member-role__container" *ngIf="true">
        <div class="member-role__container-inner">
          <div class="member-role__search-container">
            <form class="member-role__search-form" (ngSubmit)="onInputSubmit()">
              <input
                type="text"
                class="member-role__search-form-input"
                (ngModelChange)="onInputChange()"
                [formControl]="searchString"
                [attr.aria-label]="('translations.members.filter.role.search' | transloco)"
                [placeholder]="('translations.members.filter.role.search' | transloco)">
              <mat-spinner
                class="member-role__search-spinner"
                *ngIf="searchInProgress"
                [diameter]="18">
              </mat-spinner>
            </form>
            <div class="member-role__search-result" *ngIf="showSearchResult">
              <ul class="member-role__search-list" *ngIf="roles?.length">
                <li tabindex="0"
                    (click)="onRoleSelect(role)"
                    (keydown.enter)="onRoleSelect(role)"
                    *ngFor="let role of roles">
                  {{ role.name[0].value }}
                </li>
              </ul>
              <div class="member-role__search-empty" *ngIf="!roles?.length">
                {{ 'translations.global.search.label.noData' | transloco }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="selected-roles">
        <div class="selected-role" *ngFor="let selectedRole of selectedRoles;">
          <span>{{ selectedRole.name[0].value }}</span>
          <span
            class="selected-role__remove"
            [matTooltip]="('translations.members.filter.role.remove' | transloco)"
            [attr.aria-label]="('translations.global.ariaLabels.clear' | transloco: {title: selectedRole.name[0].value})"
            (click)="onRoleRemove(selectedRole)"
            (keydown.enter)="onRoleRemove(selectedRole)"
            tabindex="0">
            <mat-icon aria-hidden="true" [inline]="true">clear</mat-icon>
          </span>
        </div>
      </div>
    </div>
    <p *ngIf="noRolesSelected" class="member-filter__validation">
      {{ 'translations.members.filter.role.no' | transloco }}
    </p>
  </div>
  <ptl-member-filter-controls (cancel)="onCancel()" (save)="onSave()"></ptl-member-filter-controls>
</ng-container>
