/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { UserGroups } from './group/user-groups.model';
import {
  EditorContent,
  FormContentType,
  LanguageValue,
  OrganizationRoles,
  PresentationMode,
  ResourceTag,
  ResourceType,
  ReviewEvent
} from '..';
import { LearnerFormAnswerNote } from '../../../page-modules/resource/models';
import { PlaylistMemberReview, Review } from '../review/review.model';

export interface MembersSearchResponse {
  cardHeaders?: CardHeaders[];
  members: Members[];
  page: number;
  pageSize: number;
  total: number;
}

export interface ReviewsSearchResponse {
  reviews: PlaylistMemberReview[];
  page: number;
  pageSize: number;
  total: number;
}

export interface MemberGroup {
  title: string;
  groupId: string;
  addedOn: Date;
}

export interface CheckedMembersData {
  _id: string;
  reviewUid?: string;
}

export interface Members {
  _id?: string;
  uid?: string;
  imageUrl?: string;
  cards?: MemberCard[];
  createdAt: Date;
  email: string;
  alumniEmail?: string;
  externalId: string;
  lastActiveOn: Date;
  firstName: string;
  lastName: string;
  organizationId: string;
  password: string;
  roles: string[];
  groups: MemberGroup[];
  status: string;
  startedOn?: string;
  completedOn?: string;
  membershipStatus: 'ACTIVE' | 'ALUMNI';
  cardStatus?: 'IN_PROGRESS' | 'COMPLETED' | 'NOT_STARTED';
  playlistStatus?: 'IN_PROGRESS' | 'COMPLETED' | 'NOT_STARTED';
  event?: UserEventSummary;
  review?:Review;
  enrollment?:MemberEnrollment;

  // front-end specific
  checked?: boolean;
}

export interface MemberEnrollment {
  registeredOn?: Date;
  participatedOn?: Date;
  waitlistedOn?: Date;
}

export type MemberEnrollmentType = 'Registered' | 'Canceled'

export interface UserEventSummary {
  registeredOn?: Date;
  participatedOn?: Date;
  waitlistedOn?: Date;
  checkInMethod?: UserEventCheckInMethod;
}

export type UserEventCheckInMethod = 'MANUAL' | 'QR_CODE' | 'IMPORT';


export interface EventTicketAdminParticipateRequest {
  usersUid: string[];
  playlistUid: string;
  checkInMethod: UserEventCheckInMethod;
}

export interface EventTicketAdminRegisterRequest {
  usersUid: string[];
  playlistUid: string;
}

export interface UserSearch {
  email: string;
  externalId: string;
  firstName: string;
  image: string;
  lastName: string;
  uid: string;
  lastActiveOn?: Date;
}

export interface MemberInvitationRequest {
  emails: string[];
  groupsToAdd: string[];
  playlistsToSubscribe: string[];
}


export interface MemberInvitationSelectedGroupsPlaylists {
  title: string;
  id: string;
  type: 'GROUP' | 'PLAYLIST';
}

export interface MemberInvitationSubscribeFilteredResult {
  title: string;
  id: string;
}

export interface MembersInvitationBulkResponse {
  emailsNotSent: string[];
  emailsReSent: string[];
  emailsSent: string[];
}

export interface MemberInvitationResponse {
  _id: string;
  email: string;
  createdAt: string;
  status: {
    type: 'INVITED' | 'ACCEPTED';
    on: string;
  };
  sendCounter: number;

  // front-end specific
  resendingInProgress?: boolean;
  expanded?: boolean;
}

export interface ValidateEmailsFromCsvFileResponse {
  validUsers: UserSearch[];
  invalidEmails: string[];
}

export interface CardHeaders {
  cardId: string;
  header: string;
  type: ResourceType;
  childrenHeaders: CardHeaders[];
}

export interface FormHeaders {
  cardId: string;
  formId: string;
  header: string;
  type: FormContentType;
}

export interface MemberCard {
  cardId: string;
  cardType: ResourceType;
  completed: boolean;
  completedOn: Date;
  groupCards: MemberCard[];
  learnerCardId: string;
  required: string;
  startedOn: string;
  underReview: boolean;
  header?: string;
}

export interface MemberRole {
  type: string;
  _id: string;
  name: LanguageValue[];
}

export interface UserAddRemoveGroup {
  userGroupUid: string;
  type: UserAddRemoveType;
}


export interface UserAddRemoveRole {
  roleUid: string;
  type: UserAddRemoveType;
}

export type UserAddRemoveType = 'ADD' | 'REMOVE';

export type UserAddRemoveSectionType = 'GROUP' | 'ROLE';

export enum DistanceTravelMode {
  EARLIEST = 'EARLIEST', LATEST = 'LATEST',
}

export interface DistanceTravelInterval {
  startDate?: string;
  endDate?: string;
  mode?: DistanceTravelMode;
}

export interface DistanceTravelRequest {
  intervals?: DistanceTravelInterval[];
  includePercentage?: boolean;
}

export interface MembersSearchOrder {
  fieldName: string;
  direction: 'ASC' | 'DESC';
}

export interface MembersActiveFilter {
  filterType: string;
  content: MembersActiveFilterContent;
}

export interface MembersActiveFilterContent {
  _id?: string;
  statusFilter?: MembersActiveFilterStatus;
  title: string;
  type?: string;
}

export type MembersActiveFilterStatus = 'IN_PROGRESS' | 'COMPLETED' | 'NOT_STARTED' | 'ALL'

export interface MembersGroupsFilter extends UserGroups {
  checked?: boolean;
}

export interface MembersTagsFilter extends ResourceTag {
  checked?: boolean;
}

export interface MembersPlaylistsFilter {
  _id: string;
  type: string;
  checked?: boolean;
  title: string;
}

export interface MemberRecord {
  about: {
    email: string;
    firstName: string;
    lastName: string;
    joinedDate: Date;
    folio: string;
  };
  alumniEmail?: string;
  alumniStatus: MemberAlumniStatus;
  thumbnail: string;
  lastActiveOn: Date;
  momentsLogged: number;
  badgesEarned: number;
  playlistsCompleted: number;
  roles: MemberRole[];
  groups: {
    id: string;
    name: string;
  }[];
}

export interface MemberAlumniStatus {
  comment: MemberAlumniStatusComment;
  createdAt: Date;
  lastUpdatedOn: Date;
  type: MemberAlumniStatusType;
}

export interface MemberAlumniStatusComment {
  userEmail: string;
  type: 'FOLIO_ALREADY_CONNECTED';
}

export type MemberAlumniStatusType =
  'INITIALIZED'
  | 'VERIFICATION_EMAIL_SENT'
  | 'VERIFICATION_EMAIL_RE_SENT'
  | 'COMPLETED';

export interface MemberRecordLearnerPlaylist {
  _id: string;
  playlistId: string;
  title: string;
  image: string;
  organizationName: string;
  startedOn: Date;
  totalCardsCount: number;
  completedCardsCount: number;
  completed: boolean;
  completedOn: Date;
  uri: string;
  syndicated: boolean;
}

export interface MemberRecordLearnerPlaylistCardsResponse {
  _id: string;
  playlistId: string;
  title: string;
  card: MemberRecordPlaylistCardResponse[];
}

export interface MemberRecordPlaylistCardResponse {
  _id: string;
  userUid: string;
  cardUid: string;
  title: string;
  required: boolean;
  learnerFormAnswers: MemberRecordPlaylistCardLearnerAnswers[];
  startedOn: Date;
  completed: boolean;
  completedOn: Date;
  childCards: MemberRecordPlaylistCardResponse[];
  type: ResourceType;
  hasCardReviewEnabled: boolean;
  review: {
    reviewId: string;
    open: boolean;
    accepted: boolean;
  };
  uri: string;
}

export interface MemberRecordPlaylistCardLearnerAnswersResponse {
  formUid: string;
  answer: string;
  required: boolean;
  submitted: boolean;
  submittedOn: Date;
  startedOn: Date;
  accepted: boolean;
  type: FormContentType;
  instruction: string;
  quizId: string | null;
  quizSummary: {
    latestQuizResponsesUid: string;
    passed: boolean;
    quizUid: string;
    startedOn: Date;
  };
  collectorId: string | null;
  requiresResubmission: boolean | null;
  notes: LearnerFormAnswerNote[];
  reviewEvents: ReviewEvent[];
  wordLimit?: number;
  latestReminderTimestamp?: Date;
  presentationMode?: PresentationMode;
}

export interface MemberRecordPlaylistCardLearnerAnswers extends MemberRecordPlaylistCardLearnerAnswersResponse {
  note?: EditorContent[] | null;
}

export interface MemberRecordPlaylistsRequest {
  type: MemberRecordPlaylistsType;
  page: number;
  pageSize: number;
}

export interface ReviewNoteUpdateRequest {
  message: EditorContent[];
}

export type MemberRecordPlaylistsType = 'uncompleted' | 'completed';

export type FilterTypes = 'ROLES' | 'TAGS' | 'GROUPS' | 'STATUS' | 'CONTENTS' | 'SEARCH';

export type BooleanFilterType =
  'ROLE'
  | 'GROUP'
  | 'ACTIVITY'
  | 'GOAL'
  | 'RESOURCES'
  | 'CARD_STATUS'
  | 'PLAYLIST_STATUS'
  | 'FORM_STATUS'
  | 'REVIEW_STATUS'
  | 'REVIEW_SUBMISSION_DATE'
  | 'REVIEWERS'
  | 'FORMS'
  | 'ALL'
  | 'SUBMITTED'
  | 'NOT_SUBMITTED';

export interface AdminMembersRoles extends OrganizationRoles {
  checked?: boolean;
}

export class DateInterval {
  startDate: Date = new Date();
  endDate: Date = new Date();
}
