/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Location } from '@angular/common';
import { catchError, switchMap } from 'rxjs';
import { LanguageCodeHelper } from 'src/app/shared/helpers/language-code-helper';
import { Page } from 'src/app/shared/models/page';
import { FrameworkTrackerSectionTag, SectionReorder } from 'src/app/shared/models/page/page.model';
import { RestClientService } from 'src/app/shared/services/rest-client.service';
import { TranslationService } from 'src/app/shared/services/translation/translation.service';
import { ObservableResult } from 'src/app/shared/store';
import { environment } from 'src/environments/environment';
import { JobEntity } from '../components/view/shared/jobs-section-new/jobs.model';
import { Moment } from '../models';
import {
  AchievementCertificateBlock,
  FolioBadges,
  FolioCoverUpdateRequest,
  FolioModel,
  FolioMyActivity,
  FolioSection,
  FolioSectionItemRequest,
  FolioSectionRequest,
  FolioShowcaseRequest,
  PublicFolioBadge
} from '../store/folio.state.model';
import { FolioDataService } from './data.service';
import { FolioProject } from '../../project/models/project.model';


export class ApiFolioDataService implements FolioDataService {

  constructor(
    private client: RestClientService,
    private translationService: TranslationService,
  ) {}

  getUserFolio(languageCode?: string): ObservableResult<FolioModel> {
    return this.client.get<FolioModel>(
      Location.joinWithSlash(environment.apiRootUrl || '', 'folios'),
      null,
      languageCode ? { 'Accept-Language': languageCode } : null
    )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetFolio'))));
  }

  updateUserFolioShowcase(request: FolioShowcaseRequest): ObservableResult<void> {
    return this.client.post<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', 'folios/showcases'),
      request,
    )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdateFolioStatus'))));
  }

  updateUserFolioCoverImage(request: FolioCoverUpdateRequest): ObservableResult<{ uploadUrl: string }> {
    return this.client.post<{ uploadUrl: string }>(
      Location.joinWithSlash(environment.apiRootUrl || '', 'folios/cover/image'),
      request,
    )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdateFolioStatus'))));
  }

  deleteUserFolioCoverImage(): ObservableResult<void> {
    return this.client.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', 'folios/cover/image')
    )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdateFolioStatus'))));
  }

  reorderFolioSections(request: SectionReorder): ObservableResult<void> {
    return this.client.post<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', 'folios/reorder'),
      request,
    )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdateFolioSection'))));
  }

  updateFolioSection(sectionUid: string, request: FolioSectionRequest, languageCode?: string): ObservableResult<FolioSection> {
    return this.client.post<FolioSection>(
      Location.joinWithSlash(environment.apiRootUrl || '', `folios/sections/${sectionUid}`),
      request,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdateFolioSection'))));
  }

  enableFolioSectionItemVisibility(sectionUid: string, request: FolioSectionItemRequest): ObservableResult<void> {
    return this.client.patch<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `folios/visibilities/sections/${sectionUid}/${request.type}/${request.uid}`),
      request
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdateFolioSection'))));
  }

  disableFolioSectionItemVisibility(sectionUid: string, request: FolioSectionItemRequest): ObservableResult<void> {
    return this.client.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `folios/visibilities/sections/${sectionUid}/${request.type}/${request.uid}`),
      null
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdateFolioSection'))));
  }

  deleteCertificate(uid: string): ObservableResult<void> {
    return this.client.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `folios/achievements/certificates/${uid}`),
      null
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdateFolioSection'))));
  }

  addMomentToFolioSection(sectionUid: string, momentUid: string): ObservableResult<void> {
    return this.client.patch<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `folios/sections/${sectionUid}/moments/${momentUid}`),
      null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorAddMomentToFolio'))));
  }

  addTagToFolioSection(sectionUid: string, tagUid: string): ObservableResult<void> {
    return this.client.patch<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `folios/sections/${sectionUid}/tags/${tagUid}`),
      null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorAddGrowthToFolio'))));
  }

  removeMomentFromFolioSection(sectionUid: string, momentUid: string): ObservableResult<void> {
    return this.client.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `folios/sections/${sectionUid}/moments/${momentUid}`),
      null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorRemoveMomentFromFolio'))));
  }

  removeTagFromFolioSection(sectionUid: string, tagUid: string): ObservableResult<void> {
    return this.client.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `folios/sections/${sectionUid}/tags/${tagUid}`),
      null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorRemoveGrowthFromFolio'))));
  }

  getUserFolioProjects(folioPublicId: string, page: number, size: number = 15): ObservableResult<Page<FolioProject>> {
    return this.client.get<Page<FolioProject>>(
      Location.joinWithSlash(environment.apiRootUrl || '', `projects/folio/${folioPublicId}`),
      {
        page: page.toString(),
        size: size.toString(),
      }
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetProjects'))));
  }

  getFolioProjects(page: number, size: number = 4): ObservableResult<Page<FolioProject>> {
    return this.client.get<Page<FolioProject>>(
      Location.joinWithSlash(environment.apiRootUrl || '', 'projects/folio-sections'),
      {
        page: page.toString(),
        size: size.toString(),
      }
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetProjects'))));
  }

  getFolioMoments(sectionUid: string, page: number, size: number): ObservableResult<Page<Moment>> {
    return this.client.get<Page<Moment>>(
      Location.joinWithSlash(environment.apiRootUrl || '', `moments/folio-sections/${sectionUid}`),
      {
        page: page.toString(),
        size: size ? size.toString() : '10',
      }
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetMoments'))));
  }

  getFolioActivities(
    page: number,
    size: number,
    order: 'ASC' | 'DESC',
    dataRange: string,
    tags: string[] | null
  ): ObservableResult<Page<FolioMyActivity>> {
    let queryParams = `?page=${page}&size=${size}&order=${order}`;

    if (dataRange) {
      queryParams += ('&' + dataRange);
    }
    if (tags?.length > 0) {
      tags.forEach((tag) => {
        queryParams += ('&tags=' + tag)
      })
    }
    return this.client.get<Page<FolioMyActivity>>(
      Location.joinWithSlash(environment.apiRootUrl || '', 'activities' + queryParams),
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetFolioMyActivities'))));
  }

  getFolioSkillsActivities(
    page: number,
    size: number,
    tagId: string
  ): ObservableResult<Page<FolioMyActivity>> {

    const params = {
      page: page.toString(),
      size: size ? size.toString() : '10',
    };
    return this.client.get<Page<FolioMyActivity>>(
      Location.joinWithSlash(environment.apiRootUrl || '', `activities/skills/${tagId}`),
      params
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetFolioMyActivities'))));
  }

  getFolioGrowths(sectionUid: string, page: number, size: number): ObservableResult<FrameworkTrackerSectionTag[]> {
    return this.client.get<FrameworkTrackerSectionTag[]>(
      Location.joinWithSlash(environment.apiRootUrl || '', `tags/folio-sections/${sectionUid}`),
      {
        page: page.toString(),
        size: size ? size.toString() : '10',
      }
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetFolioGrowths')))
    );
  }

  getFolioBadges(sectionUid: string, page: number, size: number): ObservableResult<FolioBadges[]> {
    return this.client.get<FolioBadges[]>(
      Location.joinWithSlash(environment.apiRootUrl || '', `badges/folio-sections/${sectionUid}`),
      {
        page: page.toString(),
        size: size ? size.toString() : '10',
      }
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetBadges')))
    );
  }

  /**
   * Getting public folio by publicId.
   */
  getPublicUserFolio(publicId: string, languageCode?: string): ObservableResult<FolioModel> {
    return this.client.get<FolioModel>(
      Location.joinWithSlash(environment.apiRootUrl || '', `folios/public/${publicId}`),
      null,
      languageCode ? { 'Accept-Language': languageCode } : null
    )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError((err) => {
          let errorMessage = this.translationService.getTranslation('errors.errorGetFolio');
          if (err?.status === 404) {
            errorMessage = this.translationService.getTranslation('errors.errorPublicFolioNotExists');
          } else if (err?.status === 403) {
            errorMessage = this.translationService.getTranslation('errors.errorPublicFolioHidden');
          }
          return ObservableResult.ofError(errorMessage);
        }));
  }

  getPublicFolioGrowths(publicId: string, sectionUid: string, page: number, size: number): ObservableResult<FrameworkTrackerSectionTag[]> {
    return this.client.get<FrameworkTrackerSectionTag[]>(
      Location.joinWithSlash(environment.apiRootUrl || '', `tags/public/folios/${publicId}/folio-sections/${sectionUid}`),
      {
        page: page.toString(),
        size: size ? size.toString() : '10',
      }
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetFolioGrowths')))
    );
  }

  getPublicFolioMoments(publicId: string, sectionUid: string, page: number, size: number): ObservableResult<Page<Moment>> {
    return this.client.get<Page<Moment>>(
      Location.joinWithSlash(environment.apiRootUrl || '', `moments/public/folios/${publicId}/folio-sections/${sectionUid}`),
      {
        page: page.toString(),
        size: size ? size.toString() : '10',
      }
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetMoments'))));
  }

  getPublicFolioProjects(
    publicId: string,
    page: number,
    size: number
  ): ObservableResult<Page<FolioProject>> {
    return this.client.get<Page<FolioProject>>(
      Location.joinWithSlash(environment.apiRootUrl || '', `projects/public/folios/${publicId}/folio-sections`),
      {
        page: page.toString(),
        size: size ? size.toString() : '4',
      }
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetProjects'))));
  }

  getPublicFolioBadges(publicId: string, sectionUid: string, page: number, size: number): ObservableResult<FolioBadges[]> {
    return this.client.get<FolioBadges[]>(
      Location.joinWithSlash(environment.apiRootUrl || '', `badges/public/folios/${publicId}/folio-sections/${sectionUid}`),
      {
        page: page.toString(),
        size: size ? size.toString() : '10',
      }
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetBadges')))
    );
  }

  getPublicFolioCertificates(publicId: string, sectionUid: string, page: number, size: number):
    ObservableResult<Page<AchievementCertificateBlock>> {
    return this.client.get<Page<AchievementCertificateBlock>>(
      Location.joinWithSlash(environment.apiRootUrl || '', `certificates/public/folios/${publicId}/folio-sections/${sectionUid}`),
      {
        page: page.toString(),
        size: size ? size.toString() : '10',
      }
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetBadges')))
    );
  }

  getFolioUserPublicBadge(userBadgeId: string): ObservableResult<PublicFolioBadge> {
    return this.client.get<PublicFolioBadge>(
      Location.joinWithSlash(environment.apiRootUrl || '', `badges/issued/${userBadgeId}`),
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetBadge')))
    );
  }

  getFolioJobs(page: number, languageCode?: string): ObservableResult<JobEntity[]> {
    return this.client.get<JobEntity[]>(
      Location.joinWithSlash(environment.apiRootUrl || '', '/jobs/retrieve'),
      {
        page: page.toString(),
      },
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetBadges')))
    );
  }
}
