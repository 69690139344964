/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

/* eslint-disable max-len */
import { delay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { PlaylistDataService } from './data.service';
import {
  AssociateCategoryRequest,
  AssociateTagRequest,
  PlaylistCardLayoutUpdate,
  PlaylistCardOrderingUpdate,
  PlaylistCreationRequest,
  PlaylistPublicationStuffUpdateRequest,
  PlaylistThumbnailUpdateRequest,
  PlaylistUpdateRequest,
  PlaylistWebLinkCreationRequest
} from '../../../models';
import { PlaylistResourceModel } from '../../../models/playlist-resource.model';
import { ObservableResult } from '../../../../../shared/store';
import {
  Diagnostics, EnrollmentSettingsRequest, EventTicketsDetails,
  Playlist,
  PlaylistAssociateAdmin, PlaylistStandardSection,
  Resource,
  ResourceCardType,
  ResourcePermission
} from '../../../../../shared/models';
import { ItemWithUploadUrl } from '../../../../../shared/models/ItemWithUploadUrl';
import { Page } from '../../../../../shared/models/page';
import { LearnerPlaylistSummary } from '../../../../../shared/models/playlist/learner-playlist-summary.model';
import { createDummyLearnerPlaylist, createDummyPlaylist, createDummyResource } from '../../../../mock-utils';
import { ContentStorePlaylistSummary } from '../../../../../shared/models/content-store/content-store.model';
import {
  PlaylistsStatsSummary,
  PlaylistStatistics,
  StatsData
} from '../../../../../shared/models/analytics/analytics.model';
import { CreatePlaylistResponse } from './models/create-playlist-response';
import { UpdatePlaylistResponse } from './models/update-playlist-response';
import { EventTicketsRequest } from '@app/app/page-modules/resource/store/admin/resource-event-admin.state.model';

@Injectable()
export class MockPlaylistDataService implements PlaylistDataService {

  private dummyExistingPlaylist: Playlist = createDummyPlaylist('1234');

  private dummyExistingResource = createDummyResource('2435');

  private archivedPlaylistsData: Page<LearnerPlaylistSummary> = {
    content: [createDummyLearnerPlaylist('1')],
    totalNumberOfElement: 1,
    pageSize: 10,
    currentPageNumber: 1,
    numberOfPages: 1,
  };


  createPlaylist(request: PlaylistCreationRequest): ObservableResult<CreatePlaylistResponse> {
    return ObservableResult.ofSuccess({
      item: {
        uri: 'string',
        formattedUri: 'string',
        mainSection: {
          imageUrl: null,
        },
      },
      uploadUrl: null,
    });
  }

  updatePlaylist(playlistUid: string, request: PlaylistUpdateRequest): ObservableResult<UpdatePlaylistResponse> {
    return ObservableResult.ofSuccess({
      uri: 'string',
      mainSection: undefined,
      standardSections: [],
      formattedUri: 'string',
      coverImageUploadUrl: 'string',
      uploadUrls: [],
    });
  }

  getPlaylistDetails(playlistUri: string, publisherUri: string, packageUri: string, pageUri: string, languageCode?: string):
    ObservableResult<Playlist> {
    return ObservableResult.ofSuccess(this.dummyExistingPlaylist).pipe(
      delay(1500));
  }

  deletePlaylist(playlistUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  getPlaylistSummaryByUid(playlistUid: string, languageCode?: string): ObservableResult<ContentStorePlaylistSummary> {
    return ObservableResult.ofSuccess();
  }

  clonePlaylist(playlistUri: string): ObservableResult<string> {
    return ObservableResult.ofSuccess('playlistUid').pipe(
      delay(1500));
  }

  associateTag(playlistUid: string, request: AssociateTagRequest): ObservableResult<PlaylistResourceModel> {
    return undefined;
  }

  dissociateTag(playlistUid: string, tagUid: string): ObservableResult<void> {
    return undefined;
  }

  associateFeedTag(playlistUid: string, request: AssociateTagRequest): ObservableResult<PlaylistResourceModel> {
    return undefined;
  }

  dissociateFeedTag(playlistUid: string, tagUid: string): ObservableResult<void> {
    return undefined;
  }

  dissociateAllFeedTags(playlistUid: string): ObservableResult<void> {
    return undefined;
  }

  associateCategory(playlistUid: string, request: AssociateCategoryRequest): ObservableResult<PlaylistResourceModel> {
    return ObservableResult.ofSuccess();
  }
  dissociateCategory(playlistUid: string, categoryTagUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  associateAdmin(playlistUid: string, userUid: string): ObservableResult<PlaylistAssociateAdmin[]> {
    return ObservableResult.ofSuccess([]);
  }

  dissociateAdmin(playlistUid: string, userUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  enablePlaylistCollaboration(playlistUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  disablePlaylistCollaboration(playlistUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  enablePlaylistMemberCollaboration(playlistUid: string, userUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  disablePlaylistMemberCollaboration(playlistUid: string, userUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  changeAdminPermissions(playlistUid: string, userUid: string, permissions: ResourcePermission): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  getPlaylistStatistics(playlistUid: string, from: string, to: string, daysDiff: number): ObservableResult<PlaylistStatistics> {
    return ObservableResult.ofSuccess();
  }

  getPlaylistStartedStatistics(playlistUid: string, from: string, to: string): ObservableResult<StatsData[]> {
    return ObservableResult.ofSuccess([]);
  }

  getPlaylistCompletedStatistics(playlistUid: string, from: string, to: string): ObservableResult<StatsData[]> {
    return ObservableResult.ofSuccess([]);
  }

  getPlaylistStatisticsSummary(playlistUid: string): ObservableResult<PlaylistsStatsSummary> {
    return ObservableResult.ofSuccess({
      started: 10,
      completed: 5,
    });
  }

  updatePlaylistUri(
    playlistUid: string,
    data: PlaylistPublicationStuffUpdateRequest
  ): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  changeCardsLayout(
    playlistUid: string,
    sectionUid: string,
    request: PlaylistCardLayoutUpdate
  ): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  addCardToPlaylist(
    playlistUid: string,
    sectionUid: string,
    cardUid: string,
    clone?: boolean
  ): ObservableResult<string> {
    return ObservableResult.ofSuccess();
  }

  addWebLinkToPlaylistSection(
    playlistUid: string,
    sectionUid: string,
    requestBody: PlaylistWebLinkCreationRequest
  ): ObservableResult<Resource> {
    return ObservableResult.ofSuccess();
  }

  updatePlaylistSectionCards(
    playlistUid: string,
    sectionUid: string,
    request: PlaylistCardOrderingUpdate
  ) {
    return ObservableResult.ofSuccess();
  }

  movePlaylistCardFromSections(playlistUid: string, fromSectionUid: string, toSectionUid, cardUid: string) {
    return ObservableResult.ofSuccess();
  }

  removeCardFromSection(
    playlistUid: string,
    sectionUid: string,
    uid: string
  ) {
    return ObservableResult.ofSuccess();
  }

  createEmptyCard(playlistUid: string, sectionUid: string) {
    return ObservableResult.ofSuccess(this.dummyExistingResource);
  }

  createEmptyAssessment(playlistUid: string, sectionUid: string) {
    return ObservableResult.ofSuccess(this.dummyExistingResource as Diagnostics);
  }

  createEmptyEventCard(playlistUid: string, sectionUid: string) {
    return ObservableResult.ofSuccess(this.dummyExistingResource);
  }

  createEmptyResourceInGroup(playlistUid: string, sectionUid: string, groupUid: string, type: ResourceCardType)
    : ObservableResult<Resource | Diagnostics> {
    return ObservableResult.ofSuccess(this.dummyExistingResource);
  }

  createWeblinkInGroupCard(playlistUid: string, sectionUid: string, groupUid: string, requestBody: PlaylistWebLinkCreationRequest):
    ObservableResult<Resource> {
    return ObservableResult.ofSuccess(this.dummyExistingResource);
  }

  createPlaylistSection(
    playlistUid: string
  ) {
    return ObservableResult.ofSuccess({
      uid: 'string',
      title: 'string',
      content: [],
      cards: [],
      cardsLayoutType: 'CardView'
    } as PlaylistStandardSection);
  }

  deletePlaylistSection(
    playlistUid: string,
    sectionUid: string,
    cards: boolean
  ) {
    return ObservableResult.ofSuccess();
  }

  addPlaylistToPlaylist(playlistUid: string, sectionUid: string, cardUid: string, clone?: boolean): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  addPlaylistToPlaylistMainSection(playlistUid: string, cardUid: string) {
    return ObservableResult.ofSuccess();
  }

  reorderPlaylistSections(playlistUid: string, request: { sections: string[] }): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  addUserGroupToPlaylistTarget(playlistUid: string, userGroupUid: string) {
    return ObservableResult.ofSuccess();
  }

  updateUserGroupToPlaylistTargetForceStatus(playlistUid: string, userGroupUid: string, force: boolean) {
    return ObservableResult.ofSuccess();
  }

  removeUserGroupFromPlaylistTarget(playlistUid: string, userGroupUid: string) {
    return ObservableResult.ofSuccess();
  }

  removeAllUserGroupsFromPlaylistTarget(playlistUid: string) {
    return ObservableResult.ofSuccess();
  }

  addUserToPlaylistTarget(playlistUid: string, userUid: string) {
    return ObservableResult.ofSuccess();
  }

  removeUserFromPlaylistTarget(playlistUid: string, userUid: string) {
    return ObservableResult.ofSuccess();
  }

  removeAllUsersFromPlaylistTarget(playlistUid: string) {
    return ObservableResult.ofSuccess();
  }

  addSectorToPlaylistTarget(playlistId: string, sectorCode: string) {
    return ObservableResult.ofSuccess();
  };

  removeSectorFromPlaylistTarget(playlistId: string, sectorCode: string) {
    return ObservableResult.ofSuccess();
  };

  removeAllSectorsFromPlaylistTarget(playlistId: string) {
    return ObservableResult.ofSuccess();
  }

  changePlaylistThumbnail(
    playlistUid: string,
    request: PlaylistThumbnailUpdateRequest
  ): ObservableResult<ItemWithUploadUrl<PlaylistResourceModel>> {
    return ObservableResult.ofSuccess();
  }

  removePlaylistThumbnail(playlistUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  setPlaylistTimeRequired(playlistUid: string, timeRequired: number): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  getPlaylistTimeRequiredSuggestion(playlistUid: string, languageCode?: string): ObservableResult<number> {
    return ObservableResult.ofSuccess(0);
  }

  getArchivedPlaylists(page: number, size: number, term?: string): ObservableResult<Page<LearnerPlaylistSummary>> {
    return ObservableResult.ofSuccess(this.archivedPlaylistsData);
  }

  archivePlaylist(playlistUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  addLanguage(playlistUid: string, languageCode: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  removeLanguage(playlistUid: string, languageCode: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  enableLanguage(playlistUid: string, languageCode: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  disableLanguage(playlistUid: string, languageCode: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  addDataForm(pageUid: string, dataFormUid: string, event: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  removeDataForm(pageUid: string, dataFormUid: string, event: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  updateEnrollmentSettings(playlistUid: string, request: EnrollmentSettingsRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  updateTickets(playlistUid: string, request: EventTicketsRequest): ObservableResult<EventTicketsDetails> {
    return ObservableResult.ofSuccess();
  }

  deleteEnrollmentSettings(playlistUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  register(playlistUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  unregister(playlistUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  registerUserToPlaylist(playlistUid: string, userUid: string): ObservableResult<Resource> {
    return ObservableResult.ofSuccess();
  }

  unregisterUserToPlaylist(playlistUid: string, userUid: string): ObservableResult<Resource> {
    return ObservableResult.ofSuccess();
  }
}
