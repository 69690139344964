/*
 * Copyright (C) 2018 - present by Potentially
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Inject, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  CardHeaders, FormHeaders,
  Members,
  MembersSearchOrder,
  MembersSearchResponse,
  UserAddRemoveGroup,
  UserAddRemoveRole
} from '../../../../shared/models/admin/members.model';
import { MEMBERS_DATA_SERVICE, MembersDataService } from '../../services/members/members-data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminMemberButtonsComponent } from './admin-member-buttons/admin-member-buttons.component';
import { AdminMemberListComponent } from './admin-member-list/admin-member-list.component';
import { PageTitleService } from '../../../../shared/services/page-title/page-title.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ROLES_DATA_SERVICE, RolesDataService } from '../../services/roles/roles-data.service';
import { ObservableResult } from '../../../../shared/store';
import { SnackbarHelper } from '../../../../shared/helpers/snackbar-helper';
import { TranslationService } from '../../../../shared/services/translation/translation.service';
import { Select, Store } from '@ngxs/store';
import { UserAuthState } from '../../../../user-auth/store/user-auth.state';
import { Observable } from 'rxjs';
import { Organization } from '../../../../shared/models';
import {
  AndRequest,
  BooleanQueryType
} from '../../../../shared/models/admin/boolean-filters.model';
import { RedirectHelper } from '../../../resource/store/editor/content/helpers/redirect.helper';
import { UpdateSelectedMembers } from './store/members.action';

@Component({
  selector: 'ptl-admin-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss'],
})
export class AdminMembersComponent implements OnInit, OnDestroy {

  @Input() isPlaylistPage: boolean;
  @Input() playlistUid: string;
  @Input() resourceUri: string;
  @Input() isCardPage: boolean;
  @Input() isAssessment: boolean;
  @Input() cardUid: string;
  @Input() formHeaders: FormHeaders[];
  @Input() exportEnabled: boolean;

  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  @ViewChild('membersButtons') membersButtons: AdminMemberButtonsComponent;
  @ViewChild('membersList') membersList: AdminMemberListComponent;

  members: Members[];
  membersTotalCount: number;
  membersAllCount = 0;
  membersListLoaded: boolean;
  skeletonViewActive = true;
  showNotPermittedMessage = false;
  private showLoaderTimeout;
  private checkedMemberIds: string[] = [];

  cardHeaders: CardHeaders[];
  queryRequest: AndRequest;
  order: MembersSearchOrder;

  private page: number;
  private pageSize: number;
  private subscriptionEnd$ = new EventEmitter<void>();

  constructor(
    private snackBar: MatSnackBar,
    private ngZone: NgZone,
    private router: Router,
    private titleService: PageTitleService,
    private activatedRoute: ActivatedRoute,
    private translationService: TranslationService,
    private store: Store,
    @Inject(ROLES_DATA_SERVICE) private rolesDataService: RolesDataService,
    @Inject(MEMBERS_DATA_SERVICE) private membersDataService: MembersDataService
  ) {

  }

  ngOnInit() {

    this.showNotPermittedMessage = false;
    this.skeletonViewActive = true;
    this.titleService.setTitle('Members');

    this.page = 0;
    this.pageSize = 10;
    this.order = {
      fieldName: 'lastActiveOn',
      direction: 'DESC',
    };
    this.queryRequest = { type: BooleanQueryType.AND, args: [] };
    this.changeListOrderingByBooleanFilter({ order: this.order, page: this.page, pageSize: this.pageSize }, true);
  }

  ngOnDestroy() {
    this.subscriptionEnd$?.emit();
  }

  onGroupAddRemove(data: UserAddRemoveGroup) {
    if (!data.userGroupUid) {
      return SnackbarHelper.showTranslatableSnackBar(
        this.ngZone, this.snackBar, this.translationService, 'members.message.error.chooseGroup'
      );
    }

    if (!this.checkedMemberIds.length) {
      return SnackbarHelper.showTranslatableSnackBar(
        this.ngZone, this.snackBar, this.translationService, 'members.message.error.chooseOneMember'
      );
    }

    if (data.type === 'ADD') {
      this.addMembersToGroup(data.userGroupUid, this.checkedMemberIds);
    } else if (data.type === 'REMOVE') {
      this.removeMembersFromGroup(data.userGroupUid, this.checkedMemberIds);
    }
  }

  onRoleAddRemove(data: UserAddRemoveRole) {
    if (!data.roleUid) {
      return SnackbarHelper.showTranslatableSnackBar(
        this.ngZone, this.snackBar, this.translationService, 'members.message.error.chooseRole'
      );
    }

    if (!this.checkedMemberIds.length) {
      return SnackbarHelper.showTranslatableSnackBar(
        this.ngZone, this.snackBar, this.translationService, 'members.message.error.chooseOneMember'
      );
    }

    if (data.type === 'ADD') {
      this.addMembersToRole(data.roleUid, this.checkedMemberIds);
    } else if (data.type === 'REMOVE') {
      this.removeMembersFromRole(data.roleUid, this.checkedMemberIds);
    }
  }

  onMembersChecked(userIds: string[]) {
    this.checkedMemberIds = userIds;
  }

  openUserRecord(userUid: string) {
    const url = this.router.createUrlTree([userUid, 'user-record'], { relativeTo: this.activatedRoute });
    window.open(url.toString(), '_blank');
  }

  private preselectCheckedMembers(members) {
    for (const member of members) {
      if (this.checkedMemberIds.indexOf(member._id) !== -1) {
        member.checked = true;
      }
    }
    return members;
  }


  private addMembersToRole(roleUid: string, memberIds: string[]) {
    this.rolesDataService.assignRoleToMultipleUsers(roleUid, memberIds).subscribe(({ isSuccess }) => {
      if (isSuccess) {
        SnackbarHelper.showTranslatableSnackBar(
          this.ngZone, this.snackBar, this.translationService, 'members.message.success.roleAddedToMember'
        );
        this.clearCheckedMemberIds();
        this.updateListByBooleanFilter(this.queryRequest);
        this.membersButtons.hideSection();
      }
    });
  }

  private removeMembersFromRole(roleUid: string, memberIds: string[]) {
    this.rolesDataService.removeRoleFromMultipleUsers(roleUid, memberIds).subscribe(({ isSuccess }) => {
      if (isSuccess) {
        SnackbarHelper.showTranslatableSnackBar(
          this.ngZone, this.snackBar, this.translationService, 'members.message.success.roleRemovedFromMember'
        );
        this.clearCheckedMemberIds();
        this.updateListByBooleanFilter(this.queryRequest);
        this.membersButtons.hideSection();
      }
    });
  }


  private addMembersToGroup(userGroupUid: string, memberIds: string[]) {
    this.membersDataService.addMembersToGroup(userGroupUid, memberIds).subscribe(({ isSuccess }) => {
      if (isSuccess) {
        SnackbarHelper.showTranslatableSnackBar(
          this.ngZone, this.snackBar, this.translationService, 'members.message.success.memberAddedToGroup'
        );
        this.clearCheckedMemberIds();
        this.updateListByBooleanFilter(this.queryRequest);
        this.membersButtons.hideSection();
      }
    });
  }

  private removeMembersFromGroup(userGroupUid: string, memberIds: string[]) {
    this.membersDataService.removeMembersFromGroup(userGroupUid, memberIds).subscribe(({ isSuccess }) => {
      if (isSuccess) {
        SnackbarHelper.showTranslatableSnackBar(
          this.ngZone, this.snackBar, this.translationService, 'members.message.success.memberRemovedFromGroup'
        );
        this.clearCheckedMemberIds();
        this.updateListByBooleanFilter(this.queryRequest);
        this.membersButtons.hideSection();
      }
    });
  }

  onOpenMessage() {
    this.store.dispatch(new UpdateSelectedMembers(this.checkedMemberIds));
    if (this.isPlaylistPage) {
      const playlistUri = this.activatedRoute.snapshot.paramMap.get('playlistUri');
      RedirectHelper.redirectByParams(this.ngZone, this.router, this.activatedRoute,
        {
          playlistUri: playlistUri,
          extraUriParam: 'message'
        },
        'PLAYLIST',
      );
    } else if (this.isCardPage) {
      const resourceUri = this.activatedRoute.snapshot.paramMap.get('resourceUri');
      const groupUri = this.activatedRoute.snapshot.paramMap.get('groupUri');
      const playlistUri = this.activatedRoute.snapshot.paramMap.get('playlistUri');
      RedirectHelper.redirectByParams(this.ngZone, this.router, this.activatedRoute,
        {
          resourceUri: resourceUri,
          groupUri: groupUri,
          playlistUri: playlistUri,
          pageNumberUri: 'page/1',
          extraUriParam: 'message'
        },
        'RESOURCE',
      );
    } else {
      RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, '/admin/message');
    }
  }

  changeListOrderingByBooleanFilter(
    update: { order: MembersSearchOrder; page: number; pageSize: number },
    allMembers = false
  ) {
    this.page = update.page;
    this.pageSize = update.pageSize;
    this.order = update.order;
    this.updateListByBooleanFilter(this.queryRequest, allMembers);
  }

  onFilterChanged(queryRequest: AndRequest): void {
    this.page = 0;
    if (this.membersList) {
      this.membersList.currentPage = 0;
    }
    this.updateListByBooleanFilter(queryRequest);
  }

  updateListByBooleanFilter(queryRequest: AndRequest, allMembers = false) {
    this.queryRequest = queryRequest;
    const data = {
      query: this.queryRequest,
      order: this.order
    };

    if (this.membersList) {
      this.showLoaderTimeout = setTimeout(() => {
        this.membersList.isLoaderActive = true;
      }, 100);
    }

    let membersObservable: ObservableResult<MembersSearchResponse>;

    if (this.isCardPage) {
      membersObservable = this.membersDataService.searchCardMembers(data, this.playlistUid, this.cardUid, this.page, this.pageSize);
    } else if (this.isPlaylistPage) {
      membersObservable = this.membersDataService.searchPlaylistMembers(data, this.playlistUid, this.page, this.pageSize);
    } else {
      membersObservable = this.membersDataService.searchMembers(data, this.page, this.pageSize);
    }

    membersObservable.subscribe(({ isSuccess, value, error }) => {
      this.skeletonViewActive = false;
      if (this.membersList) {
        clearTimeout(this.showLoaderTimeout);
        setTimeout(() => {
          this.membersList.isLoaderActive = false;
        }, 100);
      }
      if (isSuccess) {
        if (this.isPlaylistPage) {
          value.members = this.setCardTitleForEachMember(value.members, value.cardHeaders);
          this.cardHeaders = value.cardHeaders;
        }
        this.members = this.preselectCheckedMembers(value.members);
        if (allMembers) {
          this.membersAllCount = value.total;
        }
        this.membersTotalCount = value.total;
        this.membersListLoaded = true;
      } else {
        this.showErrorView(error);
      }
    });
  }

  private clearCheckedMemberIds() {
    this.membersList.allUsersChecked = false;
    this.membersList.checkedUserIds = [];
    this.checkedMemberIds = [];
    for (const member of this.members) {
      member.checked = false;
    }

  }

  private setCardTitleForEachMember(members: Members[], cardHeaders: CardHeaders[]) {
    for (let i = 0; i < cardHeaders.length; i++) {
      for (const member of members) {
        if (member.cards && member.cards.length) {
          member.cards[i].header = cardHeaders[i].header;
          if (member.cards[i].groupCards && member.cards[i].groupCards.length) {
            for (let j = 0; j < member.cards[i].groupCards.length; j++) {
              if (cardHeaders[i].childrenHeaders && cardHeaders[i].childrenHeaders[j].header) {
                member.cards[i].groupCards[j].header = cardHeaders[i].childrenHeaders[j].header;
              }
            }
          }
        }
      }
    }
    return members;
  }

  private showErrorView(error: string) {
    if (error === '403') {
      this.showNotPermittedMessage = true;
    }
  }

}
