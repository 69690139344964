<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div
  class="form-preview"
  tabindex="0"
  role="group"
  [attr.aria-label]="('translations.global.ariaLabels.quizFormBoxTitle' | transloco : {
    title: !title ? ('translations.quiz.title.quiz' | transloco) : title,
    state: quizButtonStateMessage
  })"
  [class.form-preview--saved]="quizTaken">
  <div class="form-preview__top">
    <div class="form-preview__top-title" *ngIf="summary">
      <div class="title medium bold">{{ !title ? ('translations.quiz.title.quiz' | transloco) : title }}</div>
      <div class="form-preview__top-limits extra-small">
        {{ 'translations.answerQuestionsToPass' | transloco }}
      </div>
    </div>
    <div class="form-preview__top-icon-box">
      <div *ngIf="quizTaken" class="form-preview__top-icon non-editable" [matTooltip]="canNotEditReason">
        <mat-icon *ngIf="canEdit" aria-hidden="true">{{ quizTaken ? 'check' : 'linear_scale' }}</mat-icon>
        <svg *ngIf="!canEdit" class="svg" role="img" [attr.aria-label]="('translations.global.ariaLabels.icons.lock' | transloco)">
          <use ogSvgUnify="assets/sprite/sprite.svg#lock"></use>
        </svg>
      </div>
      <div *ngIf="!quizTaken && !canEdit" class="form-preview__top-icon non-saved non-editable" [matTooltip]="canNotEditReason">
        <svg class="svg" role="img" [attr.aria-label]="('translations.global.ariaLabels.icons.lock' | transloco)">
          <use ogSvgUnify="assets/sprite/sprite.svg#lock"></use>
        </svg>
      </div>
    </div>
  </div>

  <div class="form-preview__input">
    <div class="medium" role="status" *ngIf="!quizTaken">
      {{ 'translations.quizYouAnsweredQuestions' | transloco: {diff: summary?.answeredQuestions + '/' + summary?.totalQuestions} }}
    </div>
    <div *ngIf="quizTaken && summary" role="status" class="medium"
         [attr.aria-label]="('translations.global.ariaLabels.youAnswered' | transloco: {answeredQuestions: summary?.answeredQuestions}) + ' ' + ('translations.global.ariaLabels.outOf' | transloco: {totalQuestions: summary?.totalQuestions}) + ' ' + ('translations.global.ariaLabels.questions' | transloco)">
      {{ 'translations.quizYouAnsweredQuestions' | transloco: {diff: summary?.answeredQuestions + '/' + summary?.totalQuestions} }}
    </div>
  </div>

  <div class="form-preview-notes" *ngIf="content?.content?.notes?.length">
    <div class="note-item" *ngFor="let note of content?.content?.notes" tabindex="0">
      <div class="note-item-inner">
        <div class="note-message small">
          <ptl-resource-preview class="thin-preview" [previewContent]="note.message">
          </ptl-resource-preview>
        </div>
      </div>
      <div class="note-added-by extra-small italic">
        {{note.addedBy}}, {{ getLocalDateTime(note.addedOn) | date: 'dd MMM yyyy HH:mm' }}
      </div>
    </div>
  </div>

  <div class="form-preview__footer">

    <div class="form-preview__footer-buttons">

      <ng-container *ngIf="!quizTaken && canEdit">
        <button
          *ngIf="!resume"
          class="quiz-button uppercase"
          color="accent"
          mat-stroked-button
          [attr.aria-label]="('translations.global.ariaLabels.startTakingQuiz' | transloco)"
          (click)="showDialog()">
          {{ 'translations.start' | transloco }}
        </button>
        <button
          *ngIf="resume"
          class="quiz-button"
          color="accent"
          mat-stroked-button
          [attr.aria-label]="('translations.global.ariaLabels.continueTakingQuiz' | transloco)"
          (click)="showDialog()">
          {{ 'translations.resume' | transloco }}
        </button>
      </ng-container>

      <ng-container *ngIf="quizTaken">
        <button
          *ngIf="canRetake && !resume"
          class="quiz-button uppercase"
          color="accent"
          mat-stroked-button
          [attr.aria-label]="('translations.global.ariaLabels.retakeQuiz' | transloco)"
          (click)="retake()">
          {{ 'translations.retake' | transloco }}
        </button>
        <button
          *ngIf="quizTaken"
          color="accent"
          class="quiz-button uppercase"
          mat-stroked-button
          [attr.aria-label]="('translations.global.ariaLabels.viewQuizResult' | transloco)"
          (click)="showDialog()">
          {{ 'translations.viewResults' | transloco }}
        </button>
      </ng-container>
    </div>
  </div>
</div>
<ptl-form-update-footer
  [submitted]="quizTaken"
  [canEdit]="canEdit"
  [learnerFormAnswer]="learnerFormAnswer"
  [formType]="content?.content?.type"
  [playlistSubmissionDeadline]="userPlaylistSubmissionSummary?.submissionDeadline">
</ptl-form-update-footer>
