/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, NgZone } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Organization, Playlist } from '../../../../../shared/models';
import { PublishPage } from '../../../../pages/store/pages.actions';
import { SnackbarHelper } from '../../../../../shared/helpers/snackbar-helper';
import { Select, Store } from '@ngxs/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslationService } from '../../../../../shared/services/translation/translation.service';
import { UserAuthState } from '../../../../../user-auth/store/user-auth.state';
import { Observable } from 'rxjs';
import { DialogService } from '../../../../../shared/helpers/dialog/dialog.service';

@Component({
  templateUrl: './publish-dialog.component.html',
  styleUrls: ['./publish-dialog.component.scss'],
})

export class PagePublishDialogComponent {

  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  updateInProgress: boolean;
  activePage = 0;
  selectedPlaylist: Playlist;

  constructor(
    private store: Store,
    private ngZone: NgZone,
    private snackBar: MatSnackBar,
    private translationService: TranslationService,
    private dialogService: DialogService,
    private dialogRef: MatDialogRef<PagePublishDialogComponent>,
  ) {
  }

  onCloseDialog() {
    this.updateInProgress = false;
    this.dialogRef.close();
  }

  onPublishClick() {
    this.dialogService.showConfirmDialog(
      this.translationService.getTranslation('dialog.title.confirmPublishPage'),
      this.translationService
    ).then(confirmed => {
      if (confirmed) {
        this.updateInProgress = true;
        this.store.dispatch(new PublishPage()).toPromise().then(() => {
          SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'pages.message.success.publish');
          this.updateInProgress = false;
          this.onCloseDialog();
        }).catch(() => {
          this.updateInProgress = false;
        });
      }
    });
  }


  setPage(page: number) {
    this.activePage = page;
  }
}
