/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { UserAuthState } from '../../../user-auth/store/user-auth.state';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { Organization } from '../../models';
import { CookieHelper } from '../../helpers/cookie-helper';
import { REDIRECT_AFTER_LOGIN } from '../../constants/constants';
import { UnauthenticateUser } from '../../../user-auth/store/user-auth.actions';
import {
  NewMomentDialogComponent
} from '../../../page-modules/folio/shared-moment-module/components/new-moment-dialog/new-moment-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SHARED_UPCOMING_DATA_SERVICE, SharedUpcomingDataService } from '../../services/upcoming/upcoming-data.service';
import { take, takeUntil } from 'rxjs/operators';
import { UserRole } from '../../../user-auth/models';
import { CloseMobileNavMenu } from '../../../app-frame/store/app-frame.actions';
import { REVIEWS_DATA_SERVICE, ReviewsDataService } from '../../../page-modules/reviews/services/data.service';
import { HeaderCountsState } from '../../../app-frame/store/header-counts.state';
import { ActivatedRoute, Router } from '@angular/router';
import { RedirectHelper } from '../../../page-modules/resource/store/editor/content/helpers/redirect.helper';
import { AccessTokenService } from '../../../user-auth/services/access-token.service';
import { LanguageCodeHelper } from '../../helpers/language-code-helper';


@Component({
  selector: 'ptl-mobile-menu-side-nav',
  templateUrl: './mobile-menu-side-nav.component.html',
  styleUrls: [ './mobile-menu-side-nav.component.scss' ],
})
export class MobileMenuSideNavComponent implements OnInit, OnDestroy {

  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  @Select(UserAuthState.userHasSuperAdminRole)
  isUserSuperAdmin$: Observable<boolean>;

  @Select(UserAuthState.userHasAdminRole)
  isUserAdmin$: Observable<boolean>;

  @Select(HeaderCountsState.pendingReviewsCount)
  pendingReviewsCount$: Observable<number>

  @Select(UserAuthState.userRoles)
  private userRoles$: Observable<UserRole[]>;

  @Select(UserAuthState.categoriesFeatureFlag)
  private categoriesFeatureFlag$: Observable<boolean>;

  @Select(UserAuthState.isDiscoveryPageAvailable)
  private isDiscoveryPageAvailable$: Observable<boolean>;

  showPublisherSettings: boolean;
  hideMenuSidebar: boolean;
  hasUpcomingEvent: boolean;
  toReviewButtonShown = false;
  reviewsCount = 0;
  isExploreButtonVisible: boolean;

  private reviewsSubscription: Subscription;
  private subscriptionEnd$ = new EventEmitter<void>();

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private store: Store,
    private ngZone: NgZone,
    private activatedRoute: ActivatedRoute,
    private readonly accessTokenService: AccessTokenService,
    @Inject(SHARED_UPCOMING_DATA_SERVICE) private upcomingDataService: SharedUpcomingDataService,
    @Inject(REVIEWS_DATA_SERVICE) private reviewsDataService: ReviewsDataService,
  ) {
  }

  ngOnInit() {
    this.checkMobileMenuVisibility();
    this.userRoles$.pipe(
      take(1)
    ).subscribe((roles) => {
      if ( roles && roles.length ) {
        this.checkPublisherSettingsPrivilege(roles);
      }
    });

    combineLatest([this.categoriesFeatureFlag$, this.isDiscoveryPageAvailable$])
      .pipe(
        takeUntil(this.subscriptionEnd$)
      ).subscribe(( data ) => {
      this.isExploreButtonVisible = data[0] && data[1];
    });

    const userToken = this.accessTokenService.getAccessToken()
    if (userToken) {
      this.upcomingDataService.hasUpcomingEvents().subscribe(({ isSuccess, value }) => {
        if ( isSuccess ) {
          this.hasUpcomingEvent = value;
        }
      });

      this.reviewsDataService.getReviews().subscribe(({ isSuccess, value }) => {
        if ( isSuccess ) {
          if ( value.length > 0 ) {
            this.toReviewButtonShown = true;
          }
        }
      });
    }

    this.reviewsSubscription = this.pendingReviewsCount$.subscribe(data =>
      this.reviewsCount = data
    );
  }

  ngOnDestroy(): void {
    this.reviewsSubscription?.unsubscribe();
    this.subscriptionEnd$?.emit();
  }

  onHomeClick(): void {
    this.hideMenuSidebar = true;
  }

  onBackToMenu(): void {
    this.hideMenuSidebar = false;
  }

  closeSidebar(): void {
    setTimeout(() => {
      this.store.dispatch(new CloseMobileNavMenu());
    })
  }

  onAddNewMoment(): void {
    this.dialog.open(NewMomentDialogComponent, {
      width: '37.5rem',
      disableClose: true,
      maxHeight: '80vh',
      position: {
        top: '10vh',
      },
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      panelClass: [ 'ptl-mat-dialog', 'f_moment-dialog' ],
      backdropClass: 'dialog-backdrop',
      data: {
        addToFolio: true
      },
    });
    this.closeSidebar();
  }

  logout(): void {
    localStorage.removeItem(REDIRECT_AFTER_LOGIN);
    CookieHelper.DeleteCookie(REDIRECT_AFTER_LOGIN);
    this.store.dispatch(new UnauthenticateUser());
  }

  private checkMobileMenuVisibility(): void {
    const url = this.router?.url;
    if (url && url === '/' || url.startsWith('/pub') || url.startsWith('/pages') || url.startsWith('/playlists')) {
      this.hideMenuSidebar = false;
    } else {
      this.hideMenuSidebar = true;
    }
  }

  private checkPublisherSettingsPrivilege(roles: UserRole[]) {
    let privilegeFound = false;
    for ( const role of roles ) {
      for ( const privilege of role.privileges ) {
        if ( privilege.name === 'can_manage_publisher_settings' && privilege.assigned ) {
          this.showPublisherSettings = true;
          privilegeFound = true;
          break;
        }
      }
      if ( privilegeFound ) {
        break;
      }
    }
  }

  navigateTo(url: string) {
    RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, url);
    this.closeSidebar();
  }
}
