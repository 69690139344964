<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->


<div [class.content-media]="!newVersionEnabled"
     [class.content-media-new]="newVersionEnabled">
  <div class="regular-link-replace" *ngIf="showReplaceLink">
    <ptl-link-input
      [showLinkField]="true"
      (mediaContentAdded)="onMediaContentAdded($event)"
      (regularLinkAdded)="onRegularLinkAdded($event)">
    </ptl-link-input>
    <button mat-icon-button (click)="showReplaceLink = false"><i class="material-icons" aria-hidden="true">clear</i>
    </button>
  </div>

  <div
      #media
      [class]="!newVersionEnabled ? ('content-media__html--position-' + position) : ('content-media-html--position-'+ position)"
      [ngClass]="{
      'not-image' : !isUploadedImage,
      'content-media__html' : !newVersionEnabled,
      'content-media-html f_content-media-html' : newVersionEnabled
      }"
      tabindex="0"
      (click)="showMediaToolbar()"
      (keydown.enter)="showMediaToolbar()"
      (blur)="hideMediaToolbar($event, media)"
      (window:resize)="hideMediaToolbar()">

    <ng-content></ng-content>

    <div
      class="content-media-toolbar"
      *ngIf="showToolbar && newVersionEnabled"
      [ngStyle]="{top: (toolbarPosition.top / 16) + 'rem'}">
      <div class="content-media-toolbar-inner">
        <ul class="content-media-toolbar-items">
          <ng-container *ngIf="!disableMediaPosition">
            <li
              class="content-media-toolbar-item"
              [class.content-media-toolbar-item-active]="position === 'LEFT'"
              tabindex="0"
              data-id="LEFT"
              [attr.aria-label]="('translations.global.ariaLabels.alignLeft' | transloco)"
              (click)="setMediaPosition('LEFT')"
              (keydown.enter)="setMediaPosition('LEFT')">
              <svg class="svg" aria-hidden="true">
                <use ogSvgUnify="assets/sprite/sprite.svg#align-left"></use>
              </svg>
              <span
                class="label small">{{ 'translations.editor.toolbarNew.floating.option.alignLeft' | transloco }}</span>
            </li>
            <li
              class="content-media-toolbar-item"
              [class.content-media-toolbar-item-active]="position === 'RIGHT'"
              tabindex="0"
              data-id="RIGHT"
              [attr.aria-label]="('translations.global.ariaLabels.alignRight' | transloco)"
              (click)="setMediaPosition('RIGHT')"
              (keydown.enter)="setMediaPosition('RIGHT')">
              <svg class="svg" aria-hidden="true">
                <use ogSvgUnify="assets/sprite/sprite.svg#align-right"></use>
              </svg>
              <span
                class="label small">{{ 'translations.editor.toolbarNew.floating.option.alignRight' | transloco }}</span>
            </li>
            <li
              class="content-media-toolbar-item"
              [class.content-media-toolbar-item-active]="position === 'CENTER'"
              tabindex="0"
              data-id="CENTER"
              [attr.aria-label]="('translations.global.ariaLabels.alignCenter' | transloco)"
              (click)="setMediaPosition('CENTER')"
              (keydown.enter)="setMediaPosition('CENTER')">
              <svg class="svg" aria-hidden="true">
                <use ogSvgUnify="assets/sprite/sprite.svg#align-center"></use>
              </svg>
              <span
                class="label small">{{ 'translations.editor.toolbarNew.floating.option.alignCenter' | transloco }}</span>
            </li>
            <li
              class="content-media-toolbar-item"
              [class.content-media-toolbar-item-active]="position === 'FULL_WIDTH'"
              tabindex="0"
              data-id="FULL_WIDTH"
              [attr.aria-label]="('translations.global.ariaLabels.fullWidthAlign' | transloco)"
              (click)="setMediaPosition('FULL_WIDTH')"
              (keydown.enter)="setMediaPosition('FULL_WIDTH')">
              <svg class="svg" aria-hidden="true">
                <use ogSvgUnify="assets/sprite/sprite.svg#align-full-width"></use>
              </svg>
              <span
                class="label small">{{ 'translations.editor.toolbarNew.floating.option.alignFullWidth' | transloco }}</span>
            </li>
            <li
              *ngIf="isUploadedImage"
              class="content-media-toolbar-item"
              [class.content-media-toolbar-item-active]="position === 'THUMBNAIL'"
              tabindex="0"
              data-id="THUMBNAIL"
              (click)="setMediaPosition('THUMBNAIL')"
              (keydown.enter)="setMediaPosition('THUMBNAIL')">
              <svg class="svg" aria-hidden="true">
                <use ogSvgUnify="assets/sprite/sprite.svg#small-image"></use>
              </svg>
              <span
                class="label small">{{ 'translations.editor.toolbarNew.floating.option.thumbnail' | transloco }}</span>
            </li>
          </ng-container>
          <ng-container
            *ngIf="isUploadedImage || isUploadedDocument || isUploadedVideo || isUploadedAudio || isExternalContent">
            <li
              class="content-media-toolbar-item"
              [attr.aria-label]="('translations.global.ariaLabels.replace' | transloco)"
              tabindex="0"
              data-id="REPLACE"
              (click)="replaceMedia()"
              (keydown.enter)="replaceMedia()">
              <svg class="svg" aria-hidden="true">
                <use ogSvgUnify="assets/sprite/sprite.svg#edit"></use>
              </svg>
              <span
                class="label small">{{ 'translations.editor.toolbarNew.floating.option.replace' | transloco }}</span>
            </li>
          </ng-container>
          <ng-container *ngIf="isUploadedImage || isUploadedDocument || isUploadedVideo || isUploadedAudio">

            <li
              class="content-media-toolbar-item"
              [attr.aria-label]="('translations.global.ariaLabels.addCaption' | transloco)"
              tabindex="0"
              data-id="CAPTION"
              (click)="addCaption()"
              (keydown.enter)="addCaption()">
              <svg class="svg" aria-hidden="true">
                <use ogSvgUnify="assets/sprite/sprite.svg#caption"></use>
              </svg>
              <span
                class="label small">{{ 'translations.editor.toolbarNew.floating.option.caption' | transloco }}</span>
            </li>
            <li
              *ngIf="!isUploadedVideo && !isUploadedAudio"
              class="content-media-toolbar-item"
              [attr.aria-label]="('translations.global.ariaLabels.addLink' | transloco)"
              tabindex="0"
              data-id="LINK"
              (click)="addLink()"
              (keydown.enter)="addLink()">
              <svg class="svg" aria-hidden="true">
                <use ogSvgUnify="assets/sprite/sprite.svg#link"></use>
              </svg>
              <span class="label small">{{ 'translations.editor.toolbarNew.floating.option.link' | transloco }}</span>
            </li>

          </ng-container>

          <li
            *ngIf="canRemoveItem"
            class="content-media-toolbar-item f_delete-block"
            [attr.aria-label]="('translations.global.ariaLabels.removeItem' | transloco)"
            tabindex="0"
            data-id="DELETE"
            (click)="removeMedia()"
            (keydown.enter)="removeMedia()">
            <svg class="svg" aria-hidden="true">
              <use ogSvgUnify="assets/sprite/sprite.svg#delete"></use>
            </svg>
            <span class="label small">{{ 'translations.editor.toolbarNew.floating.option.delete' | transloco }}</span>
          </li>
        </ul>
      </div>
    </div>

    <div
      class="content-media-position-toolbar"
      *ngIf="showToolbar && !newVersionEnabled"
      [ngStyle]="{top: (toolbarPosition.top / 16) + 'rem', left: (toolbarPosition.left / 16) + 'rem'}">
      <ul class="content-media-position-toolbar__items">
        <ng-container *ngIf="!disableMediaPosition">
          <li
            class="content-media-position-toolbar__item content-media-position-toolbar__item--rotated"
            [class.content-media-position-toolbar__item--active]="position === 'LEFT'"
            tabindex="0"
            [attr.aria-label]="('translations.global.ariaLabels.alignLeft' | transloco)"
            [matTooltip]="'translations.global.ariaLabels.alignLeft' | transloco"
            (click)="setMediaPosition('LEFT')"
            (keydown.enter)="setMediaPosition('LEFT')">
            <i class="material-icons" aria-hidden="true">vertical_split</i>
          </li>
          <li
            class="content-media-position-toolbar__item"
            [class.content-media-position-toolbar__item--active]="position === 'RIGHT'"
            tabindex="0"
            [attr.aria-label]="('translations.global.ariaLabels.alignRight' | transloco)"
            [matTooltip]="'translations.global.ariaLabels.alignRight' | transloco"
            (click)="setMediaPosition('RIGHT')"
            (keydown.enter)="setMediaPosition('RIGHT')">
            <i class="material-icons" aria-hidden="true">vertical_split</i>
          </li>
          <li
            class="content-media-position-toolbar__item"
            [class.content-media-position-toolbar__item--active]="position === 'CENTER'"
            tabindex="0"
            [attr.aria-label]="('translations.global.ariaLabels.alignCenter' | transloco)"
            [matTooltip]="'translations.global.ariaLabels.alignCenter' | transloco"
            (click)="setMediaPosition('CENTER')"
            (keydown.enter)="setMediaPosition('CENTER')">
            <i class="material-icons" aria-hidden="true">calendar_view_day</i>
          </li>
          <li
            class="content-media-position-toolbar__item"
            [class.content-media-position-toolbar__item--active]="position === 'FULL_WIDTH'"
            tabindex="0"
            [attr.aria-label]="('translations.global.ariaLabels.fullWidthAlign' | transloco)"
            [matTooltip]="'translations.global.ariaLabels.fullWidthAlign' | transloco"
            (click)="setMediaPosition('FULL_WIDTH')"
            (keydown.enter)="setMediaPosition('FULL_WIDTH')">
            <i class="material-icons" aria-hidden="true">panorama</i>
          </li>
          <li
            *ngIf="isUploadedImage"
            class="content-media-position-toolbar__item"
            [matTooltip]="'Thumbnail'"
            tabindex="0"
            [class.content-media-position-toolbar__item--active]="position === 'THUMBNAIL'"
            (click)="setMediaPosition('THUMBNAIL')"
            (keydown.enter)="setMediaPosition('THUMBNAIL')">
            <i class="material-icons" aria-hidden="true">portrait</i>
          </li>
        </ng-container>
        <ng-container
          *ngIf="isUploadedImage || isUploadedDocument || isUploadedVideo || isUploadedAudio || isExternalContent">
          <li [matTooltip]="'Replace'" class="content-media-position-toolbar__item" tabindex="0"
              [attr.aria-label]="('translations.global.ariaLabels.replace' | transloco)" (click)="replaceMedia()"
              (keydown.enter)="replaceMedia()">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
                 width="20" height="20" x="0px" y="0px" viewBox="0 0 122.88 110.76"
                 style="enable-background:new 0 0 122.88 110.76" xml:space="preserve">
                <g><path fill="#fff" d="M52.86,15.96l-5.62,28.57l-5.7-8.59C29.26,40.87,22.37,49,21.35,61.51c-10.08-17.63-3.96-33.43,8.82-42.69 l-5.82-8.76L52.86,15.96L52.86,15.96L52.86,15.96L52.86,15.96L52.86,15.96z M75.63,0h41.39c1.61,0,3.08,0.66,4.14,1.72 s1.72,2.52,1.72,4.14v32.18c0,1.61-0.66,3.08-1.72,4.14c-0.12,0.12-0.24,0.23-0.37,0.33c-1.02,0.86-2.34,1.39-3.77,1.39H75.63 c-1.61,0-3.08-0.66-4.14-1.72c-1.06-1.06-1.72-2.52-1.72-4.14V5.86c0-1.6,0.66-3.06,1.72-4.13L71.5,1.72 C72.55,0.66,74.02,0,75.63,0L75.63,0z M5.86,66.87h41.39c1.61,0,3.08,0.66,4.14,1.72c1.06,1.06,1.72,2.52,1.72,4.14v32.18 c0,1.61-0.66,3.08-1.72,4.14c-0.12,0.12-0.24,0.23-0.37,0.33c-1.02,0.86-2.34,1.39-3.77,1.39H5.86c-1.61,0-3.08-0.66-4.14-1.72 S0,106.52,0,104.9V72.72c0-1.61,0.66-3.08,1.72-4.14c0.12-0.12,0.26-0.24,0.39-0.34C3.13,67.38,4.44,66.87,5.86,66.87L5.86,66.87z M45.25,74.71H7.85v28.2h37.41V74.71L45.25,74.71z M115.03,7.85H77.63v28.2h37.41V7.85L115.03,7.85z M71,101.54l5.62-28.57 l5.7,8.59C94.6,76.63,101.49,68.51,102.52,56c10.08,17.63,3.96,33.44-8.82,42.69l5.82,8.76L71,101.54L71,101.54L71,101.54 L71,101.54L71,101.54z"/></g>
              </svg>
          </li>
        </ng-container>
        <ng-container *ngIf="isUploadedImage || isUploadedDocument || isUploadedVideo || isUploadedAudio">
          <li [matTooltip]="'translations.global.ariaLabels.addCaption' | transloco"
              class="content-media-position-toolbar__item" tabindex="0"
              [attr.aria-label]="('translations.global.ariaLabels.addCaption' | transloco)" (click)="addCaption()"
              (keydown.enter)="addCaption()">
            <i class="material-icons" aria-hidden="true">closed_caption</i>
          </li>
          <li *ngIf="!isUploadedAudio && !isUploadedVideo"
              [matTooltip]="'translations.global.ariaLabels.addLink' | transloco"
              class="content-media-position-toolbar__item" tabindex="0"
              [attr.aria-label]="('translations.global.ariaLabels.addLink' | transloco)" (click)="addLink()"
              (keydown.enter)="addLink()">
            <i class="material-icons" aria-hidden="true">insert_link</i>
          </li>
        </ng-container>
        <li *ngIf="canRemoveItem" [matTooltip]="'translations.global.ariaLabels.removeItem' | transloco"
            class="content-media-position-toolbar__item remove" tabindex="0"
            [attr.aria-label]="('translations.global.ariaLabels.removeItem' | transloco)" (click)="removeMedia()"
            (keydown.enter)="removeMedia()">
          <i class="material-icons" aria-hidden="true">clear</i>
        </li>
      </ul>
    </div>

    <div *ngIf="showAddCaption">
      <div
        class="content-media-position-toolbar"
        [ngStyle]="{top: (toolbarPosition.top / 16) + 'rem', left: (toolbarPosition.left / 16) + 'rem'}">
        <form [formGroup]="captionControl" (submit)="setCaption()">
          <div class="top">
            <mat-form-field class="no-padding">
              <label for="contentInput" class="input-text">{{ 'translations.shortDescription' | transloco }}</label>
              <input
                id='contentInput'
                matInput
                type="text"
                class="input-text"
                [formControl]="captionControl"
                (window:resize)="showAddCaption = false">
            </mat-form-field>
          </div>
          <div class="bottom">
            <button
              data-id="CAPTION"
              mat-icon-button type="submit" class="input-text"
              [attr.aria-label]="('translations.global.ariaLabels.addDescription' | transloco)">
              <mat-icon aria-hidden="true">check</mat-icon>
            </button>
            <button mat-icon-button class="input-text" type="button"
                    [attr.aria-label]="('translations.global.ariaLabels.close' | transloco)" (click)="close($event)">
              <mat-icon aria-hidden="true">clear</mat-icon>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="showAddLink">
      <div
        class="content-media-position-toolbar"
        [ngStyle]="{top: (toolbarPosition.top / 16) + 'rem', left: (toolbarPosition.left / 16) + 'rem'}"
        (window:resize)="showAddLink = false">
        <form class="formContainer" [formGroup]="hyperlinkForm" (submit)="setHyperLink()">
          <div class="top">
            <mat-form-field class="no-padding">
              <label for="linkInput"
                     class="input-text">{{ 'translations.mediaContent.label.url' | transloco }}</label>
              <input
                id='linkInput'
                matInput
                type="text"
                class="input-text"
                formControlName="url">
            </mat-form-field>
          </div>
          <div class="bottom">
            <mat-checkbox
              id="newTab"
              class="input-text"
              formControlName="openUrlInTab"
              labelPosition="before"
              [aria-label]="'translations.mediaContent.label.openInNewTab' | transloco"
              color="primary">
              <label for="newTab">{{ 'translations.mediaContent.label.openInNewTab' | transloco }}</label>
            </mat-checkbox>
            <button mat-icon-button type="submit" class="input-text">
              <mat-icon aria-hidden="true">check</mat-icon>
            </button>
            <button mat-icon-button class="input-text" type="button" (click)="close($event)">
              <mat-icon aria-hidden="true">clear</mat-icon>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div class="file-upload-content" *ngIf="showUpload">
      <ptl-file-upload
        [replace]="true"
        [uploadType]="replacedFileType"
        [doNotShowButton]="true"
        (mediaContentAdded)="onMediaContentAdded($event)">
      </ptl-file-upload>
    </div>
  </div>
</div>
