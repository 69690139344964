<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="form-preview" tabindex="0"
     role="form"
     [attr.aria-label]="(!submitted && canEdit) ?
     ('translations.global.ariaLabels.fileUploadEditableFormTitle' | transloco: {
      title: formData?.content?.title,
      state: submitted ? ('translations.global.ariaLabels.update' | transloco) : ('translations.global.ariaLabels.markAsDone' | transloco)
     }) : ('translations.global.ariaLabels.fileUploadFormTitle' | transloco: {
      title: formData?.content?.title
     })"
     [class.form-preview--saved]="submitted"
     [class.form-preview--requires-resubmission]="requiresResubmission">

  <div class="form-preview__top">
    <div class="form-upload__top-bar">
      <div class="form-preview__top-title">
        <div class="title medium bold">{{ formData?.content?.title }}</div>
        <div class="form-preview__top-icon-box">
          <div *ngIf="submitted" class="form-preview__top-icon non-editable" [matTooltip]="canNotEditReason">
            <mat-icon *ngIf="canEdit" aria-hidden="true">check</mat-icon>
            <svg *ngIf="!canEdit" class="svg" role="img"
                 [attr.aria-label]="('translations.global.ariaLabels.icons.lock' | transloco)">
              <use ogSvgUnify="assets/sprite/sprite.svg#lock"></use>
            </svg>
          </div>
          <div *ngIf="!submitted && !canEdit" class="form-preview__top-icon non-saved non-editable"
               [matTooltip]="canNotEditReason">
            <svg class="svg" role="img" [attr.aria-label]="('translations.global.ariaLabels.icons.lock' | transloco)">
              <use ogSvgUnify="assets/sprite/sprite.svg#lock"></use>
            </svg>
          </div>
          <button *ngIf="canEdit" class="form-preview__top-icon editable pointer"
                  (click)="triggerUpload()">
            <svg class="svg" role="img" [attr.aria-label]="('translations.global.ariaLabels.icons.edit' | transloco)">
              <use ogSvgUnify="assets/sprite/sprite.svg#edit"></use>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div class="form-preview__top-limits extra-small">
      {{ 'translations.uploadedFileSizeLimit' | transloco: { maxFileSizeMb: formData?.content?.maxFileSizeMb } }}
      <br/>
      {{ 'translations.supportedFileTypes' | transloco: { supportedFileTypes: getSupportedFileTypes() } }}
    </div>
  </div>

  <div
    role="button"
    tabindex="0"
    class="form-preview__upload-file-section dashed-box small italic"
    [ngClass]="{'disabled': fileUploadInProgress}"
    *ngIf="(canEdit || fileUploaded) && !submitted"
    ogFileDrop
    (click)="fileInput.click()"
    [attr.aria-label]="('translations.global.ariaLabels.enterToUploadFileSection' | transloco)"
    (keydown.enter)="fileInput.click(); $event.stopImmediatePropagation();"
    (filesHovered)="filesHovered=$event"
    [class.form-preview__upload-file-section--active]="fileUploadInProgress || filesHovered"
    (filesDropped)="onFilesDropped($event)">
    <span *ngIf="fileUploadInProgress && !fileRemoveInProgress">
      {{ 'translations.editor.toolbarNew.option.formFileUpload.uploading' | transloco }}
    </span>
    <ptl-progress-bar-upload 
      *ngIf="fileUploadInProgress && !fileRemoveInProgress" 
      [content]="formData.content">
    </ptl-progress-bar-upload>
    <span *ngIf="fileRemoveInProgress">
      {{ 'translations.editor.toolbarNew.option.formFileUpload.removing' | transloco }}
    </span>
    <mat-icon aria-hidden="true" *ngIf="fileRemoveInProgress" class="form-preview__upload-file-loader">
      autorenew
    </mat-icon>
    <button *ngIf="!fileUploadInProgress" mat-icon-button class="select-file">
      <mat-icon class="icon" aria-hidden="true">
        file_download
      </mat-icon>
      <span class="select-file--text">{{ 'translations.clickToSelectFile' | transloco }}</span>
      <span>{{ 'translations.orDropItHere' | transloco }}</span>
    </button>
  </div>

  <div class="file-list">
    <div *ngFor="let answer of answersStructure; let idx = index">
      <div class="file-item">
        <svg class="icon svg svg-icon" [attr.aria-label]="('translations.global.ariaLabels.icons.file' | transloco)">
          <use ogSvgUnify="assets/sprite/sprite.svg#attachment"></use>
        </svg>
        <a href="{{answer.fullPath}}"
           (click)="$event.stopPropagation()"
           (keydown.enter)="$event.stopPropagation()"
           target="_blank"
           download>{{ answer.fileName }}
        </a>
        <button *ngIf="canEdit"
                mat-icon-button
                type="button"
                class="remove"
                [attr.aria-label]="('translations.global.ariaLabels.remove' | transloco: {name: answer.fileName})"
                (keydown.enter)="removeFile($event, answer.path, idx)"
                (click)="removeFile($event, answer.path, idx)">
          <svg class="svg svg-icon" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
          </svg>
        </button>
      </div>
    </div>
    <input
      [disabled]="!canEdit"
      type="file"
      [accept]="supportedFileTypes"
      (change)="onFileChanged($event)"
      hidden
      #fileInput>
  </div>

  <div class="form-preview__controls" *ngIf="canEdit">
    <button
      mat-stroked-button
      color="accent"
      class="form-preview__submit uppercase"
      type="button"
      (click)="submit()"
      [disabled]="isSubmissionDisabled">
      <span *ngIf="requiresResubmission">{{ 'translations.cards.form.button.resubmit' | transloco }}</span>
      <span
        *ngIf="!requiresResubmission && !submitted">{{ 'translations.cards.form.button.markAsDone' | transloco }}</span>
      <span
        *ngIf="!requiresResubmission && submitted">{{ 'translations.cards.form.button.update' | transloco }}</span>
    </button>
  </div>

  <div class="form-preview-notes" *ngIf="formData?.content?.notes?.length">
    <div class="note-item" *ngFor="let note of formData?.content?.notes" tabindex="0">
      <div class="note-item-inner">
        <div class="note-message small">
          <ptl-resource-preview class="thin-preview" [previewContent]="note.message">
          </ptl-resource-preview>
        </div>
      </div>
      <div class="note-added-by extra-small italic">
        {{ note.addedBy }}, {{ getLocalDateTime(note.addedOn) | date: 'dd MMM yyyy HH:mm' }}
      </div>
    </div>
  </div>
</div>
<ptl-form-update-footer
  [submitted]="submitted"
  [canEdit]="canEdit"
  [learnerFormAnswer]="learnerFormAnswer"
  [formType]="formData?.content?.type"
  [playlistSubmissionDeadline]="userPlaylistSubmissionSummary?.submissionDeadline">
</ptl-form-update-footer>
