<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<div class="edit-container" [class.dialog-container]="isDialog">
  <div class="tabs-container sticky-tabs"
       *ngIf="!isDialog && supportedLanguages.length && supportedLanguages.length > 1">
    <ptl-language-tabs [supportedLanguages]="supportedLanguages" [currentIndex]="currentIndex" [class.is_disabled]="languageTabChanged"
                       (tabChange)="onTabChange($event)">
    </ptl-language-tabs>
  </div>

  <ptl-skeleton-view *ngIf="skeletonViewActive; else elseTemplate"
                     [state]="{ loading: true }"
                     [viewType]="'action'">
  </ptl-skeleton-view>

  <ng-template #elseTemplate>
    <mat-card class="publish-card" *ngIf="title$ | async as title">
    <span class="cdk-visually-hidden" #pageTitle>{{ 'translations.pages.title.main' | lowercase | transloco }}</span>
    <div class="publish-card__header" *ngIf="!isDialog">
      <h1 class="publish-card__header-text"
          tabindex="0"
          [attr.aria-label]="('translations.global.ariaLabels.publish' | transloco) + ' ' + title && pageTitle.innerHTML">
        <span>{{ 'translations.pages.publish.label.publish' | transloco }}</span> {{ title && pageTitle.innerHTML }}
      </h1>
      <div class="publish-card__header-actions">
      </div>
    </div>

    <div class="publish-card__section">
      <div class="publish-card__thumbnail-settings">
        <mat-card class="card">
          <div class="card__center"
               tabindex="0"
               [attr.aria-label]="'translations.global.ariaLabels.thumbnailImage' | transloco">
            <button *ngIf="thumbnailUrl" mat-button class="button__remove" title="Remove thumbnail"
                    (click)="onThumbnailRemove()">
              <mat-icon aria-hidden="true" [inline]="true">clear</mat-icon>
            </button>
            <img class="center__image" alt="" width="266" height="150" [src]="thumbnailUrl ?
                      thumbnailUrl : '../../../../assets/card-placeholders/card-placeholder-1.png'"/>
          </div>
          <div class="card__author-info">
            <mat-icon
              class="mat__icon"
              aria-hidden="true"
              *ngIf="!page?.authorDetails?.displayConfig?.organizationName && !page?.authorDetails?.displayConfig?.authorName">
              insert_drive_file_outline
            </mat-icon>
            <ng-container *ngIf="page?.authorDetails?.displayConfig?.organizationName || page?.authorDetails?.displayConfig?.authorName">
              <img
                class="item__author-image"
                alt="" width="50" height="50"
                [src]="page?.authorDetails.displayConfig?.organizationName ? page?.authorDetails.orgLogoUrl : page?.authorDetails.userImageUrl"
                *ngIf="(page?.authorDetails.userImageUrl && page?.authorDetails.displayConfig?.authorName) ||
                   page?.authorDetails.displayConfig?.organizationName">
              <div
                [title]="page?.authorDetails.name"
                class="item__author-initials"
                *ngIf="!page?.authorDetails.userImageUrl &&
                    page?.authorDetails.displayConfig?.authorName &&
                    !page?.authorDetails.displayConfig?.organizationName">
                {{page?.authorDetails.name.charAt(0) }}
              </div>
            </ng-container>
          </div>
          <h3 class="card__description"
              tabindex="0"
              [attr.aria-label]="title">
            <span>{{ title }}</span>
          </h3>
          <p class="card__description"
             tabindex="0"
             [attr.aria-label]="subheader$ | async">
            <span>{{ subheader$ | async }}</span>
          </p>
        </mat-card>
        <div class="publish-card__thumbnail-buttons"
          *ngIf="page?.organizationUid === page?.syndicationDetails?.publisher.organization._id">
          <button mat-button class="publish-card__change-thumbnail-btn uppercase" color="primary"
                  [class.old-version]="!newVersionEnabled"
                  (click)="fileInput.click()">
            {{ 'translations.pages.publish.label.updateThumbImage' | transloco }}
            <mat-spinner
              class="publish-card__change-thumbnail-btn__spinner"
              *ngIf="thumbnailUploadInProgress"
              [color]="'blue'"
              [diameter]="20"></mat-spinner>
          </button>
        </div>
        <input #fileInput type="file" hidden [accept]="'image/*'" (change)="onThumbnailUpload($event)">
      </div>

      <div class="publish-card__publication-settings">
        <ptl-headers-editor [subheader]="subheader$ | async" [pageUid]="pageUid" [title]="title" [uri]="uri"
                            [isDisabled]="page?.organizationUid !== page?.syndicationDetails?.publisher.organization._id"
                            [currentLanguage]="currentLanguage" (titleUpdated)="onTitleUpdate($event)"
                            (uriUpdated)="onUriChanged($event)" [canEditTime]="true"
                            (subheaderUpdated)="onSubheaderUpdate($event)">
        </ptl-headers-editor>


        <div class="section-tags">
          <div class="section-tags__main">
            <div class="section-tags__main-content">
              <h2
                tabindex="0"
                [attr.aria-label]="'translations.global.ariaLabels.tags' | transloco">
                {{ 'translations.pages.publish.title.addTags' | transloco }}
              </h2>
              <p *ngIf="page?.organizationUid === page?.syndicationDetails?.publisher.organization._id"
                 tabindex="0"
                 [attr.aria-label]="'translations.global.ariaLabels.addTagsToPage' | transloco">
                {{ 'translations.framework.label.addTagsToPage' | transloco }}
              </p>
              <div class="section-tags__tags">
                <div class="section-tags__tags-item" *ngFor="let tag of tags">
                  <div class="bold">{{ tag.title }}</div>
                  <span
                    tabindex="0"
                    class="section-tags__tags-item-remove"
                    matTooltip="Remove tag"
                    [attr.aria-label]="('translations.global.ariaLabels.removeTag' | transloco)"
                    (click)="removeTag(tag)"
                    (keydown.enter)="removeTag(tag)"
                    *ngIf="page?.organizationUid === page?.syndicationDetails?.publisher.organization._id">
                    <mat-icon
                      aria-hidden="true"
                      [inline]="true"
                    >
                      clear
                    </mat-icon>
                  </span>
                </div>
                <div
                  class="section-tags__tag-box-container"
                  [ngClass]="{'show-framework-tags-panel' : showFrameworkTagsPanel}"
                  *ngIf="page?.organizationUid === page?.syndicationDetails?.publisher.organization._id">
                  <div class="section-tags__framework-tag-box">
                    <span>{{ 'translations.framework.label.AddFrom' | transloco }}</span>
                    <span
                      class="section-tags__framework-btn"
                      tabindex="0"
                      [attr.aria-label]="('translations.global.ariaLabels.addFromFramework' | transloco)"
                      (click)="onShowFrameworkTagsPanel(true)"
                      (keydown.enter)="onShowFrameworkTagsPanel(true)">

                      <span class="section-tags__framework-icon"></span>
                      {{ 'translations.framework.button.framework' | transloco }}
                    </span>
                  </div>
                  <div class="section-tags__framework-add-tag">
                    <span>{{ 'translations.framework.label.or' | transloco }}</span>
                    <mat-form-field appearance="outline" class="section-tags__add-tag-input">
                      <input
                        matInput
                        [matAutocomplete]="auto"
                        type="text"
                        placeholder="Add new tags"
                        [attr.aria-label]="('translations.global.ariaLabels.addNewTags' | transloco)"
                        (paste)="onTextPaste($event)"
                        (keyup)="onSearchInputChange($event)"
                        (keydown.enter)="onSearchInputEnter($event)"
                        (focus)="onShowFrameworkTagsPanel(false)"/>

                      <mat-autocomplete
                        [panelWidth]="450"
                        #auto="matAutocomplete"
                        [displayWith]="disabledAutocomplete"
                        (optionSelected)="onTagSelect($event)">
                        <mat-option *ngFor="let option of filteredTags" [value]="option">
                          {{ option.title }}
                        </mat-option>
                      </mat-autocomplete>
                      <mat-spinner *ngIf="loadingTags" [diameter]="14"></mat-spinner>
                    </mat-form-field>
                  </div>
                </div>
                <div
                  *ngIf="showFrameworkTagsPanel"
                  class="section-tags__framework-tags-panel">
                  <div class="section-tags__framework-tags-panel-inner">
                    <ptl-add-framework-tags
                      [isDialogPage]="false"
                      (closeFrameworkTags)="onShowFrameworkTagsPanel(false)"
                      (addTagBulk)="onSubmitTags($event); onShowFrameworkTagsPanel(false)"
                    ></ptl-add-framework-tags>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section-navigation" *ngIf="page?.type === 'STANDARD_PAGE'">
          <h2>{{ 'translations.pages.publish.title.navigationPanel' | transloco }}</h2>

          <mat-slide-toggle color="primary"
                            class="enable-page-navbar-toggle"
                            [attr.aria-label]="('translations.global.ariaLabels.pageVisibility' | transloco)"
                            [(ngModel)]="showOnLeftNav"
                            (change)="updatePageVisibilityInNav($event)">
            {{ 'translations.pages.publish.label.visible' | transloco }}
          </mat-slide-toggle>
        </div>

        <div class="section-switch" *ngIf="page?.type === 'STANDARD_PAGE'">
          <h2>{{ 'translations.pages.publish.title.switch' | transloco }}</h2>
          <div tabindex="0" (click)="switchToHomePage()" (keydown.enter)="switchToHomePage()">
            {{ 'translations.pages.publish.label.switchToHomePage' | transloco }}
          </div>
        </div>
      </div>
    </div>


    <div class="publish-card__footer" *ngIf="!isDialog">
      <ng-container *ngIf="currentLanguage?.isDefault">
        <button
          mat-raised-button
          class="uppercase"
          type="button"
          color="accent"
          [attr.aria-label]="('translations.global.ariaLabels.publishPage' | transloco)"
          *ngIf="(status$ | async) !== 'ACTIVE'"
          [disabled]="updateInProgress"
          (click)="onPublishClick()">
          <span class="publish-card__footer-btn-text">
            <span>{{ 'translations.pages.publish.button.publishNow' | transloco }}</span>
            <mat-spinner class="publish-card__footer-btn-spinner" *ngIf="updateInProgress"
                         [color]="'primary'" [diameter]="20"></mat-spinner>
          </span>
        </button>

        <button
          mat-raised-button
          class="uppercase"
          type="button"
          color="accent"
          [attr.aria-label]="('translations.global.ariaLabels.unPublishPage' | transloco)"
          *ngIf="(status$ | async) === 'ACTIVE'"
          [disabled]="updateInProgress"
          (click)="onUnpublishClick()">
          <span class="publish-card__footer-btn-text">
            <span>{{ 'translations.pages.publish.button.unPublishNow' | transloco }}</span>
            <mat-spinner class="publish-card__footer-btn-spinner" *ngIf="updateInProgress"
                         [color]="'primary'" [diameter]="20"></mat-spinner>
          </span>
        </button>
      </ng-container>
    </div>

  </mat-card>
  </ng-template>
</div>
