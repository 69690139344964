import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { UserGroups } from '../../../../../../../shared/models/admin/group/user-groups.model';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { USER_GROUPS_DATA_SERVICE, UserGroupsDataService } from '../../../../../services/groups/groups-data.service';
import { MatSelectChange } from '@angular/material/select';
import { TranslationService } from '../../../../../../../shared/services/translation/translation.service';
import {
  BooleanFilter,
  BooleanQueryRequest,
  BooleanQueryType,
  GroupsRequest, NotRequest
} from '../../../../../../../shared/models/admin/boolean-filters.model';

@Component({
  selector: 'ptl-group-member-filter',
  templateUrl: './group-member-filter.component.html',
  styleUrls: ['./group-member-filter.component.scss'],
})
export class GroupMemberFilterComponent implements OnChanges, OnDestroy {

  @Input() excludedGroups: string[];
  @Input() excludedGroupStatuses: string[];
  @Output() setGroupFilter = new EventEmitter<BooleanFilter>();
  @Output() cancelGroupFilter = new EventEmitter<void>();

  selectedGroupMembership: 'IN_GROUP' | 'NOT_IN_GROUP' = 'IN_GROUP';
  allGroupMemberships = [];
  groupMemberships = [];
  selectedGroups: UserGroups[] = [];
  noGroupsSelected = false;

  showSearchResult: boolean;
  groups: UserGroups[];
  searchString = new FormControl('');
  searchInProgress: boolean;

  private searchInputSubject$ = new Subject<void>();
  private searchInputSubscription: Subscription;
  private subscriptionEnd$ = new EventEmitter<void>();

  constructor(
    private translationService: TranslationService,
    @Inject(USER_GROUPS_DATA_SERVICE) public userGroupsService: UserGroupsDataService
  ) {
    this.allGroupMemberships = [
      { value: 'IN_GROUP', title: this.translationService.getTranslation('members.filter.group.in') },
      { value: 'NOT_IN_GROUP', title: this.translationService.getTranslation('members.filter.group.out')  },
    ];
    this.groupMemberships = this.allGroupMemberships;
    this.searchInputSubscription = this.searchInputSubject$
      .pipe(debounceTime(500))
      .subscribe(() => this.fireSearch());
  }

  ngOnDestroy() {
    this.subscriptionEnd$.emit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.excludedGroupStatuses && this.groupMemberships) {
      this.groupMemberships = this.allGroupMemberships.filter(
        groupMembership => !this.excludedGroupStatuses.includes(groupMembership.value)
      );
      if (this.groupMemberships.length) {
        this.selectedGroupMembership = this.groupMemberships[0].value;
      }
    }
  }

  onGroupMembershipChange(event: MatSelectChange) {
    this.selectedGroupMembership = event.value;
  }

  onSave() {
    if (this.selectedGroups.length === 0) {
      this.noGroupsSelected = true;
      return;
    }
    const result = {
      request: this.getRequest(),
      message: this.getMessage()
    }
    this.setGroupFilter.emit(result);
  }

  onCancel() {
    this.cancelGroupFilter.emit();
  }

  onInputSubmit() {
    this.fireSearch();
  }

  onInputChange() {
    this.searchInputSubject$.next();
  }

  onGroupSelect(group: UserGroups) {
    this.showSearchResult = false;
    if (!this.selectedGroups.map(selectedGroup => selectedGroup._id).includes(group._id)) {
      this.selectedGroups.push(group);
      this.noGroupsSelected = false;
    }
  }

  onGroupRemove(group: UserGroups) {
    this.selectedGroups = this.selectedGroups.filter(selectedGroup => selectedGroup._id !== group._id);
  }

  private fireSearch() {
    this.showSearchResult = false;
    this.searchInProgress = true;
    const searchString = this.searchString.value;
    if (!searchString) {
      this.searchInProgress = false;
      return;
    }
      this.userGroupsService.searchUserGroups(0, 10, searchString).subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          this.groups = value.content
            .filter(group => !this.selectedGroups.map(selectedGroup => selectedGroup._id).includes(group._id))
            .filter(group => !this.excludedGroups.includes(group._id));
        }
        this.searchInProgress = false;
        this.showSearchResult = true;
      });
  }

  private getRequest(): BooleanQueryRequest {
    const groupsRequest: GroupsRequest = {
      type: BooleanQueryType.GROUPS,
      groups: this.selectedGroups.map(group => group._id)
    };
    if (this.selectedGroupMembership === 'IN_GROUP') {
      return groupsRequest
    } else {
      return {
        type: BooleanQueryType.NOT,
        arg: groupsRequest
      } as NotRequest
    }
  }

  private getMessage(): string {
    if (this.selectedGroupMembership === 'IN_GROUP') {
      if (this.selectedGroups.length === 1) {
        return this.translationService.getTranslation('members.filter.summary.inGroup')
          + ' ' + `'${this.selectedGroups[0].title}'`;
      } else {
        return this.translationService.getTranslation('members.filter.summary.inGroups')
          + ' ' + this.selectedGroups.map(group => `'${group.title}'`).join(', ');
      }
    } else {
      if (this.selectedGroups.length === 1) {
        return this.translationService.getTranslation('members.filter.summary.outGroup')
          + ' ' + `'${this.selectedGroups[0].title}'`;
      } else {
        return this.translationService.getTranslation('members.filter.summary.outGroups')
          + ' ' + this.selectedGroups.map(group => `'${group.title}'`).join(', ');
      }
    }
  }
}
