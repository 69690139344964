/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../store';
import { Injectable } from '@angular/core';
import { SharedReviewDataService } from './review-data.service';
import { ReviewNoteUpdateRequest } from '../../models/admin/members.model';
import { Review } from '../../models/review/review.model';

@Injectable()
export class MockSharedReviewDataService implements SharedReviewDataService {

  private review: Review = {
    reviewUid: 'reviewUid',
    submittingUserId: 'submittingUserId',
    reviewItemType: 'CARD',
    learnerItemId: 'learnerItemId',
    learnerUserUid: 'learnerUserUid',
    learnerFirstName: 'learnerFirstName',
    learnerLastName: 'learnerLastName',
    learnerThumbnail: undefined,
    learnerEmail: 'learnerEmail',
    learnerExternalId: 'learnerExternalId',
    itemId: 'itemId',
    status: undefined,
    createdAt: new Date(),
    lastUpdatedOn: new Date()
  };

  reviewFormReSubmit(reviewId: string, cardUid: string, formUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  reviewApprove(reviewId: string, cardUid: string, itemId: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  unlockForm(cardUid: string, formId: string, learnerCardUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  reviewApproveAll(reviewId: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  reviewAddNote(reviewId: string, cardUid: string, itemUid: string, request: ReviewNoteUpdateRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  fetchReview(reviewUid: string): ObservableResult<Review> {
    return ObservableResult.ofSuccess(this.review);
  }

  remindToSubmitForm(reviewUid: string, cardUid: string, formUid: string, message: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

}
