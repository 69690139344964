/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Quiz, QuizSummary } from '../../../../models/editor/quiz-content.model';
import { DATA_SERVICE, QuizDataService } from '../../../../../editor/services/data.service';
import { ActivatedRoute } from '@angular/router';
import { Form, Organization, QuizFormContent, UserPlaylistSubmissionSummary } from '../../../../models';
import { MatDialog } from '@angular/material/dialog';
import {
  ResultsDialogComponent
} from '../../../../../page-modules/resource/components/view/quizzes/results-dialog/results-dialog.component';
import { Select, Store } from '@ngxs/store';
import { LearnerViewState } from '../../../../../page-modules/resource/store/learner-view.state';
import { RefreshLearnerViewCardStatus } from '../../../../../page-modules/resource/store/learner-view.actions';
import { Observable, Subject, timer } from 'rxjs';
import { LanguageCodeHelper } from '../../../../helpers/language-code-helper';
import { take, takeUntil } from 'rxjs/operators';
import { UserAuthState } from '../../../../../user-auth/store/user-auth.state';
import { DEFAULT_LANGUAGE_CODE } from '../../../../services/translation/translation.service';
import * as PlaylistViewActions from '../../../../../page-modules/playlist/store/view/playlist-view.state.actions';
import { LocalTimeHelper } from '../../../../helpers/local-time-helper';

@Component({
  selector: 'ptl-form-preview-quiz',
  templateUrl: './form-quiz.component.html',
  styleUrls: ['./form-quiz.component.scss'],
})
export class FormPreviewQuizComponent implements OnInit, OnDestroy {

  @Input() content: Form<QuizFormContent>;
  @Input() userPlaylistSubmissionSummary: UserPlaylistSubmissionSummary;
  @Input() playlistUri: string;
  @Input() playlistUid: string;
  @Input() groupUid: string;
  @Input() resourceUri: string;
  @Input() publisherUri: string;
  @Input() packageUri: string;
  @Input() pageUri: string;
  @Input() languageCode: string;

  @Select(LearnerViewState.currentLanguage)
  private currentLanguage$: Observable<string>;

  @Select(UserAuthState.organizationDetails)
  private organization$: Observable<Organization>;

  quiz: Quiz;
  latestVersionNumber: number;
  quizTaken: boolean;
  resourceUid: string;
  resultUid: string = undefined;
  disabled = true;
  summary: QuizSummary;
  canRetake: boolean;
  resume = false;
  reloadPending = false;
  currentLanguage: string;
  private subscriptionEnd$ = new Subject<void>();
  title: string;
  organization: Organization;

  constructor(
    @Inject(DATA_SERVICE) private dataService: QuizDataService,
    activeRoute: ActivatedRoute,
    public dialog: MatDialog,
    private ref: ChangeDetectorRef,
    private store: Store
  ) {
    this.organization$.pipe(take(1)).subscribe(org => this.organization = org);
    this.resourceUid = this.store.selectSnapshot(LearnerViewState.resourceId);
  }

  ngOnInit() {
    this.getSummary();
  }

  ngOnDestroy() {
    this.subscriptionEnd$.next();
  }

  getSummary() {
    this.currentLanguage$.pipe(takeUntil(this.subscriptionEnd$))
      .subscribe(language => {
        this.currentLanguage = language ? language : DEFAULT_LANGUAGE_CODE;
        this.dataService.getQuizSummary(this.resourceUid, this.content.content.quizId, language)
          .subscribe(({ isSuccess, value }) => {
            if (isSuccess) {
              this.title = LanguageCodeHelper.getDataByUserLanguageCode(value.title, this.organization, language).value;
              if (!value.quizResponsesUid) {
                this.quizTaken = false;
              }
              if (value.retake === true) {
                if (!value.completed) {
                  this.resume = value.answeredQuestions !== 0;
                  this.quizTaken = false;
                } else {
                  this.quizTaken = true;
                }
                this.canRetake = true;
              } else {
                if (!value.completed) {
                  this.resume = value.answeredQuestions !== 0;
                } else {
                  this.quizTaken = true;
                }
                this.canRetake = false;
              }
              this.resultUid = value.quizResponsesUid;
              this.latestVersionNumber = value.lastVersionNumber;
              this.summary = value;
              if (this.reloadPending) {
                this.showDialog();
                this.reloadPending = false;
              }
              this.ref.detectChanges();
            }
          });
      });
  }

  retake() {
    this.reloadPending = true;
    this.quizTaken = false;
    this.latestVersionNumber += 1;
    this.dataService.retakeQuiz(this.playlistUid, this.content.content.quizId, this.resultUid, this.latestVersionNumber)
      .subscribe(() => this.getSummary());
  }

  showDialog(): void {
    let width = '75rem';
    let height = '80vh';
    const position = {
      top: '10vh',
    };

    if (!this.quizTaken) {
      height = '100%';
      width = '100%';
      position.top = '0vh';
    }
    const dialogRef = this.dialog.open(ResultsDialogComponent, {
      width: width,
      height: height,
      position: position,
      panelClass: ['quiz-dialog'],
      backdropClass: 'dialog-backdrop',
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      data: {
        quizId: this.content.content.quizId,
        quizTaken: this.quizTaken,
        resourceUid: this.resourceUid,
        resultUid: this.resultUid,
        latestVersionNumber: this.latestVersionNumber,
        retake: this.summary.retake,
        summary: this.summary,
        isReviewResult: false,
      },
    });

    const sub = dialogRef.componentInstance.action.subscribe();

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        timer(1000).subscribe(() => {
          this.store.dispatch(new PlaylistViewActions.RefreshUserPlaylistSubmissionSummary(this.playlistUid));
          this.store.dispatch(
            new RefreshLearnerViewCardStatus(
              this.playlistUri,
              this.resourceUri,
              this.groupUid,
              this.publisherUri,
              this.packageUri,
              this.pageUri,
              this.languageCode,
            )
          );
        });

        if (result.event === 'showReport') {
          this.quizTaken = true;
          this.resultUid = result.resultUid;
          this.ref.detectChanges();
          this.getSummary();
          this.showDialog();
        }

        if (result.event === 'save') {
          this.resultUid = result.resultUid || undefined;
          this.getSummary();
        }
        if (result.event === 'retake') {
          this.resultUid = result.resultUid;
          this.quizTaken = true;
          this.retake();
        }

      } else {
        this.getSummary();
      }
      sub.unsubscribe();
    });
  }

  getLocalDateTime(date: Date): string {
    return LocalTimeHelper.getLocalDateTime(date).toString();
  }
}
