/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { Form, TextBoxFormContent, UserPlaylistSubmissionSummary } from '../../../../models';
import { FormSavedEvent } from '../form-preview-event.model';
import {
  RefreshLearnerViewCardStatus,
  SaveFormAnswer,
  SubmitForm
} from '../../../../../page-modules/resource/store/learner-view.actions';
import { Store } from '@ngxs/store';
import { Subject, Subscription, timer } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DialogService } from '../../../../helpers/dialog/dialog.service';
import { TranslationService } from '../../../../services/translation/translation.service';
import * as PlaylistViewActions from '../../../../../page-modules/playlist/store/view/playlist-view.state.actions';
import { ContentHelper } from '../../../../helpers/content-helper';
import { LocalTimeHelper } from '../../../../helpers/local-time-helper';

@Component({
  selector: 'ptl-form-preview-textbox',
  templateUrl: './form-textbox.component.html',
  styleUrls: ['./form-textbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormPreviewTextboxComponent implements OnInit, OnChanges {

  /** Receives the textbox type object */
  @Input() formData: Form<TextBoxFormContent>;
  @Input() userPlaylistSubmissionSummary: UserPlaylistSubmissionSummary;
  @Input() hideTextFormButtons: boolean;
  @Input() playlistUri: string;
  @Input() playlistUid: string;
  @Input() resourceUri: string;
  @Input() groupUid: string;
  @Input() publisherUri: string;
  @Input() packageUri: string;
  @Input() pageUri: string;
  @Input() languageCode: string;

  existingUserAnswer: string;
  submitButtonEnabled: boolean;
  submitted = false;

  inputFocused: boolean;
  inputTextWords = 0;

  autoSaveInProgress = false;
  autoSaved = false;

  private userInput: string;
  private autoSaveSubscription: Subscription;
  private autoSaveSubject = new Subject<void>();

  constructor(
    private store: Store,
    private dialogService: DialogService,
    private translationService: TranslationService,
    private cd: ChangeDetectorRef,
  ) {
    this.autoSaveSubscription = this.autoSaveSubject
      .pipe(debounceTime(500)).subscribe(() => this.saveUserInput());
  }

  ngOnInit() {
    if (this.formData && (this.formData.content as TextBoxFormContent).userAnswer) {
      const content = this.formData.content as TextBoxFormContent;
      if (content.formIsActive) {
        this.inputFocused = true;
      }
      if (content.userAnswer.length > 0) {
        this.existingUserAnswer = JSON.parse(JSON.stringify(content.userAnswer));
        this.submitButtonEnabled = true;
        this.inputTextWords = this.countWords();
        this.submitted = this.userPlaylistSubmissionSummary?.submittedOn ? true : content.submitted;
      }
      this.checkSubmissionMode();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.userPlaylistSubmissionSummary && changes.userPlaylistSubmissionSummary.currentValue) {
      this.checkSubmissionMode();
    }
  }

  onInputFocus() {
    this.inputFocused = true;
  }

  deactivateForm() {
    this.inputFocused = false;
  }

  saveUserInput() {
    if (this.userInput && !!this.userInput.length) {
      const event: FormSavedEvent = {
        formUid: this.formData.uid,
        answer: this.userInput,
      };
      this.submitButtonEnabled = !!this.userInput;
      this.store.dispatch(new SaveFormAnswer(this.playlistUid, event, this.formData.uid)).toPromise().then(() => {
        this.autoSaveInProgress = false;
        this.autoSaved = true;
        this.cd.detectChanges();
      });
    }
  }

  submit() {
    this.dialogService.showConfirmDialog(
      this.translationService.getTranslation('dialog.title.confirmFormSubmit'),
      this.translationService
    ).then(confirmed => {
      if (confirmed) {
        this.deactivateForm();
        this.existingUserAnswer = this.userInput;
        this.checkSubmissionMode();
        this.store.dispatch(new SubmitForm(this.playlistUid, this.formData.uid)).toPromise().then(() => {
          this.autoSaveInProgress = false;
          this.submitted = true;
          timer(1000).subscribe(() => {
            this.store.dispatch(new PlaylistViewActions.RefreshUserPlaylistSubmissionSummary(this.playlistUid));
            this.store.dispatch(
              new RefreshLearnerViewCardStatus(
                this.playlistUri,
                this.resourceUri,
                this.groupUid,
                this.publisherUri,
                this.packageUri,
                this.pageUri,
                this.languageCode,
              )
            );
          });
        });
      }
    });
  }

  onTextLengthUpdate(wordsNumber: number) {
    this.inputTextWords = wordsNumber;
  }

  onMediumEditorUpdate(userInput: string) {
    this.userInput = userInput;
    if (this.inputFocused) {
      if (this.hideTextFormButtons) {
        this.formData.content.userAnswer = userInput;
      } else {
        this.autoSaveInProgress = true;
        this.autoSaved = false;
        this.autoSaveSubject.next();
      }
    }
  }

  updateWords() {
    if (this.formData) {
      return this.formData.content.wordLimit - this.inputTextWords;
    }
    return 0;
  }

  getLocalDateTime(date: Date): string {
    return LocalTimeHelper.getLocalDateTime(date).toString();
  }

  get isSubmissionDisabled(): boolean {
    return !this.submitButtonEnabled;
  }

  get submittedAnswerContent() {
    return { content: this.existingUserAnswer, type: 'PARAGRAPH' };
  }

  private checkSubmissionMode(): void {
    if (ContentHelper.checkFormSubmissionStatus(this.userPlaylistSubmissionSummary)) {
      this.submitted = false;
    }
    this.cd.detectChanges();
  }

  private countWords(): number {
    if (this.existingUserAnswer.trim() === '<p><br></p>') {
      return 0
    }
    return this.existingUserAnswer.trim().split(/\S+/g).length;
  }
}
