/*
 * Copyright (C) 2023 - present by Potentially
 *
 * Please see distribution for license.
 */

import {
  AfterViewInit,
  Component, ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output, ViewChild
} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Select } from '@ngxs/store';
import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs';
import {
  CheckedMembersData,
  MemberEnrollmentType,
  Members,
  MembersSearchOrder,
  UserSearch
} from 'src/app/shared/models/admin/members.model';
import { AdminMembersAction, MembersListColumns } from '../../model/members.model';
import { TranslationService } from 'src/app/shared/services/translation/translation.service';
import { EventCardsHelper } from 'src/app/shared/helpers/event-cards-helper';
import { ResourceAdminState } from '../../../../../resource/store/admin/resource-admin.state';
import { Resource, UserPlaylistSubmissionSummary } from '@app/app/shared/models';
import {
  AdminReviewersManagementComponent
} from '../../../../../reviews/components/view/playlist/admin-reviewers-management/admin-reviewers-management.component';
import { LanguageCodeHelper } from '@app/app/shared/helpers/language-code-helper';
import { MatDialog } from '@angular/material/dialog';
import { UserAuthState } from '@app/app/user-auth/store/user-auth.state';
import { PlaylistViewState } from '../../../../../playlist/store/view/playlist-view.state';
import { DialogService } from '@app/app/shared/helpers/dialog/dialog.service';
import { REVIEWS_DATA_SERVICE, ReviewsDataService } from '../../../../../reviews/services/data.service';
import {
  TOAST_NOTIFICATION_SERVICE,
  ToastService
} from '@app/app/shared/services/toast-notifications/toast-service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ptl-admin-member-table',
  templateUrl: './admin-member-table.component.html',
  styleUrls: ['./admin-member-table.component.scss'],
})
export class AdminMemberTableComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() columns: MembersListColumns[];
  @Input() isPlaylistPage: boolean;
  @Input() isCardPage: boolean;
  @Input() isEvent: boolean;
  @Input() members: Members[];
  @Input() membersTotalCount: number;
  @Input() isWaitingListEnabled: boolean;
  @Input() isWaitingListOperationDisabled: boolean;
  @Input() currentPage = 0;
  @Input() pageSize = 10;
  @Input() paginationLength = 0;
  @Input() isLoaderActive = false;
  @Input() orderInput: MembersSearchOrder;
  @Input() expanded = false;

  @ViewChild('membersListContainer') membersListContainer!: ElementRef<HTMLElement>;
  @ViewChild('membersListHeader') membersHeaderContainer!: ElementRef<HTMLElement>;
  @ViewChild('membersListMain') membersListMain!: ElementRef<HTMLElement>;
  @ViewChild('sliderContainer') sliderContainer!: ElementRef<HTMLElement>;
  @ViewChild('sliderContent') sliderContent!: ElementRef<HTMLElement>;

  @Output() orderChanged = new EventEmitter<{ order: MembersSearchOrder; page: number; pageSize: number }>();
  @Output() membersChecked = new EventEmitter<CheckedMembersData[]>();
  @Output() memberActionTriggered = new EventEmitter<AdminMembersAction>();
  @Output() currentPageChanged = new EventEmitter<number>();
  @Output() pageSizeChanged = new EventEmitter<number>();
  @Output() openUserRecord = new EventEmitter<string>();

  @Select(UserAuthState.canManageReviewsPrivileges)
  canManageReviewsPrivileges$: Observable<boolean>;

  @Select(UserAuthState.enrollmentFeatureFlag)
  enrollmentFeatureFlag$: Observable<boolean>;

  @Select(PlaylistViewState.userPlaylistSubmissionSummary)
  userPlaylistSubmissionSummary$: Observable<UserPlaylistSubmissionSummary>;

  @Select(PlaylistViewState.playlistHasTicketsRegistration)
  playlistHastTicketsRegistration$: Observable<boolean>;

  @Select(ResourceAdminState.resource)
  private resource$: Observable<Resource>;

  STATIC_CELLS_HEIGHT = 16;
  allUsersChecked = false;
  defaultOrderBy: string;
  order: 'ASC' | 'DESC';
  updateTimeout: number;
  loadingMembers: boolean;
  checkedMembers: CheckedMembersData[] = [];
  willAttendOptions = ['Yes', 'No'];
  enrollOption: MemberEnrollmentType[] = ['Registered', 'Canceled'];
  addSomeoneManuallySearchEnabled = false;
  searchedMembers: UserSearch[];
  addUserManuallyDisabledText: string;
  cardPublished = false;
  hoveredUserUid: string;
  disabledWaitingListText: string;

  stopScroll$ = new BehaviorSubject('');
  private currentScrollItem: string;
  private contentSubscription: Subscription;
  private subscriptionEnd$ = new EventEmitter<void>();

  constructor(
    private dialog: MatDialog,
    private translationService: TranslationService,
    private dialogService: DialogService,
    @Inject(REVIEWS_DATA_SERVICE) private reviewsDataService: ReviewsDataService,
    @Inject(TOAST_NOTIFICATION_SERVICE) private toastService: ToastService
  ) {
  }

  ngOnInit() {
    this.setTimeOutBasedOnBrowser();

    if (this.isWaitingListEnabled) {
      this.willAttendOptions.push('Waiting list');
    }

    if (this.members) {
      this.checkedMembers = this.members.filter(member => member.checked).map((item) => {
        return {
          _id: item._id,
          reviewUid: item.review?._id
        }
      });
    }

    if (this.isCardPage) {
      this.contentSubscription = this.resource$.subscribe((value) => {
        if (value.status === 'PUBLISHED') {
          this.cardPublished = true;
          this.addUserManuallyDisabledText = undefined;
        } else {
          this.addSomeoneManuallySearchEnabled = false;
          this.cardPublished = false;
          this.addUserManuallyDisabledText =
            this.translationService.getTranslation('membersNew.list.table.addUserManuallyDisabledText');
        }
      });
    }

    this.updateWaitingListDisabledText();

    if (this.orderInput) {
      this.order = this.orderInput.direction;
      this.defaultOrderBy = this.orderInput.fieldName;
    }

    this.updateAllUsersChecked();
  }

  ngAfterViewInit() {
    this.updateAllUsersChecked();
    setTimeout(() => {
      this.updateHeight();
    }, this.updateTimeout);

    this.setUpScrollBehaviour();
  }

  ngOnChanges() {
    this.updateAllUsersChecked();
    setTimeout(() => {
      this.updateHeight();
    }, this.updateTimeout);
    this.updateWaitingListDisabledText();

    setTimeout(() => {
      this.calculateSliderSizes();
    }, 500);
  }

  ngOnDestroy() {
    this.contentSubscription?.unsubscribe();
    this.subscriptionEnd$?.emit();
  }

  openUserRecordByUid(memberUid: string): void {
    this.openUserRecord.emit(memberUid);
  }

  setHoveredMember(memberUid: string): void {
    this.hoveredUserUid = memberUid;
  }

  resetHoveredMember(): void {
    this.hoveredUserUid = undefined;
  }

  clearCheckedMembers(): void {
    this.checkedMembers = [];
  }

  updateAllUsersChecked(): void {
    if (this.members && this.members.length > 0) {
      for (const member of this.members) {
        if (!this.checkedMembers.find(m => m._id === member._id)) {
          this.allUsersChecked = false;
          return;
        }
      }
      this.allUsersChecked = true;
      return;
    }
    this.allUsersChecked = false;
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    this.currentPageChanged.emit(page);
    this.allUsersChecked = false;
    this.orderChanged.emit(this.getOrderingData());
  }

  onSizeChange(size: number): void {
    this.pageSize = size;
    this.currentPage = 0;
    this.currentPageChanged.emit(0);
    this.pageSizeChanged.emit(this.pageSize);
    this.orderChanged.emit(this.getOrderingData());
  }

  changeListOrdering(orderBy: string): void {
    if (orderBy === this.defaultOrderBy) {
      this.order = this.order === 'DESC' ? 'ASC' : 'DESC';
    } else {
      this.order = (orderBy === 'firstName' || orderBy === 'lastName') ? 'ASC' : 'DESC';
    }
    this.defaultOrderBy = orderBy;
    this.orderChanged.emit(this.getOrderingData());
  }

  isColumnEnabled(columnName: MembersListColumns): boolean {
    return this.columns.includes(columnName);
  }

  openManageReviewersModal(reviewUid: string, event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    const dialogRef = this.dialog.open(AdminReviewersManagementComponent, {
      width: '37.5rem',
      disableClose: true,
      maxHeight: '80vh',
      position: {
        top: '10vh',
      },
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      panelClass: ['ptl-mat-dialog'],
      backdropClass: 'dialog-backdrop',
      data: {
        reviewUid: reviewUid
      },
    });

    const dialogSubscription = dialogRef.afterClosed().subscribe(() => {
      dialogSubscription.unsubscribe();
    });
  }

  openUndoReviewModal(reviewUid: string, event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    const element = document.createElement('div');
    element.innerHTML = this.translationService.getTranslation('dialog.title.undoReview');

    this.dialogService.showConfirmDialog(
      element,
      this.translationService
    ).then(confirmed => {
      if (confirmed) {
        this.reviewsDataService.undoReview(reviewUid).subscribe(( { isSuccess, value, error }  ) => {
          if (isSuccess) {
            this.toastService.showSuccess(this.translationService.getTranslation('successes.successReviewUndone'));
          } else {
            this.toastService.showFail(error);
          }
        })
      }
    });
  }

  onAllCheckboxChange(): void {
    for (const member of this.members) {
      member.checked = this.allUsersChecked;
      if (this.allUsersChecked) {
        if (!this.checkedMembers.find(m => m._id === member._id)) {
          this.checkedMembers.push({
            _id: member._id,
            reviewUid: member.review?._id
          });
        }
      } else {
        const index = this.checkedMembers.findIndex(m => m._id === member._id);
        if (index !== -1) {
          this.checkedMembers.splice(index, 1);
        }
      }
    }
    this.updateAllUsersChecked();
    this.membersChecked.emit(this.checkedMembers);
  }

  onCheckboxChange(userUid: string): void {
    const member = this.members.find(m => m._id === userUid);
    if (!member) {
      return;
    }

    if (!this.checkedMembers.find(m => m._id === userUid)) {
      this.checkedMembers.push({
        _id: member._id,
        reviewUid: member.review?._id
      });
      member.checked = true;
    } else {
      const index = this.checkedMembers.findIndex(m => m._id === userUid);
      if (index !== -1) {
        this.checkedMembers.splice(index, 1);
      }
      member.checked = false;
    }
    this.updateAllUsersChecked();
    this.membersChecked.emit(this.checkedMembers);
  }

  getMemberWillAttendField(member: Members): string {
    if(member.event) {
      if (member.event.participatedOn || member.event.registeredOn) {
        return 'Yes';
      } else if (member.event.waitlistedOn) {
        return 'Waiting list';
      }
    }
    return 'No';
  }

  getMemberCompletionStatus(member: Members): 'IN_PROGRESS' | 'COMPLETED' | 'NOT_STARTED' {
    if(member.cardStatus === 'COMPLETED' || member.playlistStatus === 'COMPLETED') {
      return 'COMPLETED';
    } else if (member.cardStatus === 'IN_PROGRESS' || member.playlistStatus === 'IN_PROGRESS') {
      return 'IN_PROGRESS';
    }
    return 'NOT_STARTED';
  }

  getEnrollmentFieldNumber(member: Members): number {
    const enrollmentMemberState: MemberEnrollmentType = this.getEnrollmentField(member);
    return this.enrollOption.indexOf(enrollmentMemberState);
  }

  getEnrollmentField(member: Members): MemberEnrollmentType {
    return member.enrollment ? 'Registered' : 'Canceled';
  }

  getMemberWillAttendFieldNumber(member: Members): number {
    return this.willAttendOptions.indexOf(this.getMemberWillAttendField(member));
  }

  onWillAttendSelect(event: MatSelectChange, userUid: string): void {
    switch (this.willAttendOptions[event.value]) {
      case 'Yes':
        this.memberActionTriggered.emit({type: 'WILL_ATTEND', memberIds: [userUid], allMembersRefresh: false});
        break;
      case 'No':
        this.memberActionTriggered.emit({type: 'WILL_NOT_ATTEND', memberIds: [userUid], allMembersRefresh: false});
        break;
      case 'Waiting list':
        this.memberActionTriggered.emit({type: 'WAITING_LIST', memberIds: [userUid], allMembersRefresh: false});
        break;
    }
  }

  onEnrolSelect(event: MatSelectChange, userUid: string): void {
    switch (this.enrollOption[event.value]) {
      case 'Registered':
        this.memberActionTriggered.emit({type: 'REGISTERED', memberIds: [userUid], allMembersRefresh: false});
        break;
      case 'Canceled':
        this.memberActionTriggered.emit({type: 'CANCELED', memberIds: [userUid], allMembersRefresh: false});
        break;
    }
  }

  getEventResponseDate(member: Members): Date {
    if(member.event) {
      if (member.event.waitlistedOn) {
        return member.event.waitlistedOn;
      }

      if (member.event.registeredOn) {
        return member.event.registeredOn;
      }

      if (member.event.participatedOn) {
        return member.event.participatedOn;
      }
    }

    return undefined;
  }

  getMemberFormattedParticipationTime(member: Members): string {
    if (member.event?.checkInMethod && member.event?.participatedOn) {
      return EventCardsHelper.convertParticipationStatusTimestampToLocale(member.event.participatedOn.toString());
    }
    return undefined;
  }

  hasMemberAttendedEvent(member: Members): boolean {
    return !!(member.event && member.event.participatedOn);
  }

  onHasAttendedEvent(event: MatSelectChange, member: Members): void {
    if (event.value) {
      this.memberActionTriggered.emit({ type: 'HAVE_ATTENDED', memberIds: [member._id], allMembersRefresh: false })
    } else {
      this.memberActionTriggered.emit({ type: 'WILL_NOT_ATTEND', memberIds: [member._id], allMembersRefresh: false })
    }
  }

  onMemberSelected(event: AdminMembersAction): void {
    this.memberActionTriggered.emit(event);
  }

  updateHeight(): void {
    const rows = document.getElementsByTagName('TR');
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      let height = 0;
      const roleCell = row.getElementsByClassName('role-cell')[0] as HTMLElement;
      if (roleCell) {
        height = roleCell.offsetHeight;
      }
      const checkboxCell = row.getElementsByClassName('checkbox-cell')[0] as HTMLElement;
      const externalIdCell = row.getElementsByClassName('external-id')[0] as HTMLElement;
      const firstNameCell = row.getElementsByClassName('first-name')[0] as HTMLElement;
      const lastNameCell = row.getElementsByClassName('last-name')[0] as HTMLElement;

      if(checkboxCell) {
        checkboxCell.style.height = (height / this.STATIC_CELLS_HEIGHT) + 'rem';
        checkboxCell.style.visibility = 'visible';
      }
      if(externalIdCell) {
        externalIdCell.style.height = (height / this.STATIC_CELLS_HEIGHT) + 'rem';
        externalIdCell.style.visibility = 'visible';
      }
      if (firstNameCell) {
        firstNameCell.style.height = (height / this.STATIC_CELLS_HEIGHT) + 'rem';
        firstNameCell.style.visibility = 'visible';
      }
      if (lastNameCell) {
        lastNameCell.style.height = (height / this.STATIC_CELLS_HEIGHT) + 'rem';
        lastNameCell.style.visibility = 'visible';
      }
    }
  }

  checkOverflow(element): boolean {
    return element.offsetHeight < element.scrollHeight ||
           element.offsetWidth < element.scrollWidth;
  }

  showExpandedContent(member: Members, column: MembersListColumns): string {
    switch (column) {
      case 'ROLES': return member?.roles?.filter(role => role).join(', ');
      case 'GROUPS': return member?.groups?.filter(group => group.title).map(group => group.title).join(', ');
    }
    return undefined;
  }

  calculateSliderSizes(from: 'header' | 'body' | null = null): void {
    const elements = this.getSliderElements();

    if (!elements) {
      return;
    }

    const {membersListContainer, membersHeaderContainer, sliderContainer} = elements;
    const maxScroll = elements.sliderContainer.scrollWidth - elements.sliderContainer.clientWidth;
    const scrollPercentage = (elements.sliderContainer.scrollLeft / maxScroll) * 100;

    if (from === null || from === 'body') {
      const maxMainScroll = membersListContainer.scrollWidth - membersListContainer.clientWidth;
      membersListContainer.scrollLeft = (maxMainScroll / 100) * scrollPercentage;
    }

    if (from === null || from === 'header') {
      const maxMainScroll = membersHeaderContainer.scrollWidth - membersHeaderContainer.clientWidth;
      membersHeaderContainer.scrollLeft = (maxMainScroll / 100) * scrollPercentage;
    }
  }

  onCustomSliderScroll(): void {
    if (this.currentScrollItem && this.currentScrollItem !== 'slider') {
      return;
    }

    this.stopScroll$.next('slider');
    this.calculateSliderSizes(null);
  }

  onHeaderContainerScroll(): void {
    if (this.currentScrollItem && this.currentScrollItem !== 'header') {
      return;
    }

    this.stopScroll$.next('header');
    this.updateSliderPosition(this.membersHeaderContainer?.nativeElement, this.sliderContainer?.nativeElement);
    this.calculateSliderSizes('body');
  }

  onBodyContainerScroll(): void {
    if (this.currentScrollItem && this.currentScrollItem !== 'body') {
      return;
    }

    this.stopScroll$.next('body');
    this.updateSliderPosition(this.membersListContainer?.nativeElement, this.sliderContainer?.nativeElement);
    this.calculateSliderSizes('header');
  }

  setCustomScrollSizes() {
    const elements = this.getSliderElements();

    if (!elements) {
      return;
    }

    const {
      membersListContainer,
      membersListContent,
      sliderContainer,
      sliderContent
    } = elements;

    this.setCustomScrollWidth(membersListContainer, membersListContent, sliderContainer, sliderContent);

    this.updateSliderPosition(membersListContainer, sliderContainer);
  }

  private getOrderingData(): { order: MembersSearchOrder; page: number; pageSize: number } {
    return {
      order: {
        fieldName: this.defaultOrderBy,
        direction: this.order,
      },
      page: this.currentPage,
      pageSize: this.pageSize,
    }
  }

  private updateWaitingListDisabledText(): void {
    this.disabledWaitingListText = undefined;
    if (this.isWaitingListOperationDisabled) {
      this.disabledWaitingListText = this.translationService.getTranslation('membersNew.waitingListDisabledText')
    }
  }

  private setTimeOutBasedOnBrowser(): void {
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf('Firefox') > -1) {
      this.updateTimeout = 40;
    } else if (userAgent.indexOf('Chrome') > -1) {
      this.updateTimeout = 10;
    } else if (userAgent.indexOf('Safari') > -1) {
      this.updateTimeout = 10;
    } else {
      this.updateTimeout = 30;
    }
  }

  private setUpScrollBehaviour() {
    this.setCustomScrollSizes();
    this.addScrollEventListeners();
  }

  private setCustomScrollWidth(mainContentWrapper: HTMLElement,
                               mainContent: HTMLElement,
                               sliderContainer: HTMLElement,
                               sliderContent: HTMLElement): void {
    const diff = mainContent.clientWidth / mainContentWrapper.clientWidth;
    sliderContent.style.width = sliderContainer.clientWidth * diff + 'px';
  }

  private addScrollEventListeners(): void {
    this.stopScroll$.pipe(
      takeUntil(this.subscriptionEnd$)
    ).subscribe((value) => {
      this.currentScrollItem = value;

      const intervalId = setInterval(() => {
        this.currentScrollItem = '';
      }, 1000);

      return () => clearInterval(intervalId);
    });
  }

  private updateSliderPosition(mainContentWrapper: HTMLElement, sliderContainer: HTMLElement) {
    const maxMainScroll = mainContentWrapper.scrollWidth - mainContentWrapper.clientWidth;
    const scrollPercentage = (mainContentWrapper.scrollLeft / maxMainScroll) * 100;
    const maxSliderScroll = sliderContainer.scrollWidth - sliderContainer.clientWidth;
    sliderContainer.scrollLeft = (maxSliderScroll / 100) * scrollPercentage;
  }

  private getSliderElements(): {
    membersListContainer: HTMLElement;
    membersHeaderContainer: HTMLElement;
    membersListContent: HTMLElement;
    sliderContainer: HTMLElement;
    sliderContent: HTMLElement;
  } {
    const membersListContainer = this.membersListContainer?.nativeElement;
    const membersHeaderContainer = this.membersHeaderContainer?.nativeElement;
    const membersListContent = this.membersListMain?.nativeElement;
    const sliderContainer = this.sliderContainer?.nativeElement;
    const sliderContent = this.sliderContent?.nativeElement;

    if (!membersListContainer || !membersListContent || !sliderContainer || !sliderContent) {
      return null;
    }

    return {
      membersListContainer,
      membersHeaderContainer,
      membersListContent,
      sliderContainer,
      sliderContent
    };
  }
}
