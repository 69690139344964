/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { InjectionToken } from '@angular/core';
import { ObservableResult } from '../../../../../shared/store';
import { LinkableCardSummary } from '../../../../../shared/models';
import { Page } from '../../../../../shared/models/page';


/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
export const PLAYLIST_CARDS_DATA_SERVICE =
  new InjectionToken<PlaylistResourceCardsDataService>('[playlist-editor] PlaylistResourceCardsDataService');

/** The service resource member management operations. */
export interface PlaylistResourceCardsDataService {

  /**
   * Fetches all resources.
   */
  searchLinkableCards(page: number, size: number, headlineFragment: string): ObservableResult<Page<LinkableCardSummary>>;

}
