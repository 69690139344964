/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  DiagnosticQuestionOptionRequest, DiagnosticQuestionOptionResponse,
  DiagnosticQuestionOptionWithSelection,
  DiagnosticQuestionResponse, EditorContent,
  LanguageValue,
  Organization
} from '../../models';
import { ActivatedRoute } from '@angular/router';
import { LanguageCodeHelper } from '../../helpers/language-code-helper';
import { VersionHelper } from '../../helpers/version.helper';

@Component({
  selector: 'ptl-diagnostics-view-grid-question-type-new',
  templateUrl: './grid-question-type-new.component.html',
  styleUrls: ['./grid-question-type-new.component.scss'],
})
export class DiagnosticsViewGridQuestionTypeNewComponent implements OnInit {

  /** Receives the question to answer */
  @Input() question: DiagnosticQuestionResponse;
  @Input() answeredQuestions;
  @Input() disabled: boolean;
  @Input() currentLanguage: string;
  @Input() organization: Organization;

  /** Emits the answer when selected. */
  @Output() selectedAnswer = new EventEmitter<{
    uid: string;
    answer: DiagnosticQuestionOptionResponse[];
    question: DiagnosticQuestionResponse;
  }>();

  diagnosticUri: string = undefined;
  options: DiagnosticQuestionOptionWithSelection[] = [];
  isNoneOfTheseSelected: boolean;
  instruction: string;
  newVersionEnabled = VersionHelper.newVersionEnabled();
  private selectedItemsCount = 0;

  constructor(private activeRoute: ActivatedRoute) {
    this.diagnosticUri = activeRoute.snapshot.params['diagnosticUri'];
  }

  ngOnInit() {
    if (this.question) {
      this.instruction = LanguageCodeHelper
        .getDataByUserLanguageCode(this.question.instructions, this.organization, this.currentLanguage).value;
      this.options = [...this.question.options].map(option => ({ ...option, selected: false }));
      const selectedOptions =
        this.answeredQuestions[this.question.uid] ?
          this.answeredQuestions[this.question.uid] : this.answeredQuestions.selectedOptions ?
            this.answeredQuestions.selectedOptions : '';
      if (selectedOptions) {
        for (const selectedOption of selectedOptions) {
          for (const option of this.options) {
            if (selectedOption.optionUid === option.uid) {
              this.selectAnswer(option, true);
              break;
            }
          }
        }
      }
    }
  }

  selectAnswer(option: DiagnosticQuestionOptionWithSelection, override = false) {
    if (this.disabled && !override) {
      return;
    }

    if (option.deselectOthers || this.isNoneOfTheseSelected) {
      this.deselectOtherOptions(option, this.options);
      this.selectedAnswer.emit({ uid: this.question.uid, answer: this.options, question: this.question });
      return;
    }
    if (this.question.selectionLimit === 1) {
      for (const opt of this.options) {
        if (opt.uid === option.uid) {
          opt.selected = !opt.selected;
        } else {
          opt.selected = false;
        }
      }
    } else {
      option.selected = !option.selected;
      if (option.selected) {
        this.selectedItemsCount++;
      } else {
        this.selectedItemsCount--;
      }
      if (this.selectedItemsCount > this.question.selectionLimit) {
        option.selected = false;
        this.selectedItemsCount--;
        return;
      }
    }
    this.selectedAnswer.emit({ uid: this.question.uid, answer: this.options, question: this.question });
  }

  getTranslatedValue(values: LanguageValue[]) {
    return LanguageCodeHelper.getDataByUserLanguageCode(values, this.organization, this.currentLanguage).value;
  }

  getPreviewContent(): EditorContent[] {
    return [{ type: 'PARAGRAPH', content: this.instruction }];
  }

  private deselectOtherOptions(option: DiagnosticQuestionOptionRequest, options: DiagnosticQuestionOptionRequest[]) {
    for (const opt of options) {
      if (opt.uid === option.uid) {
        opt.selected = !opt.selected;
        this.isNoneOfTheseSelected = !this.isNoneOfTheseSelected;
        if (opt.selected) {
          this.selectedItemsCount = 1;
        } else {
          this.selectedItemsCount = 0;
        }
      } else {
        opt.selected = false;
      }
    }
  }
}
