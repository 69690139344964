<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ptl-side-nav-item
  [sideNavSection]="sideNavSection"
  (itemLinkClick)="onLinkClick($event)"
  (loadMore)="onLoadMore()"
  (expandToggled)="onExpandToggle($event)"
  (openWebLink)="onOpenWebLink($event)"></ptl-side-nav-item>
