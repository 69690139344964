/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Inject,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Form, TextBoxFormContent } from '../../../../models';
import { VersionHelper } from '../../../../../shared/helpers/version.helper';
import { TEXT_FORM_WORD_LIMIT } from '../../../../../shared/constants/constants';
import { Select } from '@ngxs/store';
import { ResourceAdminState } from '../../../../../page-modules/resource/store/admin/resource-admin.state';
import { Observable, Subscription, takeUntil } from 'rxjs';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { withLatestFrom } from 'rxjs/operators';
import { UserAuthState } from '../../../../../user-auth/store/user-auth.state';
import { Organization } from '../../../../../shared/models';
import { AI_ASSISTANT_SERVICE, AIAssistantSettings } from '../../../../services/assistant/ai-assistant.service';
import { ApiAiAssistantDataService } from '../../../../services/assistant/ai-assistant-api-data.service';
import { FormAIAssistantSettingsComponent } from './form-ai-assistant-settings/form-ai-assistant-settings.component';
import { MatDialog } from '@angular/material/dialog';
import { LanguageCodeHelper } from '../../../../../shared/helpers/language-code-helper';
import { FormAiSearchDialogComponent } from './form-ai-search-dialog/form-ai-search-dialog.component';

@Component({
	selector: 'ptl-form-textbox',
	templateUrl: './form-textbox.component.html',
	styleUrls: ['../form-new.component.scss', '../form.component.scss', './form-textbox.component.scss']
})
export class FormTextboxComponent implements OnInit, OnChanges, OnDestroy {

	/** Receives the input data object */
	private _form: Form;
	private _canRemoveItem: boolean | undefined;
	private _position: number | string | undefined;

	@Input()
	set form( value: Form ) {
		if ( typeof value === 'string' ) {
			this._form = JSON.parse(decodeURIComponent(value));
		} else {
			this._form = value;
		}
	}

	get form() {
		return this._form;
	}

	/** Position in the Form sections */
	@Input()
	set position( value: number | string | undefined ) {
		if ( typeof value === 'string' ) {
			this._position = JSON.parse(decodeURIComponent(value));
		} else {
			this._position = value;
		}
	}

	get position() {
		return this._position;
	}

	@Input()
	set canRemoveItem( value ) {
		if ( typeof value === 'string' ) {
			this._canRemoveItem = JSON.parse(decodeURIComponent(value));
		} else {
			this._canRemoveItem = value;
		}
	}

	get canRemoveItem() {
		return this._canRemoveItem;
	}

	/** Emits content data on saveForm() */
	@Output() formElementAdded = new EventEmitter<Form>();

	/** Emits removing event of this form with index */
	@Output() formElementRemoved = new EventEmitter<void>();

	@ViewChild('textBoxFormElement', { static: false }) private textBoxFormElement: ElementRef;
	@ViewChild('formAiAssistantSettings', { static: false }) private formAiAssistantSettings: FormAIAssistantSettingsComponent;

	@Select(ResourceAdminState.contentStateChanged)
	contentStateChanged$: Observable<boolean>;

	@Select(ResourceAdminState.resourceUid)
	resourceUid$: Observable<string>;

	@Select(UserAuthState.organizationDetails)
	private organizationData$: Observable<Organization>;

	@Select(UserAuthState.aiAssistantFeatureFlag)
	private aiAssistantFeatureFlag$: Observable<boolean>;

	textboxForm: FormGroup;
	expanded = true;
	focused = false;
	hasError = false;
	saveInProgress = false;
	newVersionEnabled = VersionHelper.newVersionEnabled();
	aiAssistantEnabled = false;
	isTemplateSettingsEnabled = false;
	aiAssistantFeatureEnabled = false;
	aiAssistantSettingsUid: string;
	aiAssistantData: AIAssistantSettings;
	isTemplate: boolean;
	saveButtonPressed: boolean;
	updatedAssistantSettings: AIAssistantSettings;
	isDialogOpen = false;
	cardUid: string;

	private subscriptionEnd$ = new EventEmitter<void>();
	private resourceSubscriptionEnd$ = new EventEmitter<void>();
	private aiFeatureFlagSubscription: Subscription;
	private assistantUpdateSubscription: Subscription;
	private assistantCreateSubscription: Subscription;

	constructor(
		private fb: FormBuilder,
		private dialog: MatDialog,
		private cdr: ChangeDetectorRef,
		@Inject(AI_ASSISTANT_SERVICE) private aiAssistantService: ApiAiAssistantDataService ) {
		this.textboxForm = this.fb.group({
			title: ['', [Validators.required]],
			wordLimit: [TEXT_FORM_WORD_LIMIT, [Validators.required]],
			answerType: ['RICH', [Validators.required]],
			aiAssistantEnabled: [false],
			aiAssistantSettingsUid: [null]
		});
		this.aiFeatureFlagSubscription = this.aiAssistantFeatureFlag$.pipe(
			withLatestFrom(this.organizationData$)
		).subscribe(( [flagEnabled] ) => {
			this.aiAssistantFeatureEnabled = flagEnabled;
		});
	}

	ngOnChanges( changes: SimpleChanges ): void {
		if ( this.form && !!this.form.uid ) {
			this.setFormContent(this.form.content as TextBoxFormContent, this.form.newAddedForm);
		}
	}

	ngOnInit() {
		const content = this.form ? this.form.content as TextBoxFormContent : null;
		this.isTemplateSettingsEnabled = !!content?.aiAssistantSettingsUid;

		if ( this.form && (!!this.form.uid || !!content?.title) ) {
			this.setFormContent(content, this.form.newAddedForm);
		}

		if ( this.form && !this.form.uid && !content?.title ) {
			setTimeout(() => {
				(this.textBoxFormElement.nativeElement as HTMLElement)?.focus();
			}, 100);
		}

		this.contentStateChanged$.pipe(takeUntil(this.subscriptionEnd$)).subscribe(() => {
			this.saveInProgress = false;
		});

		this.resourceUid$.pipe(
			takeUntil(this.resourceSubscriptionEnd$)
		).subscribe(( resourceUid ) => {
			if ( resourceUid ) {
				this.cardUid = resourceUid;
				if ( content?.aiAssistantSettingsUid && this.cardUid ) {
					this.getAiAssistantData(this.cardUid, content.aiAssistantSettingsUid);
				}
			}
		});
	}

	ngOnDestroy() {
		this.subscriptionEnd$?.emit();
		this.resourceSubscriptionEnd$?.emit();
		this.aiFeatureFlagSubscription?.unsubscribe();
		this.assistantCreateSubscription?.unsubscribe();
		this.assistantUpdateSubscription?.unsubscribe();
	}

	saveForm() {
		this.saveButtonPressed = true;
		this.hasError = false;

		if ( !this.cardUid || !this.aiAssistantEnabled ) {
			this.proceedFormSave();
			return;
		}

		const aiAssistantModel = this.formAiAssistantSettings?.getValidAssistantSettings() || this.updatedAssistantSettings;

		if ( !aiAssistantModel ) {
			return;
		}

		if ( this.aiAssistantSettingsUid ) {
			this.assistantUpdateSubscription = this.aiAssistantService
			.updateAssistantSettings(this.cardUid, this.aiAssistantSettingsUid, aiAssistantModel)
			.subscribe(( { isSuccess, value } ) => {
				if ( isSuccess && value ) {
					this.updatedAssistantSettings = value;
				}
				this.proceedFormSave();
			});
			return;
		}

		this.assistantCreateSubscription = this.aiAssistantService
		.createAssistantSettings(this.cardUid,
			aiAssistantModel)
		.subscribe(( { isSuccess, value } ) => {
			if ( value ) {
				const uid = value._id;
				this.updatedAssistantSettings = value;
				this.onAssistantSettingsCreated(uid);
				this.aiAssistantSettingsUid = uid;
			}
			this.proceedFormSave();
		});
	}

	expandForm() {
		this.expanded = true;
	}

	collapseForm( event: PointerEvent ) {
		event.stopPropagation();
		this.expanded = false;
	}

	removeForm() {
		this.formElementRemoved.emit();

		if ( !this.isTemplate && this.aiAssistantSettingsUid ) {
			this.aiAssistantService.deleteAssistantSettings(this.cardUid, this.aiAssistantSettingsUid).subscribe();
		}
	}

	openAiSettings() {
		this.isTemplateSettingsEnabled = true;
		this.aiAssistantData = null;
	}

	onAiAssistantToggle( event: MatSlideToggleChange ) {
		this.aiAssistantEnabled = event.checked;
		this.textboxForm.patchValue({
			aiAssistantEnabled: this.aiAssistantEnabled
		});
	}

	onAssistantSettingsCreated( assistantId: string ) {
		this.textboxForm.patchValue({
			aiAssistantSettingsUid: assistantId
		});
	}

	formChanged( event: null | string ) {
		this.aiAssistantSettingsUid = event;
	}

	saveAsTemplate( event: boolean ) {
		this.isTemplate = event;
	}

	openAiTemplatesDialog() {
		this.isDialogOpen = true;
		this.isTemplateSettingsEnabled = false;
		if ( this.dialog ) {
			this.dialog.closeAll();
		}

		let dialogRef = this.dialog.open(FormAiSearchDialogComponent, {
			width: '90vw',
			maxWidth: '60rem',
			maxHeight: '80vh',
			position: {
				top: '10vh',
				bottom: '10vh'
			},
			direction: LanguageCodeHelper.getBodyLanguageDir(),
			panelClass: ['page-publish-dialog', 'ptl-mat-dialog', 'ptl-mat-dialog-without-padding'],
			backdropClass: 'dialog-backdrop',
			autoFocus: false,
			data: {
				cardUid: this.cardUid
			}
		});

		dialogRef.afterClosed()
		.pipe(takeUntil(this.subscriptionEnd$))
		.subscribe(( dialogData ) => {
			this.updatedAssistantSettings = null;
			if ( dialogData?.assistantSettingsUid ) {
				this.aiAssistantSettingsUid = dialogData.assistantSettingsUid;
				this.getAiAssistantData(this.cardUid, this.aiAssistantSettingsUid);
			}
			this.isDialogOpen = false;
			this.isTemplateSettingsEnabled = true;
			dialogRef = null;
		});
	}

	private getAiAssistantData( cardUid: string, aiAssistantSettingsUid: string ) {
		this.aiAssistantService.getAssistantSettingsById(cardUid, aiAssistantSettingsUid)
		.subscribe(( { isSuccess, value } ) => {
			if ( !value ) {
				return;
			}
			this.aiAssistantData = value;
			this.aiAssistantSettingsUid = value?._id;
			this.isTemplate = value?.isTemplate || false;
			this.textboxForm.patchValue({
				aiAssistantSettingsUid: value._id
			});
		});
	}

	private setFormContent( content: TextBoxFormContent, newAddedForm: boolean ) {
		this.expanded = newAddedForm ?? false;
		this.aiAssistantEnabled = content.aiAssistantEnabled ? content.aiAssistantEnabled : false;
		this.aiAssistantSettingsUid = content.aiAssistantSettingsUid ? content.aiAssistantSettingsUid : null;
		this.textboxForm.setValue({
			title: content.title,
			wordLimit: content.wordLimit,
			aiAssistantEnabled: this.aiAssistantEnabled,
			aiAssistantSettingsUid: content.aiAssistantSettingsUid ? content.aiAssistantSettingsUid : null,
			answerType: 'RICH'
		});
	}

	private proceedFormSave() {
		if ( this.textboxForm.valid ) {
			this.expanded = false;
			this.outputData();
			this.saveInProgress = true;
		} else {
			this.hasError = true;
		}
	}

	private outputData() {
		const outputData: Form = {
			...this.form,
			newAddedForm: false,
			content: {
				...this.textboxForm.value,
				type: 'TEXTBOX'
			} as TextBoxFormContent
		};

		this.formElementAdded.emit(outputData);
	}
}

