/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PlaylistCardShort, SideNavSection } from '../../models';
import { Store } from '@ngxs/store';
import { OpenWebLink } from '../../../page-modules/playlist/store/view/playlist-view.state.actions';

@Component({
  selector: 'ptl-side-nav-section',
  templateUrl: './side-nav-section.component.html',
  styleUrls: ['./side-nav-section.component.scss'],
})
export class SideNavSectionComponent {
  /** The side nav section to display. */
  @Input() sideNavSection!: SideNavSection;

  @Output() itemLinkClick = new EventEmitter<string>();
  @Output() loadMore = new EventEmitter<void>();
  @Output() expandToggled = new EventEmitter<boolean>();

  constructor(private store: Store) {
  }

  onLinkClick(event: string) {
    this.itemLinkClick.emit(event);
  }

  onLoadMore() {
    this.loadMore.emit();
  }

  onExpandToggle($event: boolean) {
    this.expandToggled.emit($event);
  }

  onOpenWebLink(card: PlaylistCardShort) {
    this.store.dispatch(new OpenWebLink(card));
  }
}
