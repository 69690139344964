import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  BooleanFilter,
  BooleanQueryRequest,
  BooleanQueryType,
  ResourcesStatusRequest
} from '../../../../../../../shared/models/admin/boolean-filters.model';
import { TranslationService } from '../../../../../../../shared/services/translation/translation.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'ptl-resources-member-filter',
  templateUrl: './resources-member-filter.component.html',
  styleUrls: ['./resources-member-filter.component.scss'],
})
export class ResourcesMemberFilterComponent implements OnInit {

  @Output() setResourcesFilter = new EventEmitter<BooleanFilter>();
  @Output() cancelResourcesFilter = new EventEmitter<void>();

  selectedResourceStatus: 'COMPLETED' | 'STARTED' = 'COMPLETED';
  resourceStatuses: { value: string; title: string }[] = [];

  selectedAmountOperator: 'AT_MOST' | 'AT_LEAST' = 'AT_LEAST';
  amountOperators: { value: string; title: string }[] = [];


  selectedResourceType: 'PLAYLISTS' | 'CARDS' = 'PLAYLISTS';
  resourceTypes: { value: string; title: string }[] = [];

  resourcesNumber = 0;

  noNumber = false;

  constructor(
    private translationService: TranslationService,
  ) {
  }

  ngOnInit() {
    this.resourceStatuses = [
      { value: 'COMPLETED', title: this.translationService.getTranslation('members.filter.resources.completed')},
      { value: 'STARTED', title: this.translationService.getTranslation('members.filter.resources.started')}
    ];
    this.resourceTypes = [
      { value: 'PLAYLISTS', title: this.translationService.getTranslation('members.filter.resources.playlists')},
      { value: 'CARDS', title: this.translationService.getTranslation('members.filter.resources.cards')}
    ];
    this.amountOperators = [
      { value: 'AT_MOST', title: this.translationService.getTranslation('members.filter.resources.atMost')},
      { value: 'AT_LEAST', title: this.translationService.getTranslation('members.filter.resources.atLeast')}
    ];
  }

  onResourceStatusChange(event: MatSelectChange) {
    this.selectedResourceStatus = event.value;
  }

  onResourceTypeChange(event: MatSelectChange) {
    this.selectedResourceType = event.value;
  }

  onAmountOperatorChange(event: MatSelectChange) {
    this.selectedAmountOperator = event.value
  }

  onSave() {
    if (isNaN(Number(this.resourcesNumber))) {
      this.noNumber = true;
      return;
    }
    this.noNumber = false;
    const result = {
      request: this.getRequest(),
      message: this.getMessage()
    };

    this.setResourcesFilter.emit(result);
  }

  onCancel() {
    this.cancelResourcesFilter.emit();
  }

  private getMessage() {
    let message = '';

    if (this.selectedResourceStatus === 'COMPLETED') {
      message += this.translationService.getTranslation('members.filter.summary.completed');
    } else {
      message += this.translationService.getTranslation('members.filter.summary.started');
    }

    switch(this.selectedAmountOperator) {
      case 'AT_LEAST':
        message += ' ' + this.translationService.getTranslation('members.filter.summary.atLeast');
        break;
      case 'AT_MOST':
        message += ' ' + this.translationService.getTranslation('members.filter.summary.atMost');
        break;
      default:
        break;
    }

    message += ' ' + this.resourcesNumber;

    if (this.selectedResourceType === 'CARDS') {
      message += ' ' + this.translationService.getTranslation('members.filter.summary.cards');
    } else {
      message += ' ' + this.translationService.getTranslation('members.filter.summary.playlists');
    }

    return message;
  }

  private getRequest(): ResourcesStatusRequest {
    return {
      type: BooleanQueryType.RESOURCES_STATUS,
      resourcesType: this.selectedResourceType,
      operator: this.selectedAmountOperator,
      amount: this.resourcesNumber,
      status: this.selectedResourceStatus,
      required: false
    }
  }
}
