<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<mat-sidenav-container class="main main-view-sidenav-container" [hasBackdrop]="(isMobile$ | async)">

  <mat-sidenav
    (closed)="closeSidebarMenu()"
    class="main__side-nav"
    [mode]="(isMobile$ | async) ? 'over' : 'side'"
    [disableClose]="true"
    [fixedInViewport]="true"
    [style.top.rem]="(isMobile$ | async) ? 0 : fixedTopGap"
    [opened]="(mobileMenuExpanded$ | async)">

    <ptl-workspaces-sidebar *ogBetaFeature></ptl-workspaces-sidebar>

    <ptl-side-nav *ngIf="sideNavSections"
                  class="main-side-nav"
                  [sideNavSections]="sideNavSections"
                  [saveState]="true"
                  [showAddNewPage]="!isContentStorePage && !isFolioPage"
                  [showAddNewPageIndex]="3"
                  (itemLinkClick)="onLinkClick($event)"
                  (expandToggled)="onExpandToggle($event)"
                  (loadMore)="onLoadMore($event)"
    ></ptl-side-nav>
  </mat-sidenav>

  <mat-sidenav-content class="main__content" id="mainContent" tabindex="-1">
    <ng-content></ng-content>
  </mat-sidenav-content>

</mat-sidenav-container>
