<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div
  class="form-preview"
  role="form"
  tabindex="0"
  [attr.aria-label]="('translations.global.ariaLabels.checkboxFormTitle' | transloco: {
    title: formData?.content?.title,
    state: (submitted ? 'translations.global.ariaLabels.update' : 'translations.global.ariaLabels.markAsDone') | transloco
  })"
  [class.form-preview--saved]="submitted"
  [class.form-preview--requires-resubmission]="requiresResubmission">
  <div class="form-preview__top">
    <div role="heading" aria-level="2" class="form-preview__top-title">
      <div class="title medium bold">{{ formData?.content?.title }}</div>
      <div class="form-preview__top-icon-box">
        <div *ngIf="submitted" class="form-preview__top-icon non-editable" [matTooltip]="canNotEditReason">
          <mat-icon *ngIf="canEdit" aria-hidden="true">check</mat-icon>
          <svg *ngIf="!canEdit" class="svg" role="img"
               [attr.aria-label]="('translations.global.ariaLabels.icons.lock' | transloco)">
            <use ogSvgUnify="assets/sprite/sprite.svg#lock"></use>
          </svg>
        </div>
        <div *ngIf="!submitted && !canEdit" class="form-preview__top-icon non-saved non-editable"
             [matTooltip]="canNotEditReason">
          <svg class="svg" role="img" [attr.aria-label]="('translations.global.ariaLabels.icons.lock' | transloco)">
            <use ogSvgUnify="assets/sprite/sprite.svg#lock"></use>
          </svg>
        </div>
        <div *ngIf="canEdit" class="form-preview__top-icon editable">
          <svg class="svg" role="img" [attr.aria-label]="('translations.global.ariaLabels.icons.edit' | transloco)">
            <use ogSvgUnify="assets/sprite/sprite.svg#edit"></use>
          </svg>
        </div>
      </div>
    </div>
  </div>
  <div class="form-preview__checkbox">
    <mat-checkbox
      color="primary"
      [aria-label]="('translations.global.ariaLabels.checkForm' | transloco)"
      [(ngModel)]="isChecked"
      [disabled]="!canEdit"
      (change)="onCheckboxChange()" (keydown.enter)="onCheckboxChange()">
    </mat-checkbox>
    <div class="printable" [class.checked]="isChecked"></div>
  </div>
  <div class="form-preview__footer" *ngIf="canEdit">
    <button
      mat-stroked-button
      color="accent"
      class="form-preview__footer-submit uppercase"
      [title]="(submitted ? 'translations.cards.form.button.update' : 'translations.cards.form.button.markAsDone') | transloco"
      type="button"
      (click)="submit()"
      [disabled]="isSubmissionDisabled">
      <span *ngIf="requiresResubmission">{{ 'translations.cards.form.button.resubmit' | transloco }}</span>
      <span
        *ngIf="!requiresResubmission && !submitted">{{
          'translations.cards.form.button.markAsDone' | transloco
        }}</span>
      <span
        *ngIf="!requiresResubmission && submitted">{{
          'translations.cards.form.button.update' | transloco
        }}</span>
    </button>
  </div>
  <div class="form-preview-notes" *ngIf="formData?.content?.notes?.length">
    <div class="note-item" *ngFor="let note of formData?.content?.notes">
      <div class="note-item-inner">
        <div class="note-message small">
          <ptl-resource-preview class="thin-preview" [previewContent]="note.message">
          </ptl-resource-preview>
        </div>
      </div>
      <div class="note-added-by extra-small italic">
        {{ note.addedBy }}, {{ getLocalDateTime(note.addedOn) | date: 'dd MMM yyyy HH:mm' }}
      </div>
    </div>
  </div>
</div>
<ptl-form-update-footer
  [submitted]="submitted"
  [canEdit]="canEdit"
  [learnerFormAnswer]="learnerFormAnswer"
  [formType]="formData?.content?.type"
  [playlistSubmissionDeadline]="userPlaylistSubmissionSummary?.submissionDeadline">
</ptl-form-update-footer>
