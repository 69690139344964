<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="members-button__container">

  <div class="members-total__count-cell-container"
       [attr.aria-label]="('translations.global.ariaLabels.totalMembersFound' | transloco: {
         totalMembersCount: totalMembersCount,
         allMembersCount: allMembersCount
       })">
    <ng-container *ngIf="totalMembersCount !== allMembersCount">
      <span class="members-total__count-number">{{totalMembersCount}}</span>
      <span class="members-total__count-slash">{{'/ '}}</span>
    </ng-container>
    <span>{{ 'translations.members.label.totalMembersFound' | transloco :{membersCount: allMembersCount} }}</span>
  </div>
  <button
    class="members-icon-button"
    mat-icon-button
    [attr.aria-label]="('translations.global.ariaLabels.changeRoles' | transloco)"
    [matTooltip]="('translations.members.button.changeRoles' | transloco)"
    (click)="openSection('ROLE')"
    (keydown.enter)="openSection('ROLE')">
    <i class="ptl-icon-add-administrator"></i>
  </button>
  <button
    class="members-icon-button"
    mat-icon-button
    [attr.aria-label]="('translations.global.ariaLabels.changeGroups' | transloco)"
    [matTooltip]="('translations.members.button.changeGroups' | transloco)"
    (click)="openSection('GROUP')"
    (keydown.enter)="openSection('GROUP')">
    <i class="ptl-icon-add-user-group-man"></i>
  </button>
  <button
    mat-icon-button
    *ngIf="!isPlaylistPage && !isCardPage"
    [class.is_hidden]="(organizationData$ | async)?.type === 'FOLIO'"
    class="members-icon-button"
    [attr.aria-label]="('translations.global.ariaLabels.userAlumniUpload' | transloco)"
    [matTooltip]="('translations.members.button.uploadAlumni' | transloco)"
    (click)="openAlumniUploadCsv()"
    (keydown.enter)="openAlumniUploadCsv()">
    <i class="ptl-icon-upload"></i>
  </button>
  <button
    class="members-icon-button message-icon-button"
    mat-icon-button
    [attr.aria-label]="('translations.global.ariaLabels.sendMessage' | transloco)"
    [matTooltip]="('translations.members.button.sendMessage' | transloco)"
    [disabled]="!this.totalMembersCount"
    (click)="openMessageEditor()"
  >
    <i class="ptl-icon-new-message"></i>
  </button>
  <ptl-find-people
    [isPlaylistPage]="isPlaylistPage"
    [isCardPage]="isCardPage"
    [playlistUid]="playlistUid"
    [cardUid]="cardUid">
  </ptl-find-people>
  <mat-slide-toggle
    *ngIf="isCardPage && isAssessment"
    color="primary"
    class="distance-travel-toggle"
    [attr.aria-label]="('translations.global.ariaLabels.includeDistanceTravel' | transloco) + includeDistanceTravel ? ' ' + ('translations.global.ariaLabels.enabled' | transloco) : ' ' + ('translations.global.ariaLabels.disabled' | transloco)"
    [checked]="includeDistanceTravel"
    (change)="onDistanceTravelToggleChange($event)">
    <div class="distance-travel-toggle-title">{{ 'translations.members.distanceTravelled.includeDistanceTravelled' | transloco }}</div>
  </mat-slide-toggle>
  <button class="members-icon-button export-icon-button"
      mat-icon-button
      *ngIf="exportEnabled"
      [matMenuTriggerFor]="exportMenu"
      [attr.aria-label]="('translations.global.ariaLabels.export' | transloco)"
      [matTooltip]="('translations.members.button.export' | transloco)"
      [class.last-item]="!(isCardPage && isAssessment)">
    <i class="ptl-icon-download"></i>
    <mat-icon aria-hidden="true" class="arrow-down-icon">expand_more</mat-icon>
  </button>
  <mat-menu #exportMenu>
    <button
      mat-menu-item
      [attr.aria-label]="('translations.global.ariaLabels.exportMembersCsvFile' | transloco)"
      (click)="downloadCsv($event)">
      <mat-spinner class="loader-icon" *ngIf="exportStarted" [diameter]="20"></mat-spinner>
      <span>{{ 'translations.members.button.exportCsv' | transloco }}</span>
    </button>
    <mat-divider class="export-divider"></mat-divider>
    <button
      *ngIf="canViewExports"
      mat-menu-item
      (click)=openAdvancedExport()>
      {{ 'translations.advancedExport.title.main' | transloco }}
    </button>
  </mat-menu>
</div>
<div class="members-assign__container" *ngIf="groupSectionActive">
  <div class="members-assign__container-inner">
    <button
      mat-icon-button
      class="cancel-btn"
      [attr.aria-label]="('translations.global.ariaLabels.closeGroupsSection' | transloco)"
      (click)="hideSection()">
      <mat-icon aria-hidden="true">close</mat-icon>
    </button>

    <div class="members-assign__search-container">
      <form class="members-assign__search-form" (ngSubmit)="onInputSubmit('GROUP')">
        <input
          type="text"
          class="members-assign__search-form-input"
          (ngModelChange)="onInputChange('GROUP')"
          [formControl]="searchString"
          [attr.aria-label]="( 'translations.members.input.label.group' | transloco)"
          [placeholder]="('translations.members.input.label.group' | transloco)">
        <mat-spinner
          class="members-assign__search--spinner"
          *ngIf="searchInProgress"
          [diameter]="18">
        </mat-spinner>
      </form>
      <button mat-stroked-button
              class="save-btn uppercase"
              [attr.aria-label]="('translations.global.ariaLabels.addMembersToGroup' | transloco)"
              (click)="onMemberAddToGroup()">
        {{ 'translations.members.button.addToGroup' | transloco }}
      </button>
      <button mat-stroked-button
              class="save-btn uppercase"
              [attr.aria-label]="('translations.global.ariaLabels.removeMembersFromGroup' | transloco)"
              (click)="onMemberRemoveFromGroup()">
        {{ 'translations.members.button.removeFromGroup' | transloco }}
      </button>
      <div class="member-group__search-result" *ngIf="showSearchResult">
        <ul class="member-group__search-list" *ngIf="groups?.length">
          <li tabindex="0"
              (click)="onGroupSelect(group._id, group.title)"
              (keydown.enter)="onGroupSelect(group._id, group.title)"
              *ngFor="let group of groups">
            {{ group.title }}
          </li>
        </ul>
        <div class="member-group__search-empty" *ngIf="!groups?.length">
          {{ 'translations.global.search.label.noData' | transloco }}
        </div>
      </div>
    </div>

    <div class="selected-group" *ngIf="selectedGroupName">
      <span>{{ selectedGroupName }}</span>
    </div>

    <button
      (click)="onCreateNewGroup()"
      [attr.aria-label]="('translations.global.ariaLabels.createNewGroupAndAddMembers' | transloco)"
      mat-button
      class="add-new-group-button">
      {{ 'translations.members.button.saveAsNewGroup' | transloco }}
    </button>
  </div>
</div>

<div class="members-assign__container" *ngIf="roleSectionActive">
  <div class="members-assign__container-inner">
    <button
      mat-icon-button
      class="cancel-btn"
      [attr.aria-label]="('translations.global.ariaLabels.closeRolesSection' | transloco)"
      (click)="hideSection()">
      <mat-icon aria-hidden="true">close</mat-icon>
    </button>

    <div class="members-assign__search-container">
      <form class="members-assign__search-form" (ngSubmit)="onInputSubmit('ROLE')">
        <input
          type="text"
          class="members-assign__search-form-input"
          (ngModelChange)="onInputChange('ROLE')"
          [formControl]="searchString"
          [attr.aria-label]="('translations.members.input.label.role' | transloco)"
          [placeholder]="('translations.members.input.label.role' | transloco)">
        <mat-spinner
          class="members-assign__search--spinner"
          *ngIf="searchInProgress"
          [diameter]="18">
        </mat-spinner>
      </form>
      <button mat-stroked-button
              class="save-btn uppercase"
              [attr.aria-label]="('translations.global.ariaLabels.addMembersToRole' | transloco)"
              (click)="onMemberAddToRole()">
        {{ 'translations.members.button.addToRole' | transloco }}
      </button>
      <button mat-stroked-button
              class="save-btn uppercase"
              [attr.aria-label]="('translations.global.ariaLabels.removeMembersFromRole' | transloco)"
              (click)="onMemberRemoveFromRole()">
        {{ 'translations.members.button.removeFromRole' | transloco }}
      </button>
      <div class="member-group__search-result" *ngIf="showSearchResult">
        <ul class="member-group__search-list" *ngIf="roles?.length">
          <li tabindex="0"
              (click)="onRoleSelect(role._id, role.name[0].value)"
              (keydown.enter)="onRoleSelect(role._id, role.name[0].value)"
              *ngFor="let role of roles">
            {{ role.name[0].value }}
          </li>
        </ul>
        <div class="member-group__search-empty" *ngIf="!roles?.length">
          {{ 'translations.global.search.label.noData' | transloco }}
        </div>
      </div>
    </div>

    <div class="selected-group" *ngIf="selectedRoleName">
      <span>{{ selectedRoleName }}</span>
    </div>
  </div>
</div>
<div class="distance-travel-box" *ngIf="isCardPage && isAssessment && showDistanceTravelled">
  <div class="distance-row" *ngIf="includeDistanceTravel">
    <div class="distance-column">
      <strong>{{ 'translations.members.distanceTravelled.firstTitle' | transloco }}</strong>
      <br/>
      <mat-checkbox
        class="input-text"
        labelPosition="after"
        color="primary"
        [aria-label]="'translations.members.distanceTravelled.earliest' | transloco"
        [disabled]="!activeFirstInterval.startDate && !activeFirstInterval.endDate"
        [checked]="isFirstIntervalEarliest"
        (change)="toggleFirstIntervalMode('EARLIEST')"
      >
        <span>{{ 'translations.members.distanceTravelled.earliest' | transloco }}</span>
      </mat-checkbox>
      <br/>
      <mat-checkbox
        class="input-text"
        labelPosition="after"
        color="primary"
        [aria-label]="'translations.members.distanceTravelled.latest' | transloco"
        [disabled]="!activeFirstInterval.startDate && !activeFirstInterval.endDate"
        [checked]="isFirstIntervalLatest"
        (change)="toggleFirstIntervalMode('LATEST')"
      >
        <span>{{ 'translations.members.distanceTravelled.latest' | transloco }}</span>
      </mat-checkbox>
      <br/>
      <input
        type="text"
        class="distance-travel-input f_filter-input"
        (click)="toggleFirstIntervalCalendar()"
        (keydown.enter)="toggleFirstIntervalCalendar()"
        [attr.aria-label]="('translations.global.ariaLabels.firstDistanceTravelDate' | transloco)"
        [readOnly]="true"
        placeholder="{{activeFirstInterval.startDate && activeFirstInterval.endDate ? (activeFirstInterval.startDate | date) + ' - ' + (activeFirstInterval.endDate | date) : 'translations.members.distanceTravelled.firstEmptyPlaceholder' | transloco }}">
      <div class="advanced-export__filters-container-popup calendar f_filter-item" *ngIf="showFirstIntervalCalendar">
        <div class="advanced-export__filters-container-popup-inner">
          <div class="calendar-wrapper">
            <h3>{{ 'translations.global.label.from' | transloco }}</h3>
            <mat-calendar
              [maxDate]="firstInterval.endDate"
              [selected]="firstInterval.startDate"
              (selectedChange)="firstInterval.startDate = $event"></mat-calendar>
          </div>
          <div class="calendar-wrapper">
            <h3>{{ 'translations.global.label.to' | transloco }}</h3>
            <mat-calendar
              [minDate]="firstInterval.startDate"
              [selected]="firstInterval.endDate"
              (selectedChange)="firstInterval.endDate = $event"></mat-calendar>
          </div>

          <div class="advanced-export__filters-container-popup-actions">
            <button mat-stroked-button
                    class="cancel-btn uppercase"
                    [attr.aria-label]="('translations.global.ariaLabels.closeDateInterval' | transloco)"
                    (click)="showFirstIntervalCalendar = !showFirstIntervalCalendar">
              {{ 'translations.global.button.cancel' | transloco }}
            </button>
            <button *ngIf="activeFirstInterval.startDate && activeFirstInterval.endDate"
                    mat-stroked-button
                    class="cancel-btn uppercase"
                    [disabled]="!(firstInterval.startDate && firstInterval.endDate)"
                    [attr.aria-label]="('translations.global.ariaLabels.clearDateInterval' | transloco)"
                    (click)="onFirstIntervalClear()">
              {{ 'translations.global.button.clear' | transloco }}
            </button>
            <button
              mat-stroked-button
              class="apply-btn uppercase"
              [disabled]="!(firstInterval.startDate && firstInterval.endDate)"
              [attr.aria-label]="('translations.global.ariaLabels.applyDateInterval' | transloco)"
              (click)="onFirstIntervalApply()">
              {{ 'translations.global.button.apply' | transloco }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="versus-column">
      <strong>{{ 'translations.members.distanceTravelled.versus' | transloco }}</strong>
    </div>
    <div class="distance-column">
    <strong>{{ 'translations.members.distanceTravelled.secondTitle' | transloco }}</strong><br/>
    <mat-checkbox
      class="input-text"
      labelPosition="after"
      color="primary"
      [aria-label]="'translations.members.distanceTravelled.earliest' | transloco"
      [disabled]="!activeSecondInterval.startDate && !activeSecondInterval.endDate"
      [checked]="isSecondIntervalEarliest"
      (change)="toggleSecondIntervalMode('EARLIEST')"
    >
      <span>{{ 'translations.members.distanceTravelled.earliest' | transloco }}</span>
    </mat-checkbox>
    <br/>
    <mat-checkbox
      class="input-text"
      labelPosition="after"
      color="primary"
      [aria-label]="'translations.members.distanceTravelled.latest' | transloco"
      [disabled]="!activeSecondInterval.startDate && !activeSecondInterval.endDate"
      [checked]="isSecondIntervalLatest"
      (change)="toggleSecondIntervalMode('LATEST')"
    >
      <span>{{ 'translations.members.distanceTravelled.latest' | transloco }}</span>
    </mat-checkbox>
    <br/>
    <input
      type="text"
      class="distance-travel-input f_filter-input"
      (keydown.enter)="toggleSecondIntervalCalendar()"
      (click)="toggleSecondIntervalCalendar()"
      [attr.aria-label]="('translations.global.ariaLabels.secondDistanceTravelDate' | transloco)"
      [readOnly]="true"
      placeholder="{{activeSecondInterval.startDate && activeSecondInterval.endDate ? (activeSecondInterval.startDate | date) + ' - ' + (activeSecondInterval.endDate | date) : 'translations.members.distanceTravelled.secondEmptyPlaceholder' | transloco }}">
    <div class="advanced-export__filters-container-popup calendar f_filter-item" *ngIf="showSecondIntervalCalendar">
      <div class="advanced-export__filters-container-popup-inner">
        <div class="calendar-wrapper">
          <h3>{{ 'translations.global.label.from' | transloco }}</h3>
          <mat-calendar
            [maxDate]="secondInterval.endDate"
            [selected]="secondInterval.startDate"
            (selectedChange)="secondInterval.startDate = $event"></mat-calendar>
        </div>
        <div class="calendar-wrapper">
          <h3>{{ 'translations.global.label.to' | transloco }}</h3>
          <mat-calendar
            [minDate]="secondInterval.startDate"
            [selected]="secondInterval.endDate"
            (selectedChange)="secondInterval.endDate = $event"></mat-calendar>
        </div>

        <div class="advanced-export__filters-container-popup-actions">
          <button mat-stroked-button
                  class="cancel-btn uppercase"
                  [attr.aria-label]="('translations.global.ariaLabels.closeDateInterval' | transloco)"
                  (click)="showSecondIntervalCalendar = !showSecondIntervalCalendar">
            {{ 'translations.global.button.cancel' | transloco }}
          </button>
          <button *ngIf="activeSecondInterval.startDate && activeSecondInterval.endDate"
                  mat-stroked-button
                  class="cancel-btn uppercase"
                  [disabled]="!(secondInterval.startDate && secondInterval.endDate)"
                  [attr.aria-label]="('translations.global.ariaLabels.clearDateInterval' | transloco)"
                  (click)="onSecondIntervalClear()">
            {{ 'translations.global.button.clear' | transloco }}
          </button>
          <button
            mat-stroked-button
            class="apply-btn uppercase"
            [disabled]="!(secondInterval.startDate && secondInterval.endDate)"
            [attr.aria-label]="('translations.global.ariaLabels.applyDateInterval' | transloco)"
            (click)="onSecondIntervalApply()">
            {{ 'translations.global.button.apply' | transloco }}
          </button>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>
