import { SideNavSection } from '../models';

export const STATIC_NAV_CONTENT_STORE: SideNavSection[] = [
  {
    title: '',
    titleId: '',
    url: '/',
    icon: {
      name: 'west',
      color: 'cornflower-blue',
    },
  },
  {
    title: 'Content store',
    titleId: 'contentStore',
    expandable: true,
    emptyPlaceholder: 'No categories on this page',
    url: '/content-store',
    shouldToggle: true,
    parentPath: 'content-store',
    icon: {
      class: 'ptl-icon-new-store',
      color: 'cod-gray',
    },
    navItems: [],
    sideNavSectionType: 'CATEGORY',
  },
  {
    title: 'MY SUBMISSIONS',
    titleId: 'mySubmissions',
    url: '',
    parentPath: 'content-store',
    icon: {
      name: 'settings',
      color: 'cod-gray',
    },
    hideIfEmpty: true,
    displayBottomLineSeparator: false,
    navItems: [
      {
        label: 'Submitted',
        labelId: 'submitted',
        url: '/content-store/submitted',
        icon: {
          name: 'list',
          color: 'cod-gray',
        },
      },
    ],
  },
];

export const STATIC_NAV_FOLIO_PAGE: SideNavSection[] = [
  {
    title: '',
    titleId: '',
    url: '/',
    icon: {
      name: 'west',
      color: 'cornflower-blue',
    },
  },
  {
    title: 'Folio',
    titleId: 'folio',
    expandable: false,
    url: '/folio',
    shouldToggle: false,
    icon: {
      name: '',
      class: 'ptl-icon-customer',
      extraClasses: ['path1', 'path2'],
      color: 'cod-gray',
    },
  },
  {
    title: 'Skills',
    titleId: 'skills',
    url: '/folio/skills',
    icon: {
      name: '',
      class: 'ptl-icon-happy-file',
      extraClasses: ['path1', 'path2', 'path3'],
      color: 'cod-gray',
    },
  }, {
    title: 'Moments',
    titleId: 'moments',
    url: '/folio/moments',
    icon: {
      name: '',
      class: 'ptl-icon-hashtag',
      color: 'cod-gray',
    },
    // TODO temporary hidden
    // expandable: true,
    // shouldToggle: true,
    // navItems: [
    //   {
    //     label: 'Archived Moments',
    //     labelId: 'archivedMoments',
    //     url: '/folio/archived-moments',
    //     icon: {
    //       name: '',
    //       class: 'ptl-icon-note-2',
    //       extraClasses: ['path1', 'path2', 'path3', 'path4', 'path5'],
    //       color: 'cod-gray',
    //     },
    //     childMenuLevel: 0,
    //   },
    // ],
  }, {
    title: 'Achievements',
    titleId: 'achievements',
    url: '/folio/achievements',
    icon: {
      name: '',
      class: 'ptl-icon-diploma-1',
      extraClasses: ['path1', 'path2', 'path3', 'path4', 'path5', 'path6', 'path7'],
      color: 'cod-gray',
    },
  }, {
    title: 'Log',
    titleId: 'log',
    url: '/folio/logs',
    icon: {
      name: '',
      class: 'ptl-icon-test',
      color: 'cod-gray',
    },
  }
];

export const STATIC_NAV_CONTENT_DISCOVERY: SideNavSection[] = [
  {
    title: '',
    titleId: '',
    url: '/',
    icon: {
      name: 'west',
      color: 'cornflower-blue',
    },
  },
  {
    title: 'Discovery',
    titleId: 'discovery',
    expandable: true,
    emptyPlaceholder: 'No categories on this page',
    url: localStorage.getItem('orgDiscoveryUrl'),
    shouldToggle: true,
    parentPath: localStorage.getItem('orgDiscoveryUrl')?.replace('/', ''),
    icon: {
      class: 'ptl-icon-shop',
      color: 'cod-gray',
    },
    navItems: [],
    sideNavSectionType: 'CATEGORY',
  },
];

export const STATIC_NAV_OPTIONS_SECTION: SideNavSection[] = [
  {
    title: '',
    titleId: '',
    url: '/',
    icon: {
      name: 'home',
      color: 'cornflower-blue',
    },
  },
  {
    title: '',
    titleId: '',
    url: '/pages',
    icon: 'no-icon',
    parentPath: 'pages',
    navItems: [],
  },
  {
    title: 'Discovery',
    titleId: 'discovery',
    expandable: true,
    emptyPlaceholder: 'No categories on this page',
    url: localStorage.getItem('orgDiscoveryUrl'),
    shouldToggle: true,
    parentPath: localStorage.getItem('orgDiscoveryUrl')?.replace('/', ''),
    menuLevel: 2,
    icon: {
      class: 'ptl-icon-shop',
      color: 'cod-gray',
    },
    navItems: [],
    sideNavSectionType: 'CATEGORY',
  },
  {
    title: 'Content store',
    titleId: 'contentStore',
    expandable: true,
    emptyPlaceholder: 'No categories on this page',
    url: '/content-store',
    shouldToggle: true,
    parentPath: 'content-store',
    menuLevel: 2,
    icon: {
      class: 'ptl-icon-new-store',
      color: 'cod-gray',
    },
    navItems: [],
    sideNavSectionType: 'CATEGORY',
  },
  {
    title: 'Upcoming',
    titleId: 'upcoming',
    url: '/upcoming',
    menuLevel: 2,
    icon: {
      class: 'ptl-icon-calendar-12',
      color: 'cod-gray',
    },
  },
  {
    title: 'Folio',
    titleId: 'folio',
    url: '/folio',
    icon: {
      name: 'share',
      color: 'cod-gray',
    },
  },
];

export const STATIC_NAV_OPTIONS_SECTION_DISCOVERY_V2: SideNavSection[] = [
  {
    title: '',
    titleId: '',
    url: '/',
    icon: {
      name: 'west',
      color: 'cornflower-blue',
    },
  },
  {
    title: 'Discovery',
    titleId: 'discovery',
    expandable: true,
    emptyPlaceholder: 'No categories on this page',
    url: `/v2${localStorage.getItem('orgDiscoveryUrl')}`,
    shouldToggle: true,
    parentPath: `v2${localStorage.getItem('orgDiscoveryUrl')}`,
    icon: {
      name: 'graphic_eq',
      color: 'cod-gray',
    },
    navItems: [],
    sideNavSectionType: 'CATEGORY',
  },
  {
    title: 'MY SUBMISSIONS',
    titleId: 'mySubmissions',
    url: '',
    parentPath: `v2${localStorage.getItem('orgDiscoveryUrl')}`,
    icon: {
      name: 'settings',
      color: 'cod-gray',
    },
    navItems: [
      {
        label: 'New submission',
        labelId: 'newSubmissions',
        url: `/v2${localStorage.getItem('orgDiscoveryUrl')}/new-submissions`,
        icon: {
          name: 'add',
          color: 'cod-gray',
        },
      },
      {
        label: 'Submitted',
        labelId: 'submitted',
        url: `/v2${localStorage.getItem('orgDiscoveryUrl')}/submitted`,
        icon: {
          name: 'list',
          color: 'cod-gray',
        },
      },
      {
        label: 'Approved',
        labelId: 'approved',
        url: `/v2${localStorage.getItem('orgDiscoveryUrl')}/approved`,
        icon: {
          name: 'check',
          color: 'cod-gray',
        },
      },
    ],
  },
  {
    title: 'DISCOVERY ADMIN',
    titleId: 'discoveryAdmin',
    expandable: true,
    url: `/admin/v2${localStorage.getItem('orgDiscoveryUrl')}`,
    shouldToggle: true,
    parentPath: `admin/v2${localStorage.getItem('orgDiscoveryUrl')}`,
    icon: {
      name: 'settings',
      color: 'cod-gray',
    },
  },
];

export const STATIC_NAV_OPTIONS_SECTION_DISCOVERY_ADMIN_V2: SideNavSection[] = [
  {
    title: '',
    titleId: '',
    url: '/',
    icon: {
      name: 'west',
      color: 'cornflower-blue',
    },
  },
  {
    title: 'Discovery',
    titleId: 'discovery',
  },
  {
    title: 'DISCOVERY ADMIN',
    titleId: 'discoveryAdmin',
    url: `/admin/v2${localStorage.getItem('orgDiscoveryUrl')}`,
    shouldToggle: true,
    expandable: true,
    parentPath: `admin/v2${localStorage.getItem('orgDiscoveryUrl')}`,
    icon: {
      name: 'settings',
      color: 'cod-gray',
    },
    navItems: [
      {
        label: 'Edit Discovery page',
        labelId: 'editDiscoveryPage',
        url: `/admin/v2${localStorage.getItem('orgDiscoveryUrl')}/layout`,
        icon: {
          name: 'edit',
          color: 'cod-gray',
        },
      },
      {
        label: 'Awaiting review',
        labelId: 'awaitingReview',
        url: `/admin/v2${localStorage.getItem('orgDiscoveryUrl')}/awaiting-review`,
        icon: {
          name: 'list',
          color: 'cod-gray',
        },
      },
      {
        label: 'Approved',
        labelId: 'approved',
        url: `/admin/v2${localStorage.getItem('orgDiscoveryUrl')}/approved`,
        icon: {
          name: 'check',
          color: 'cod-gray',
        },
      },
      {
        label: 'Rejected',
        labelId: 'rejected',
        url: `/admin/v2${localStorage.getItem('orgDiscoveryUrl')}/rejected`,
        icon: {
          name: 'close',
          color: 'cod-gray',
        },
      },
      {
        label: 'Approval settings',
        labelId: 'approvalSettings',
        url: `/admin/v2${localStorage.getItem('orgDiscoveryUrl')}/approval`,
        icon: {
          name: 'assignment_turned_in',
          color: 'cod-gray',
        },
      },
    ],
  },
];
