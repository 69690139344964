import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  BooleanFilter,
  BooleanQueryRequest,
  BooleanQueryType,
  ResourcesStatusRequest
} from '../../../../../../../shared/models/admin/boolean-filters.model';
import { TranslationService } from '../../../../../../../shared/services/translation/translation.service';
import { MatSelectChange } from '@angular/material/select';
import {
  GOALS_DATA_SERVICE,
  GoalsDataService
} from '../../../../../../playlist/services/create/goals/goals-data.service';
import { GoalResponse } from '../../../../../../../shared/models';

@Component({
  selector: 'ptl-goal-member-filter',
  templateUrl: './goal-member-filter.component.html',
  styleUrls: ['./goal-member-filter.component.scss'],
})
export class GoalMemberFilterComponent implements OnChanges, OnInit {

  @Input() isCardPage: boolean;
  @Input() isPlaylistPage: boolean;
  @Input() cardUid: string;
  @Input() playlistUid: string;
  @Input() excludedResourceStatuses: string[];
  @Output() setGoalFilter = new EventEmitter<BooleanFilter>();
  @Output() cancelGoalFilter = new EventEmitter<void>();

  goalResourceType: 'CARDS' | 'FORMS';

  goal: GoalResponse;

  selectedResourceStatus: 'COMPLETED' | 'STARTED' = 'COMPLETED';
  allResourceStatuses: { value: 'COMPLETED' | 'STARTED'; title: string }[] = [];
  resourceStatuses: { value: 'COMPLETED' | 'STARTED'; title: string }[] = [];

  selectedAmountOperator: 'AT_MOST' | 'AT_LEAST' = 'AT_LEAST';
  amountOperators: { value: string; title: string }[] = [];

  goalTotalNumber = 0;
  resourcesNumber = 0;

  noNumber = false;

  constructor(
    private translationService: TranslationService,
    @Inject(GOALS_DATA_SERVICE) private goalsDataService: GoalsDataService,
  ) {
  }

  ngOnInit() {
    this.goalResourceType = this.isPlaylistPage ? 'CARDS' : 'FORMS';

    this.allResourceStatuses = [
      { value: 'COMPLETED', title: this.translationService.getTranslation('members.filter.resources.completed')},
      { value: 'STARTED', title: this.translationService.getTranslation('members.filter.resources.started')}
    ];
    this.updateStatuses();

    this.amountOperators = [
      { value: 'AT_MOST', title: this.translationService.getTranslation('members.filter.resources.atMost')},
      { value: 'AT_LEAST', title: this.translationService.getTranslation('members.filter.resources.atLeast')}
    ];

    if (this.isPlaylistPage && this.playlistUid) {
      this.getPlaylistGoal(this.playlistUid);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.excludedResourceStatuses) {
      this.updateStatuses();
    }
  }

  onResourceStatusChange(event: MatSelectChange) {
    this.selectedResourceStatus = event.value;
  }

  onAmountOperatorChange(event: MatSelectChange) {
    this.selectedAmountOperator = event.value
  }

  onSave() {
    if (isNaN(Number(this.resourcesNumber))) {
      this.noNumber = true;
      return;
    }
    this.noNumber = false;
    const result = {
      request: this.getRequest(),
      message: this.getMessage()
    };

    this.setGoalFilter.emit(result);
  }

  onCancel() {
    this.cancelGoalFilter.emit();
  }

  private getMessage() {
    let message = '';

    if (this.selectedResourceStatus === 'COMPLETED') {
      message += this.translationService.getTranslation('members.filter.summary.completed');
    } else {
      message += this.translationService.getTranslation('members.filter.summary.started');
    }

    switch(this.selectedAmountOperator) {
      case 'AT_LEAST':
        message += ' ' + this.translationService.getTranslation('members.filter.summary.atLeast');
        break;
      case 'AT_MOST':
        message += ' ' + this.translationService.getTranslation('members.filter.summary.atMost');
        break;
      default:
        break;
    }

    message += ' ' + this.resourcesNumber + '/' + this.goalTotalNumber
      + ' ' + this.translationService.getTranslation('members.filter.summary.goals');

    return message;
  }

  private getRequest(): ResourcesStatusRequest {
    return {
      type: BooleanQueryType.RESOURCES_STATUS,
      resourcesType: this.goalResourceType,
      operator: this.selectedAmountOperator,
      amount: this.resourcesNumber,
      status: this.selectedResourceStatus,
      required: true
    }
  }

  private getPlaylistGoal(playlistUid: string) {
    this.goalsDataService.getGoal(playlistUid).subscribe(({value}) => {
      this.goalTotalNumber = value.totalCards;
    });
  }

  private updateStatuses() {
    this.resourceStatuses = this.allResourceStatuses.filter(
      status => !this.excludedResourceStatuses.includes(status.value)
    );
    if (this.resourceStatuses.length) {
      this.selectedResourceStatus = this.resourceStatuses[0].value;
    }
  }
}
