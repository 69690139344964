<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<form class="add-question__section" [formGroup]="question">
  <div class="add-question-section-grid">
    <div class="add-question__section__grid--spacing" [class.is_disabled]="!isDefaultLanguage">
      <div class="grid-space-container">
        <span
          tabindex="0"
          class="extra-small"
          [attr.aria-label]="'translations.global.ariaLabels.userSelectMaximum' | transloco">
          {{ 'translations.userSelectMaximum' | transloco }}</span>
        <mat-form-field class="add-question__section-field add-question__section-field--small"
                        appearance="standard">
          <input
            matInput
            type="number"
            formControlName="selectionLimit"
            min="0">
        </mat-form-field>
        <span
          tabindex="0"
          class="extra-small"
          [attr.aria-label]="'translations.global.ariaLabels.optionsLower' | transloco">
          {{ 'translations.optionsLower' | transloco }}</span>
      </div>
      <div class="grid-space-container">
        <span
          tabindex="0"
          class="extra-small"
          [attr.aria-label]="'translations.global.ariaLabels.optionsPerRowText' | transloco">
          {{ 'translations.optionsPerRowText' | transloco }}</span>

        <mat-select class="custom-mat-select" formControlName="optionsPerRow">
          <mat-option *ngFor="let option of gridOptionsPerRow" [value]="option"
                      tabindex="0"
                      [attr.aria-label]="option">{{ option }}</mat-option>
        </mat-select>
      </div>
      <div class="grid-space-container">
        <mat-checkbox
          [(ngModel)]="isNoneOfTheseActive"
          (change)="onNoneOfTheseSelected()"
          [ngModelOptions]="{standalone: true}"
          tabindex="0"
          [aria-label]="'translations.global.ariaLabels.addNoneOfTheseOption' | transloco"
          color="primary"
          class="extra-small">
          <span>{{ 'translations.addNoneOfTheseOption' | transloco }}</span>
        </mat-checkbox>
      </div>
    </div>
    <div formArrayName="options">

      <div
        cdkDropList
        [cdkDropListData]="question.controls['options']['controls']"
        (cdkDropListDropped)="dropOption($event)">
      <div
        class="grid-question-box f_grid-question-box"
        cdkDrag
        cdkDragLockAxis="y"
        *ngFor="let item of question.controls['options']['controls']; let i = index"
        [formGroupName]="i"
        [cdkDragData]="item">

        <div class="add-question-section-table-new">
          <div
            class="add-question__section-column draggable-area"
            cdkDragHandle
            [class.is_disabled]="!isDefaultLanguage">
            <svg class="svg svg-icon" [attr.aria-label]="('translations.global.ariaLabels.icons.draggable' | transloco)">
              <use ogSvgUnify="assets/sprite/sprite.svg#drag-indicator"></use>
            </svg>
          </div>

          <div class="add-question__section-column add-question-section-title f_add-question-section-title"
               formGroupName="value">
            <div class="question-input-container"
                 *ngIf="item.editable || (item.controls['value'].controls['value'].errors); else elseTemplate">
              <input
                class="f_question-title-input custom-question-input"
                [class.has-error]="item.controls['value'].controls['value'].errors &&
                 item.controls['value'].controls['value'].touched"
                matInput
                [placeholder]="('translations.global.ariaLabels.enterOptionTitle' | transloco)"
                type="text"
                id="input{{ i }}"
                (focus)="makeEditableFieldActive(item, $event)"
                (blur)="makeEditableFieldActive(item, $event, false)"
                [attr.aria-label]="('translations.global.ariaLabels.enterOptionTitle' | transloco)"
                formControlName="value">
            </div>
            <ng-template #elseTemplate>
              <div class="small add-question-section-placeholder"
                   (keydown.enter)="makeEditableFieldActive(item, $event)"
                   (click)="makeEditableFieldActive(item, $event)">
                {{item.controls['value'].controls['value'].value}}
              </div>
            </ng-template>
          </div>

          <div class="add-question__section-column add-question__section-score small">
            {{ 'translations.score' | transloco }}
          </div>

          <div class="add-question__section-column" [class.is_disabled]="!isDefaultLanguage">
            <div class="add-question__section-field add-question__section-field--small">
              <input
                matInput
                type="number"
                [class.has-error]="item.controls['score'].errors &&
                 item.controls['score'].touched"
                class="custom-question-input"
                [placeholder]="'0'"
                min="0"
                [attr.aria-label]="('translations.global.ariaLabels.enterOptionScore' | transloco)"
                pattern="^\d+$"
                formControlName="score">
            </div>
          </div>

          <span #removeCategory class="cdk-visually-hidden"
                tabindex="0"
                [attr.aria-label]="'translations.global.ariaLabels.removeOption' | transloco">
            {{ 'translations.removeOption' | transloco }}</span>
          <div class="add-question__section-column-remove add-question__section-column">
            <button type="button" *ngIf="i > 0"
                    mat-icon-button
                    [class.is_disabled]="!isDefaultLanguage"
                    [title]="removeCategory.innerHTML"
                    [attr.aria-label]="('translations.global.ariaLabels.removeOption' | transloco)"
                    (click)="removeOption(i)">
              <svg class="svg svg-icon" aria-hidden="true">
                <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
              </svg>
            </button>
          </div>
        </div>

        <ng-container *ngIf="item.controls['instructions']">
          <ptl-question-instruction
            [content]="item.controls['instructions'].value"
            (contentRemoval)="onContentRemoval($event, item.controls['instructions'].value, i)"
            (contentUpdate)="onContentUpdate($event, item.controls['instructions'].value, i)">
          </ptl-question-instruction>
        </ng-container>
      </div>

      </div>
      <button
        mat-button
        [class.is_disabled]="!isDefaultLanguage"
        class="add-new-question-btn"
        type="button"
        tabindex="0"
        [attr.aria-label]="'translations.global.ariaLabels.clickToAddOption' | transloco"
        (click)="addNewOption(true)">
        <svg class="svg svg-icon icon-left" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#plus"></use>
        </svg>
        <span>{{ 'translations.diagnostics.buttons.addOption' | transloco }}</span>
      </button>
    </div>
  </div>
</form>
