/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ElementRef,
  Inject,
  Input,
  NgZone, OnChanges,
  OnDestroy,
  OnInit, SimpleChanges, ViewChild
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription, timer } from 'rxjs';
import { filter } from 'rxjs/operators';
import { LearnerFormAnswerRequest, LearnerResourceProgress } from '../../../../../page-modules/resource/models';
import { LEARNER_VIEW_DATA_SERVICE, LearnerViewDataService } from '../../../../../page-modules/resource/services/data.service';
import {
  RefreshLearnerViewCardStatus,
  SubmitForm
} from '../../../../../page-modules/resource/store/learner-view.actions';
import { LearnerViewState } from '../../../../../page-modules/resource/store/learner-view.state';
import { UserDetails } from '../../../../../user-auth/models';
import { UserAuthState } from '../../../../../user-auth/store/user-auth.state';
import { FileUploadHelper } from '../../../../helpers/file-upload-helper';
import { FileUploadBoxFormContent, Form, Organization, Resource, UserPlaylistSubmissionSummary } from '../../../../models';
import {
  FileUploadService,
  RESOURCES_FILE_UPLOAD_DATA_SERVICE
} from '../../../../services/file-upload/file-upload.service';
import { Result } from '../../../../store';
import { SnackbarHelper } from '../../../../helpers/snackbar-helper';
import { TranslationService } from '../../../../services/translation/translation.service';
import { DialogService } from '../../../../helpers/dialog/dialog.service';
import * as PlaylistViewActions from '../../../../../page-modules/playlist/store/view/playlist-view.state.actions';
import { ContentHelper } from '../../../../helpers/content-helper';
import { LocalTimeHelper } from '../../../../helpers/local-time-helper';

@Component({
  selector: 'ptl-form-preview-file-upload',
  templateUrl: './form-preview-file-upload.component.html',
  styleUrls: ['./form-preview-file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormPreviewFileUploadComponent implements OnInit, OnChanges, OnDestroy {

  /** Receives the file upload type object */
  @Input() formData: Form<FileUploadBoxFormContent>;
  @Input() userPlaylistSubmissionSummary: UserPlaylistSubmissionSummary;
  @Input() playlistUri: string;
  @Input() playlistUid: string;
  @Input() resourceUri: string;
  @Input() groupUid: string;
  @Input() publisherUri: string;
  @Input() packageUri: string;
  @Input() pageUri: string;
  @Input() languageCode: string;

  @Select(UserAuthState.userDetailsData)
  private userDetails$: Observable<UserDetails>;

  @Select(UserAuthState.organizationDetails)
  private organizationData$: Observable<Organization>;

  @Select(LearnerViewState.resourceData)
  private resourceData$: Observable<Resource>;

  private fileInput: ElementRef;

  @ViewChild('fileInput', { static: false }) set fileInputContent(content: ElementRef) {
    if (content) {
      this.fileInput = content;
    }
  }


  uploadedFileName = '';
  filesHovered = false;
  fileUploaded = false;
  fileUploadInProgress: boolean;
  uploadedFileLink = '';
  submitted = false;
  supportedFileTypes = [];

  private organization: Organization;
  private cardUid = '';
  private oneMegabyte = 1048576;
  private userData: UserDetails;
  private userDetailsSubscription: Subscription;
  private organizationDataSubscription: Subscription;
  private resourceSubscription: Subscription;

  constructor(
    private store: Store,
    private ref: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private ngZone: NgZone,
    private dialogService: DialogService,
    private cd: ChangeDetectorRef,
    private translationService: TranslationService,
    @Inject(LEARNER_VIEW_DATA_SERVICE) private dataService: LearnerViewDataService,
    @Inject(RESOURCES_FILE_UPLOAD_DATA_SERVICE) private fileUploadService: FileUploadService
  ) {
  }

  submit(): void {
    this.dialogService.showConfirmDialog(
      this.translationService.getTranslation('dialog.title.confirmFormSubmit'),
      this.translationService
    ).then(confirmed => {
      if (confirmed) {
        this.submitted = true;
        this.checkSubmissionMode();
        this.store.dispatch(new SubmitForm(this.playlistUid, this.formData.uid)).toPromise().then(() => {
          timer(1000).subscribe(() => {
            this.store.dispatch(new PlaylistViewActions.RefreshUserPlaylistSubmissionSummary(this.playlistUid));
            this.store.dispatch(
              new RefreshLearnerViewCardStatus(
                this.playlistUri,
                this.resourceUri,
                this.groupUid,
                this.publisherUri,
                this.packageUri,
                this.pageUri,
                this.languageCode,
              )
            );
          });
        });
      }
    });
  }

  ngOnInit() {
    this.userDetailsSubscription = this.userDetails$
      .pipe(filter(userData => !!userData))
      .subscribe((userData: UserDetails) => this.userData = userData);

    this.saveOrganizationDataToBeUsedForFileUpload();

    this.resourceSubscription = this.resourceData$.pipe(filter(resourceData => !!resourceData))
      .subscribe((resourceData) => {
        this.cardUid = resourceData._id;
      });
    if (this.formData && this.formData.content) {
      const content = this.formData.content as FileUploadBoxFormContent;
      if (content.userAnswer) {
        this.submitted = content.submitted;
        this.fileUploaded = true;
        this.setLinkAndName(content.userAnswer);
      }
      if (content.supportedFileTypes) {
        this.supportedFileTypes = content.supportedFileTypes;
      }

      this.checkSubmissionMode();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.userPlaylistSubmissionSummary && changes.userPlaylistSubmissionSummary.currentValue) {
      this.checkSubmissionMode();
    }
  }

  get isSubmissionDisabled(): boolean {
    return !this.uploadedFileLink;
  }

  ngOnDestroy() {
    this.userDetailsSubscription?.unsubscribe();
    this.organizationDataSubscription?.unsubscribe();
    this.resourceSubscription?.unsubscribe();
  }

  onFilesDropped(fileList: FileList): void {
    if (fileList.length === 1) {
      this.tryToSaveAnswer(fileList.item(0));
    } else {
      SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'uploadFileCountLimitError');
    }
  }

  onFileChanged(eventData: Event): void {
    this.tryToSaveAnswer((eventData.target as HTMLInputElement).files[0]);
  }

  getSupportedFileTypes(): string {
    return this.supportedFileTypes.join(', ').replace(/\./g, '');
  }

  getLocalDateTime(date: Date): string {
    return LocalTimeHelper.getLocalDateTime(date).toString();
  }

  private saveOrganizationDataToBeUsedForFileUpload() {
    this.organizationDataSubscription = this.organizationData$.subscribe(data => {
      this.organization = data;
    });
  }

  private tryToSaveAnswer(file: File): void {
    if (file) { // falsy when cancel is clicked
      if (file.size > (this.oneMegabyte * this.formData.content.maxFileSizeMb)) {
        const translation = this.translationService.getTranslation('uploadFileLimitError');
        const message = translation.replace('{maxFileSize}', this.formData.content.maxFileSizeMb.toString());
        SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, message);
        (this.fileInput.nativeElement as HTMLInputElement).value = ''
        return;
      }
      const fileExtension = file.name.split('.').splice(-1);
      if (!this.supportedFileTypes.includes(`.${fileExtension[0].toLowerCase()}`)) {
        SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'uploadFileTypeError');
        (this.fileInput.nativeElement as HTMLInputElement).value = ''
        return;
      }
      this.uploadFileAndSaveAnswer(file);
    }
  }

  private uploadFileAndSaveAnswer(file: File): void {
    this.fileUploaded = true;
    this.fileUploadInProgress = true;
    const learnerAnswer: LearnerFormAnswerRequest = {
      formUid: this.formData.uid,
      answer: FileUploadHelper.formatFileNameForUpload(file.name),
    };
    this.dataService.saveFormAnswer(this.playlistUid, this.cardUid, learnerAnswer).subscribe(({ isSuccess, value }) => {
      if (isSuccess) {
        this.uploadFile(file, value).then(() => this.handleSaveAnswerSuccess(value));
      }
    });
  }

  private uploadFile(file: File, progressFromBackend: LearnerResourceProgress): Promise<Result<void>> {
    const currentForm = progressFromBackend.learnerFormAnswers.find(form => form.formUid === this.formData.uid);
    const fullUrl = currentForm.answer;
    const filePath = FileUploadHelper.filePath(fullUrl);
    return this.fileUploadService.uploadFile(filePath, file).toPromise();
  }

  private handleSaveAnswerSuccess(progress: LearnerResourceProgress) {
    const currentForm = progress.learnerFormAnswers.find(form => form.formUid === this.formData.uid);
    this.setLinkAndName(currentForm.answer);
    this.fileUploadInProgress = false;
    this.ref.detectChanges();
  }

  private setLinkAndName(url: string): void {
    this.uploadedFileLink = url;
    const fileName = this.uploadedFileLink.split('?')[0].split('/');
    this.uploadedFileName = this.getNameWithoutOriginalSuffix(fileName[fileName.length - 1]);
  }

  private getNameWithoutOriginalSuffix(name: string): string {
    return name.replace('_original', '');
  }

  private checkSubmissionMode(): void {
    if (ContentHelper.checkFormSubmissionStatus(this.userPlaylistSubmissionSummary)) {
      this.submitted = false;
    }
    this.cd.detectChanges();
  }

}
