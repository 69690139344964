/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import { Inject, Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { EMPTY, Subject } from 'rxjs';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';

import { EditorHelper } from '../../../../shared/helpers/editor.helper';
import { SnackbarHelper } from '../../../../shared/helpers/snackbar-helper';
import { VersionHelper } from '../../../../shared/helpers/version.helper';
import {
  EditorContent,
  Playlist,
  PlaylistCardShort,
  PlaylistMainSection, PlaylistStandardSection,
  ResourceAuthorDetails,
  ResourceTag
} from '../../../../shared/models';
import {
  FileUploadService,
  RESOURCES_FILE_UPLOAD_DATA_SERVICE
} from '../../../../shared/services/file-upload/file-upload.service';
import {
  SUBHEADER_DATA_SERVICE,
  SubheaderDataService
} from '../../../../shared/services/subheader/subheader-data.service';
import { CORE_TAG_DATA_SERVICE, TagDataService } from '../../../../shared/services/tags/tag-data.service';
import { DEFAULT_LANGUAGE_CODE, TranslationService } from '../../../../shared/services/translation/translation.service';
import { UpdateSidebarItemData } from '../../../../shared/side-nav/store/side-nav.actions';
import { dataLoadedState, errorState, LoadableState, loadingState, ObservableResult } from '../../../../shared/store';
import { UserAuthState } from '../../../../user-auth/store/user-auth.state';
import { PROJECT_DATA_SERVICE, ProjectDataService } from '../../../project/services/data.service';
import { RedirectHelper } from '../../../resource/store/editor/content/helpers/redirect.helper';
import { TagErrorObject } from '../../models';
import { PlaylistResourceModel } from '../../models/playlist-resource.model';
import { CORE_PLAYLIST_DATA_SERVICE, PlaylistDataService } from '../../services/create/core/data.service';
import { LoadPlaylistViewDetails, SetPlaylistViewDetails, UpdatePlaylistLearnerViewCards } from '../view/playlist-view.state.actions';
import { PlaylistMainSectionActionHelpers } from './content/helpers/main-section-action.helpers';
import { PlaylistNewSectionActionHelpers } from './content/helpers/new-section-action.helpers';
import { PlaylistSaveHelper } from './content/helpers/playlist-save.helper';
import { PlaylistResourceEditorGoalsState } from './golas/playlist-goals.state';
import * as PlaylistAdminActions from './playlist-creation.actions';
import {
  LoadPlaylistResourceDetails,
  LoadProjectPlaylistResourceDetails,
  RedirectToPlaylistEdit,
  RedirectToPlaylistView,
  RedirectToProjectEdit,
  RemoveSectionIndexAndTypeForAddedItem,
  ResetCurrentLanguage,
  ResetNewCardData,
  SaveNewCardDataToPlaylistSection,
  SavePlaylistSectionIndexForNewCard,
  SaveSectionIndexAndTypeForAddedItem,
  SetCurrentLanguage
} from './playlist-creation.actions';
import {
  CurrentLanguage,
  INITIAL_STATE,
  PlaylistCreationStateModel,
  PlaylistPaletteActionModel,
  PlaylistResourceContentStateParentDetails,
  SaveStatusFailureReason
} from './playlist-creation.state.model';
import { UpdatePlaylistResponse } from '../../services/create/core/models/update-playlist-response';
import { PlaylistResourceEditorPublicationState } from './publication/playlist-publication.state';
import { SetPublicationSettings } from './publication/playlist-publiction.actions';

@State<PlaylistCreationStateModel>({
  name: 'playlistEditor',
  defaults: INITIAL_STATE,
  children: [PlaylistResourceEditorPublicationState, PlaylistResourceEditorGoalsState],
})
@Injectable()
export class PlaylistCreationState {
  private autoSaveDispatcher$ = new Subject<string>();

  @Selector()
  static currentLanguage({ currentLanguage }: PlaylistCreationStateModel): CurrentLanguage {
    return currentLanguage;
  }

  @Selector()
  static resource({ playlist }: PlaylistCreationStateModel): LoadableState<PlaylistResourceModel> {
    return playlist;
  }

  @Selector()
  static newCard({ newCard }: PlaylistCreationStateModel): { sectionIndex: number } {
    return newCard;
  }

  @Selector()
  static hasNewCard({ hasNewCard }: PlaylistCreationStateModel): boolean {
    return hasNewCard;
  }

  @Selector()
  static paletteAction({ paletteAction }: PlaylistCreationStateModel): PlaylistPaletteActionModel {
    if (paletteAction.type !== undefined && paletteAction.sectionIndex !== undefined) {
      return paletteAction;
    }
    return undefined;
  }

  @Selector()
  static playlistData({ playlist }: PlaylistCreationStateModel): LoadableState<PlaylistResourceModel> {
    return playlist;
  }

  @Selector()
  static title({ playlist }: PlaylistCreationStateModel): string {
    return playlist.data.mainSection.title;
  }

  @Selector()
  static subheader({ playlist }: PlaylistCreationStateModel): string {
    return playlist.data.subheader;
  }

  @Selector()
  static timeRequired({ playlist }: PlaylistCreationStateModel): number {
    return playlist.data.timeRequired;
  }

  @Selector()
  static authorDetails({ authorDetails }: PlaylistCreationStateModel): ResourceAuthorDetails {
    return authorDetails;
  }

  @Selector()
  static tags({ tags }: PlaylistCreationStateModel): ResourceTag[] {
    return tags;
  }

  @Selector()
  static filteredTags({ filteredTags }: PlaylistCreationStateModel): ResourceTag[] {
    return filteredTags;
  }

  @Selector()
  static feedTags({ feedTags }: PlaylistCreationStateModel): ResourceTag[] {
    return feedTags ? feedTags : [];
  }

  @Selector()
  static filteredFeedTags({ filteredFeedTags }: PlaylistCreationStateModel): ResourceTag[] {
    return filteredFeedTags;
  }

  @Selector()
  static categories({ categories }: PlaylistCreationStateModel): ResourceTag[] {
    return categories;
  }

  @Selector()
  static filteredCategories({ filteredCategories }: PlaylistCreationStateModel): ResourceTag[] {
    return filteredCategories;
  }

  @Selector()
  static playlistSaved({ playlist }: PlaylistCreationStateModel): boolean {
    return !!playlist.data._id;
  }

  @Selector()
  static contentChanged({ playlist }: PlaylistCreationStateModel): boolean {
    return playlist.data.contentChanged;
  }

  @Selector()
  static validationErrors({ validationErrors }: PlaylistCreationStateModel): string[] {
    return validationErrors;
  }

  @Selector()
  static saveInProgress({ saveStatus }: PlaylistCreationStateModel): boolean {
    return saveStatus.saveInProgress;
  }

  @Selector()
  static autoSaveInProgress({ saveStatus }: PlaylistCreationStateModel): boolean {
    return saveStatus.autoSaveInProgress;
  }

  @Selector()
  static saveFailureReason({ saveStatus }: PlaylistCreationStateModel): SaveStatusFailureReason {
    return saveStatus.saveFailureReason;
  }

  @Selector()
  static standardSections({ playlist }: PlaylistCreationStateModel): PlaylistStandardSection[] {
    return playlist.data.standardSections;
  }

  @Selector()
  static mainSection({ playlist }: PlaylistCreationStateModel): PlaylistMainSection {
    return playlist.data.mainSection;
  }

  @Selector()
  static activePaletteAction({ activePaletteAction }: PlaylistCreationStateModel): boolean {
    return activePaletteAction;
  }

  @Selector()
  static parentPlaylistDetails({ parentPlaylistDetails }: PlaylistCreationStateModel):
    PlaylistResourceContentStateParentDetails {
    return parentPlaylistDetails;
  }

  constructor(
    private translationService: TranslationService,
    private store: Store,
    private router: Router,
    private ngZone: NgZone,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    @Inject(PROJECT_DATA_SERVICE) private projectDataService: ProjectDataService,
    @Inject(SUBHEADER_DATA_SERVICE) private subheaderDataService: SubheaderDataService,
    @Inject(CORE_PLAYLIST_DATA_SERVICE) private playlistService: PlaylistDataService,
    @Inject(PROJECT_DATA_SERVICE) private projectService: ProjectDataService,
    @Inject(CORE_TAG_DATA_SERVICE) private tagService: TagDataService,
    @Inject(RESOURCES_FILE_UPLOAD_DATA_SERVICE) private fileUploadService: FileUploadService,
  ) {
    this.autoSaveDispatcher$.pipe(
      debounceTime(3000)
    )
      .subscribe(
        currentLanguage => this.store.dispatch(new PlaylistAdminActions.SaveCurrentChanges(currentLanguage))
      );
  }

  @Action(SetCurrentLanguage)
  setCurrentLanguage(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { supportedLanguage, index }: SetCurrentLanguage
  ) {
    patchState({
      currentLanguage: { supportedLanguage, index } as CurrentLanguage,
    });
  }

  @Action(ResetCurrentLanguage)
  resetCurrentLanguage(
    { patchState }: StateContext<PlaylistCreationStateModel>,
  ) {
    patchState({
      currentLanguage: null,
    });
  }

  @Action(LoadPlaylistResourceDetails)
  loadResourceDetails(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { playlistUri, publisherUri, packageUri, pageUri }: LoadPlaylistResourceDetails
  ) {

    patchState({
      playlist: loadingState(),
    });
    const languageCode = getState().currentLanguage?.supportedLanguage?.language?.code;
    return this.playlistService.getPlaylistDetails(playlistUri, publisherUri, packageUri, pageUri, languageCode).pipe(
      tap(({ isSuccess, value, error }) => {
        this.loadPlaylistResourceDetails(isSuccess, patchState, value, error);
      })
    );
  }

  @Action(LoadProjectPlaylistResourceDetails)
  loadProjectResourceDetails(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { playlistUri }: LoadProjectPlaylistResourceDetails
  ) {

    patchState({
      playlist: loadingState(),
    });
    const languageCode = getState().currentLanguage?.supportedLanguage?.language?.code;
    return this.projectDataService.getProjectDetails(playlistUri, languageCode).pipe(
      tap(({ isSuccess, value, error }) => {
        this.loadPlaylistResourceDetails(isSuccess, patchState, value, error);
      })
    );
  }

  private loadPlaylistResourceDetails(
    isSuccess: boolean,
    patchState: (val: Partial<PlaylistCreationStateModel>) => PlaylistCreationStateModel, value: Playlist, error: string
  ) {
    if (isSuccess) {
      patchState({
        resourceLoadingStatus: {
          loadingResourceDetails: false,
        },
        playlist: dataLoadedState(value),
        authorDetails: value.authorDetails,
        tags: value.tags,
        feedTags: value.feedTags,
        categories: value.categories
      });
      this.store.dispatch(new SetPlaylistViewDetails(value as Playlist));
      this.store.dispatch(new SetPublicationSettings(
        value._id,
        value.type,
        { ...value.publicationSettings, published: value.status === 'PUBLISHED' }));
    } else {
      patchState({
        playlist: errorState(error),
      });
    }
  }

  @Action(RedirectToPlaylistEdit)
  redirectToPlaylistEdit(
    _: StateContext<PlaylistCreationStateModel>,
    { playlistUri, formattedUri }: RedirectToPlaylistEdit
  ) {
    RedirectHelper.redirectByParams(this.ngZone, this.router, this.activatedRoute, {
      playlistUri: playlistUri,
      formattedUri: formattedUri,
      extraUriParam: 'edit'
    }, 'PLAYLIST');
  }

  @Action(RedirectToProjectEdit)
  redirectToProjectEdit(
    _: StateContext<PlaylistCreationStateModel>,
    { folioPublicId, playlistUri }: RedirectToProjectEdit
  ) {
    RedirectHelper.redirectByParams(this.ngZone, this.router, this.activatedRoute, {
      folioPublicId: folioPublicId,
      playlistUri: playlistUri,
      extraUriParam: 'edit'
    }, 'PROJECT');
  }

  @Action(RedirectToPlaylistView)
  redirectToPlaylistView(
    { patchState }: StateContext<PlaylistCreationStateModel>,
    { playlistUri, formattedUri }: RedirectToPlaylistView
  ) {
    RedirectHelper.redirectByParams(this.ngZone, this.router, this.activatedRoute, {
      playlistUri: playlistUri,
      formattedUri: formattedUri
    }, 'PLAYLIST');
  }

  @Action(SaveNewCardDataToPlaylistSection)
  saveNewCardDataToPlaylistSection(
    { getState }: StateContext<PlaylistCreationStateModel>,
    { resource }: SaveNewCardDataToPlaylistSection
  ) {

    const state = getState();
    const sectionIndex = (state.newCard.sectionIndex !== undefined) ? state.newCard.sectionIndex : -1;
    let sectionToUpdate;

    if (sectionIndex >= 0) {
      sectionToUpdate = state.playlist.data.standardSections[sectionIndex];
    } else {
      sectionToUpdate = state.playlist.data.mainSection;
    }

    const updatedSectionCards = {
      cards: [...sectionToUpdate.cards, resource].map(card => card._id),
    };

    this.store.dispatch(new ResetNewCardData());

    const publisherUri = this.activatedRoute.snapshot.paramMap.get('publisherUri');
    const packageUri = this.activatedRoute.snapshot.paramMap.get('packageUri');
    const pageUri = this.activatedRoute.snapshot.paramMap.get('pagesUri');

    return this.playlistService.updatePlaylistSectionCards(state.playlist.data._id,
      sectionToUpdate.uid, updatedSectionCards).pipe(
      tap(({ isSuccess }) => {
        if (isSuccess) {
          SnackbarHelper.showTranslatableSnackBar(
            this.ngZone,
            this.snackBar,
            this.translationService,
            resource.type === 'ASSESSMENT' ? 'assessmentAddedToPlaylist' : 'cardAddedToPlaylist'
          );
          this.store.dispatch(
            new LoadPlaylistViewDetails(state.playlist.data.uri, publisherUri, packageUri, pageUri, null)
          );
        }
      })
    );
  }

  @Action(SavePlaylistSectionIndexForNewCard)
  savePlaylistSectionIndexForNewCard(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { sectionIndex, newMainSection, newStandardSections }: SavePlaylistSectionIndexForNewCard
  ) {

    const state = getState();
    const mainSection: PlaylistMainSection = newMainSection ?
      newMainSection : state.playlist.data ? state.playlist.data.mainSection : undefined;
    const standardSections: PlaylistStandardSection[] = newStandardSections ?
      newStandardSections : state.playlist.data ? state.playlist.data.standardSections : [];

    patchState({
      hasNewCard: true,
      newCard: {
        sectionIndex: sectionIndex,
      },
      playlist: {
        ...state.playlist,
        data: {
          ...state.playlist.data,
          mainSection: mainSection,
          standardSections: standardSections,
        },
      },
    });
  }

  @Action(ResetNewCardData)
  resetNewCardData(
    { patchState }: StateContext<PlaylistCreationStateModel>
  ) {

    patchState({
      hasNewCard: false,
      newCard: {
        sectionIndex: undefined,
      },
    });
  }

  @Action(PlaylistAdminActions.NavigateToResourceCreation)
  navigateToResourceCreation(
    { getState }: StateContext<PlaylistCreationStateModel>,
    { uri, formattedUri, isProjectResource, folioPublicId }: PlaylistAdminActions.NavigateToResourceCreation
  ) {
    const playlistUri = getState().playlist.data.uri;
    if (isProjectResource) {
      this.redirectToProjectResourceCreationPage(folioPublicId, playlistUri, uri, formattedUri, getState().newCard.sectionIndex);
    } else {
      this.redirectToResourceCreationPage(playlistUri, uri, formattedUri, getState().newCard.sectionIndex);
    }
  }

  @Action(PlaylistAdminActions.NavigateToEventCardCreation)
  navigateToEventCardCreation(
    { getState }: StateContext<PlaylistCreationStateModel>,
    { uri, formattedUri }: PlaylistAdminActions.NavigateToEventCardCreation
  ) {
    const playlistUri = getState().playlist.data.uri;
    this.redirectToEventCardCreationPage(playlistUri, uri, formattedUri, getState().newCard.sectionIndex);
  }


  @Action(SaveSectionIndexAndTypeForAddedItem)
  saveSectionIndexAndTypeForAddedItem(
    { patchState }: StateContext<PlaylistCreationStateModel>,
    { sectionIndex, type }: SaveSectionIndexAndTypeForAddedItem
  ) {
    patchState({
      paletteAction: {
        sectionIndex: sectionIndex,
        type: type,
      },
    });
  }

  @Action(RemoveSectionIndexAndTypeForAddedItem)
  removePlaylistSectionIndexForDialogOpen(
    { patchState }: StateContext<PlaylistCreationStateModel>
  ) {
    patchState({
      paletteAction: {
        sectionIndex: undefined,
        type: undefined,
      },
    });
  }

  @Action(PlaylistAdminActions.InitAutoSave)
  initAutoSave(
    { patchState }: StateContext<PlaylistCreationStateModel>,
    { languageCode }: PlaylistAdminActions.InitAutoSave
  ) {
    patchState({
      saveStatus: {
        saveInProgress: false,
        autoSaveInProgress: true,
      },
    });
    this.autoSaveDispatcher$.next(languageCode);
  }

  @Action(PlaylistAdminActions.SetPlaylistResourceDetails)
  setPlaylistResourceDetails(
    { patchState }: StateContext<PlaylistCreationStateModel>,
    { playlist }: PlaylistAdminActions.SetPlaylistResourceDetails
  ) {
    return patchState({
      ...INITIAL_STATE,
      resourceLoadingStatus: {
        loadingResourceDetails: false,
      },
      playlist: dataLoadedState(playlist),
      authorDetails: playlist.authorDetails,
      tags: playlist.tags,
      feedTags: playlist.feedTags,
      categories: playlist.categories
    });
  }

  @Action(PlaylistAdminActions.ResetPlaylistState)
  resetPlaylistState(
    { setState, getState }: StateContext<PlaylistCreationStateModel>,
    { keepParentPlaylistDetails, pageId, pageSectionId, targetGroups }: PlaylistAdminActions.ResetPlaylistState
  ) {
    const parentPlaylistDetails = getState().parentPlaylistDetails;
    const initialState = INITIAL_STATE;
    if (pageId) {
      initialState.playlist.data.pageId = pageId;
    }
    if (pageSectionId) {
      initialState.playlist.data.pageSectionId = pageSectionId;
    }
    if (targetGroups) {
      initialState.playlist.data.targetGroups = targetGroups.split(',');
    }
    if (parentPlaylistDetails && keepParentPlaylistDetails) {
      return setState({ ...initialState, parentPlaylistDetails });
    } else {
      return setState(initialState);
    }
  }

  @Action(PlaylistAdminActions.UpdatePlaylistHeadline)
  updatePlaylistHeadline(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { headline, languageCode }: PlaylistAdminActions.UpdatePlaylistHeadline
  ) {
    const state = getState();
    this.store.dispatch(new PlaylistAdminActions.InitAutoSave(languageCode));
    const newVersionEnabled = VersionHelper.newVersionEnabled();

    if (newVersionEnabled) {
      if (!languageCode || languageCode === DEFAULT_LANGUAGE_CODE) {
        this.store.dispatch(new UpdateSidebarItemData(getState().playlist.data._id, { title: headline }));
      }
    }

    patchState({
      playlist: {
        ...state.playlist,
        data:  {
          ...getState().playlist.data,
          mainSection: { ...getState().playlist.data.mainSection, title: headline },
          contentChanged: true,
        },
      }
    });
  }

  @Action(PlaylistAdminActions.SavePlaylistHeadline)
  savePlaylistHeadline(
    { getState, patchState, setState }: StateContext<PlaylistCreationStateModel>,
    { headline, languageCode }: PlaylistAdminActions.SavePlaylistHeadline
  ) {
    const oldState = getState();
    patchState({
      playlist: {
        ...oldState.playlist,
        data: {
          ...getState().playlist.data,
          mainSection: { ...oldState?.playlist?.data.mainSection, title: headline },
        }
      },
    });
    const newState = getState();

    return PlaylistSaveHelper
      .updatePlaylist(this.playlistService, newState.playlist, languageCode).pipe(
        map(({ isSuccess, value, error }) => {
          if (isSuccess) {
            return value;
          } else {
            throw new Error(error);
          }
        }),
        switchMap(value =>
          PlaylistSaveHelper.createUploadsObservable(newState.playlist, value, this.fileUploadService).pipe(
            tap(() => {
              SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'titleUpdated');
            }),
            catchError(error => {
              SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
              setState(oldState);
              return EMPTY;
            })
          ),
        )
      );
  }

  @Action(PlaylistAdminActions.UpdatePlaylistTimeRequired)
  updatePlaylistTimeRequired(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { timeRequired }: PlaylistAdminActions.UpdatePlaylistTimeRequired
  ) {
    const state = getState();
    const playlist = state.playlist;
    const id = playlist.data._id;

    return this.playlistService.setPlaylistTimeRequired(id, timeRequired).pipe(
      tap(({ isSuccess }) => {
        if (isSuccess) {
          patchState({
            playlist: {
              ...getState().playlist,
              data: {
                ...getState().playlist.data,
                timeRequired: timeRequired,
              }
            },
          });
          SnackbarHelper.showTranslatableSnackBar(
            this.ngZone, this.snackBar, this.translationService, 'completion.message.success.timeEstimateUpdated'
          );
        } else {
          SnackbarHelper.showTranslatableSnackBar(
            this.ngZone, this.snackBar, this.translationService, 'completion.message.error.timeEstimateUpdated'
          );
        }
      })
    );
  }


  @Action(PlaylistAdminActions.UpdatePlaylistAuthorVisibility)
  updatePlaylistAuthorVisibility(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { request }: PlaylistAdminActions.UpdatePlaylistAuthorVisibility
  ) {
    const state = getState();
    const authorDetails = state.authorDetails;
    patchState({
      authorDetails: {
        ...authorDetails,
        displayConfig: {
          ...authorDetails.displayConfig,
          authorName: request.authorName,
          organizationName: request.organizationName,
        }
      },
    });
  }


  @Action(PlaylistAdminActions.UpdatePlaylistMainSectionCards)
  updatePlaylistMainSectionCards(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { cards }: PlaylistAdminActions.UpdatePlaylistMainSectionCards
  ) {
    patchState({
      playlist: {
        ...getState().playlist,
        data: {
          ...getState().playlist.data,
          mainSection: { ...getState().playlist.data.mainSection, cards: cards },
        }
      },
    });

    this.store.dispatch(new UpdatePlaylistLearnerViewCards(cards, null));

  }

  @Action(PlaylistAdminActions.UpdatePlaylistAdditionalSectionCards)
  updatePlaylistAdditionalSectionCards(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { cards, sectionUid }: PlaylistAdminActions.UpdatePlaylistAdditionalSectionCards
  ) {
    patchState({
      playlist: {
        ...getState().playlist,
        data: {
          ...getState().playlist.data,
          standardSections: getState().playlist.data.standardSections.map((section) => section.uid === sectionUid
            ? ({
              ...section,
              cards: cards,
            })
            : section),
        }
      },
    });

    this.store.dispatch(new UpdatePlaylistLearnerViewCards(cards, sectionUid));
  }

  @Action(PlaylistAdminActions.AddInitialContentToMainSection)
  addInitialContentToMainSection(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { position }: PlaylistAdminActions.AddInitialContentToMainSection
  ) {

    patchState({
      playlist: {
        ...getState().playlist,
        data: {
        ...getState().playlist.data,
        mainSection: {
          ...getState().playlist.data.mainSection,
          content: [{ type: 'PARAGRAPH', content: '' } as EditorContent],
          layoutType: position,
        },
        contentChanged: true,
      }} ,
    });
  }


  @Action(PlaylistAdminActions.ChangeAutosaveStatus)
  changeAutosaveStatus(
    { patchState }: StateContext<PlaylistCreationStateModel>,
    { status }: PlaylistAdminActions.ChangeAutosaveStatus
  ) {

    patchState({
      saveStatus: {
        saveInProgress: status,
        autoSaveInProgress: status,
      },
    });
  }

  @Action(PlaylistAdminActions.SavePlaylistUpdates)
  savePlaylistUpdates(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { isProject, folioPublicId, languageCode }: PlaylistAdminActions.SavePlaylistUpdates
  ) {
    return PlaylistSaveHelper
      .saveUpdates(
        getState(),
        patchState,
        this.playlistService,
        this.projectService,
        this.store,
        this.fileUploadService,
        isProject,
        folioPublicId,
        languageCode
      );
  }

  @Action(PlaylistAdminActions.SaveCurrentChanges)
  saveCurrentChanges(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { languageCode }: PlaylistAdminActions.SaveCurrentChanges
  ) {
    const state = getState();
    const playlistContent = state.playlist;

    if (!playlistContent.data._id) {
      return null;
    }

    const hasFiles = PlaylistSaveHelper.checkIfHasFile(playlistContent);

    const result = PlaylistSaveHelper.updatePlaylist(
      this.playlistService,
      playlistContent,
      languageCode,
    ).pipe(
      tap(( { value, error } ) => {
        if ( !error ) {
          if (hasFiles) {
            PlaylistSaveHelper.createUploadsObservable(playlistContent, value, this.fileUploadService).subscribe(() => {
              patchState(this.patchCurrentChangesState(playlistContent, value));
            });
          } else {
            patchState(this.patchCurrentChangesState(playlistContent, value));
          }
        }
      })
    );
    return result;
  }

  @Action(PlaylistAdminActions.RefreshPlaylist)
  refreshPlaylistContent(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { playlistUri, publisherUri, packageUri, pageUri, languageCode }: PlaylistAdminActions.RefreshPlaylist
  ) {
    this.playlistService.getPlaylistDetails(playlistUri, publisherUri, packageUri, pageUri, languageCode).subscribe(
      ({ isSuccess, value }) => {
        if (isSuccess) {
          this.store.dispatch(new SetPlaylistViewDetails(value as Playlist));
          patchState({
            playlist:{
              ...getState().playlist,
              data: {
                ...INITIAL_STATE.playlist.data,
                _id: value._id,
                mainSection: {
                  ...value.mainSection,
                },
                standardSections: value.standardSections.map(section => ({
                  ...section,
                })),
                formattedUri: value.formattedUri,
                uri: value.uri,
                type: value.type,
                subheader: value.subHeader,
              }
            } ,
          });
        } else {
          SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'publicationSettingsUpdateFailed');
        }
      }
    );
  }

  @Action(PlaylistAdminActions.UpdateUri)
  updateUri(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { value }: PlaylistAdminActions.UpdateUri
  ) {
    const state = getState();
    const playlist = state.playlist;
    const id = playlist.data._id;
    const oldUri = playlist.data.uri;
    const data = {
      uri: value,
    };
    return this.playlistService.updatePlaylistUri(id, data).pipe(
      tap(({ isSuccess }) => {
        if (isSuccess) {
          patchState({
            playlist: {
              ...playlist,
              data: {
                ...playlist.data,
                uri: value,
              }
            },
          });
          SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'uriUpdated');
          const cardOldUri = '/playlists/' + oldUri;
          const cardNewUrl = '/playlists/' + value;
          const url = location.href.replace(cardOldUri, cardNewUrl);
          window.history.replaceState('', '', url);
          const newVersionEnabled = VersionHelper.newVersionEnabled();
          if(newVersionEnabled) {
            this.store.dispatch(new UpdateSidebarItemData(id, { uri: value, oldUri: oldUri }));
          }
          RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, location.pathname);
        } else {
          SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'publicationSettingsUpdateFailed');
        }
      })
    );
  }

  @Action(PlaylistAdminActions.UpdateSubheader)
  updateSubheader(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { subheader, languageCode }: PlaylistAdminActions.UpdateSubheader
  ) {
    this.subheaderDataService.updatePlaylistSubheader(getState().playlist.data._id, { subHeader: subheader }, languageCode)
      .subscribe(({ isSuccess, error }) => {
        if (isSuccess) {
          patchState({
            playlist: {
              ...getState().playlist,
              data:  {
                ...getState().playlist.data,
                subheader,
              }
            },
          });
          SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'subheaderUpdated');
        } else {
          SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
        }
      });

  }

  @Action(PlaylistAdminActions.NavigateToRootPage)
  navigateToRootPage() {
    this.navigateToRoot();
  }

  @Action(PlaylistAdminActions.RedirectToPlaylistViewPage)
  navigateToPlaylistViewPage(
    { getState }: StateContext<PlaylistCreationStateModel>,
    { uri, formattedUri }: PlaylistAdminActions.RedirectToPlaylistViewPage
  ) {
    const playlistUri = uri ?? getState().playlist.data.uri;
    this.navigateToPlaylistView(playlistUri, formattedUri);
  }

  @Action(PlaylistAdminActions.UpdateContentAtMainSection)
  updateContentAtMainSection(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    action: PlaylistAdminActions.UpdateContentAtMainSection
  ) {
    if (!EditorHelper.isSameContent(action.updateContent, action.oldContent) || action.updateContent.type === 'EMBED_CODE') {
      this.store.dispatch(new PlaylistAdminActions.InitAutoSave(action.languageCode));
    }
    return PlaylistMainSectionActionHelpers.updateContentAtMainSection(getState(), patchState, action);
  }

  @Action(PlaylistAdminActions.RemoveContentAtMainSection)
  removeContentAtMainSection(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    action: PlaylistAdminActions.RemoveContentAtMainSection
  ) {
    this.store.dispatch(new PlaylistAdminActions.InitAutoSave(action.languageCode));
    return PlaylistMainSectionActionHelpers.removeContentAtMainSection(getState(), patchState, action);
  }

  @Action(PlaylistAdminActions.AddContentAtMainSection)
  addContentAtMainSection(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    action: PlaylistAdminActions.AddContentAtMainSection
  ) {
    this.store.dispatch(new PlaylistAdminActions.InitAutoSave(action.languageCode));
    return PlaylistMainSectionActionHelpers.addContentAtMainSection(getState(), patchState, action);
  }

  @Action(PlaylistAdminActions.TogglePositionOnMainPlaylistSection)
  togglePositionOnMainPlaylistSection(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { languageCode }: PlaylistAdminActions.TogglePositionOnMainPlaylistSection
  ) {
    PlaylistMainSectionActionHelpers.togglePositionOnMainPlaylistSection(getState(), patchState);
    this.store.dispatch(new PlaylistAdminActions.SaveCurrentChanges(languageCode));
  }

  @Action(PlaylistAdminActions.CreateSection)
  createSection(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>
  ) {
    return PlaylistNewSectionActionHelpers.createSection(getState(), patchState,
      this.playlistService, this.snackBar, this.ngZone);
  }

  @Action(PlaylistAdminActions.DeleteNewSection)
  deleteNewSection(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    action: PlaylistAdminActions.DeleteNewSection
  ) {
    return PlaylistNewSectionActionHelpers.deleteNewSection(getState(), patchState, action,
      this.playlistService, this.snackBar, this.ngZone);
  }

  @Action(PlaylistAdminActions.AddDynamicContentToNewSection)
  addDynamicContentToNewSection(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    action: PlaylistAdminActions.AddDynamicContentToNewSection
  ) {
    this.store.dispatch(new PlaylistAdminActions.InitAutoSave(action.languageCode));
    return PlaylistNewSectionActionHelpers.addDynamicContentToNewSection(getState(), patchState, action);
  }

  @Action(PlaylistAdminActions.UpdateDynamicContentOfNewSection)
  updateDynamicContentOfNewSection(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    action: PlaylistAdminActions.UpdateDynamicContentOfNewSection
  ) {
    if (!EditorHelper.isSameContent(action.updateContent, action.oldContent) || action.updateContent.type === 'EMBED_CODE') {
      this.store.dispatch(new PlaylistAdminActions.InitAutoSave(action.languageCode));
    }
    return PlaylistNewSectionActionHelpers.updateDynamicContentOfNewSection(getState(), patchState, action);
  }

  @Action(PlaylistAdminActions.RemoveDynamicContentFromNewSection)
  removeDynamicContentFromNewSection(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    action: PlaylistAdminActions.RemoveDynamicContentFromNewSection
  ) {
    this.store.dispatch(new PlaylistAdminActions.InitAutoSave(action.languageCode));
    return PlaylistNewSectionActionHelpers.removeDynamicContentFromNewSection(getState(), patchState, action);
  }

  @Action(PlaylistAdminActions.ReorderPlaylistSections)
  reorderPlaylistSections(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    action: PlaylistAdminActions.ReorderPlaylistSections
  ) {
    return PlaylistNewSectionActionHelpers.reorderPlaylistSections(getState(), patchState, action,
      this.playlistService, this.snackBar, this.ngZone);
  }

  @Action(PlaylistAdminActions.RemoveAllContentFromNewSection)
  removeAllContentFromNewSection(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    action: PlaylistAdminActions.RemoveAllContentFromNewSection
  ) {
    PlaylistNewSectionActionHelpers.removeAllContentFromNewSection(getState(), patchState, action);
    this.store.dispatch(new PlaylistAdminActions.SaveCurrentChanges(action.languageCode));
  }

  @Action(PlaylistAdminActions.SetPlaylistCoverImage)
  seyPlaylistCoverImage(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    action: PlaylistAdminActions.SetPlaylistCoverImage
  ) {
    PlaylistMainSectionActionHelpers.setPlaylistCoverImage(getState(), patchState, action);
    this.store.dispatch(new PlaylistAdminActions.InitAutoSave(action.languageCode));
  }

  @Action(PlaylistAdminActions.SetPlaylistCoverImageUrl)
  seyPlaylistCoverImageUrl(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    action: PlaylistAdminActions.SetPlaylistCoverImageUrl
  ) {
    PlaylistMainSectionActionHelpers.setPlaylistUploadUrl(getState(), patchState, action);
    this.store.dispatch(new PlaylistAdminActions.InitAutoSave(action.languageCode));
  }

  @Action(PlaylistAdminActions.FireNewSectionPaletteAction)
  fireNewSectionPaletteAction(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    action: PlaylistAdminActions.FireNewSectionPaletteAction
  ) {
    return PlaylistNewSectionActionHelpers.fireNewSectionPaletteAction(getState(), patchState, action, this.store);
  }

  @Action(PlaylistAdminActions.FireMainSectionPaletteAction)
  fireMainSectionPaletteAction(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    action: PlaylistAdminActions.FireMainSectionPaletteAction
  ) {
    return PlaylistMainSectionActionHelpers.fireMainSectionPaletteAction(getState(), patchState, action, this.store);
  }

  @Action(PlaylistAdminActions.ChangeMainSectionCardLayoutType)
  changeMainSectionCardLayoutType(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { playlistUid, mainSectionUid, cardsLayoutType }: PlaylistAdminActions.ChangeMainSectionCardLayoutType
  ) {
    const requestBody = {
      newCardsLayoutType: cardsLayoutType,
    };

    return this.playlistService.changeCardsLayout(
      playlistUid,
      mainSectionUid,
      requestBody
    ).pipe(
      tap(({ isSuccess, error }) => {
        if (isSuccess) {
          patchState({
            playlist: {
              ...getState().playlist,
               data: {
              ...getState().playlist.data,
              mainSection: {
                ...getState().playlist.data.mainSection,
                cardsLayoutType: cardsLayoutType,
              },
              contentChanged: true,
            }}

          });
        } else {
          SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
        }
      })
    );
  }

  @Action(PlaylistAdminActions.ChangeSectionCardLayoutType)
  changeSectionCardLayoutType(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { playlistUid, sectionUid, sectionIndex, cardsLayoutType }: PlaylistAdminActions.ChangeSectionCardLayoutType
  ) {
    const state = getState();
    const requestBody = {
      newCardsLayoutType: cardsLayoutType,
    };

    return this.playlistService.changeCardsLayout(playlistUid, sectionUid, requestBody).pipe(
      tap(({ isSuccess, error }) => {
        if (isSuccess) {
          patchState({
            playlist: {
              ...state.playlist,
              data: {
                ...state.playlist.data,
                standardSections: state.playlist.data.standardSections.map((section, idx) => idx === sectionIndex
                  ? ({
                    ...section,
                    cardsLayoutType: cardsLayoutType,
                  })
                  : section),
                contentChanged: true,
              }
            } ,
          });
        } else {
          SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
        }
      })
    );
  }

  @Action(PlaylistAdminActions.ChangeSectionCardsOrdering)
  changeSectionCardsOrdering(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { playlistUid, sectionUid, sectionIndex, cardsIds }: PlaylistAdminActions.ChangeSectionCardsOrdering
  ) {
    const state = getState();
    const requestBody = { cards: cardsIds };

    return this.playlistService.updatePlaylistSectionCards(playlistUid, sectionUid, requestBody).pipe(
      tap(({ isSuccess, error }) => {
        if (isSuccess) {
          patchState({
            playlist: {
              ...state.playlist,
              data: {
                ...state.playlist.data,
                standardSections: state.playlist.data.standardSections.map((section, idx) => idx === sectionIndex
                  ? ({
                    ...section,
                    cards: this.getSortedPlaylistCards(section.cards, cardsIds),
                  }) : section
                ),
                contentChanged: true,
              },
            },
          });
        } else {
          SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
        }
      })
    );
  }

  @Action(PlaylistAdminActions.ChangeMainSectionCardsOrdering)
  changeMainSectionCardsOrdering(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { playlistUid, sectionUid, cardsIds }: PlaylistAdminActions.ChangeMainSectionCardsOrdering
  ) {
    const state = getState();
    const cards = state.playlist.data.mainSection.cards;
    const requestBody = { cards: cardsIds };

    return this.playlistService.updatePlaylistSectionCards(playlistUid, sectionUid, requestBody).pipe(
      tap(({ isSuccess, error }) => {
        if (isSuccess) {
          patchState({
            playlist: {
              ...state.playlist,
              data:  {
                ...state.playlist.data,
                mainSection: {
                  ...state.playlist.data.mainSection,
                  cards: this.getSortedPlaylistCards(cards, cardsIds),
                },
                contentChanged: true,
              }
            }
           ,
          });
        } else {
          SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
        }
      })
    );
  }


  @Action(PlaylistAdminActions.ChangeCardPositionSection)
  changeCardPositionSection(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { playlistUid, fromSectionUid, toSectionUid, cardUid }: PlaylistAdminActions.ChangeCardPositionSection
  ) {
    const playlist = getState().playlist.data;
    const updatedSection = playlist.mainSection.uid === toSectionUid ?
    playlist.mainSection : playlist.standardSections.find(section => section.uid === toSectionUid);
    const requestBody = { cards: this.getCardsIdArray(updatedSection.cards) };
    return this.playlistService.movePlaylistCardFromSections(playlistUid, fromSectionUid, toSectionUid, cardUid).pipe(
      tap(({ isSuccess, error }) => {
        if (isSuccess) {
          patchState({
            playlist: {
              ...getState().playlist,
              data: {
                ...playlist,
              }
            },
          });
          this.playlistService.updatePlaylistSectionCards(playlistUid, toSectionUid, requestBody).subscribe();
        } else {
          SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
        }
      })
    );
  }


  @Action(PlaylistAdminActions.CreateNewCardInSection)
  createNewCardInSection(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { playlistSectionIndex }: PlaylistAdminActions.CreateNewCardInSection
  ) {
    const state = getState();
    this.store.dispatch(new SavePlaylistSectionIndexForNewCard(
      playlistSectionIndex, state.playlist.data.mainSection, state.playlist.data.standardSections));
    return this.savePlaylistAndCreateCard(getState(), patchState, 'NEW_CARD', playlistSectionIndex);
  }

  @Action(PlaylistAdminActions.CreateNewCardInMainSection)
  createNewCardInMainSection(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>
  ) {
    const state = getState();
    this.store.dispatch(new SavePlaylistSectionIndexForNewCard(
      -1, state.playlist.data.mainSection, state.playlist.data.standardSections)); // -1 for mainSection
    return this.savePlaylistAndCreateCard(getState(), patchState, 'NEW_CARD', -1);
  }

  @Action(PlaylistAdminActions.CreateNewSection)
  createNewSection(
    { getState }: StateContext<PlaylistCreationStateModel>,
  ) {
    const state = getState();

    return this.store.dispatch(new PlaylistAdminActions.CreateSection()).toPromise().then(() => {
      RedirectHelper.redirectByParams(this.ngZone, this.router, this.activatedRoute, {
        playlistUri: state.playlist.data.uri,
        formattedUri: state.playlist.data.formattedUri,
        extraUriParam: 'edit'
      }, 'PLAYLIST');
    });
  }

  @Action(PlaylistAdminActions.CreateNewEventCardInSection)
  createNewEventCardInSection(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { playlistSectionIndex }: PlaylistAdminActions.CreateNewEventCardInSection
  ) {
    const state = getState();
    this.store.dispatch(new SavePlaylistSectionIndexForNewCard(
      playlistSectionIndex, state.playlist.data.mainSection, state.playlist.data.standardSections));
    return this.savePlaylistAndCreateCard(getState(), patchState, 'NEW_EVENT_CARD', playlistSectionIndex);
  }

  @Action(PlaylistAdminActions.CreateNewEventCardInMainSection)
  createNewEventCardInMainSection(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>
  ) {
    const state = getState();
    this.store.dispatch(new SavePlaylistSectionIndexForNewCard(
      -1, state.playlist.data.mainSection, state.playlist.data.standardSections)); // -1 for mainSection
    return this.savePlaylistAndCreateCard(getState(), patchState, 'NEW_EVENT_CARD', -1);
  }

  @Action(PlaylistAdminActions.RedirectToDiagnosticsCreation)
  redirectToDiagnosticsCreation(
    { getState }: StateContext<PlaylistCreationStateModel>,
    { diagnosticUri, formattedUri }: PlaylistAdminActions.RedirectToDiagnosticsCreation
  ) {
    const playlistUri = getState().playlist.data.uri;
    this.redirectToDiagnosticsCreationPage(playlistUri, diagnosticUri, formattedUri);
  }

  @Action(PlaylistAdminActions.RemoveCardFromSection)
  removeCardFromSection(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    action: PlaylistAdminActions.RemoveCardFromSection
  ) {
    return PlaylistNewSectionActionHelpers.removeCardFromSection(getState(), patchState, action,
      this.playlistService, this.snackBar, this.ngZone, this.store);
  }

  @Action(PlaylistAdminActions.RemoveCardFromMainSection)
  removeCardFromMainSection(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    action: PlaylistAdminActions.RemoveCardFromMainSection
  ) {
    return PlaylistMainSectionActionHelpers.removeCardFromMainSection(getState(), patchState, action,
      this.playlistService, this.snackBar, this.ngZone, this.store);
  }

  @Action(PlaylistAdminActions.AssociateNewTag)
  associateNewTag(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { playlistId, title }: PlaylistAdminActions.AssociateNewTag
  ) {
    const state = getState();
    const foundTag = state.filteredTags?.find(tag => tag.title === title);
    if (!foundTag) {
      return this.tagService.createTag({ title: title }).pipe(
        switchMap(({ isSuccess, value, error }) => {
          if (isSuccess) {
            return this.addTagToPlaylist(playlistId, value, patchState, state);
          } else {
            const errorObject: TagErrorObject = JSON.parse(error);
            SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, errorObject.errorMessage);

            return ObservableResult.ofError(errorObject.errorMessage);
          }
        })
      );
    } else {
      return this.addTagToPlaylist(playlistId, foundTag, patchState, state);
    }
  }

  @Action(PlaylistAdminActions.AssociateExistingTag)
  associateExistingTag(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { playlistId, tag }: PlaylistAdminActions.AssociateExistingTag
  ) {
    const state = getState();
    return this.addTagToPlaylist(playlistId, tag, patchState, state);
  }

  private addTagToPlaylist(
    playlistId: string,
    tag: ResourceTag,
    patchState: (val: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    state: PlaylistCreationStateModel
  ) {
    return this.playlistService.associateTag(playlistId, { tagUid: tag._id }).pipe(
      tap(({ isSuccess }) => {
        if (isSuccess) {
          patchState({
            tags: [...state.tags, { ...tag, playlistTag: true }],
          });
        } else {
          SnackbarHelper.showTranslatableSnackBar(
            this.ngZone, this.snackBar, this.translationService, 'framework.message.error.associateTagFailed'
          );
        }
      })
    );
  }

  @Action(PlaylistAdminActions.DissociateTag)
  removeTag(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { playlistUid, tag }: PlaylistAdminActions.DissociateTag
  ) {
    return this.playlistService.dissociateTag(playlistUid, tag._id).pipe(
      tap(({ isSuccess }) => {
        if (isSuccess) {
          patchState({
            tags: getState().tags.filter(t => t !== tag),
          });
        } else {
          SnackbarHelper.showTranslatableSnackBar(
            this.ngZone, this.snackBar, this.translationService, 'framework.message.error.dissociateTagFailed'
          );
        }
      })
    );
  }

  @Action(PlaylistAdminActions.FilterTags)
  filterTags(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { text }: PlaylistAdminActions.FilterTags
  ) {
    const updateState = (newFilteredTags) => {
      patchState({
        filteredTags: newFilteredTags,
      });
    };
    if (text) {
      return this.tagService.findTagsWithTitleTerm(text).pipe(
        tap(({ value }) => updateState(value))
      );
    } else {
      return updateState([]);
    }
  }

  @Action(PlaylistAdminActions.AssociateNewFeedTag)
  associateNewFeedTag(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { playlistId, title }: PlaylistAdminActions.AssociateNewFeedTag
  ) {
    const state = getState();
    const foundTag = state.filteredFeedTags?.find(tag => tag.title === title);
    if (!foundTag) {
      return this.tagService.createTag({ title: title }).pipe(
        switchMap(({ isSuccess, value, error }) => {
          if (isSuccess) {
            return this.addFeedTagToPlaylist(playlistId, value, patchState, state);
          } else {
            const errorObject: TagErrorObject = JSON.parse(error);
            SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, errorObject.errorMessage);

            return ObservableResult.ofError(errorObject.errorMessage);
          }
        })
      );
    } else {
      return this.addFeedTagToPlaylist(playlistId, foundTag, patchState, state);
    }
  }

  @Action(PlaylistAdminActions.AssociateExistingFeedTag)
  associateExistingFeedTag(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { playlistId, tag }: PlaylistAdminActions.AssociateExistingFeedTag
  ) {
    const state = getState();
    return this.addFeedTagToPlaylist(playlistId, tag, patchState, state);
  }

  private addFeedTagToPlaylist(
    playlistId: string,
    tag: ResourceTag,
    patchState: (val: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    state: PlaylistCreationStateModel
  ) {
    return this.playlistService.associateFeedTag(playlistId, { tagUid: tag._id }).pipe(
      tap(({ isSuccess }) => {
        if (isSuccess) {
          const tags = state.feedTags ? state.feedTags : [];
          patchState({
            feedTags: [...tags, { ...tag, playlistTag: true }],
          });
        } else {
          SnackbarHelper.showTranslatableSnackBar(
            this.ngZone, this.snackBar, this.translationService, 'framework.message.error.associateTagFailed'
          );
        }
      })
    );
  }

  @Action(PlaylistAdminActions.DissociateFeedTag)
  removeFeedTag(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { playlistUid, tag }: PlaylistAdminActions.DissociateFeedTag
  ) {
    return this.playlistService.dissociateFeedTag(playlistUid, tag._id).pipe(
      tap(({ isSuccess }) => {
        if (isSuccess) {
          patchState({
            feedTags: getState().feedTags.filter(t => t !== tag),
          });
        } else {
          SnackbarHelper.showTranslatableSnackBar(
            this.ngZone, this.snackBar, this.translationService, 'framework.message.error.dissociateTagFailed'
          );
        }
      })
    );
  }


  @Action(PlaylistAdminActions.DissociateAllFeedTags)
  removeAllFeedTags(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { playlistUid }: PlaylistAdminActions.DissociateAllFeedTags
  ) {
    return this.playlistService.dissociateAllFeedTags(playlistUid).pipe(
      tap(({ isSuccess }) => {
        if (isSuccess) {
          patchState({
            feedTags: [],
            filteredFeedTags: [],
          });
        } else {
          SnackbarHelper.showTranslatableSnackBar(
            this.ngZone, this.snackBar, this.translationService, 'framework.message.error.dissociateTagFailed'
          );
        }
      })
    );
  }

  @Action(PlaylistAdminActions.FilterFeedTags)
  filterFeedTags(
    { patchState }: StateContext<PlaylistCreationStateModel>,
    { text }: PlaylistAdminActions.FilterFeedTags
  ) {
    const updateState = (newFilteredTags) => {
      patchState({
        filteredFeedTags: newFilteredTags,
      });
    };
    if (text) {
      return this.tagService.findTagsWithTitleTerm(text).pipe(
        tap(({ value }) => updateState(value))
      );
    } else {
      return updateState([]);
    }
  }

  @Action(PlaylistAdminActions.AssociateCategory)
  associateCategory(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { playlistId, categoryTag }: PlaylistAdminActions.AssociateCategory
  ) {
    const state = getState();
    return this.playlistService.associateCategory(playlistId, { categoryTagUid: categoryTag._id }).pipe(
      tap(({ isSuccess, error }) => {
        if (isSuccess) {
          patchState({
            categories: [...state.categories, { ...categoryTag, playlistTag: false }],
          });
        } else {
          SnackbarHelper.showSnackBar(
            this.ngZone, this.snackBar, error
          );
        }
      })
    );
  }

  @Action(PlaylistAdminActions.DissociateCategory)
  removeCategory(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { playlistUid, categoryTag }: PlaylistAdminActions.DissociateCategory
  ) {
    return this.playlistService.dissociateCategory(playlistUid, categoryTag._id).pipe(
      tap(({ isSuccess, error }) => {
        if (isSuccess) {
          patchState({
            categories: getState().categories.filter(c => c !== categoryTag),
          });
        } else {
          SnackbarHelper.showSnackBar(
            this.ngZone, this.snackBar, error
          );
        }
      })
    );
  }

  @Action(PlaylistAdminActions.FilterCategories)
  filterCategories(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { text }: PlaylistAdminActions.FilterCategories
  ) {
    const existingCategories = getState().categories;
    const updateState = (newFilteredCategories) => {
      patchState({
        filteredCategories: newFilteredCategories,
      });
    };
    if (text) {
      return this.tagService.findCategoriesWithTitleTerm(text).pipe(
        tap(({ value }) => {
          const filteredCategories = value.filter((category) => existingCategories.findIndex((c) => c._id === category._id) === -1);
          updateState(filteredCategories)
        })
      );
    } else {
      return updateState([]);
    }
  }

  @Action(PlaylistAdminActions.CreateNewDiagnosticsCardMainSection)
  createNewDiagnosticsCardMainSection(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>
  ) {
    const state = getState();
    this.store.dispatch(new SavePlaylistSectionIndexForNewCard(
      -1, state.playlist.data.mainSection, state.playlist.data.standardSections)); // -1 for mainSection
    return this.savePlaylistAndCreateCard(getState(), patchState, 'DIAGNOSTICS', -1);
  }

  @Action(PlaylistAdminActions.CreateNewDiagnosticsSection)
  createNewDiagnosticsSection(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { sectionIndex }: PlaylistAdminActions.CreateNewDiagnosticsSection
  ) {
    const state = getState();
    this.store.dispatch(new SavePlaylistSectionIndexForNewCard(
      sectionIndex, state.playlist.data.mainSection, state.playlist.data.standardSections));
    return this.savePlaylistAndCreateCard(getState(), patchState, 'DIAGNOSTICS', sectionIndex);
  }

  @Action(PlaylistAdminActions.SavePlaylistAndNavigateToSubPlaylistCreation)
  savePlaylistAndNavigateToSubPlaylistCreation(
    { getState, patchState }: StateContext<PlaylistCreationStateModel>,
    { sectionIndex }: PlaylistAdminActions.SavePlaylistAndNavigateToSubPlaylistCreation
  ) {
    return PlaylistSaveHelper.savePlaylistAndRedirectToSubPlaylistCreation(
      getState(),
      patchState,
      this.playlistService,
      this.store,
      this.snackBar,
      this.fileUploadService,
      this.router,
      this.ngZone,
      this.activatedRoute,
      this.translationService,
      sectionIndex
    );
  }

  private redirectToResourceCreationPage(playlistUri: string, uri: string, formattedUri: string, sectionIndex: number) {
    const url = RedirectHelper.getRedirectUrl(this.activatedRoute, {
      playlistUri: playlistUri,
      resourceUri: uri,
      pageNumberUri: 'page/1',
      formattedUri: formattedUri,
      extraUriParam: 'edit',
      queryUriParam: `sectionIndex=${sectionIndex}&create=1`,
    }, 'RESOURCE');
    window.history.replaceState('', '', url);
    RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, url);
  }

  private redirectToEventCardCreationPage(playlistUri: string, uri: string, formattedUri: string, sectionIndex: number) {
    const url = RedirectHelper.getRedirectUrl(this.activatedRoute, {
      playlistUri: playlistUri,
      resourceUri: uri,
      pageNumberUri: 'page/1',
      formattedUri: formattedUri,
      extraUriParam: 'edit',
      queryUriParam: `sectionIndex=${sectionIndex}&create=1`,
    }, 'RESOURCE');
    window.history.replaceState('', '', url);
    RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, url);
  }

  private redirectToProjectResourceCreationPage(
    folioPublicId: string,
    playlistUri: string,
    uri: string,
    formattedUri: string,
    sectionIndex: number
  ) {
    const url = RedirectHelper.getRedirectUrl(this.activatedRoute, {
      folioPublicId: folioPublicId,
      playlistUri: playlistUri,
      resourceUri: uri,
      pageNumberUri: 'page/1',
      formattedUri: formattedUri,
      extraUriParam: 'edit',
      queryUriParam: `sectionIndex=${sectionIndex}&create=1`,
    }, 'PROJECT_CARD');
    window.history.replaceState('', '', url);
    RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, url);
  }

  private savePlaylistAndCreateCard(
    state: PlaylistCreationStateModel,
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    action: 'NEW_CARD' | 'DIAGNOSTICS' | 'NEW_EVENT_CARD',
    sectionIndex?: number
  ) {
    return PlaylistSaveHelper.savePlaylistAndCreateCard(
      state,
      patchState,
      this.playlistService,
      this.projectService,
      this.store,
      this.snackBar,
      this.ngZone,
      this.translationService,
      action,
      this.fileUploadService,
      sectionIndex
    );
  }

  private navigateToRoot() {
    const homePageUri = this.store.selectSnapshot(UserAuthState.homePageUri);
    RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, homePageUri);
  }

  private navigateToPlaylistView(playlistUri: string, formattedUri: string) {
    RedirectHelper.redirectByParams(this.ngZone, this.router, this.activatedRoute, {
      playlistUri: playlistUri,
      formattedUri: formattedUri
    }, 'PLAYLIST');
  }

  private redirectToDiagnosticsCreationPage(playlistUri: string, diagnosticUri: string, formattedUri: string) {
    RedirectHelper.redirectByParams(this.ngZone, this.router, this.activatedRoute, {
      playlistUri: playlistUri,
      resourceUri: diagnosticUri,
      formattedUri: formattedUri,
      pageNumberUri: 'page/1',
      extraUriParam: 'edit',
      queryUriParam: 'create=1'
    }, 'ASSESSMENT');
  }

  private getSortedPlaylistCards(cards, cardsIds): PlaylistCardShort[] {
    const cardsCopy = JSON.parse(JSON.stringify(cards));

    return cardsCopy.map(item => {
      const n = cardsIds.indexOf(item[1]);
      cardsIds[n] = '';
      return [n, item];
    }).sort().map(j => j[1]);
  }

  private getCardsIdArray(cards: PlaylistCardShort[]) {
    const cardsCopy = JSON.parse(JSON.stringify(cards));
    return cardsCopy.map(card => {
      return card._id;
    });
  }

  private patchCurrentChangesState(playlistContent: LoadableState<PlaylistResourceModel>, value: UpdatePlaylistResponse) {
    return {
        playlist: {
          ...playlistContent,
          data: {
            ...playlistContent.data,
            mainSection: {
              ...playlistContent.data.mainSection,
              content: PlaylistSaveHelper.getPlaylistUpdatedSectionContent(
                playlistContent.data.mainSection,
                value.mainSection,
                playlistContent.data.mainSection.uid,
              ),
            },
            standardSections: playlistContent.data.standardSections.map(( section, idx ) =>
              ({
                ...section,
                content: PlaylistSaveHelper.getPlaylistUpdatedSectionContent(
                  playlistContent.data.standardSections[idx],
                  value.standardSections[idx],
                  section.uid,
                ),
              })),
          }
        },
        saveStatus: {
          saveInProgress: false,
          autoSaveInProgress: false,
        },
      };
    }
}
