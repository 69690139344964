<!--
  ~ Copyright (C) 2022 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ng-container>
  <div class="member-filter__wrapper">
    <div class="member-filter__dropdowns">
      <mat-select
        [(value)]="selectedPlaylistStatus"
        (selectionChange)="onPlaylistStatusChange($event)"
        class="member-filter__dropdown">
        <mat-option *ngFor="let playlistStatus of playlistStatuses" [value]="playlistStatus.value">{{ playlistStatus.title }}</mat-option>
      </mat-select>
      <div class="member-filter__dropdown member-filter__search" *ngIf="!isPlaylistPage">
        <input
          matInput
          [matAutocomplete]="auto"
          [(ngModel)]="searchString"
          [placeholder]="('translations.members.filter.playlist.find'| transloco)"
          [ngModelOptions]="{standalone: true}"
          (paste)="onTextPaste($event)"
          (keyup)="onSearchInputChange($event)"/>
        <mat-autocomplete
          [panelWidth]="450"
          #auto="matAutocomplete"
          [displayWith]="searchAutocompleteFormat"
          (optionSelected)="onPlaylistSelected($event)">
          <mat-option
            *ngFor="let playlist of searchedPlaylists"
            [value]="playlist">
            <div>{{ playlist.title }}</div>
          </mat-option>
        </mat-autocomplete>

        <mat-icon aria-hidden="true" matSuffix class="flex">
          <mat-spinner *ngIf="loadingSearch" [diameter]="20">
          </mat-spinner>
        </mat-icon>
      </div>
      <div class="member-filter__filler">
        <div *ngFor="let selectedPlaylist of selectedPlaylists" class="selected-playlist">
          <span>{{ selectedPlaylist.title }}</span>
          <span
            class="selected-playlist__remove"
            [matTooltip]="('translations.members.filter.playlist.remove' | transloco)"
            [attr.aria-label]="('translations.global.ariaLabels.clear' | transloco: {title: selectedPlaylist.title})"
            (click)="onPlaylistRemove(selectedPlaylist.id)"
            (keydown.enter)="onPlaylistRemove(selectedPlaylist.id)"
            tabindex="0">
            <mat-icon aria-hidden="true" [inline]="true">clear</mat-icon>
          </span>
        </div>
      </div>
    </div>
    <p *ngIf="noPlaylist" class="member-filter__validation">
      {{ 'translations.members.filter.playlist.noPlaylist' | transloco }}
    </p>
  </div>
  <ptl-member-filter-controls (cancel)="onCancel()" (save)="onSave()"></ptl-member-filter-controls>
</ng-container>
