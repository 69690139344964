import { BooleanFilter } from '../../../../../shared/models/admin/boolean-filters.model';

export const INITIAL_MEMBERS_STATE: MembersStateModel = {
  selectedMembers: [],
  memberFilters: [],
  cardFilters: [],
  playlistFilters: [],
  playlistReviewFilters: []
};

export interface MembersStateModel {
  selectedMembers: string[];
  memberFilters: BooleanFilter[];
  cardFilters: CardFilter[];
  playlistFilters: PlaylistFilter[];
  playlistReviewFilters: PlaylistFilter[];
}

export interface CardFilter {
  cardUid: string;
  value: BooleanFilter[];
}

export interface PlaylistFilter {
  playlistUid: string;
  value: BooleanFilter[];
}
