import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  BooleanFilter,
  BooleanQueryRequest,
  BooleanQueryType,
  FormStatusRequest,
  NotRequest
} from '../../../../../../../shared/models/admin/boolean-filters.model';
import { TranslationService } from '../../../../../../../shared/services/translation/translation.service';
import { MatSelectChange } from '@angular/material/select';
import { FormHeaders } from '../../../../../../../shared/models/admin/members.model';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'ptl-form-member-filter',
  templateUrl: './form-member-filter.component.html',
  styleUrls: ['./form-member-filter.component.scss'],
})
export class FormMemberFilterComponent implements OnChanges, OnInit {

  @Input() formHeaders: FormHeaders[];
  @Input() excludedFormsTitle: string[];
  @Input() excludedFormTitleStatuses: string[];
  @Output() setFormFilter = new EventEmitter<BooleanFilter>();
  @Output() cancelFormFilter = new EventEmitter<void>();

  selectedFormStatus: 'COMPLETED' | 'NOT_COMPLETED' | 'STARTED' | 'NOT_STARTED' = 'COMPLETED';
  allFormStatuses: { value: 'COMPLETED' | 'NOT_COMPLETED' | 'STARTED' | 'NOT_STARTED'; title: string }[] = [];
  formStatuses: { value: 'COMPLETED' | 'NOT_COMPLETED' | 'STARTED' | 'NOT_STARTED'; title: string }[] = [];

  selectedForms: { id: string; header: string }[];
  noForm = false;

  constructor(
    private translationService: TranslationService,
  ) {
  }

  ngOnInit() {
    this.allFormStatuses = [
      { value: 'COMPLETED', title: this.translationService.getTranslation('members.filter.resources.completed') },
      {
        value: 'NOT_COMPLETED',
        title: this.translationService.getTranslation('members.filter.resources.notCompleted')
      },
      { value: 'STARTED', title: this.translationService.getTranslation('members.filter.resources.started') },
      { value: 'NOT_STARTED', title: this.translationService.getTranslation('members.filter.resources.notStarted') }
    ];
    this.updateStatuses();
    this.selectedForms = [];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.excludedCardStatuses) {
      this.updateStatuses();
    }
  }

  onSave() {
    if (!this.selectedForms.length) {
      this.noForm = true;
      return;
    }
    this.noForm = false;
    if (this.selectedForms.length > 1) {
      this.setFilters();
    } else {
      this.setFilter(this.selectedForms[0].id, this.selectedForms[0].header);
    }
  }

  onCancel() {
    this.cancelFormFilter.emit();
  }

  onFormStatusChange(event: MatSelectChange) {
    this.selectedFormStatus = event.value;
  }

  onFormSelected({ option }: MatAutocompleteSelectedEvent) {
    this.selectedForms.push({ id: option.value.id, header: option.value.header });
  }

  onFormHeaderSelected(event: MatSelectChange) {
    this.selectedForms.push({ id: event.value.formId, header: event.value.header });
  }

  onFormRemove(formId: string) {
    this.selectedForms = this.selectedForms.filter(selectedForm => selectedForm.id !== formId);
  }

  private setFilter(formUid: string, formHeader?: string) {
    const result = {
      request: this.getRequest(formUid),
      message: this.getMessage(formHeader ?? formUid)
    };
    this.setFormFilter.emit(result);
  }

  private setFilters() {
    const result = {
      request: this.getRequests(),
      message: this.getMessages()
    };
    this.setFormFilter.emit(result);
  }

  private getMessage(title: string): string {
    let message = this.getStatus();
    message += ' ' + `'${title}'`;
    return message;
  }

  private getMessages() {
    let message = this.getStatus();
    message += (' ' + this.selectedForms.map(form => `'${form.header ?? form.id}'`).join(', '));
    return message;
  }

  private getStatus(): string {
    switch (this.selectedFormStatus) {
      case 'COMPLETED':
        return this.translationService.getTranslation('members.filter.summary.completed');
      case 'NOT_COMPLETED':
        return this.translationService.getTranslation('members.filter.summary.notCompleted');
      case 'STARTED':
        return this.translationService.getTranslation('members.filter.summary.started');
      case 'NOT_STARTED':
        return this.translationService.getTranslation('members.filter.summary.notStarted');
    }
  }

  private getRequests(): BooleanQueryRequest[] {
    const requests = [];
    for (const form of this.selectedForms) {
      requests.push(this.getRequest(form.id));
    }
    return requests;
  }

  private getRequest(formUid: string): BooleanQueryRequest {

    const isNotRequest = this.isStatusNegated();
    const absoluteStatus = this.getAbsoluteStatus();
    const formRequest: FormStatusRequest = {
      type: BooleanQueryType.FORM_STATUS,
      uid: formUid,
      status: absoluteStatus
    };

    if (isNotRequest) {
      return {
        type: BooleanQueryType.NOT,
        arg: formRequest
      } as NotRequest;
    } else {
      return formRequest;
    }
  }

  private isStatusNegated(): boolean {
    return this.selectedFormStatus.startsWith('NOT');
  }

  private getAbsoluteStatus(): 'STARTED' | 'COMPLETED' {
    if (this.selectedFormStatus === 'NOT_STARTED') {
      return 'STARTED';
    } else if (this.selectedFormStatus === 'NOT_COMPLETED') {
      return 'COMPLETED';
    } else {
      return this.selectedFormStatus;
    }
  }

  private updateStatuses() {
    this.formStatuses = this.allFormStatuses.filter(
      status => !this.excludedFormTitleStatuses.includes(status.value)
    );
    this.formStatuses = this.formStatuses.filter(
      status => !this.excludedFormTitleStatuses.includes(this.opposite(status.value))
    );
    if (this.formStatuses.length) {
      this.selectedFormStatus = this.formStatuses[0].value;
    }
  }

  private opposite(status: 'STARTED' | 'COMPLETED' | 'NOT_STARTED' | 'NOT_COMPLETED'): string {
    switch (status) {
      case 'STARTED':
        return 'NOT_STARTED';
      case 'COMPLETED':
        return 'NOT_COMPLETED';
      case 'NOT_STARTED':
        return 'STARTED';
      case 'NOT_COMPLETED':
        return 'COMPLETED';
    }
  }
}
