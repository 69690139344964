<!--
  ~ Copyright (C) 2022 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ng-container>
  <div class="member-filter__wrapper">
    <div class="member-filter__dropdowns">
      <mat-select
        [(value)]="selectedFormStatus"
        (selectionChange)="onFormStatusChange($event)"
        class="member-filter__dropdown">
        <mat-option *ngFor="let formStatus of formStatuses" [value]="formStatus.value">{{ formStatus.title }}</mat-option>
      </mat-select>
      <mat-select
        [(value)]="selectedAmountOperator"
        (selectionChange)="onAmountOperatorChange($event)"
        class="member-filter__dropdown">
        <mat-option *ngFor="let amountOperator of amountOperators" [value]="amountOperator.value">{{ amountOperator.title }}</mat-option>
      </mat-select>
      <div class="resources-filter__number">
        <input
          matInput
          type="number"
          class="resources-filter__number__input"
          pattern="^\d+$"
          min="0"
          [max]="formTotalNumber"
          [(ngModel)]="formsNumber"/>
      </div>
      <div class="member-filter__goals">
        <span>{{ ('translations.members.filter.form.of' | transloco)
        + ' ' + formTotalNumber + ' ' + ('translations.members.filter.form.title' | transloco)}}</span>
      </div>
    </div>
    <p *ngIf="noNumber" class="member-filter__validation">
      {{ 'translations.members.filter.form.noNumber' | transloco }}
    </p>
  </div>
  <ptl-member-filter-controls (cancel)="onCancel()" (save)="onSave()"></ptl-member-filter-controls>
</ng-container>
