/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { MemberRecordPlaylistCardLearnerAnswersResponse } from 'src/app/shared/models/admin/members.model';
import { Review } from '../../../shared/models/review/review.model';
import { EditorContent, Form } from 'src/app/editor/models';
import { RequirementsMode } from '../../playlist/models';

export interface ReviewItem {
  uid: string;
  uri: string;
  title: string;
  pendingCount: number;
  totalCount: number;
  thumbnail: string;
  deadline: Date;
  reviews: Review[];
  feedbackOnFinalDecisionRequired: boolean;
  reviewerCanEditSubmission: boolean;
}

export interface CommentRequest {
  message: EditorContent[];
}

export interface ReviewRequest {
  comment: string;
}

export interface ReviewCard {
  title: string;
  uri: string;
  cardUid: string;
  learnerFormAnswers: ReviewLearnerFormAnswer[];
  required: boolean;
  completed: boolean;
  hasCardReviewEnabled: boolean;
}

export interface ReviewLearnerFormAnswer {
  uid: string;
  answer: MemberRecordPlaylistCardLearnerAnswersResponse;
  form: Form;
}

export interface UserAnswerRequest {
  formUid: string;
  answer: string;
  requiresResubmission: boolean;
  submitted: boolean;
  submittedOn?: Date;
}

export interface SubmissionsReviewRequest {
  itemId: string;
  reviews: string[];
  comment: string;
}

export interface ReviewRelevantCardWithoutFormSummary {
  title: string;
  formattedUri: string;
  completed: boolean;
  completedOn: Date;
}

export interface ProgressivePlaylistReviewStatus {
  reviewCanBeApproved: boolean;
  playlistRequirementsMode: RequirementsMode;
  minimumFulfilledRelevantCardsCount: number;
  fulfilledRelevantItemCount: number;
  relevantCardsWithoutForms: ReviewRelevantCardWithoutFormSummary[];
}

export interface ReviewActivityStatus {
  title: string;
  status: ReviewActivityStatusType;
  required: boolean;
}

export type ReviewFormUnavailableAction =
  'CARD_LEVEL_REVIEW_ENABLED' |
  'FORM_COMPLETED_IN_CARD_REVIEW' |
  'FORM_ALREADY_ACCEPTED' |
  'FORM_REQUIRES_RESUBMISSION' |
  'FORM_ANSWER_NOT_SUBMITTED' |
  'PLAYLIST_REVIEW_NOT_OPEN_FOR_REVIEWER';

export type ReviewActivityStatusType =
  ReviewFormUnavailableAction |
  'WAITING_FOR_REVIEW';

export enum ReviewAction {
  NEVERMIND = 'NEVERMIND',
  ACCEPTED = 'ACCEPTED',
  RESUBMITTED = 'RESUBMITTED',
  REJECTED = 'REJECTED',
  REOPEN = 'REOPEN',
  CLOSE = 'CLOSE'
}

export type ReviewCardsFilterStatus = 'ALL' | 'SUBMITTED' | 'NOT_SUBMITTED';

export interface ReviewCloseReason {
  listUid?: string;
  listItemUid?: string;
  freeText?: string;
}

export interface ReviewAdminRequest {
  reviewUids: string[];
  reason?: ReviewCloseReason;
}

export interface ReviewerAdminRequest {
  reviewerUids: string[];
}
