/*
 * Copyright (C) 2018 - present by Potentially
 *
 * Please see distribution for license.
 */

import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Members, MembersSearchOrder } from '../../../../../shared/models/admin/members.model';
import { Observable, Subscription } from 'rxjs';
import { Select } from '@ngxs/store';
import { UserAuthState } from '../../../../../user-auth/store/user-auth.state';
import { Organization } from '../../../../../shared/models';

@Component({
  selector: 'ptl-admin-member-list',
  templateUrl: './admin-member-list.component.html',
  styleUrls: ['./admin-member-list.component.scss'],
})
export class AdminMemberListComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() members: Members[];

  @Input() isCardPage: boolean;

  @Input() isPlaylistPage: boolean;

  @Input() membersTotalCount: number;

  @Output() orderChanged = new EventEmitter<{ order: MembersSearchOrder; page: number; pageSize: number }>();

  @Output() membersChecked = new EventEmitter<string[]>();

  @Output() openUserRecord = new EventEmitter<string>();

  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  pageSize = 10;
  currentPage = 0;
  allUsersChecked = false;
  defaultOrderBy = 'lastActiveOn';
  order: 'ASC' | 'DESC' = 'DESC';
  isLoaderActive = false;
  nestedCardIndex: number;
  updateTimeout: number;

  checkedUserIds: string[] = [];

  ngOnInit() {
    this.setTimeOutBasedOnBrowser();
  }

  ngAfterViewInit() {
    if (this.isPlaylistPage) {
      setTimeout(() => {
        this.updateHeight();
      }, this.updateTimeout);
    }
  }

  ngOnChanges() {
    if (this.isPlaylistPage) {
      setTimeout(() => {
        this.updateHeight();
      }, this.updateTimeout);
    }
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    this.allUsersChecked = false;
    this.orderChanged.emit(this.getOrderingData());
  }

  onSizeChange(size: number): void {
    this.pageSize = size;
    this.currentPage = 0;
    this.orderChanged.emit(this.getOrderingData());
  }

  changeListOrdering(orderBy: string) {
    if (orderBy === this.defaultOrderBy) {
      this.order = this.order === 'DESC' ? 'ASC' : 'DESC';
    } else {
      this.order = (orderBy === 'firstName' || orderBy === 'lastName') ? 'ASC' : 'DESC';
    }
    this.defaultOrderBy = orderBy;
    this.orderChanged.emit(this.getOrderingData());
  }

  onOpenMobileMenu(event, index) {
    const clickedElement = event.currentTarget;
    const openMenuItem = clickedElement.querySelector('.f_expand-more-icon');
    const closeMenuItem = clickedElement.querySelector('.f_expand-less-icon');
    const mobileElement = document.getElementById(index);

    closeMenuItem.classList.toggle('active');
    openMenuItem.classList.toggle('active');
    mobileElement.classList.toggle('active');
  }

  onAllCheckboxChange() {
    for (const member of this.members) {
      member.checked = this.allUsersChecked;
      if (this.allUsersChecked) {
        if (this.checkedUserIds.indexOf(member._id) === -1) {
          this.checkedUserIds.push(member._id);
        }
      } else {
        const index = this.checkedUserIds.indexOf(member._id);
        this.checkedUserIds.splice(index, 1);
      }
    }
    this.membersChecked.emit(this.checkedUserIds);
  }

  onCheckboxChange(userUid: string) {
    if (this.checkedUserIds.indexOf(userUid) === -1) {
      this.checkedUserIds.push(userUid);
    } else {
      const index = this.checkedUserIds.indexOf(userUid);
      this.checkedUserIds.splice(index, 1);
    }
    this.membersChecked.emit(this.checkedUserIds);
  }

  openUserRecordByUid(memberUid: string) {
    this.openUserRecord.emit(memberUid);
  }

  getMemberStatusTitle(member: Members): string {
    const status: string = member.playlistStatus ? member.playlistStatus : member.cardStatus;
    const title = status?.replace('_', ' ').toLowerCase();
    return title ? (title[0].toUpperCase() + title.slice(1)) : '';
  }

  expandNestedCards(index: number) {
    setTimeout(() => {
      this.updateHeaderRowHeight();
    }, this.updateTimeout);
    if (this.nestedCardIndex === index) {
      this.nestedCardIndex = undefined;
      return;
    }
    this.nestedCardIndex = index;

  }


  private setTimeOutBasedOnBrowser() {
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf('Firefox') > -1) {
      this.updateTimeout = 40;
    } else if (userAgent.indexOf('Chrome') > -1) {
      this.updateTimeout = 10;
    } else if (userAgent.indexOf('Safari') > -1) {
      this.updateTimeout = 10;
    } else {
      this.updateTimeout = 30;
    }
  }

  private updateHeight() {
    const rows = document.getElementsByTagName('TR');
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      const roleCell = row.getElementsByClassName('role-cell')[0] as HTMLElement;
      const height = roleCell.offsetHeight;
      const checkboxCell = row.getElementsByClassName('checkbox-cell')[0] as HTMLElement;
      const firstNameCell = row.getElementsByClassName('first-name')[0] as HTMLElement;
      const lastNameCell = row.getElementsByClassName('last-name')[0] as HTMLElement;
      checkboxCell.style.height = (height / 16) + 'rem';
      firstNameCell.style.height = (height / 16) + 'rem';
      lastNameCell.style.height = (height / 16) + 'rem';
    }
  }

  private updateHeaderRowHeight() {
    const row = document.getElementsByClassName('header-row')[0];
    const roleCell = row.getElementsByClassName('role-cell')[0] as HTMLElement;
    const height = roleCell.offsetHeight;
    const checkboxCell = row.getElementsByClassName('checkbox-cell')[0] as HTMLElement;
    const firstNameCell = row.getElementsByClassName('first-name')[0] as HTMLElement;
    const lastNameCell = row.getElementsByClassName('last-name')[0] as HTMLElement;
    checkboxCell.style.height = (height / 16) + 'rem';
    firstNameCell.style.height = (height / 16) + 'rem';
    lastNameCell.style.height = (height / 16) + 'rem';
  }

  private getOrderingData(): { order: MembersSearchOrder; page: number; pageSize: number } {
    return {
      order: {
        fieldName: this.defaultOrderBy,
        direction: this.order,
      },
      page: this.currentPage,
      pageSize: this.pageSize,
    }
  }

}
