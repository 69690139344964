/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { LanguageCodeHelper } from '../../../../../../shared/helpers/language-code-helper';
import { LocalTimeHelper } from '../../../../../../shared/helpers/local-time-helper';
import { LanguageValue, Organization } from '../../../../../../shared/models';
import { UserAuthState } from '../../../../../../user-auth/store/user-auth.state';
import { FolioBadges } from '../../../../store/folio.state.model';
import { FOLIO_DATA_SERVICE, FolioDataService } from 'src/app/page-modules/folio/services/data.service';

@Component({
  selector: 'ptl-achievements-section',
  templateUrl: './achievements-section.component.html',
  styleUrls: ['./achievements-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AchievementsSectionComponent implements OnInit {

  /** The cards to display. */
  @Input() badges: FolioBadges[] = [];
  @Input() pageSize: number;
  @Input() editMode: boolean;
  @Input() publicId: string;
  @Input() canOpenPublicBadge: boolean;

  /** The sectionUid for which should loading badges. */
  @Input() sectionUid: string;

  @Output() badgeClicked = new EventEmitter<string>();

  @ViewChild('content') private contentElement: ElementRef;

  page = 0;
  size = 4;
  showOnlyFirstRow = true;
  scrollPosition: number;
  windowsScrollPosition: number;
  loadingMore: boolean;
  loadedMore: boolean;
  paginatedBadges = [];
  canLoadMore: boolean;

  cards: FolioBadges[] = [];

  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  constructor(
    private cd: ChangeDetectorRef,
    private ngZone: NgZone,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(FOLIO_DATA_SERVICE) private folioDataService: FolioDataService,
  ) {
  }


  ngOnInit() {
    if (this.pageSize) {
      this.showOnlyFirstRow = false;
      this.size = this.pageSize;
    }

    this.formatBadges();

    if (this.sectionUid) {
      this.loadBadgesBySectionUid(true);
    } else {
      this.getPaginatedBadges();
      this.loadBadgesFromLocal(true);
    }
  }

  formatBadges() {
    if (this.badges?.length > 0 && this.badges[0].issuedBadge) {
      this.badges = this.badges.map((item) => {
        return {
          badgeId: item.issuedBadge.badgeId,
          organizationId: item.issuedBadge.organizationId,
          imageUrl: item.issuedBadge.imageUrl,
          issuedOn: item.issuedBadge.issuedOn,
          userBadgeUid: item.issuedBadge.userBadgeUid,
          issuedBy: item.issuedBadge.issuedBy,
          title: item.issuedBadge.title,
          userWorkspacesUid: item.issuedBadge.userWorkspacesUid,
          visible: item.visible
        }
      })
    }
  }

  getTitle(titleArr: LanguageValue[]): string {
    const currentTitle = LanguageCodeHelper.getDataByLanguageCode(titleArr);
    return currentTitle ? currentTitle.value : '';
  }

  onBadgeClicked(userBadgeId: string) {
    // using for public folio view to open public badge
    this.badgeClicked.emit(userBadgeId);
  }

  onShowMore() {
    this.page++;
    if (!this.loadingMore && !this.editMode) {

      if (!this.scrollPosition) {
        this.scrollPosition = (this.contentElement?.nativeElement as HTMLElement).offsetTop;
      }
      this.windowsScrollPosition = window.pageYOffset;
      this.showOnlyFirstRow = false;

      if (!this.loadedMore) {
        this.loadedMore = true;
        if (!this.pageSize) {
          this.page = 0;
          this.size = this.pageSize ? this.pageSize : 10;
        }
        const override = !this.pageSize;
        this.loadMoreBadges(override);
      } else {
        this.loadMoreBadges(false);
      }
    }
  }

  onLoadLess() {
    this.page--;
    if (this.page < 0) {
      this.page = 0;
      this.size = this.pageSize ? this.pageSize : 4;
      this.loadedMore = false;
      this.cards.length = this.size;
      window.scrollTo(0, this.scrollPosition);
      this.showOnlyFirstRow = true;
    } else {
      const length = this.cards.length - this.size;
      this.cards.length = (length < this.size ? this.size : length);
    }
    this.canLoadMore = true;
    this.cd.detectChanges();
  }

  onBadgesContainerScroll(event) {
    const element = event.target;

    if (element.offsetWidth + element.scrollLeft >= element.scrollWidth - 30) {
      this.onShowMore();
    }
  }

  getLocalDateTime(date: Date): string {
    return LocalTimeHelper.getLocalDateTime(date).toString();
  }

  private loadMoreBadges(override: boolean) {
    if (this.sectionUid) {
      this.loadBadgesBySectionUid(override);
    } else {
      this.loadBadgesFromLocal(override);
    }
  }

  private loadBadgesFromLocal(override: boolean) {
    this.loadingMore = false;
    this.getPaginatedBadges();
    if (override) {
      this.cards = this.paginatedBadges[this.page];
    } else {
      this.cards = this.cards.concat(this.paginatedBadges[this.page]);
    }
    this.cd.detectChanges();
  }

  private getPaginatedBadges() {
    this.paginatedBadges = [];
    const badges = [...this.badges];
    for (let i = 0; i < badges.length; i += this.size) {
      this.paginatedBadges.push(badges.slice(i, i + this.size));
    }
  }

  private loadBadgesBySectionUid(override: boolean) {
    this.loadingMore = true;
    if (this.publicId) {
      this.folioDataService.getPublicFolioBadges(this.publicId, this.sectionUid, this.page, this.size)
        .subscribe(({ isSuccess, value }) => {
        this.handleBadges(isSuccess, override, value);
      });
    } else {
      this.folioDataService.getFolioBadges(this.sectionUid, this.page, this.size)
        .subscribe(({ isSuccess, value }) => {
        this.handleBadges(isSuccess, override, value);
      });
    }
  }

  private handleBadges(isSuccess: boolean, override: boolean, value: FolioBadges[]) {
    if (isSuccess) {
      if (override) {
        this.cards = value;
      } else {
        this.cards = this.cards.concat(value);
        window.scrollTo(0, this.windowsScrollPosition);
      }
      if (value.length < this.size) {
        this.canLoadMore = false;
        this.page--;
        if (this.page < 0) {
          this.page = 0;
        }
      } else {
        this.canLoadMore = true;
      }
    }
    this.loadingMore = false;
    this.cd.detectChanges();
  }
}
