<div class="pagination-block">

  <div class="per-page-box" *ngIf="showPageSize">
    <div class="per-page-prefix">
      {{ 'translations.global.pagination.showOnPage' | transloco }}
    </div>

    <mat-select [(ngModel)]="pageSize"
                [attr.aria-label]="('translations.global.ariaLabels.showItemsPerPage' | transloco)"
                (selectionChange)="onSizeChange()">
      <mat-option [value]="10">{{ 'translations.global.pagination.showPerPage.show10' | transloco }}</mat-option>
      <mat-option [value]="25">{{ 'translations.global.pagination.showPerPage.show25' | transloco }}</mat-option>
      <mat-option [value]="50">{{ 'translations.global.pagination.showPerPage.show50' | transloco }}</mat-option>
    </mat-select>
  </div>

  <ptl-pagination class="pagination"
    [itemsLength]="totalNumberOfElement"
    [startedFromZero]="startedFromZero"
    [totalPages]="totalPages"
    [currentPage]="currentPage"
    [itemsPerPage]="pageSize"
    (pageChange)="onPageChange($event)"
  ></ptl-pagination>

</div>
