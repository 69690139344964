<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ng-container *ngIf="currentEvent">
  <div class="review-status" *transloco
    [class.small-text-style]="textStyle === 'SMALL'"
    [class.medium-bold-text-style]="textStyle === 'MEDIUM_BOLD'"
    [class.large-bold-text-style]="textStyle === 'LARGE_BOLD'"
    [class.background-indicator]="backgroundIndicator"
    [ngClass]="{
      approved: currentEvent === 'ACCEPTED',
      rejected: currentEvent === 'REJECTED_TO_REVIEW',
      changesRequest: currentEvent === 'REQUIRES_RESUBMISSION',
      closed: currentEvent === 'CLOSED',
      reOpened: currentEvent === 'REOPENED'
    }">
    <ng-container *ngIf="prefixDate !== null; else noDateTemplate">
      <div
        class="review-status_updatedOn"
        *ngIf="prefixDate && !isAcceptOrRejected">
        {{ (currentEvent | learnerReviewStatus:'dateLabel':reviewEventStyle) | transloco }}
        {{ lastUpdatedOn | date:'d MMM YYYY' }}
        <ng-container *ngTemplateOutlet="lateSubmissionTemplate"></ng-container>
      </div>

      <div
        class="review-status_badge"
        [class.medium]="textStyle === 'MEDIUM_BOLD'"
        [class.large]="textStyle === 'LARGE_BOLD'"
        [ngClass]="{
            approved: currentEvent === 'ACCEPTED',
            rejected: currentEvent === 'REJECTED_TO_REVIEW',
            changesRequest: !reviewEventStyle && (currentEvent === 'REQUIRES_RESUBMISSION'),
            needsReview: !reviewEventStyle && (currentEvent === 'SUBMITTED_FOR_REVIEW' || currentEvent === 'RESUBMITTED'),
            closed: currentEvent === 'CLOSED',
            reOpened: currentEvent === 'REOPENED',
          }">
        <ng-container [ngSwitch]="currentEvent">
          <ng-container *ngSwitchCase="'ACCEPTED'">
            <svg class="svg" role="img" [attr.aria-label]="('translations.global.ariaLabels.icons.check' | transloco)">
              <use ogSvgUnify="assets/sprite/sprite.svg#check"></use>
            </svg>
            {{ (currentEvent | learnerReviewStatus:'dateLabel':reviewEventStyle) | transloco }}
            {{ lastUpdatedOn | date:'d MMM YYYY' }}
            <ng-container *ngTemplateOutlet="lateSubmissionTemplate"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'REJECTED_TO_REVIEW'">
            <svg class="svg" role="img" [attr.aria-label]="('translations.global.ariaLabels.icons.close' | transloco)">
              <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
            </svg>
            {{ (currentEvent | learnerReviewStatus:'dateLabel':reviewEventStyle) | transloco }}
            {{ lastUpdatedOn | date:'d MMM YYYY' }}
            <ng-container *ngTemplateOutlet="lateSubmissionTemplate"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'REQUIRES_RESUBMISSION'">
            <svg *ngIf="!reviewEventStyle" class="svg" role="img"
                 [attr.aria-label]="('translations.global.ariaLabels.icons.warning' | transloco)">
              <use ogSvgUnify="assets/sprite/sprite.svg#warning"></use>
            </svg>
            <mat-icon *ngIf="reviewEventStyle" aria-hidden="true" class="mat-icon warn">loop</mat-icon>
          </ng-container>
          <ng-container *ngSwitchCase="'SUBMITTED_FOR_REVIEW'">
            <svg *ngIf="!reviewEventStyle" class="svg" role="img"
                 [attr.aria-label]="('translations.global.ariaLabels.icons.rateReview' | transloco)">
              <use ogSvgUnify="assets/sprite/sprite.svg#rate-review"></use>
            </svg>
            <mat-icon *ngIf="reviewEventStyle" aria-hidden="true" class="mat-icon">forward</mat-icon>
          </ng-container>
          <ng-container *ngSwitchCase="'RESUBMITTED'">
            <svg *ngIf="!reviewEventStyle" class="svg" role="img"
                 [attr.aria-label]="('translations.global.ariaLabels.icons.rateReview' | transloco)">
              <use ogSvgUnify="assets/sprite/sprite.svg#rate-review"></use>
            </svg>
            <mat-icon *ngIf="reviewEventStyle" aria-hidden="true" class="mat-icon highlight">replay</mat-icon>
          </ng-container>
          <ng-container *ngSwitchCase="'CLOSED'">
            <svg class="svg" role="img" [attr.aria-label]="('translations.global.ariaLabels.icons.close' | transloco)">
              <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
            </svg>
          </ng-container>
          <ng-container *ngSwitchCase="'REOPENED'">
            <svg class="svg" role="img"
                 [attr.aria-label]="('translations.global.ariaLabels.icons.refresh' | transloco)">
              <use ogSvgUnify="assets/sprite/sprite.svg#refresh"></use>
            </svg>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="!isAcceptOrRejected">
          {{ (currentEvent | learnerReviewStatus:'badge':reviewEventStyle) | transloco }}
        </ng-container>
      </div>

      <div
        class="review-status_updatedOn"
        *ngIf="!prefixDate && !isAcceptOrRejected">
        {{ (currentEvent | learnerReviewStatus:'dateLabel':reviewEventStyle) | transloco }}
        {{ lastUpdatedOn | date:'d MMM YYYY' }}
        <ng-container *ngTemplateOutlet="lateSubmissionTemplate"></ng-container>
      </div>
    </ng-container>
  </div>
</ng-container>

<!-- Templates -->
<ng-template #noDateTemplate>
  <div
    class="review-status_badge"
    [ngClass]="{
      approved: currentEvent === 'ACCEPTED',
      rejected: currentEvent === 'REJECTED_TO_REVIEW',
      changesRequest: currentEvent === 'REQUIRES_RESUBMISSION',
      needsReview: currentEvent === 'SUBMITTED_FOR_REVIEW' || currentEvent === 'RESUBMITTED',
      closed: currentEvent === 'CLOSED',
      reOpened: currentEvent === 'REOPENED'
    }">
    <svg class="svg" role="img" aria-hidden="true">
      <ng-container [ngSwitch]="currentEvent">
        <use ogSvgUnify="assets/sprite/sprite.svg#check" *ngSwitchCase="'ACCEPTED'"></use>
        <use ogSvgUnify="assets/sprite/sprite.svg#close" *ngSwitchCase="'CLOSED'"></use>
        <use ogSvgUnify="assets/sprite/sprite.svg#close" *ngSwitchCase="'REJECTED_TO_REVIEW'"></use>
        <use ogSvgUnify="assets/sprite/sprite.svg#warning" *ngSwitchCase="'REQUIRES_RESUBMISSION'"></use>
        <use ogSvgUnify="assets/sprite/sprite.svg#rate-review" *ngSwitchCase="'SUBMITTED_FOR_REVIEW'"></use>
        <use ogSvgUnify="assets/sprite/sprite.svg#rate-review" *ngSwitchCase="'RESUBMITTED'"></use>
        <use ogSvgUnify="assets/sprite/sprite.svg#refresh" *ngSwitchCase="'REOPENED'"></use>
      </ng-container>
    </svg>

    {{ (currentEvent | learnerReviewStatus:'dashboard':reviewEventStyle) | transloco }}
  </div>
</ng-template>

<ng-template #lateSubmissionTemplate>
  <div class="review-status_late-submission" *ngIf="isLateSubmission">
    ({{ 'translations.reviews.status.dateLabel.lateSubmission' | transloco }})
  </div>
</ng-template>


