/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MediumEditorDirective } from '../../medium-editor/medium-editor.directive';
import { Subject, Subscription } from 'rxjs';
import { TextBoxFormContentType } from '../../models';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TranslationService } from '../../services/translation/translation.service';

@Component({
  selector: 'ptl-tiny-medium-editor',
  templateUrl: './medium-editor.component.html',
  styleUrls: ['./medium-editor.component.scss'],
})
export class TinyMediumEditorComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  /** Used for form type editor */

  /** Receives the string for the medium-editor placeholder text. */
  @Input() textPlaceholder = this.translationService.getTranslation('formEnterYourAnswer');
  /** Receives the text characters max limit. Defaults to 10000. */
  @Input() wordLimit = 10000;
  /** Receives the inputType for the medium-editor. RICH | NORMAL. Defaults to RICH. */
  @Input() inputType: TextBoxFormContentType = 'RICH';
  /** Receives the answer/content if present. */
  @Input() content: string;
  /** Disable the medium-editor. Defaults to false. */
  @Input() disableEditing = false;
  @Input() editClicked = true;

  /** Outputs the various click and change events */
  @Output() mediumEditorUpdate = new EventEmitter<string>();
  /** Outputs the various click and change events */
  @Output() inputFocus = new EventEmitter<void>();
  /** Outputs the various click and change events */
  @Output() inputPast = new EventEmitter<string>();
  /** Outputs the various click and change events */
  @Output() inputBlur = new EventEmitter<string>();
  /** Outputs the text character length */
  @Output() textLength = new EventEmitter<number>();

  /** Hooking up to MediumEditorDirective functions through ViewChild */
  @ViewChild('mediumEditor', { read: MediumEditorDirective, static: true }) mediumEditor: MediumEditorDirective;

  private editorUpdateSubject$ = new Subject<string>();
  private mediumEditorUpdateSubscription: Subscription;
  private mediumEditorBlurSubscription: Subscription;
  private mediumEditorFocusSubscription: Subscription;
  private mediumEditorPasteSubscription: Subscription;
  private editorInputSubscription: Subscription;
  private elementPasted = false;
  editorInput = '';

  editorOptions;

  constructor(private translationService: TranslationService) {
  }


  ngOnInit() {
    if (this.inputType === 'NORMAL') {
      // disable toolbar and keyboard shortcuts
      this.editorOptions = {
        disableEditing: this.disableEditing,
        toolbar: false,
        keyboardCommands: {
          commands: [
            {
              key: 'B',
              meta: true,
              shift: false,
              alt: false,
            }, {
              key: 'I',
              meta: true,
              shift: false,
              alt: false,
            },
          ],
        },
      };
    } else {
      this.editorOptions = {
        toolbar: this.getToolbarButtons(),
      };

    }
  }

  ngAfterViewInit() {
    this.loadSubscriptions();
    this.editorInputSubscription = this.editorUpdateSubject$
      .pipe(
        debounceTime(100),
        distinctUntilChanged((data: string) => data === this.editorInput)
      )
      .subscribe((value: string) => {
        this.mediumEditorUpdate.emit(value);
      });
  }

  ngOnDestroy() {
    this.mediumEditorUpdateSubscription.unsubscribe();
    this.mediumEditorBlurSubscription.unsubscribe();
    this.mediumEditorFocusSubscription.unsubscribe();
    this.mediumEditorPasteSubscription.unsubscribe();
    this.editorInputSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.content) {
      this.editorInput = JSON.parse(JSON.stringify(this.content));
    }
    if (changes.editClicked?.currentValue) {
      setTimeout(() => this.setCursorPositionOnTheEndOfText(), 150);
    }
  }

  private loadSubscriptions() {
    // subscribe to medium-editor-directive's update events, note 'editor' is a private member
    this.mediumEditorUpdateSubscription =
      this.mediumEditor.editor.subscribe('editableInput', (ev: Event, html: HTMLElement) => {
        this.editorUpdateSubject$.next(this.editorInput);
        if (this.countWords(html.innerText) > this.wordLimit) {
          this.preventFromExceedingWordLimit(html);
          this.setCursorPositionOnTheEndOfText();
          if (ev.preventDefault) {
            ev.preventDefault();
          }
        } else {
          this.textLength.emit(this.countWords(html.innerText));
        }
      });
    this.mediumEditorPasteSubscription =
      this.mediumEditor.editor.subscribe('editablePaste', (_, html: HTMLElement) => {
        // if textContent is equal or higher than our wordLimit, trim string to the limit and update content
        this.elementPasted = true;
        this.inputFocus.emit();
        this.inputPast.emit(html.innerText);
        if (this.countWords(html.innerText) > this.wordLimit) {
          this.preventFromExceedingWordLimit(html);
          this.setCursorPositionOnTheEndOfText();
        } else {
          this.textLength.emit(this.countWords(html.innerText));
          this.mediumEditorUpdate.emit(this.editorInput);
        }
      });
    this.mediumEditorBlurSubscription =
      this.mediumEditor.editor.subscribe('blur', () => {
        if (!this.elementPasted) {
          this.inputBlur.emit(this.editorInput);
        }
        this.elementPasted = false;
      });
    this.mediumEditorFocusSubscription = this.mediumEditor.editor.subscribe('focus', () => this.inputFocus.emit());
  }

  private preventFromExceedingWordLimit(html: HTMLElement) {
    const reducedHtml = this.truncateString(html.innerHTML, this.wordLimit);
    this.mediumEditor.editor.setContent(reducedHtml);
  }

  truncateString(text: string, wordCount: number): string {
    const expr = new RegExp('(([^\\s]+\\s+){' + wordCount + '}).+');
    return text.replace(expr, '$1');
  }

  private setCursorPositionOnTheEndOfText() {
    const range = document.createRange();
    const selection = window.getSelection();
    const textElement = this.mediumEditor.editor.elements[0];
    if (!textElement.lastChild) {
      const emptyParagraph = document.createElement('p');
      emptyParagraph.innerHTML = '<br>';
      textElement.appendChild(emptyParagraph);
    }
    range.setStart(textElement.lastChild, textElement.lastChild.childNodes.length);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
    textElement.focus();
  }

  private countWords(text: string) {
    if (this.checkIfStringIsNotEmpty(text)) {
      return text.trim().split(/\s+/g).length;
    } else {
      return 0;
    }
  }

  private checkIfStringIsNotEmpty(text: string) {
    return (/\S/.test(text));
  }

  private getToolbarButtons() {
    return {
      buttons: [
        {
          name: 'bold',
          contentDefault: '<i class="material-icons" aria-hidden="true">format_bold</i>',
        },
        {
          name: 'italic',
          contentDefault: '<i class="material-icons" aria-hidden="true">format_italic</i>',
        },
        {
          name: 'orderedlist',
          contentDefault: '<i class="material-icons" aria-hidden="true">format_list_numbered</i>',
        },
        {
          name: 'unorderedlist',
          contentDefault: '<i class="material-icons" aria-hidden="true">format_list_bulleted</i>',
        },
      ],
    }
  }
}
