/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ReviewEventType } from '../../models';

type TextStyle = 'SMALL' | 'MEDIUM_BOLD' | 'LARGE_BOLD';

@Component({
  selector: 'ptl-review-status',
  templateUrl: './review-status.component.html',
  styleUrls: ['./review-status.component.scss']
})
export class ReviewStatusComponent implements OnInit, OnChanges {

  @Input() currentEvent: ReviewEventType;
  @Input() lastUpdatedOn: Date;
  @Input() prefixDate: true | false | null;
  @Input() deadline: Date;
  @Input() createdAt: Date;
  @Input() textStyle: TextStyle = 'SMALL';
  @Input() reviewEventStyle = false;
  @Input() backgroundIndicator = false;

  isLateSubmission: boolean;
  isAcceptOrRejected: boolean;

  ngOnInit(): void {
    if (this.deadline && this.createdAt) {
      this.isLateSubmission = new Date(this.deadline) < new Date(this.createdAt);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentEvent.currentValue !== changes.currentEvent.previousValue) {
      this.isAcceptOrRejected = this.currentEvent === 'ACCEPTED' || this.currentEvent === 'REJECTED_TO_REVIEW';
    }
  }

}
