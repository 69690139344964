import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  BooleanFilter,
  BooleanQueryRequest,
  BooleanQueryType, NotRequest,
  PlaylistStatusRequest
} from '../../../../../../../shared/models/admin/boolean-filters.model';
import {
  TranslationService
} from '../../../../../../../shared/services/translation/translation.service';
import { MatSelectChange } from '@angular/material/select';
import {
  GLOBAL_SEARCH_DATA_SERVICE,
  GlobalSearchDataService
} from '../../../../../../global-search/services/global-search-data.service';
import { debounceTime } from 'rxjs/operators';
import { SearchPlaylist } from '../../../../../../../shared/models';
import { Subject, Subscription } from 'rxjs';
import { canTriggerSearch } from '../../../../../../../shared/helpers/content-helper';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'ptl-playlist-member-filter',
  templateUrl: './playlist-member-filter.component.html',
  styleUrls: ['./playlist-member-filter.component.scss'],
})
export class PlaylistMemberFilterComponent implements OnChanges, OnInit, OnDestroy {

  @Input() isPlaylistPage: boolean;
  @Input() playlistUid: string;
  @Input() excludedPlaylistStatuses: string[];
  @Input() excludedPlaylists: string[];
  @Output() setPlaylistFilter = new EventEmitter<BooleanFilter>();
  @Output() cancelPlaylistFilter = new EventEmitter<void>();

  selectedPlaylistStatus: 'COMPLETED' | 'NOT_COMPLETED' | 'STARTED' | 'NOT_STARTED' = 'COMPLETED';
  allPlaylistStatuses: { value: 'COMPLETED' | 'NOT_COMPLETED' | 'STARTED' | 'NOT_STARTED'; title: string }[] = [];
  playlistStatuses: { value: 'COMPLETED' | 'NOT_COMPLETED' | 'STARTED' | 'NOT_STARTED'; title: string }[] = [];

  selectedPlaylists: SearchPlaylist[];
  noPlaylist = false;

  loadingSearch: boolean;
  searchString: string;
  searchedPlaylists: SearchPlaylist[];
  private playlistSearchSubject$ = new Subject<void>();
  private playlistSearchSubscription: Subscription;

  constructor(
    private translationService: TranslationService,
    @Inject(GLOBAL_SEARCH_DATA_SERVICE) private globalSearchDataService: GlobalSearchDataService
  ) {
    this.playlistSearchSubscription = this.playlistSearchSubject$
      .pipe(debounceTime(500)).subscribe(() => this.fireSearch());
  }

  ngOnInit() {
    this.allPlaylistStatuses = [
      { value: 'COMPLETED', title: this.translationService.getTranslation('members.filter.resources.completed')},
      { value: 'NOT_COMPLETED', title: this.translationService.getTranslation('members.filter.resources.notCompleted')},
      { value: 'STARTED', title: this.translationService.getTranslation('members.filter.resources.started')},
      { value: 'NOT_STARTED', title: this.translationService.getTranslation('members.filter.resources.notStarted')}
    ];
    this.updateStatuses();
    this.selectedPlaylists = [];
  }

  ngOnDestroy() {
    this.playlistSearchSubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.excludedPlaylsitStatuses) {
      this.updateStatuses();
    }
  }

  onSave() {
    if (!this.isPlaylistPage && !this.selectedPlaylists.length) {
      this.noPlaylist = true;
      return;
    }
    this.noPlaylist = false;
    this.saveSelected();
  }

  onCancel() {
    this.cancelPlaylistFilter.emit();
  }

  onPlaylistStatusChange(event: MatSelectChange) {
    this.selectedPlaylistStatus = event.value;
  }

  onPlaylistSelected({ option }: MatAutocompleteSelectedEvent) {
    this.selectedPlaylists.push(option.value);
  }

  onPlaylistRemove(playlistId: string) {
    this.selectedPlaylists = this.selectedPlaylists.filter(selectedPlaylist => selectedPlaylist.id !== playlistId)
  }

  onTextPaste(event: ClipboardEvent) {
    if (event.type === 'paste') {
      setTimeout(() => {
        if (this.searchString) {
          this.loadingSearch = true;
          this.playlistSearchSubject$.next();
        }
      }, 0);
    }
  }

  onSearchInputChange(event: KeyboardEvent) {
    if (canTriggerSearch(event)) {
      if (this.searchString) {
        this.loadingSearch = true;
        this.playlistSearchSubject$.next();
      }
    }
  }

  searchAutocompleteFormat(): string {
    return '';
  }

  private saveSelected() {
    if (!this.isPlaylistPage)
      if (this.selectedPlaylists.length > 1) {
        this.setFilters();
      } else {
        this.setFilter(this.selectedPlaylists[0].id, this.selectedPlaylists[0].title)
      } else {
      this.setFilter(this.playlistUid);
    }
  }

  private setFilter(playlistUid: string, playlistTitle?: string) {
    const result = {
      request: this.getRequest(playlistUid),
      message: this.getMessage(playlistTitle ?? playlistUid)
    };
    this.setPlaylistFilter.emit(result);
  }

  private setFilters() {
    const result = {
      request: this.getRequests(),
      message: this.getMessages()
    };
    this.setPlaylistFilter.emit(result);
  }

  private getMessage(title: string): string {
    let message = this.getStatus();
    if (!this.isPlaylistPage) {
      message += ' ' + `'${title}'`;
    } else {
      message = this.translationService.getTranslation('members.filter.summary.status') + ' ' + message;
    }
    return message;
  }

  private getMessages() {
    let message = this.getStatus();
    message += (' ' + this.selectedPlaylists.map(playlist => `'${playlist.title ?? playlist.id}'`).join(', '));
    return message;
  }

  private getStatus(): string {
    switch(this.selectedPlaylistStatus) {
      case 'COMPLETED':
        return this.translationService.getTranslation('members.filter.summary.completed');
      case 'NOT_COMPLETED':
        return this.translationService.getTranslation('members.filter.summary.notCompleted');
      case 'STARTED':
        return this.translationService.getTranslation('members.filter.summary.started');
      case 'NOT_STARTED':
        return this.translationService.getTranslation('members.filter.summary.notStarted');
    }

  }

  private getRequests(): BooleanQueryRequest[] {
    const requests = [];
    for (const playlist of this.selectedPlaylists) {
      requests.push(this.getRequest(playlist.id));
    }
    return requests;
  }

  private getRequest(playlistUid: string): BooleanQueryRequest {

    const isNotRequest = this.isStatusNegated();
    const absoluteStatus = this.getAbsoluteStatus();
    const playlistRequest: PlaylistStatusRequest = {
      type: BooleanQueryType.PLAYLIST_STATUS,
      uid: playlistUid,
      status: absoluteStatus
    }

    if (isNotRequest) {
      return {
        type: BooleanQueryType.NOT,
        arg: playlistRequest
      } as NotRequest
    } else {
      return playlistRequest;
    }
  }

  private isStatusNegated(): boolean {
    return this.selectedPlaylistStatus.startsWith('NOT');
  }

  private getAbsoluteStatus(): 'STARTED' | 'COMPLETED' {
    if (this.selectedPlaylistStatus === 'NOT_STARTED') {
      return 'STARTED';
    } else if (this.selectedPlaylistStatus === 'NOT_COMPLETED') {
      return 'COMPLETED'
    } else {
      return this.selectedPlaylistStatus;
    }
  }

  private fireSearch() {
    this.globalSearchDataService.searchPlaylists(0, this.searchString).subscribe(({ value }) => {
      if (value) {
        this.searchedPlaylists = (value.content ?? [])
          .filter(playlist => !this.selectedPlaylists.map(selectedPlaylist => selectedPlaylist.id).includes(playlist.id))
          .filter(playlist => !this.excludedPlaylists.includes(playlist.id));
      }
      this.loadingSearch = false;
    });
  }

  private updateStatuses() {
    this.playlistStatuses = this.allPlaylistStatuses.filter(
      status => !this.excludedPlaylistStatuses.includes(status.value)
    );
    if (this.isPlaylistPage) {
      this.playlistStatuses = this.playlistStatuses.filter(
        status => !this.excludedPlaylistStatuses.includes(this.opposite(status.value))
      );
    }
    if (this.playlistStatuses.length) {
      this.selectedPlaylistStatus = this.playlistStatuses[0].value;
    }
  }

  private opposite(status: 'STARTED' | 'COMPLETED' | 'NOT_STARTED' | 'NOT_COMPLETED'): string {
    switch (status) {
      case 'STARTED':
        return 'NOT_STARTED';
      case 'COMPLETED':
        return 'NOT_COMPLETED';
      case 'NOT_STARTED':
        return 'STARTED';
      case 'NOT_COMPLETED':
        return 'COMPLETED';
    }
  }
}
