<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->


<div>
  <div class="question-aria" id="gridQuestionAria">
    {{ 'translations.gridQuestionAria' | transloco }}
  </div>

  <ptl-resource-preview class="question-description" [previewContent]="getPreviewContent()">
  </ptl-resource-preview>
  <div class="question-card__options question-card__options-per-row-{{question?.optionsPerRow}}">
    <ng-container *ngFor="let option of options, index as i">
      <div class="question-card__option">
        <div class="question-card__option-text"
             tabindex="0"
             [class.none-selected]="isNoneOfTheseSelected && !option.deselectOthers"
             [attr.aria-label]="option.selected ? ('translations.global.ariaLabels.questionSelectedOption' | transloco: {number: i + 1, name: getTranslatedValue(option.values)}) : ('translations.global.ariaLabels.questionOption' | transloco: {number: i + 1, name: getTranslatedValue(option.values)})"
             (click)="selectAnswer(option)"
             (keydown.space)="selectAnswer(option)"
             (keydown.enter)="selectAnswer(option)"
             [class.is-selected]="option.selected">
          {{getTranslatedValue(option.values)}}
        </div>
        <div class="question-card__option-info" *ngIf="newVersionEnabled
                                                      && option.instructions?.length
                                                      && option.instructions[0].content !== ''">
          <ptl-resource-preview class="thin-preview"
                                [previewContent]="option.instructions"
          ></ptl-resource-preview>
        </div>
      </div>
    </ng-container>
  </div>
</div>


