import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  BooleanFilter,
  BooleanQueryRequest,
  BooleanQueryType,
  CardStatusRequest,
  NotRequest
} from '../../../../../../../shared/models/admin/boolean-filters.model';
import {
  TranslationService
} from '../../../../../../../shared/services/translation/translation.service';
import { MatSelectChange } from '@angular/material/select';
import { debounceTime } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { canTriggerSearch } from '../../../../../../../shared/helpers/content-helper';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {
  PLAYLIST_CARDS_DATA_SERVICE,
  PlaylistResourceCardsDataService
} from '../../../../../../playlist/services/create/cards/data.service';
import { LinkableCardSummary } from '../../../../../../../shared/models';
import { CardHeaders } from '../../../../../../../shared/models/admin/members.model';

@Component({
  selector: 'ptl-card-member-filter',
  templateUrl: './card-member-filter.component.html',
  styleUrls: ['./card-member-filter.component.scss'],
})
export class CardMemberFilterComponent implements OnChanges, OnInit, OnDestroy {

  @Input() isCardPage: boolean;
  @Input() isPlaylistPage: boolean;
  @Input() cardUid: string;
  @Input() cardHeaders: CardHeaders[];
  @Input() excludedCardStatuses: string[];
  @Input() excludedCards: string[];
  @Output() setCardFilter = new EventEmitter<BooleanFilter>();
  @Output() cancelCardFilter = new EventEmitter<void>();

  selectedCardStatus: 'COMPLETED' | 'NOT_COMPLETED' | 'STARTED' | 'NOT_STARTED' = 'COMPLETED';
  allCardStatuses: { value: 'COMPLETED' | 'NOT_COMPLETED' | 'STARTED' | 'NOT_STARTED'; title: string }[] = [];
  cardStatuses: { value: 'COMPLETED' | 'NOT_COMPLETED' | 'STARTED' | 'NOT_STARTED'; title: string }[] = [];

  selectedCards: { id: string; header: string }[];
  noCard = false;

  loadingSearch: boolean;
  searchString: string;
  searchedCards: LinkableCardSummary[];
  private cardSearchSubject$ = new Subject<void>();
  private cardSearchSubscription: Subscription;

  constructor(
    private translationService: TranslationService,
    @Inject(PLAYLIST_CARDS_DATA_SERVICE) private cardsDataService: PlaylistResourceCardsDataService,
  ) {
    this.cardSearchSubscription = this.cardSearchSubject$
      .pipe(debounceTime(500)).subscribe(() => this.fireSearch());
  }

  ngOnInit() {
    this.allCardStatuses = [
      { value: 'COMPLETED', title: this.translationService.getTranslation('members.filter.resources.completed')},
      { value: 'NOT_COMPLETED', title: this.translationService.getTranslation('members.filter.resources.notCompleted')},
      { value: 'STARTED', title: this.translationService.getTranslation('members.filter.resources.started')},
      { value: 'NOT_STARTED', title: this.translationService.getTranslation('members.filter.resources.notStarted')}
    ];
    this.updateStatuses();
    this.selectedCards = [];
  }

  ngOnDestroy() {
    this.cardSearchSubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.excludedCardStatuses) {
      this.updateStatuses();
    }
  }

  onSave() {
    if (!this.isCardPage && !this.selectedCards.length) {
      this.noCard = true;
      return;
    }
    this.noCard = false;
    if (!this.isCardPage) {
      if (this.selectedCards.length > 1) {
        this.setFilters();
      } else {
        this.setFilter(this.selectedCards[0].id, this.selectedCards[0].header)
      }
    } else {
      this.setFilter(this.cardUid);
    }
  }

  onCancel() {
    this.cancelCardFilter.emit();
  }

  onCardStatusChange(event: MatSelectChange) {
    this.selectedCardStatus = event.value;
  }

  onCardSelected({ option }: MatAutocompleteSelectedEvent) {
    this.selectedCards.push({ id: option.value.id, header: option.value.header });
  }

  onCardHeaderSelected(event: MatSelectChange) {
    this.selectedCards.push({ id: event.value.cardId, header: event.value.header });
  }

  onCardRemove(cardId: string) {
    this.selectedCards = this.selectedCards.filter(selectedCard => selectedCard.id !== cardId);
  }

  onTextPaste(event: ClipboardEvent) {
    if (event.type === 'paste') {
      setTimeout(() => {
        if (this.searchString) {
          this.loadingSearch = true;
          this.cardSearchSubject$.next();
        }
      }, 0);
    }
  }

  onSearchInputChange(event: KeyboardEvent) {
    if (canTriggerSearch(event)) {
      if (this.searchString) {
        this.loadingSearch = true;
        this.cardSearchSubject$.next();
      }
    }
  }

  searchAutocompleteFormat(): string {
    return '';
  }

  private setFilter(cardUid: string, cardHeader?: string) {
    const result = {
      request: this.getRequest(cardUid),
      message: this.getMessage(cardHeader ?? cardUid)
    };
    this.setCardFilter.emit(result);
  }

  private setFilters() {
    const result = {
      request: this.getRequests(),
      message: this.getMessages()
    };
    this.setCardFilter.emit(result);
  }

  private getMessage(title: string): string {
    let message = this.getStatus();
    if (!this.isCardPage) {
      message += ' ' + `'${title}'`;
    } else {
      message = this.translationService.getTranslation('members.filter.summary.status') + ' ' + message;
    }
    return message;
  }

  private getMessages() {
    let message = this.getStatus();
    message += (' ' + this.selectedCards.map(card => `'${card.header ?? card.id}'`).join(', '));
    return message;
  }

  private getStatus(): string {
    switch (this.selectedCardStatus) {
      case 'COMPLETED':
        return this.translationService.getTranslation('members.filter.summary.completed');
      case 'NOT_COMPLETED':
        return this.translationService.getTranslation('members.filter.summary.notCompleted');
      case 'STARTED':
        return this.translationService.getTranslation('members.filter.summary.started');
      case 'NOT_STARTED':
        return this.translationService.getTranslation('members.filter.summary.notStarted');
    }
  }

  private getRequests(): BooleanQueryRequest[] {
    const requests = [];
    for (const card of this.selectedCards) {
      requests.push(this.getRequest(card.id));
    }
    return requests;
  }

  private getRequest(cardUid: string): BooleanQueryRequest {

    const isNotRequest = this.isStatusNegated();
    const absoluteStatus = this.getAbsoluteStatus();
    const cardRequest: CardStatusRequest = {
      type: BooleanQueryType.CARD_STATUS,
      uid: cardUid,
      status: absoluteStatus
    }

    if (isNotRequest) {
      return {
        type: BooleanQueryType.NOT,
        arg: cardRequest
      } as NotRequest
    } else {
      return cardRequest;
    }
  }

  private isStatusNegated(): boolean {
    return this.selectedCardStatus.startsWith('NOT');
  }

  private getAbsoluteStatus(): 'STARTED' | 'COMPLETED' {
    if (this.selectedCardStatus === 'NOT_STARTED') {
      return 'STARTED';
    } else if (this.selectedCardStatus === 'NOT_COMPLETED') {
      return 'COMPLETED'
    } else {
      return this.selectedCardStatus;
    }
  }

  private fireSearch() {
    this.cardsDataService.searchLinkableCards(0, 10, this.searchString).subscribe(({ value }) => {
      if (value) {
        this.searchedCards = (value.content ?? [])
          .filter(card => !this.selectedCards.map(selectedCard => selectedCard.id).includes(card.id))
          .filter(card => !this.excludedCards.includes(card.id));
      }
      this.loadingSearch = false;
    });
  }

  private updateStatuses() {
    this.cardStatuses = this.allCardStatuses.filter(
      status => !this.excludedCardStatuses.includes(status.value)
    );
    if (this.isCardPage) {
      this.cardStatuses = this.cardStatuses.filter(
        status => !this.excludedCardStatuses.includes(this.opposite(status.value))
      );
    }
    if (this.cardStatuses.length) {
      this.selectedCardStatus = this.cardStatuses[0].value;
    }
  }

  private opposite(status: 'STARTED' | 'COMPLETED' | 'NOT_STARTED' | 'NOT_COMPLETED'): string {
    switch (status) {
      case 'STARTED':
        return 'NOT_STARTED';
      case 'COMPLETED':
        return 'NOT_COMPLETED';
      case 'NOT_STARTED':
        return 'STARTED';
      case 'NOT_COMPLETED':
        return 'COMPLETED';
    }
  }
}
