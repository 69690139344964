/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { RestClientService } from '../rest-client.service';
import { SharedCardDataService } from './card-data.service';
import { ObservableResult } from '../../store';
import { Location } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { catchError, switchMap } from 'rxjs/operators';
import { ApprovalRequest, DiagnosticsWithProgress } from '../../models';
import { Review } from '../../models/review/review.model';
import { Page } from '../../models/page';
import { TranslationService } from '../translation/translation.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ApiSharedCardDataService implements SharedCardDataService {

  constructor(private client: RestClientService, private translationService: TranslationService) {
  }

  registerCardOpen(resourceUid: string): ObservableResult<void> {
    return this.client.post<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `cards/${resourceUid}/progress/openings`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorRegisterCardOpen')))
    );
  }

  getCardDetailWithProgress(cardUri: string): ObservableResult<DiagnosticsWithProgress> {
    return this.client.get<DiagnosticsWithProgress>(
      Location.joinWithSlash(environment.apiRootUrl || '', `cards/cards-with-progress/${cardUri}`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorGetCard')))
    );
  }

  associateTag(resourceUid: string, tagUid: string): ObservableResult<void> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `cards/${resourceUid}/settings/tags/${tagUid}`);
    return this.client.post<void>(url)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorAddTag')))
      );
  }

  dissociateTag(resourceUid: string, tagUid: string): ObservableResult<void> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `cards/${resourceUid}/settings/tags/${tagUid}`);
    return this.client.delete(url).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorRemoveTag')))
    );
  }

  updateCardUri(resourceUid: string, newUri: string): ObservableResult<void> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `cards/${resourceUid}/settings/publishing/uri?newUri=${newUri}`);
    return this.client.post<void>(url)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError((err) => this.handleErrorResponse(err))
      );
  }

  publishCard(resourceUid: string): ObservableResult<void> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `cards/${resourceUid}/settings/publishing/publish`);
    return this.client.post<void>(url)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorPublishCard')))
      );
  }

  unpublishCard(resourceUid: string): ObservableResult<void> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `cards/${resourceUid}/settings/publishing/unpublish`);
    return this.client.post<void>(url)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorUnPublishCard')))
      );
  }

  archiveCard(resourceUid: string): ObservableResult<void> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `cards/${resourceUid}/settings/publishing/archive`);
    return this.client.post<void>(url)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorArchiveCard')))
      );
  }

  updateApprovalMethod(resourceUid: string, request: ApprovalRequest): ObservableResult<void> {
    return this.client.post<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `cards/${resourceUid}/settings/approvals`),
      request
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorUpdatingCardApproval')))
    );
  }

  getReviewsCount(cardId: string): ObservableResult<number> {
    return this.client.get<number>(
      Location.joinWithSlash(environment.apiRootUrl || '', `reviews/count/${cardId}`))
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorGetCardReviewsCount')))
      );
  }

  associateReviewer(resourceUid: string, userUid: string): ObservableResult<void> {
    return this.client.post<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `cards/${resourceUid}/settings/approvals/reviewers/${userUid}`),
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorUpdatingCardReviewers')))
    );
  }

  dissociateReviewer(resourceUid: string, userUid: string): ObservableResult<void> {
    return this.client.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `cards/${resourceUid}/settings/approvals/reviewers/${userUid}`),
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorUpdatingCardReviewers')))
    );
  }

  getReviews(playlistUri: string, cardUri: string, page: number, size: number):
    ObservableResult<Page<Review>> {
    return this.client.post<Page<Review>>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUri}/cards/${cardUri}/reviews?page=${page}&size=${size}`))
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorGetCardReviewers')))
      );
  }

  private handleErrorResponse(err: HttpErrorResponse) {
    if (err?.status === 409) {
      return ObservableResult.ofError(this.translationService?.getTranslation('errors.errorCardUriExists'));
    }
    return ObservableResult.ofError(this.translationService?.getTranslation('errors.errorUpdatingCardUri'));
  }
}
