/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  dataLoadedState,
  defaultLoadableState,
  errorState,
  LoadableState,
  loadingState,
  ObservableResult
} from '../../../shared/store';
import {
  CategoriesSection,
  CategoryItem,
  Page,
  PageAuthorDetails,
  PageList,
  Section
} from '../../../shared/models/page/page.model';
import { Inject, Injectable, NgZone } from '@angular/core';
import * as PageActions from './pages.actions';
import { tap } from 'rxjs/operators';
import { PAGES_DATA_SERVICE, PageDataService } from '../services/data.service';
import { CORE_MOMENT_DATA_SERVICE, MomentDataService } from '../../folio/shared-moment-module/services/data.service';
import { MomentCommonHelpers } from '../../folio/shared-moment-module/services/common.helpers';
import { ActivatedRoute, Router } from '@angular/router';
import { Page as Pagination } from '../../../shared/models/page';
import { LearnerPlaylistSummary } from '../../../shared/models/playlist/learner-playlist-summary.model';
import {
  SHARED_UPCOMING_DATA_SERVICE,
  SharedUpcomingDataService
} from '../../../shared/services/upcoming/upcoming-data.service';
import { SnackbarHelper } from '../../../shared/helpers/snackbar-helper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CurrentLanguage } from '../../playlist/store/create/playlist-creation.state.model';
import { UserAuthState, UserAuthStateModel } from '../../../user-auth/store/user-auth.state';
import { DEFAULT_LANGUAGE_CODE, TranslationService } from '../../../shared/services/translation/translation.service';
import { cloneDeep } from 'lodash-es';
import { UpdateHomePageTitle } from '../../../user-auth/store/user-auth.actions';
import { RedirectHelper } from '../../resource/store/editor/content/helpers/redirect.helper';
import { RefreshFavoritePlaylists } from '../../../app-frame/store/summaries.actions';
import {
  ResetSidebarTree,
  UpdateSidebarItemData
} from '../../../shared/side-nav/store/side-nav.actions';
import { VersionHelper } from '../../../shared/helpers/version.helper';

export interface PagesStateModel {
  pages: LoadableState<PageList[]>;
  /** The home page of the user. */
  page: LoadableState<Page>;
  currentLanguage: CurrentLanguage;
  yourSectionPlaylists: LoadableState<Pagination<LearnerPlaylistSummary>>;
  subscriptionSectionPlaylists: LoadableState<Pagination<LearnerPlaylistSummary>>;
  saveInProgress: boolean;
}

@State<PagesStateModel>({
  name: 'pages',
  defaults: {
    pages: defaultLoadableState(),
    page: defaultLoadableState(),
    currentLanguage: null,
    yourSectionPlaylists: defaultLoadableState(),
    subscriptionSectionPlaylists: defaultLoadableState(),
    saveInProgress: false,
  },
})
@Injectable()
export class PageState {

  @Selector()
  static page( { page }: PagesStateModel ): LoadableState<Page> {
    return page;
  }

  @Selector()
  static saveInProgress( { saveInProgress }: PagesStateModel ): boolean {
    return saveInProgress;
  }

  @Selector()
  static headline( { page }: PagesStateModel ): string {
    return page.data.headline;
  }

  @Selector()
  static subheader( { page }: PagesStateModel ): string {
    return page.data.subHeader;
  }

  @Selector()
  static status( { page }: PagesStateModel ): string {
    return page.data.status;
  }

  @Selector()
  static pageUri( { page }: PagesStateModel ): string {
    return page.data.uri;
  }

  @Selector()
  static pageFormattedUri( { page }: PagesStateModel ): string {
    return page.data.formattedUri;
  }

  @Selector()
  static authorDetails( { page }: PagesStateModel ): PageAuthorDetails {
    return page.data.authorDetails;
  }

  @Selector()
  static currentLanguage( { currentLanguage }: PagesStateModel ): CurrentLanguage {
    return currentLanguage;
  }

  @Selector()
  static yourSectionPlaylists( { yourSectionPlaylists }: PagesStateModel ):
    LoadableState<Pagination<LearnerPlaylistSummary>> {
    return yourSectionPlaylists;
  }

  @Selector()
  static allPages( { pages }: PagesStateModel ): PageList[] {
    return pages.data;
  }

  @Selector()
  static subscriptionSectionPlaylists( { subscriptionSectionPlaylists }: PagesStateModel ):
    LoadableState<Pagination<LearnerPlaylistSummary>> {
    return subscriptionSectionPlaylists;
  }

  @Selector()
  static categories( { page }: PagesStateModel ): CategoryItem[] | undefined {
    if ( page.data?.type === 'DISCOVERY_PAGE' || page.data?.type === 'CATEGORY_PAGE' ||
      page.data?.type === 'CONTENT_STORE_PAGE' || page.data?.type === 'CONTENT_STORE_CATEGORY_PAGE' ) {
      const result = page.data.sections.find(section => section.type === 'CATEGORIES') as CategoriesSection;

      if ( result ) {
        return result.categories;
      } else {
        return [];
      }
    }

    return undefined;
  }

  @Selector([UserAuthState])
  static canEdit( { page }: PagesStateModel, userAuthState: UserAuthStateModel ) {
    if ( userAuthState.userDetails.data && page.data?.syndicationDetails?.publisher &&
      page.data.syndicationDetails.publisher.organization._id === page.data.organizationUid ) {
      if ( page.data.creator.adminId === userAuthState.userDetails.data.uid ) {
        return true;
      } else {
        for ( const admin of page.data.adminRights ) {
          if ( admin.adminId === userAuthState.userDetails.data.uid ) {
            return true;
          }
        }
      }
    }
    return false;
  }

  constructor(
    @Inject(PAGES_DATA_SERVICE) private pageDataService: PageDataService,
    @Inject(SHARED_UPCOMING_DATA_SERVICE) private upcomingDataService: SharedUpcomingDataService,
    @Inject(CORE_MOMENT_DATA_SERVICE) private momentDataService: MomentDataService,
    private store: Store,
    private snackBar: MatSnackBar,
    private router: Router,
    private ngZone: NgZone,
    private activatedRoute: ActivatedRoute,
    private translationService: TranslationService
  ) {
  }

  @Action(PageActions.ResetPageState)
  resetPageState( { patchState }: StateContext<PagesStateModel> ) {
    return patchState({
      pages: defaultLoadableState(),
      page: defaultLoadableState(),
      currentLanguage: null,
      yourSectionPlaylists: defaultLoadableState(),
      subscriptionSectionPlaylists: defaultLoadableState(),
      saveInProgress: false,
    });
  }

  @Action(PageActions.ResetCurrentPageState)
  resetCurrentPageState( { patchState }: StateContext<PagesStateModel> ) {
    return patchState({
      page: defaultLoadableState(),
      saveInProgress: false,
    });
  }

  @Action(PageActions.CreatePage)
  createPage(
    { patchState }: StateContext<PagesStateModel>,
    { request }: PageActions.CreatePage,
  ) {
    return this.pageDataService.createPage(request).pipe(
      tap(( { isSuccess, value, error } ) => {
          if ( isSuccess ) {
            patchState({
              page: dataLoadedState(value),
            });

            const newVersionEnabled = VersionHelper.newVersionEnabled();
            const uri = value.uri;

            if ( newVersionEnabled ) {
              this.store.dispatch(new ResetSidebarTree(true));
            }

            RedirectHelper.redirectByParams(this.ngZone, this.router, this.activatedRoute, {
              pageUri: uri,
              extraUriParam: 'edit'
            }, 'PAGE');
          } else {
            SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
            patchState({
              page: errorState(error),
            });
          }
        }
      ));
  }

  @Action(PageActions.SetPageCurrentLanguage)
  setPageCurrentLanguage(
    { patchState }: StateContext<PagesStateModel>,
    { supportedLanguage, index }: PageActions.SetPageCurrentLanguage
  ) {
    patchState({
      currentLanguage: { supportedLanguage, index } as CurrentLanguage,
    });
  }


  @Action(PageActions.ResetPageCurrentLanguage)
  resetPageCurrentLanguage(
    { patchState }: StateContext<PagesStateModel>,
  ) {
    patchState({
      currentLanguage: null,
    });
  }


  @Action(PageActions.LoadHome)
  loadHome(
    { patchState }: StateContext<PagesStateModel>,
    { languageCode }: PageActions.LoadHome
  ) {
    return this.loadPage(patchState, null, null, null, null, null, languageCode);
  }

  @Action(PageActions.LoadPageByUriAndLanguageCode)
  loadPageByUriAndLanguageCode(
    { patchState }: StateContext<PagesStateModel>,
    {
      pageUri,
      publisherUri,
      packageUri,
      frameworkId,
      tagId,
      languageCode,
      contentStore
    }: PageActions.LoadPageByUriAndLanguageCode
  ) {
    return this.loadPage(patchState, pageUri, publisherUri, packageUri, frameworkId, tagId, languageCode, contentStore);
  }

  @Action(PageActions.GetContinuePlaylists)
  getContinuePlaylists(
    { getState, patchState }: StateContext<PagesStateModel>,
    { sectionUid, page, size, languageCode }: PageActions.GetContinuePlaylists
  ) {
    return this.pageDataService.getUncompletedPlaylists(page, size, languageCode).pipe(
      tap(( { isSuccess, value } ) => {
          if ( isSuccess ) {
            patchState({
              page: dataLoadedState({
                ...getState().page.data,
                sections: getState()?.page?.data?.sections?.map(section => section.uid === sectionUid ? {
                    ...section,
                    loaded: true,
                    playlists: (section.playlists && page > 0) ? [...section.playlists, ...value.content] : value.content,
                    totalNumberOfElement: value.totalNumberOfElement,
                  } : section
                ),
              }),
            });
          }
        }
      ));
  }

  @Action(PageActions.GetLatestPlaylists)
  getLatestPlaylists(
    { getState, patchState }: StateContext<PagesStateModel>,
    { sectionUid, page, size, languageCode, contentStore }: PageActions.GetLatestPlaylists
  ) {
    return this.pageDataService.getLatestPlaylists(page, size, getState().page.data?.frameworkId, getState().page.data?.tagId,
      languageCode, contentStore).pipe(tap(( { isSuccess, value } ) => {
        if ( isSuccess ) {
          patchState({
            page: dataLoadedState({
              ...getState().page.data,
              sections: getState().page.data?.sections?.map(section => section.uid === sectionUid ? {
                  ...section,
                  loaded: true,
                  playlists: (section.playlists && page > 0) ? [...section.playlists, ...value.content] : value.content,
                  totalNumberOfElement: value.totalNumberOfElement,
                } : section
              ),
            }),
          });
        }
      }
    ));
  }

  @Action(PageActions.GetYourPlaylists)
  getYourPlaylists(
    { getState, patchState }: StateContext<PagesStateModel>,
    { sectionUid, page, size, languageCode }: PageActions.GetYourPlaylists
  ) {
    return this.pageDataService.getYourPlaylists(page, size, null, languageCode).pipe(
      tap(( { isSuccess, value } ) => {
          if ( isSuccess ) {
            patchState({
              page: dataLoadedState({
                ...getState().page.data,
                sections: getState().page.data?.sections?.map(section => section.uid === sectionUid ? {
                    ...section,
                    loaded: true,
                    playlists: (section.playlists && page > 0) ? [...section.playlists, ...value.content] : value.content,
                    totalNumberOfElement: value.totalNumberOfElement,
                  } : section
                ),
              }),
            });
          }
        }
      ));
  }


  @Action(PageActions.GetUpcomingEvents)
  getUpcomingEvents(
    { getState, patchState }: StateContext<PagesStateModel>,
    { sectionUid, page, size, languageCode }: PageActions.GetUpcomingEvents
  ) {
    return this.upcomingDataService.getUpcomingEvents(page, size, getState().page.data?.frameworkId,
      getState().page.data?.tagId, languageCode).pipe(tap(( { isSuccess, value } ) => {
        if ( isSuccess ) {
          patchState({
            page: dataLoadedState({
              ...getState().page.data,
              sections: getState().page.data?.sections?.map(section => section.uid === sectionUid ? {
                  ...section,
                  loaded: true,
                  playlists: (section.playlists && page > 0) ? [...section.playlists, ...value.content] : value.content,
                  totalNumberOfElement: value.totalNumberOfElement,
                } : section
              ),
            }),
          });
        }
      }
    ));
  }


  @Action(PageActions.GetPagesItems)
  getPagesItems(
    { getState, patchState }: StateContext<PagesStateModel>,
    { sectionUid, page, size, languageCode }: PageActions.GetPagesItems
  ) {
    return this.pageDataService.getStorePagesItems(page, size, languageCode).pipe(tap(( { isSuccess, value } ) => {
        if ( isSuccess ) {
          patchState({
            page: dataLoadedState({
              ...getState().page.data,
              sections: getState().page.data?.sections?.map(section => section.uid === sectionUid ? {
                  ...section,
                  loaded: true,
                  playlists: (section.playlists && page > 0) ? [...section.playlists, ...value.content] : value.content,
                  totalNumberOfElement: value.totalNumberOfElement,
                } : section
              ),
            }),
          });
        }
      }
    ));
  }

  @Action(PageActions.ShowLessHomePageItems)
  showLessHomePageItems(
    { getState, patchState }: StateContext<PagesStateModel>,
    { sectionUid, size, isDefaultView, isMoments }: PageActions.ShowLessHomePageItems
  ) {
    patchState({
      page: dataLoadedState({
        ...getState().page.data,
        sections: getState().page.data?.sections?.map(section => {
            if ( section.uid === sectionUid ) {
              const sectionObject = {
                ...section,
                loaded: true,
                totalNumberOfElement: section.totalNumberOfElement,
              };
              if ( isDefaultView ) {
                if ( isMoments ) {
                  sectionObject.moments = section.moments.slice(0, size);
                } else {
                  sectionObject.playlists = section.playlists.slice(0, size);
                }
              } else {
                if ( isMoments ) {
                  section.moments.length = section.moments.length - size;
                  sectionObject.moments = section.moments;
                } else {
                  section.playlists.length = section.playlists.length - size;
                  sectionObject.playlists = section.playlists;
                }
              }
              return sectionObject;
            } else {
              return section;
            }
          }
        ),
      }),
    });
  }

  @Action(PageActions.GetUserMoments)
  getUserMoments(
    { getState, patchState }: StateContext<PagesStateModel>,
    { sectionUid, page, size, languageCode }: PageActions.GetUserMoments
  ) {
    return this.momentDataService.getMoments(page, size, languageCode).pipe(
      tap(( { isSuccess, value } ) => {
          if ( isSuccess ) {

            const content = value.content.map(moment => {
              const { url, external, textOnly, text } = MomentCommonHelpers.extractMomentImageAndText(moment);
              return {
                _id: moment._id,
                title: moment.title,
                image: url,
                momentAddedToFolio: moment.addedToFolio,
                content: moment.content,
                tags: moment.tags,
                imageExternal: external,
                textOnly: textOnly,
                text: text,
                viewType: 'VIEW',
              };
            });

            patchState({
              page: dataLoadedState({
                ...getState().page.data,
                sections: getState().page.data?.sections?.map(section => section.uid === sectionUid ? {
                    ...section,
                    loaded: true,
                    moments: section.moments ? [...section.moments, ...content] : content,
                    totalNumberOfElement: value.totalNumberOfElement,
                  } : section
                ),
              }),
            });
          }
        }
      ));
  }

  @Action(PageActions.GetAchievements)
  getAchievements(
    { getState, patchState }: StateContext<PagesStateModel>,
    { sectionUid, languageCode }: PageActions.GetAchievements
  ) {

    return this.momentDataService.getBadges(languageCode).pipe(
      tap(( { isSuccess, value } ) => {
        if ( isSuccess ) {
          patchState({
            page: dataLoadedState({
              ...getState().page.data,
              sections: getState().page.data?.sections?.map(section => section.uid === sectionUid ? {
                  ...section,
                  loaded: true,
                  badges: section.badges ? [...section.badges, ...value] : value,
                } : section
              ),
            }),
          });
        }
      })
    );
  }

  @Action(PageActions.UpdateFrameworkTracker)
  updateFrameworkTracker(
    { patchState, getState }: StateContext<PagesStateModel>
  ) {
    const state = getState();
    const isAlumni = this.store.selectSnapshot(UserAuthState.isAlumniUser);
    return this.pageDataService.getHomePage(isAlumni, '').pipe(
      tap(( { isSuccess, value } ) => {
          if ( isSuccess ) {
            patchState({
              page: dataLoadedState({
                ...state.page.data,
                sections: state.page.data?.sections.map(( section ) => {
                    if ( section.type === 'FRAMEWORK_TRACKER' ) {
                      const currentSection = value.sections.find(item => item.uid === section.uid);
                      return {
                        ...section,
                        learnerTags: currentSection ? currentSection.learnerTags : section.learnerTags,
                      };
                    }
                    return section;
                  }
                ),
              }),
            });
          }
        }
      ));
  }


  @Action(PageActions.OpenResourceDetails)
  openResourceDetails(
    _: StateContext<PagesStateModel>,
    { resourceUri, formattedUri }: PageActions.OpenResourceDetails
  ) {
    RedirectHelper.redirectByParams(this.ngZone, this.router, this.activatedRoute, {
      playlistUri: resourceUri,
      formattedUri: formattedUri,
    }, 'PLAYLIST');
  }

  @Action(PageActions.UpdateMainPageFavoritePlaylists)
  togglePlaylistFavorite(
    { getState, patchState }: StateContext<PagesStateModel>,
    { playlistUri, favorite }: PageActions.UpdateMainPageFavoritePlaylists
  ) {
    patchState({
      page: dataLoadedState({
        ...getState().page.data,
        sections: getState().page.data?.sections?.map(section => this.sectionSupportsFavorite(section) ? {
            ...section,
            playlists: section.playlists?.map(playlist => playlist.uri === playlistUri ?
              { ...playlist, favorite: favorite } : playlist),
          } : (this.isFeaturedSection(section) ? {
            ...section,
            items: section.items?.map(item => item.playlistUri === playlistUri ?
              { ...item, favorite: favorite } : item),
          } : section)
        ),
      }),
    });

    this.store.dispatch(new RefreshFavoritePlaylists());
  }

  @Action(PageActions.LoadYourSectionPlaylists)
  getYourSectionPlaylists(
    { getState, patchState }: StateContext<PagesStateModel>,
    { page }: PageActions.LoadYourSectionPlaylists
  ) {
    const oldContent = page === 0 ? [] :
      (getState().yourSectionPlaylists.data?.content ? getState().yourSectionPlaylists.data.content : []);

    patchState({
      yourSectionPlaylists: loadingState(),
    });

    return this.pageDataService.getYourPlaylists(page, 10, null, null).pipe(
      tap(( { isSuccess, value, error } ) => {
          if ( isSuccess ) {
            patchState({
              yourSectionPlaylists: dataLoadedState({
                ...value,
                content: oldContent.concat(value.content),
              }),
            });
          } else {
            patchState({
              yourSectionPlaylists: errorState(error),
            });
          }
        }
      ));
  }


  @Action(PageActions.LoadMorePagesSection)
  loadMorePagesSection(
    { getState, patchState }: StateContext<PagesStateModel>,
    { page }: PageActions.LoadMorePagesSection
  ) {
    const oldContent = page === 0 ? [] :
      (getState().pages.data ? getState().pages.data : []);

    return this.pageDataService.getPages(page, 50).pipe(
      tap(( { isSuccess, value, error } ) => {
          if ( isSuccess ) {
            const list = oldContent.concat(value);
            patchState({
              pages: dataLoadedState(list),
            });
          } else {
            patchState({
              pages: errorState(error),
            });
          }
        }
      ));
  }

  @Action(PageActions.RefreshYourSectionPlaylists)
  refreshYourSectionPlaylists(
    { getState, patchState }: StateContext<PagesStateModel>
  ) {
    let pageSize = getState().yourSectionPlaylists.data?.content ?
      getState().yourSectionPlaylists.data.content.length : 0;
    let currentPageNumber: number;

    patchState({
      yourSectionPlaylists: loadingState(),
    });

    if ( pageSize > 10 ) {
      pageSize = 20;
      currentPageNumber = 2;
    } else {
      pageSize = 10;
      currentPageNumber = 1;
    }

    return this.pageDataService.getYourPlaylists(0, pageSize, null, null).pipe(
      tap(( { isSuccess, value, error } ) => {
          if ( isSuccess ) {
            patchState({
              yourSectionPlaylists: dataLoadedState({
                ...value,
                currentPageNumber: currentPageNumber,
              }),
            });
          } else {
            patchState({
              yourSectionPlaylists: errorState(error),
            });
          }
        }
      ));
  }

  @Action(PageActions.LoadSubscriptionSectionPlaylists)
  getSubscriptionSectionPlaylists(
    { getState, patchState }: StateContext<PagesStateModel>,
    { page }: PageActions.LoadYourSectionPlaylists
  ) {
    const oldContent = page === 0 ? [] :
      (getState().subscriptionSectionPlaylists.data?.content ? getState().subscriptionSectionPlaylists.data.content : []);

    patchState({
      subscriptionSectionPlaylists: loadingState(),
    });

    return this.pageDataService.getSubscriptionPlaylists(page, 10).pipe(
      tap(( { isSuccess, value, error } ) => {
          if ( isSuccess ) {
            patchState({
              subscriptionSectionPlaylists: dataLoadedState({
                ...value,
                content: oldContent.concat(value.content),
              }),
            });
          } else {
            patchState({
              subscriptionSectionPlaylists: errorState(error),
            });
          }
        }
      ));
  }

  @Action(PageActions.RefreshSubscriptionSectionPlaylists)
  refreshSubscriptionSectionPlaylists(
    { getState, patchState }: StateContext<PagesStateModel>
  ) {
    let pageSize = getState().subscriptionSectionPlaylists.data?.content ?
      getState().subscriptionSectionPlaylists.data.content.length : 0;
    let currentPageNumber: number;

    patchState({
      subscriptionSectionPlaylists: loadingState(),
    });

    if ( pageSize > 10 ) {
      pageSize = 20;
      currentPageNumber = 2;
    } else {
      pageSize = 10;
      currentPageNumber = 1;
    }

    return this.pageDataService.getSubscriptionPlaylists(0, pageSize).pipe(
      tap(( { isSuccess, value, error } ) => {
          if ( isSuccess ) {
            patchState({
              subscriptionSectionPlaylists: dataLoadedState({
                ...value,
                currentPageNumber: currentPageNumber,
              }),
            });
          } else {
            patchState({
              subscriptionSectionPlaylists: errorState(error),
            });
          }
        }
      ));
  }

  @Action(PageActions.SetSaveInProgress)
  setSaveInProgress(
    { patchState }: StateContext<PagesStateModel>,
    { saveInProgress }: PageActions.SetSaveInProgress
  ) {
    patchState({
      saveInProgress: saveInProgress,
    });
  }

  @Action(PageActions.UpdatePageHeadline)
  updatePageHeadline(
    { getState, patchState }: StateContext<PagesStateModel>,
    { headline, languageCode }: PageActions.UpdatePageHeadline
  ) {
    const state = getState();
    patchState({
      page: dataLoadedState({
        ...state.page.data,
        headline: headline,
      }),
    });
    if ( !headline ) {
      return false;
    }

    const newVersionEnabled = VersionHelper.newVersionEnabled();

    patchState({
      saveInProgress: true,
    });

    return this.pageDataService.updatePage(state.page.data._id, { headline: headline }, languageCode).pipe(
      tap(( { isSuccess } ) => {
          if ( isSuccess ) {
            patchState({
              page: dataLoadedState({
                ...state.page.data,
                headline: headline,
              }),
            });
          }

          if ( newVersionEnabled ) {
            if ( !languageCode || languageCode === DEFAULT_LANGUAGE_CODE ) {
              this.store.dispatch(new UpdateSidebarItemData(state.page.data._id, { title: headline }));
            }
          }

          patchState({
            saveInProgress: false,
          });
        }
      ));
  }


  @Action(PageActions.UpdatePageSubheader)
  updatePageSubheader(
    { getState, patchState }: StateContext<PagesStateModel>,
    { subheader, languageCode }: PageActions.UpdatePageSubheader
  ) {
    const state = getState();
    return this.pageDataService.updateHeaders(state.page.data._id, { subHeader: subheader }, languageCode).pipe(
      tap(( { isSuccess } ) => {
          if ( isSuccess ) {
            patchState({
              page: dataLoadedState({
                ...state.page.data,
                subHeader: subheader,
              }),
            });
          }
        }
      ));
  }


  @Action(PageActions.UpdatePageUri)
  updatePageUri(
    { getState, patchState }: StateContext<PagesStateModel>,
    { value }: PageActions.UpdatePageUri
  ) {
    const state = getState();
    const newVersionEnabled = VersionHelper.newVersionEnabled();
    return this.pageDataService.updatePage(state.page.data._id, { uri: value }).pipe(
      tap(( { isSuccess } ) => {
          if ( isSuccess ) {
            let cardOldUri = '';
            let cardNewUrl = '';
            if ( state.page.data.type !== 'HOME_PAGE' ) {
              cardOldUri = '/pages/' + state.page.data.uri;
              cardNewUrl = '/pages/' + value;
            }
            const url = location.href.replace(cardOldUri, cardNewUrl);
            if ( newVersionEnabled ) {
              this.store.dispatch(new UpdateSidebarItemData(state.page.data._id, {
                uri: value,
                oldUri: state.page.data.uri
              }));
            }
            window.history.replaceState('', '', url);
            if ( location.href.endsWith('/publish') ) {
              RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, location.pathname);
            }
            patchState({
              page: dataLoadedState({
                ...state.page.data,
                uri: value,
              }),
            });
          }
        }
      ));
  }


  @Action(PageActions.UpdatePageSidenavVisibility)
  updatePageSidenavVisibility(
    { getState, patchState }: StateContext<PagesStateModel>,
    { enable }: PageActions.UpdatePageSidenavVisibility
  ) {
    const state = getState();
    return this.pageDataService.updateNavigation(state.page.data._id, { enable: enable }).pipe(
      tap(( { isSuccess } ) => {
          if ( isSuccess ) {
            patchState({
              page: dataLoadedState({
                ...state.page.data,
                showOnLeftNav: enable,
              }),
            });
          }
        }
      ));
  }


  @Action(PageActions.ClonePage)
  clonePage(
    { getState, patchState }: StateContext<PagesStateModel>,
  ) {
    const state = getState();
    return this.pageDataService.clonePage(state.page.data._id).pipe(
      tap(( { isSuccess, value } ) => {
          if ( isSuccess ) {
            patchState({
              page: loadingState(),
            });
            RedirectHelper.redirectByParams(this.ngZone, this.router, this.activatedRoute, {
              pageUri: value.uri,
              extraUriParam: 'edit'
            }, 'PAGE');
          }
        }
      ));
  }


  @Action(PageActions.PublishPage)
  publishPage(
    { getState, patchState }: StateContext<PagesStateModel>,
  ) {
    const state = getState();
    return this.pageDataService.updatePage(state.page.data._id, { status: 'ACTIVE' }).pipe(
      tap(( { isSuccess } ) => {
          if ( isSuccess ) {
            patchState({
              page: dataLoadedState({
                ...state.page.data,
                status: 'ACTIVE',
              }),
            });
          }
        }
      ));
  }

  @Action(PageActions.SwitchToHomePage)
  switchToHomePage(
    { getState, patchState }: StateContext<PagesStateModel>,
    { pageUid }: PageActions.SwitchToHomePage
  ) {
    const state = getState();
    return this.pageDataService.switchToHomePage(pageUid).pipe(
      tap(( { isSuccess, error } ) => {
          if ( isSuccess ) {
            SnackbarHelper.showTranslatableSnackBar(
              this.ngZone, this.snackBar, this.translationService, 'pages.message.success.switchToHomePage'
            );
            patchState({
              page: dataLoadedState({
                ...state.page.data,
                type: 'HOME_PAGE',
              }),
            });
          } else {
            SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
          }
        }
      ));
  }

  @Action(PageActions.UnpublishPage)
  unpublishPage(
    { getState, patchState }: StateContext<PagesStateModel>,
  ) {
    const state = getState();
    return this.pageDataService.updatePage(state.page.data._id, { status: 'INACTIVE' }).pipe(
      tap(( { isSuccess } ) => {
          if ( isSuccess ) {
            patchState({
              page: dataLoadedState({
                ...state.page.data,
                status: 'INACTIVE',
              }),
            });
          }
        }
      ));
  }

  @Action(PageActions.PageAssociateAdmin)
  pageAssociateAdmin(
    { getState, patchState }: StateContext<PagesStateModel>,
    { request }: PageActions.PageAssociateAdmin,
  ) {
    const state = getState();
    return this.pageDataService.associateAdmin(state.page.data._id, request.adminId).pipe(
      tap(( { isSuccess } ) => {
          if ( isSuccess ) {
            patchState({
              page: dataLoadedState({
                ...state.page.data,
                adminRights: [...state.page.data.adminRights, request],
              }),
            });
          }
        }
      ));
  }

  @Action(PageActions.PageDissociateAdmin)
  pageDissociateAdmin(
    { getState, patchState }: StateContext<PagesStateModel>,
    { adminUid }: PageActions.PageDissociateAdmin,
  ) {
    const state = getState();
    return this.pageDataService.dissociateAdmin(state.page.data._id, adminUid).pipe(
      tap(( { isSuccess } ) => {
          if ( isSuccess ) {
            patchState({
              page: dataLoadedState({
                ...state.page.data,
                adminRights: state.page.data.adminRights.filter(admin => admin.adminId !== adminUid),
              }),
            });
          }
        }
      ));
  }


  @Action(PageActions.PageAddTag)
  pageAddTag(
    { getState, patchState }: StateContext<PagesStateModel>,
    { tag }: PageActions.PageAddTag,
  ) {
    const state = getState();
    return this.pageDataService.addTag(state.page.data._id, tag._id).pipe(
      tap(( { isSuccess } ) => {
          if ( isSuccess ) {
            patchState({
              page: dataLoadedState({
                ...state.page.data,
                tags: state.page.data.tags.length ? [...state.page.data.tags, tag] : [tag],
              }),
            });
          }
        }
      ));
  }

  @Action(PageActions.PageRemoveTag)
  pageRemoveTag(
    { getState, patchState }: StateContext<PagesStateModel>,
    { tagUid }: PageActions.PageRemoveTag,
  ) {
    const state = getState();
    const newTags = state.page.data.tags.filter(item => item._id !== tagUid);
    return this.pageDataService.removeTag(state.page.data._id, tagUid).pipe(
      tap(( { isSuccess } ) => {
          if ( isSuccess ) {
            patchState({
              page: dataLoadedState({
                ...state.page.data,
                tags: newTags,
              }),
            });
          }
        }
      ));
  }

  @Action(PageActions.PageTargetToGroup)
  pageTargetToGroup(
    { getState, patchState }: StateContext<PagesStateModel>,
    { request }: PageActions.PageTargetToGroup,
  ) {
    const state = getState();
    let groups = cloneDeep(state.page.data.target.groups);
    if ( groups && groups.length ) {
      groups.push(request);
    } else {
      groups = [request];
    }
    return this.pageDataService.targetPageToGroup(state.page.data._id, request._id).pipe(
      tap(( { isSuccess } ) => {
          if ( isSuccess ) {
            patchState({
              page: dataLoadedState({
                ...state.page.data,
                target: {
                  ...state.page.data.target,
                  groups: groups,
                },
              }),
            });
          }
        }
      ));
  }


  @Action(PageActions.PageTargetRemoveGroup)
  pageTargetRemoveGroup(
    { getState, patchState }: StateContext<PagesStateModel>,
    { groupUid }: PageActions.PageTargetRemoveGroup,
  ) {
    const state = getState();
    const newGroups = state.page.data.target.groups.filter(item => item._id !== groupUid);
    return this.pageDataService.targetPageRemoveGroup(state.page.data._id, groupUid).pipe(
      tap(( { isSuccess } ) => {
          if ( isSuccess ) {
            patchState({
              page: dataLoadedState({
                ...state.page.data,
                target: {
                  ...state.page.data.target,
                  groups: newGroups,
                },
              }),
            });
          }
        }
      ));
  }


  @Action(PageActions.PageTargetToMember)
  pageTargetToMember(
    { getState, patchState }: StateContext<PagesStateModel>,
    { request }: PageActions.PageTargetToMember,
  ) {
    const state = getState();
    let users = cloneDeep(state.page.data.target.users);
    if ( users && users.length ) {
      users.push(request);
    } else {
      users = [request];
    }
    return this.pageDataService.targetPageToUser(state.page.data._id, request._id).pipe(
      tap(( { isSuccess } ) => {
          if ( isSuccess ) {
            patchState({
              page: dataLoadedState({
                ...state.page.data,
                target: {
                  ...state.page.data.target,
                  users: users,
                },
              }),
            });
          }
        }
      ));
  }


  @Action(PageActions.PageTargetRemoveMember)
  pageTargetRemoveMember(
    { getState, patchState }: StateContext<PagesStateModel>,
    { userUid }: PageActions.PageTargetRemoveMember,
  ) {
    const state = getState();
    const newUsers = state.page.data.target.users.filter(item => item._id !== userUid);
    return this.pageDataService.targetPageRemoveUser(state.page.data._id, userUid).pipe(
      tap(( { isSuccess } ) => {
          if ( isSuccess ) {
            patchState({
              page: dataLoadedState({
                ...state.page.data,
                target: {
                  ...state.page.data.target,
                  users: newUsers,
                },
              }),
            });
          }
        }
      ));
  }


  @Action(PageActions.PageTargetRemoveAllMembers)
  pageTargetRemoveAllMembers(
    { getState, patchState }: StateContext<PagesStateModel>,
  ) {
    const state = getState();
    return this.pageDataService.targetPageRemoveAllUsers(state.page.data._id).pipe(
      tap(( { isSuccess } ) => {
          if ( isSuccess ) {
            patchState({
              page: dataLoadedState({
                ...state.page.data,
                target: {
                  ...state.page.data.target,
                  users: [],
                },
              }),
            });
          }
        }
      ));
  }

  @Action(PageActions.PageTargetRemoveAllGroups)
  pageTargetRemoveAllGroups(
    { getState, patchState }: StateContext<PagesStateModel>,
  ) {
    const state = getState();
    return this.pageDataService.targetPageRemoveAllGroups(state.page.data._id).pipe(
      tap(( { isSuccess } ) => {
          if ( isSuccess ) {
            patchState({
              page: dataLoadedState({
                ...state.page.data,
                target: {
                  ...state.page.data.target,
                  groups: [],
                },
              }),
            });
          }
        }
      ));
  }


  @Action(PageActions.PageTargetSetPrivate)
  pageTargetSetPrivate(
    { getState, patchState }: StateContext<PagesStateModel>,
  ) {
    const state = getState();
    const userDetails = this.store.selectSnapshot(UserAuthState.userDetailsData);
    return this.pageDataService.targetPageToUser(state.page.data._id, userDetails.uid).pipe(
      tap(( { isSuccess } ) => {
          if ( isSuccess ) {
            patchState({
              page: dataLoadedState({
                ...state.page.data,
                target: {
                  ...state.page.data.target,
                  groups: [],
                  users: [{ _id: userDetails.uid, name: userDetails.firstName + ' ' + userDetails.lastName }],
                },
              }),
            });
          }
        }
      ));
  }


  @Action(PageActions.UpdatePageAuthorInfo)
  updatePageAuthorInfo(
    { getState, patchState }: StateContext<PagesStateModel>,
    { request }: PageActions.UpdatePageAuthorInfo,
  ) {
    const isAuthorNameUpdate = request.hasOwnProperty('authorName');
    const state = getState();
    return this.pageDataService.updateAuthorInfo(state.page.data._id, request).pipe(
      tap(( { isSuccess } ) => {
          if ( isSuccess ) {
            patchState({
              page: dataLoadedState({
                ...state.page.data,
                authorDetails: {
                  ...state.page.data.authorDetails,
                  displayConfig: {
                    ...state.page.data.authorDetails.displayConfig,
                    authorName: isAuthorNameUpdate ?
                      request.authorName : state.page.data.authorDetails.displayConfig?.authorName,
                    organizationName: !isAuthorNameUpdate ?
                      request.organizationName : state.page.data.authorDetails.displayConfig?.organizationName,
                  },
                },
              }),
            });
          }
        }
      ));
  }

  @Action(PageActions.UpdatePageAuthorVisibility)
  updatePageAuthorVisibility(
    { getState, patchState }: StateContext<PagesStateModel>,
    { request }: PageActions.UpdatePageAuthorVisibility,
  ) {
    const state = getState();
    return this.pageDataService.updateAuthorInfo(state.page.data._id, request).pipe(
      tap(( { isSuccess } ) => {
          if ( isSuccess ) {
            patchState({
              page: dataLoadedState({
                ...state.page.data,
                authorDetails: {
                  ...state.page.data.authorDetails,
                  displayConfig: {
                    ...state.page.data.authorDetails.displayConfig,
                    authorName: request.authorName,
                    organizationName: request.organizationName,
                  },
                },
              }),
            });
          }
        }
      ));
  }

  @Action(PageActions.ArchivePage)
  archivePage(
    { getState }: StateContext<PagesStateModel>,
  ) {
    const state = getState();
    return this.pageDataService.archivePage(state.page.data._id).pipe(
      tap(( { isSuccess } ) => {
          if ( isSuccess ) {
            const homePageUri = this.store.selectSnapshot(UserAuthState.homePageUri);
            RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, homePageUri);
          }
        }
      ));
  }

  @Action(PageActions.RedirectToPageLearnerView)
  navigateToPageLearnerView(
    { getState }: StateContext<PagesStateModel>,
    { uri }: PageActions.RedirectToPageLearnerView
  ) {
    const pageUri = uri ?? getState().page.data.uri;
    const isHomePage = getState().page.data.type === 'HOME_PAGE';

    if ( isHomePage ) {
      RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, '/');
    } else {
      RedirectHelper.redirectByParams(this.ngZone, this.router, this.activatedRoute, { pageUri: pageUri }, 'PAGE');
    }
  }


  private sectionSupportsFavorite( section: Section ): boolean {
    return section.type === 'CONTINUE_PLAYLISTS' ||
      section.type === 'LATEST_PLAYLISTS' ||
      section.type === 'YOUR_PLAYLISTS';
  }

  private isFeaturedSection( section: Section ): boolean {
    return section.type === 'FEATURED' ||
      section.type === 'FEATURED_SLIM' ||
      section.type === 'NEW_PLAYLISTS';
  }

  private loadPage(
    patchState: ( p: Partial<PagesStateModel> ) => PagesStateModel,
    pageUri?: string,
    publisherUri?: string,
    packageUri?: string,
    frameworkId?: string,
    tagId?: string,
    languageCode?: string,
    contentStore?: boolean,
  ) {
    patchState({
      saveInProgress: false,
      page: loadingState(),
    });
    const isAlumni = this.store.selectSnapshot(UserAuthState.isAlumniUser);
    const homePageUri = this.store.selectSnapshot(UserAuthState.homePageUri);
    const pageObservable: ObservableResult<Page> =
      pageUri ? this.pageDataService.getPage(pageUri, publisherUri, packageUri, frameworkId, tagId, languageCode, contentStore) :
        this.pageDataService.getHomePage(isAlumni, languageCode);

    return pageObservable.pipe(
      tap(( { isSuccess, value, error, dataForms } ) => {
          if ( isSuccess && value ) {
            const updatedSection = value.sections.map(( section ) => ({
              ...section,
              mappedTitle: section.title,
              loaded: false,
            }));
            if ( value.type === 'HOME_PAGE' ) {
              this.store.dispatch(new UpdateHomePageTitle(value.headline));
            }
            if ( value.uri === homePageUri ) {
              this.store.dispatch(new ResetSidebarTree(true));
            }
            patchState({
              page: dataLoadedState({
                ...value,
                sections: updatedSection,
                frameworkId: frameworkId,
                tagId: tagId,
              }),
            });
          } else {
            patchState({
              page: errorState(error, dataForms),
            });
          }
        }
      ));
  }
}
