<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
-->

<mat-form-field class="group-search-input-field" appearance="outline">
  <mat-icon matPrefix>search</mat-icon>
  <input
    matInput
    [matAutocomplete]="auto"
    [placeholder]="placeholder"
    (focus)="onFocus()"
    (paste)="onTextPaste($event)"
    (keyup)="onSearchInputChange($event)">
  <mat-autocomplete
    #auto="matAutocomplete"
    class="group-search-result-autocomplete"
    [displayWith]="disabledAutocomplete"
    [thresholdPercent]="0.9"
    (optionsScroll)="onAutocompleteScroll()"
    (optionSelected)="onGroupSelected($event)">
    <mat-option *ngFor="let group of groups" [value]="group"
                matTooltipClass="medium-tooltip"
                [matTooltip]="group.title" class="search-result-group-option">
      <div class="search-result-group">{{group.title}}</div>
    </mat-option>
    <div class="loading-more-indicator" *ngIf="isLoadingMore">
      <mat-spinner class="loading-more-indicator__spinner" [diameter]="30"></mat-spinner>
    </div>
  </mat-autocomplete>
  <mat-icon aria-hidden="true" matSuffix class="flex">
    <mat-spinner *ngIf="loadingGroups" [diameter]="18">
    </mat-spinner>
  </mat-icon>
</mat-form-field>
