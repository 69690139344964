/*
 * Copyright (C) 2024 - present by Potentially
 *
 * Please see distribution for license.
 */

import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import {
  MEMBERS_DATA_SERVICE,
  MembersDataService
} from 'src/app/page-modules/admin/services/members/members-data.service';
import {
  UserSearchAutocompleteNewComponent
} from 'src/app/shared/components/user-search-autocomplete-new/user-search-autocomplete-new.component';
import {
  Members,
  UserSearch
} from 'src/app/shared/models/admin/members.model';
import { TranslationService } from 'src/app/shared/services/translation/translation.service';
import { ResourceAdminState } from '../../../../../resource/store/admin/resource-admin.state';
import { Resource } from '@app/app/shared/models';
import { AdminMembersAction } from '@app/app/page-modules/admin/components/members-new/model/members.model';

@Component({
  selector: 'ptl-admin-add-member',
  templateUrl: './admin-add-member.component.html',
  styleUrls: ['./admin-add-member.component.scss'],
})
export class AdminAddMemberComponent implements OnInit, OnDestroy {

  @Input() isPlaylistPage: boolean;
  @Input() isCardPage: boolean;
  @Input() isEvent: boolean;
  @Input() members: Members[];

  @Output() memberActionTriggered = new EventEmitter<AdminMembersAction>();

  @ViewChild('userSearchAutocomplete') private userSearchAutocomplete: UserSearchAutocompleteNewComponent;

  @Select(ResourceAdminState.resource)
  private resource$: Observable<Resource>;

  loadingMembers: boolean;
  addSomeoneManuallySearchEnabled = false;
  searchedMembers: UserSearch[];
  addUserManuallyDisabledText: string;
  cardPublished = false;
  addUserManuallyButtonDisabled: boolean;

  private userSearchPage = 0;
  private userSearchPageSize = 20;
  private searchString: string;
  private searchInputSubject = new Subject<string>();
  private subscriptionEnd$ = new EventEmitter<void>();

  constructor(
    private translationService: TranslationService,
    @Inject(MEMBERS_DATA_SERVICE) private membersDataService: MembersDataService
  ) {
  }

  ngOnInit() {
    this.searchInputSubject.pipe(
      takeUntil(this.subscriptionEnd$),
      debounceTime(500)
    ).subscribe(() => this.fireSearch(true));

    if (this.isCardPage) {
      this.resource$.pipe(
        takeUntil(this.subscriptionEnd$)
      ).subscribe((value) => {
        if (value.status === 'PUBLISHED') {
          this.cardPublished = true;
          this.addUserManuallyDisabledText = undefined;
        } else {
          this.addSomeoneManuallySearchEnabled = false;
          this.cardPublished = false;
          this.addUserManuallyDisabledText =
            this.translationService.getTranslation('membersNew.list.table.addUserManuallyDisabledText');
        }

        this.addUserManuallyButtonDisabled = !this.cardPublished && !this.isPlaylistPage;
      });
    }
  }

  ngOnDestroy() {
    this.subscriptionEnd$?.emit();
  }

  addSomeoneManuallyEnabled(enabled: boolean): void {
    if (!this.addUserManuallyButtonDisabled) {
      this.addSomeoneManuallySearchEnabled = enabled;
    }
  }

  onSearchInputChange(searchValue: string): void {
    this.searchString = searchValue;
    this.loadingMembers = true;
    this.searchInputSubject.next(searchValue);
  }

  onSearchLoadingMore(): void {
    this.fireSearch(false);
  }

  onMemberSelected(data: UserSearch): void {
    const action = this.isPlaylistPage ? 'REGISTERED' : 'WILL_ATTEND'
    this.memberActionTriggered.emit({type: action, memberIds: [data.uid], allMembersRefresh: true})
  }

  private fireSearch(override: boolean): void {
    this.userSearchPage = override ? 0 : this.userSearchPage + 1;
    if (!this.searchString) {
      this.searchString = '';
    }
    this.membersDataService.searchUsers(this.userSearchPage, this.userSearchPageSize, this.searchString)
      .subscribe(({isSuccess, value}) => {
        if (isSuccess) {
          if (override) {
            this.searchedMembers = value.content;
          } else {
            this.searchedMembers = this.searchedMembers.concat(value.content);
          }
        }
        this.searchedMembers = this.searchedMembers.filter(searchedMember => !this.members.map(m => m._id).includes(searchedMember.uid))
        if (this.userSearchAutocomplete) {
          this.userSearchAutocomplete.canLoadMore = value.totalNumberOfElement > this.members.length;
          this.userSearchAutocomplete.isLoadingMore = false;
        }
        this.loadingMembers = false;
      });
  }
}
