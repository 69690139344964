<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="clear"></div>
<div class="chart-preview-section" *ngIf="!chartContent?.showStaticChart" [class.polar-chart]="chartType === 'polarArea'">

  <div class="chart-preview-section__body"
       *ngIf="chartData.length"
       tabindex="0"
       [attr.aria-labelledby]="'chart'+chartId">

    <div [id]="'chart'+chartId" class="chart-preview-hidden-section" aria-hidden="true">
      <span>{{ 'translations.assessmentReportChartTitle' | transloco }}</span>
      <span *ngFor="let title of chartDataLabels, index as i">
        {{ 'translations.assessmentReportChartItem' | transloco }} {{ title }},
        {{ 'translations.assessmentReportChartScore' | transloco }} {{ chartData[i] }}.
      </span>
    </div>

    <ng-container *ngIf="chartType === 'polarArea'">
      <div class="user-image" *ngIf="(userDetailsData$ | async)?.imageUrl">
        <img [src]="(userDetailsData$ | async)?.imageUrl"
             alt="{{(userDetailsData$ | async)?.firstName}}"
             width="60"
             height="60"/>
      </div>
      <div class="user-no-image" *ngIf="!(userDetailsData$ | async)?.imageUrl">
        {{( userDetailsData$ | async )?.firstName[0] }}
      </div>

      <canvas #Chart
              baseChart
              [datasets]="chartData"
              [labels]="chartDataLabels"
              [plugins]="chartPlugins"
              [type]="chartType"
              [options]="chartOptions" style="height: 31.125rem"></canvas>

      <div #circlePoints class="circle" *ngIf="chartType === 'polarArea'">
        <div class="point f_point" *ngFor="let sectionItem of chartSectionItems; let i = index"
             (click)="scrollTo(sectionItem.sectionUid)"
             (keydown.enter)="scrollTo(sectionItem.sectionUid)"
             [style.border-bottom-color]="chartDataAlphaColors[i]">
        <span
          tabindex="0"
          [attr.aria-label]="sectionItem.title">
          {{sectionItem.title}}
        </span>
        </div>
      </div>
      <div #circleLines class="circle circle-1" *ngIf="chartType === 'polarArea'">
      </div>
    </ng-container>


    <ng-container *ngIf="chartType !== 'polarArea'">
      <ptl-bar-chart-preview
        [chartVersionStart]="chartVersionStart"
        [chartVersionEnd]="chartVersionEnd"
        [datasets]="chartData"
        [labels]="chartDataLabels"
        [chartSectionItems]="chartSectionItems"
        [chartContent]="chartContent"
      ></ptl-bar-chart-preview>
    </ng-container>


  </div>
  <div class="chart-preview-section__keys"
       *ngIf="!!(chartType === 'polarArea') && !!(chartData?.length)">
    <div class="key" *ngFor="let item of chartDataLabels; let i = index">
      <span [style.backgroundColor]="chartDataAlphaColors[i]"></span>
      <span>{{ chartDataLabels[i] }}</span>
    </div>
  </div>

  <div class="chart-preview-version-select" *ngIf="chartContent?.versionedScores?.length > 2">
    <div class="chart-preview-version-select-title" tabindex="0" >
      {{ 'translations.report.title.compareCompletionDates' | transloco }}
    </div>

    <div class="chart-preview-version-select-box">
      <mat-form-field class="date-field" appearance="outline">
        <div class="chart-preview-background-color-striped" *ngIf="chartType !== 'polarArea'"></div>
        <mat-select [attr.aria-label]="('translations.global.ariaLabels.chooseFirstCompletionDate' | transloco)"
                    [(ngModel)]="chartVersionStart">
          <mat-option *ngFor="let completion of chartContent?.versionedScores" [value]="completion.version"
                      [disabled]="completion.version === chartVersionEnd">
            {{ getLocalDateTime( completion.completedOn ) | date:  'd MMMM y, HH:mm' }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="date-field" appearance="outline" >
        <div class="chart-preview-background-color" *ngIf="chartType !== 'polarArea'"></div>
        <mat-select [attr.aria-label]="('translations.global.ariaLabels.chooseSecondCompletionDate' | transloco)"
                    [(ngModel)]="chartVersionEnd">
          <mat-option *ngFor="let completion of chartContent?.versionedScores" [value]="completion.version"
                      [disabled]="completion.version === chartVersionStart">
            {{ getLocalDateTime( completion.completedOn ) | date:  'd MMMM y, HH:mm' }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button
        mat-flat-button
        class="apply-button"
        color="primary"
        [attr.aria-label]="('translations.global.ariaLabels.pressEnterToApplyChanges' | transloco)"
        (click)="applyDateFilter()">
        {{ 'translations.global.button.apply' | transloco }}
      </button>

    </div>
  </div>

</div>

<div class="chart-preview-section" *ngIf="chartContent?.showStaticChart">

  <div class="chart-preview-section__static">

    <img *ngIf="chartContent?.chartType === 'RADIAL'"
         src="../assets/chart-radial.jpg"
         width="302"
         height="302"
         alt="chart">
    <img *ngIf="chartContent?.chartType === 'BAR'" src="../assets/chart-bar.jpg" width="813" height="365" alt="chart">

  </div>

</div>

<div id="pics" style="display: none"></div>
