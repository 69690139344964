/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { MomentCard } from '../../../../../models';
import { Select, Store } from '@ngxs/store';
import { DeleteMoment } from '../../../../../store/moment/moment-view-all/moment-view-all.actions';
import { ViewMoment } from '../../../../../../../app-frame/store/app-frame.actions';
import { UserAuthState } from '../../../../../../../user-auth/store/user-auth.state';
import { Observable } from 'rxjs';
import { UserDetails } from '../../../../../../../user-auth/models';
import { FolioSectionItemRequest, FolioSectionItemType } from '../../../../../store/folio.state.model';
import { DisableFolioSectionItemVisibility, EnableFolioSectionItemVisibility } from '../../../../../store/folio.action';
import { MatDialog } from '@angular/material/dialog';
import { FolioPublicMomentContentComponent } from '../../../public-moment/public-moment-content.component';
import { LanguageCodeHelper } from '../../../../../../../shared/helpers/language-code-helper';

@Component({
  selector: 'ptl-masonry-card-new',
  templateUrl: './masonry-card-new.component.html',
  styleUrls: ['./masonry-card-new.component.scss'],
})
export class MasonryCardNewComponent {

  /** Card data. */
  @Input() card: MomentCard;
  /** define if moment should be archived. */
  @Input() archiveMoment: boolean;
  @Input() selected;
  @Input() uid: string;
  @Input() editMode: boolean;
  @Input() isFolioSection: boolean;

  @Output() cardClick = new EventEmitter<MomentCard>();
  @Output() removeCard = new EventEmitter<string>();

  @Select(UserAuthState.userDetailsData)
  userDetailsData$: Observable<UserDetails>;

  constructor(
    private store: Store,
    private dialog: MatDialog,
  ) {
  }

  onRemoveMoment( id: string ) {
    this.removeCard.emit(id);
  }

  onArchiveMoment( id: string ) {
    this.store.dispatch(new DeleteMoment(id));
  }

  onItemVisibilityState( card: MomentCard, event: Event ) {
    event.stopPropagation();

    const request: FolioSectionItemRequest = {
      type: FolioSectionItemType.MOMENTS,
      uid: card._id
    }

    if ( card.visible ) {
      this.store.dispatch(new DisableFolioSectionItemVisibility(this.uid, request));
    } else {
      this.store.dispatch(new EnableFolioSectionItemVisibility(this.uid, request));
    }

    card.visible = !card.visible;
  }

  openMomentForView() {
    if ( this.card.isPublicFolio ) {
      this.dialog.open(FolioPublicMomentContentComponent, {
        width: '75rem',
        maxHeight: '80vh',
        position: {
          top: '10vh',
        },
        direction: LanguageCodeHelper.getBodyLanguageDir(),
        restoreFocus: true,
        panelClass: 'ptl-mat-dialog',
        backdropClass: 'dialog-backdrop',
        data: {
          moment: this.card,
        },
      });
      return;
    }
    if ( this.card.viewType !== 'COLLECTOR' && !this.card.isDisabled ) {
      this.store.dispatch(new ViewMoment(this.card._id));
    }
  }

  onButtonClick() {
    this.cardClick.emit(this.card);
  }

}
