<!--
  ~ Copyright (C) 2022 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ng-container>
  <div class="member-filter__wrapper">
    <div class="member-filter__dropdowns">
      <mat-select
        [(value)]="selectedFormStatus"
        (selectionChange)="onFormStatusChange($event)"
        class="member-filter__dropdown">
        <mat-option *ngFor="let formStatus of formStatuses" [value]="formStatus.value">{{ formStatus.title }}</mat-option>
      </mat-select>
      <mat-select
        [placeholder]="'translations.members.filter.form.find' | transloco"
        (selectionChange)="onFormHeaderSelected($event)"
        class="member-filter__dropdown">
        <mat-option *ngFor="let formHeader of formHeaders" [value]="formHeader">{{ formHeader.header }}</mat-option>
      </mat-select>
      <div class="member-filter__filler">
        <div *ngFor="let selectedForm of selectedForms" class="selected-card">
          <span>{{ selectedForm.header }}</span>
          <span
            class="selected-card__remove"
            [matTooltip]="('translations.members.filter.form.remove' | transloco)"
            [attr.aria-label]="('translations.global.ariaLabels.clear' | transloco: {title: selectedForm.header})"
            (click)="onFormRemove(selectedForm.id)"
            (keydown.enter)="onFormRemove(selectedForm.id)"
            tabindex="0">
            <mat-icon aria-hidden="true" [inline]="true">clear</mat-icon>
          </span>
        </div>
      </div>
    </div>
    <p *ngIf="noForm" class="member-filter__validation">
      {{ 'translations.members.filter.card.noForm' | transloco }}
    </p>
  </div>
  <ptl-member-filter-controls (cancel)="onCancel()" (save)="onSave()"></ptl-member-filter-controls>
</ng-container>
