<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div tabindex="0" aria-labelledby="sliderQuestionAria">

  <div class="question-aria" id="sliderQuestionAria">
    {{ 'translations.sliderQuestionAria' | transloco : {leftText: leftText, rightText: rightText} }}
  </div>

  <span #ariaMiddle class="question-aria">{{ 'translations.sliderQuestionAriaMiddle' | transloco : {
    leftText: leftText,
    rightText: rightText
  } }}</span>
  <span #ariaLeft
        class="question-aria">{{ 'translations.sliderQuestionAriaLeft' | transloco : {leftText: leftText} }}</span>
  <span #ariaRight
        class="question-aria">{{ 'translations.sliderQuestionAriaRight' | transloco : {rightText: rightText} }}</span>
  <span #ariaLeftEnd
        class="question-aria">{{ 'translations.sliderQuestionAriaLeftEnd' | transloco : {leftText: leftText} }}</span>
  <span #ariaRightEnd
        class="question-aria">{{ 'translations.sliderQuestionAriaRightEnd' | transloco : {rightText: rightText} }}</span>

  <ptl-resource-preview class="question-slider-info"
                        [previewContent]="[{ type: 'PARAGRAPH', content: question.instructions ? getTranslatedValue(question.instructions) : null }]">
  </ptl-resource-preview>
  <div class="question-card__slider-wrapper">
    <mat-slider
      [class.checked-slider-image]="sliderHasChanged"
      class="question-card__slider"
      color="primary"
      [min]="leftScore"
      [max]="rightScore"
      [(ngModel)]="selectedValue"
      [step]="0.01"
      [disabled]="disabled"
      [attr.aria-valuemax]=""
      [attr.aria-valuemin]=""
      [attr.aria-valuenow]=""
      [attr.aria-label]="ariaLabelText"
      (click)="sliderClicked(selectedValue)"
      (change)="sliderChanged($event)">
    </mat-slider>
    <div class="question-card__slider-ranges">
      <div class="question-card__slider-range-item"></div>
      <div class="question-card__slider-range-item"></div>
      <div class="question-card__slider-range-item"></div>
    </div>
    <div class="question-card__slider-texts">
      <div class="question-card__slider-text left-text">{{ leftText }}</div>
      <div class="question-card__slider-text right-text">{{ rightText }}</div>
    </div>

    <div class="question-card__slider-instructions" *ngIf="newVersionEnabled">
      <div class="question-card__slider-instruction-item">
        <ptl-resource-preview *ngIf="leftInstruction?.length && leftInstruction[0].content !== ''"
                              [previewContent]="leftInstruction"
                              class="thin-preview"
        ></ptl-resource-preview>
      </div>
      <div class="question-card__slider-instruction-item">
        <ptl-resource-preview *ngIf="rightInstruction?.length && rightInstruction[0].content !== ''"
                              [previewContent]="rightInstruction"
                              class="thin-preview"
        ></ptl-resource-preview>
      </div>
    </div>
  </div>

</div>
