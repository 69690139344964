<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="analytics-chart-section-top" >
  <div class="title" [attr.aria-label]="chartTitle">
    {{ chartTitle }}
  </div>
  <div class="chart-style-box">
    <button mat-button
            class="chart-style-button"
            [attr.aria-label]="('translations.global.ariaLabels.chartTypes' | transloco)"
            [matMenuTriggerFor]="actionsMenu">
      <span>{{ 'translations.analytics.filter.label.chartStyle' | transloco }}</span>
      <mat-icon aria-hidden="true">reorder</mat-icon>
    </button>

    <mat-menu #actionsMenu="matMenu">
      <button mat-menu-item
              type="button"
              class="chart-type-button"
              [class.active]="chartType === 'line'"
              [attr.aria-label]="('translations.global.ariaLabels.lineChart' | transloco)"
              (click)="changeChart('line')">
        <span>{{ 'translations.analytics.charts.line' | transloco }}</span>
      </button>

      <button mat-menu-item
              type="button"
              class="chart-type-button"
              [class.active]="chartType === 'bar'"
              [attr.aria-label]="('translations.global.ariaLabels.barChart' | transloco)"
              (click)="changeChart('bar')">
        <span>{{ 'translations.analytics.charts.bar' | transloco }}</span>
      </button>

      <button mat-menu-item
              type="button"
              class="chart-type-button"
              [class.active]="chartType === 'horizontalBar'"
              [attr.aria-label]="('translations.global.ariaLabels.horizontalBarChart' | transloco)"
              (click)="changeChart('horizontalBar')">
        <span>{{ 'translations.analytics.charts.horizontalBar' | transloco }}</span>
      </button>

      <button mat-menu-item
              type="button"
              class="chart-type-button"
              [class.active]="chartType === 'pie'"
              [attr.aria-label]="('translations.global.ariaLabels.pieChart' | transloco)"
              (click)="changeChart('pie')">
        <span>{{ 'translations.analytics.charts.pie' | transloco }}</span>
      </button>

      <button mat-menu-item
              type="button"
              class="chart-type-button"
              [class.active]="chartType === 'polarArea'"
              [attr.aria-label]="('translations.global.ariaLabels.polarAreaChart' | transloco)"
              (click)="changeChart('polarArea')">
        <span>{{ 'translations.analytics.charts.polarArea' | transloco }}</span>
      </button>

      <button mat-menu-item
              type="button"
              class="chart-type-button"
              [class.active]="chartType === 'doughnut'"
              [attr.aria-label]="('translations.global.ariaLabels.doughnutChart' | transloco)"
              (click)="changeChart('doughnut')">
        <span>{{ 'translations.analytics.charts.doughnut' | transloco }}</span>
      </button>

    </mat-menu>
  </div>

</div>



<div class="analytics-chart-container" [class.is_loading]="loadingInProgress">
  <canvas baseChart *ngIf="updateChartView"
          [data]="chartDatasets"
          [options]="chartOptions"
          [type]="chartType">
  </canvas>

  <div class="analytics-chart-loader" *ngIf="loadingInProgress">
    <mat-spinner [diameter]="40"></mat-spinner>
  </div>
</div>
