/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Inject, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, mergeMap, map, pluck, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { TranslationService } from '../../../../../shared/services/translation/translation.service';
import {
  ImageCropDialogComponent
} from '../../../../../shared/components/image-crop-dialog/image-crop-dialog.component';
import { PlaylistThumbnailUpdateRequest } from '../../../../playlist/models';
import { ImageUploadNameResolver } from '../../../../../shared/helpers/image-upload-name-resolver';
import { FileUploadHelper } from '../../../../../shared/helpers/file-upload-helper';
import { SnackbarHelper } from '../../../../../shared/helpers/snackbar-helper';
import { AvailableLanguage, Organization, ResourceTag } from '../../../../../shared/models';
import { LoadableState } from '../../../../../shared/store';
import { UserAuthState } from '../../../../../user-auth/store/user-auth.state';
import { OrganizationLanguageSummary, SupportedLanguage } from '../../../../../shared/models/languages/languages.model';
import { ActivatedRoute, Router } from '@angular/router';
import {
  LANGUAGES_DATA_SERVICE,
  LanguagesDataService
} from '../../../../../shared/services/languages/languages-data.service';
import {
  FileUploadService,
  ORGANIZATIONS_FILE_UPLOAD_DATA_SERVICE
} from '../../../../../shared/services/file-upload/file-upload.service';
import { PageState } from '../../../store/pages.state';
import { Page, PageStatus } from '../../../../../shared/models/page/page.model';
import {
  LoadPageByUriAndLanguageCode,
  RedirectToPageLearnerView,
  PageAddTag,
  PageRemoveTag,
  PublishPage, ResetPageCurrentLanguage,
  SetPageCurrentLanguage, SwitchToHomePage,
  UnpublishPage,
  UpdatePageHeadline,
  UpdatePageSubheader,
  UpdatePageUri, UpdatePageSidenavVisibility
} from '../../../store/pages.actions';
import { PAGES_DATA_SERVICE, PageDataService } from '../../../services/data.service';
import { CORE_TAG_DATA_SERVICE, TagDataService } from '../../../../../shared/services/tags/tag-data.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { canTriggerSearch } from '../../../../../shared/helpers/content-helper';
import { LanguageCodeHelper } from '../../../../../shared/helpers/language-code-helper';
import { DialogService } from '../../../../../shared/helpers/dialog/dialog.service';
import { RedirectHelper } from '../../../../resource/store/editor/content/helpers/redirect.helper';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { VersionHelper } from '../../../../../shared/helpers/version.helper';
import { CurrentLanguage } from '@app/app/page-modules/playlist/store/create/playlist-creation.state.model';


@Component({
  selector: 'ptl-pages-publish',
  templateUrl: './publish.component.html',
  styleUrls: [ './publish.component.scss' ],
})
export class PagePublishComponent implements OnInit, OnDestroy {

  @Input() isDialog: boolean;

  @Select(PageState.headline)
  title$: Observable<string>;

  @Select(PageState.subheader)
  subheader$: Observable<string>;

  @Select(PageState.status)
  status$: Observable<PageStatus>;

  @Select(PageState.currentLanguage)
  private currentLanguage$: Observable<CurrentLanguage>;

  @Select(PageState.page)
  private page$: Observable<LoadableState<Page>>;

  @Select(UserAuthState.organizationDetails)
  private organizationData$: Observable<Organization>;

  @Select(UserAuthState.homePageUri)
  homePageUri$: Observable<string>;

  updateInProgress: boolean;
  saveInProgress: boolean;
  showFrameworkTagsPanel: boolean;
  thumbnailUploadInProgress: boolean;
  loadingTags: boolean;

  organizationLanguageSummary: OrganizationLanguageSummary;
  currentLanguage: SupportedLanguage;
  supportedLanguages: SupportedLanguage[] = [];
  currentIndex: number;
  filteredTags: ResourceTag[] = [];

  uri: string;
  subscription: Subscription;
  thumbnailUrl: string;
  tags: ResourceTag[];

  page: Page;
  authorDetails = {};
  pageUid: string;
  showOnLeftNav: boolean;
  languageTabChanged = false;
  skeletonViewActive: boolean;

  newVersionEnabled = VersionHelper.newVersionEnabled();

  private pagesUri: string;
  private newTagCreateInProgress: boolean;
  private subscriptionEnd$ = new Subject<void>();
  private searchInputSubscription: Subscription;
  private tagsAddSubscription: Subscription;
  private searchInputSubject = new Subject<string>();
  private homePageUri: string;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private ngZone: NgZone,
    private dialogService: DialogService,
    @Inject(LANGUAGES_DATA_SERVICE) private languageService: LanguagesDataService,
    @Inject(PAGES_DATA_SERVICE) private pageDataService: PageDataService,
    @Inject(ORGANIZATIONS_FILE_UPLOAD_DATA_SERVICE) private fileUploadService: FileUploadService,
    @Inject(CORE_TAG_DATA_SERVICE) private tagService: TagDataService,
    private translationService: TranslationService
  ) {
    this.searchInputSubscription = this.searchInputSubject
      .pipe(debounceTime(500)).subscribe((searchValue) => this.fireSearch(searchValue));
  }


  ngOnInit() {

    this.skeletonViewActive = true;
    if ( this.isDialog ) {
      this.skeletonViewActive = false;
    }

    this.homePageUri$.pipe(filter(data => !!data)).subscribe(data => this.homePageUri = data);
    this.pagesUri = this.route.snapshot.paramMap.get('pagesUri');
    const publisherUri = this.route.snapshot.paramMap.get('publisherUri');
    const packageUri = this.route.snapshot.paramMap.get('packageUri');
    this.organizationData$.pipe(
      pluck('_id'),
      mergeMap(orgId => this.languageService.getOrganizationLanguages(orgId)),
      takeUntil(this.subscriptionEnd$),
    ).subscribe(result => {
        if ( result.isSuccess ) {
          this.organizationLanguageSummary = result.value;
          let languageIndex = 0;
          this.subscription = this.page$.pipe(
            filter(({ loading, data }) => !loading && !!data),
            map(page => page.data))
            .subscribe(page => {
              this.page = page;
              this.pageUid = page._id;
              this.thumbnailUrl = page.thumbnail;
              this.tags = page.tags;
              this.uri = page.uri;
              this.showOnLeftNav = this.page.showOnLeftNav;
              page.availableLanguages = LanguageCodeHelper.orderAvailableLanguages(
                result.value.supportedLanguages, page.availableLanguages,
              );
              languageIndex = LanguageCodeHelper.getActiveLanguageTabIndex(
                result.value.supportedLanguages, page.availableLanguages, this.translationService
              );
              this.skeletonViewActive = false;
              this.languageTabChanged = false;
              this.supportedLanguages = result.value.supportedLanguages.filter((language) =>
                this.page.availableLanguages.map((lang: AvailableLanguage) => lang.code).includes(language.language.code));

              this.currentLanguage = this.supportedLanguages[languageIndex];
              this.currentIndex = languageIndex
            });
          this.store.dispatch(new SetPageCurrentLanguage(this.supportedLanguages[languageIndex], languageIndex));
        }
      }
    );

    this.currentLanguage$.pipe(
      filter(it => !!it),
      takeUntil(this.subscriptionEnd$)
    ).subscribe(currentLanguage => {
      this.currentLanguage = currentLanguage.supportedLanguage;
      this.currentIndex = currentLanguage.index;
      if ( !this.isDialog ) {
        this.store.dispatch(
          new LoadPageByUriAndLanguageCode(
            this.pagesUri,
            publisherUri,
            packageUri,
            null,
            null,
            this.currentLanguage?.language.code,
          )
        );
      }
    });
  }

  onTabChange(currentLanguage: CurrentLanguage): void {
    this.languageTabChanged = true;
    this.skeletonViewActive = true;
    this.store.dispatch(new SetPageCurrentLanguage(currentLanguage.supportedLanguage, currentLanguage.index));
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetPageCurrentLanguage());
    this.subscription?.unsubscribe();
    this.tagsAddSubscription?.unsubscribe();
    this.subscriptionEnd$.next();
  }

  onTextPaste(event: ClipboardEvent) {
    if ( event.type === 'paste' ) {
      setTimeout(() => {
        const searchValue = (event.target as HTMLInputElement).value;
        this.searchInputSubject.next(searchValue);
      }, 0);
    }
  }

  onSearchInputChange(event: KeyboardEvent) {
    if ( canTriggerSearch(event) ) {
      const searchValue = (event.target as HTMLInputElement).value;
      this.searchInputSubject.next(searchValue);
    }
  }

  onSearchInputEnter(event: KeyboardEvent) {
    const searchValue = (event.target as HTMLInputElement).value;
    this.newTagCreateInProgress = true;
    this.loadingTags = true;
    this.tagService.createTag({ title: searchValue }).subscribe(({ isSuccess, value }) => {
      if ( isSuccess ) {
        this.store.dispatch(new PageAddTag(value)).toPromise().then(() => {
          this.loadingTags = false;
          (event.target as HTMLInputElement).value = '';
        });
      } else {
        this.loadingTags = false;
      }
      this.newTagCreateInProgress = false;
    });
  }

  onShowFrameworkTagsPanel(show: boolean) {
    this.showFrameworkTagsPanel = show;
  }

  disabledAutocomplete(): string {
    return '';
  }

  onThumbnailUpload(eventData: Event) {
    const selectedImage: File = (eventData.target as HTMLInputElement).files[0];

    const dialogRef = this.dialog.open(ImageCropDialogComponent, {
      width: '90vw',
      minWidth: '15.625rem',
      maxWidth: '37.5rem',
      maxHeight: '80vh',
      position: {
        top: '10vh',
      },
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      panelClass: 'ptl-mat-dialog',
      backdropClass: 'dialog-backdrop',
      data: {
        imageFile: selectedImage,
        cropperShape: 'rectangle',
        imageFormat: 'jpeg',
        aspectRatio: 1.77,
        resizeToHeight: 300,
      },
    });

    const dialogSubscription = dialogRef.afterClosed().subscribe((data) => {
      if ( data ) {
        this.thumbnailUploadInProgress = true;
        const request: PlaylistThumbnailUpdateRequest = {
          imageName: ImageUploadNameResolver.resolveImageName(data.croppedImageFile.name),
          contentLength: data.croppedImageFile.size,
        };

        this.pageDataService.changePageThumbnail(this.pageUid, request, this.currentLanguage?.language.code)
          .subscribe(({ isSuccess, value }) => {
            if ( isSuccess ) {
              this.fileUploadService.uploadFile(
                FileUploadHelper.filePath(value.url),
                data.croppedImageFile
              ).subscribe(() => {
                this.thumbnailUrl = data.croppedImageDataUrl;
                this.thumbnailUploadInProgress = false;
                SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'thumbnailImageUpdated');
              });
            }
          });
      }

      (eventData.target as HTMLInputElement).value = '';
      dialogSubscription.unsubscribe();
    });
  }

  onThumbnailRemove() {
    this.pageDataService.removePageThumbnail(this.pageUid, this.currentLanguage?.language.code).subscribe(({ isSuccess }) => {
      if ( isSuccess ) {
        this.thumbnailUrl = undefined;
        SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'thumbnailImageRestored');
      }
    });
  }

  onSubheaderUpdate(newSubheader: string): void {
    this.store.dispatch(new UpdatePageSubheader(newSubheader, this.currentLanguage.language.code));
  }

  onTitleUpdate(newTitle: string): void {
    this.store.dispatch(new UpdatePageHeadline(newTitle, this.currentLanguage.language.code));
  }

  switchToHomePage(): void {
    const element = document.createElement('div');
    element.innerHTML = this.translationService.getTranslation('dialog.title.confirmSwitchToHomePage');

    this.dialogService.showConfirmDialog(
      element,
      this.translationService
    ).then(confirmed => {
      if ( confirmed ) {
        this.store.dispatch(new SwitchToHomePage(this.pageUid)).toPromise().then(() => {
          if ( this.isDialog ) {
            RedirectHelper.redirectToHomePage(this.ngZone, this.router, this.route, this.homePageUri);
          }
        });
      }
    });
  }

  removeTag(tag: ResourceTag) {
    this.store.dispatch(new PageRemoveTag(tag._id));
    this.showFrameworkTagsPanel = false;
  }

  onTagSelect({ option }: MatAutocompleteSelectedEvent) {
    const tag: ResourceTag = option.value;
    this.filteredTags = [];
    this.loadingTags = true;
    this.store.dispatch(new PageAddTag(tag)).toPromise().then(() => {
      this.loadingTags = false;
    });
  }

  onSubmitTags(tags: ResourceTag[]) {
    if ( tags.length ) {
      const tag = tags.shift();
      this.tagsAddSubscription = this.store.dispatch(new PageAddTag(tag)).subscribe(() => {
        this.onSubmitTags(tags);
      });
    }
  }

  onPublishClick() {

    this.dialogService.showConfirmDialog(
      this.translationService.getTranslation('dialog.title.confirmPublishPage'),
      this.translationService
    ).then(confirmed => {
      if ( confirmed ) {
        this.updateInProgress = true;
        this.store.dispatch(new PublishPage()).toPromise().then(() => {
          SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'pages.message.success.publish');
          this.updateInProgress = false;

          this.store.dispatch(new RedirectToPageLearnerView(this.pagesUri));
        }).catch(() => {
          this.updateInProgress = false;
        });
      }
    });
  }

  onUnpublishClick() {
    this.dialogService.showConfirmDialog(
      this.translationService.getTranslation('dialog.title.confirmUnPublishPage'),
      this.translationService
    ).then(confirmed => {
      if ( confirmed ) {
        this.updateInProgress = true;
        this.store.dispatch(new UnpublishPage()).toPromise().then(() => {
          SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'pages.message.success.unPublish');
          this.updateInProgress = false;
        }).catch(() => {
          this.updateInProgress = false;
        });
      }
    });
  }

  onUriChanged(newUri: string): void {
    if ( newUri === '' ) {
      return;
    }
    this.store.dispatch(new UpdatePageUri(newUri));
  }

  updatePageVisibilityInNav(data: MatSlideToggleChange) {
    if ( !data.checked ) {
      this.dialogService.showConfirmDialog(
        this.translationService.getTranslation('dialog.title.confirmHidePageInSidenav'),
        this.translationService
      ).then(confirmed => {
        if ( confirmed ) {
          this.store.dispatch(new UpdatePageSidenavVisibility(data.checked)).toPromise().then(() => {
            this.showOnLeftNav = false;
          });
        } else {
          this.showOnLeftNav = true;
        }
      });
    } else {
      this.store.dispatch(new UpdatePageSidenavVisibility(data.checked)).toPromise().then(() => {
        this.showOnLeftNav = data.checked;
      });
    }
  }

  private fireSearch(searchValue: string) {
    this.loadingTags = true;
    if ( !searchValue ) {
      this.loadingTags = false;
      return;
    }
    if ( this.newTagCreateInProgress ) {
      return;
    }
    this.tagService.findTagsWithTitleTerm(searchValue).subscribe(({ isSuccess, value }) => {
      if ( isSuccess ) {
        this.filteredTags = value;
      }
      this.loadingTags = false;
    });
  }
}
