/*
 * Copyright (C) 2018 - present by Potentially
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, HostListener, Inject, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { MEMBERS_DATA_SERVICE, MembersDataService } from '../../../services/members/members-data.service';
import {
  AdminMembersRoles,
  DateInterval,
  DistanceTravelMode,
  DistanceTravelRequest,
  UserAddRemoveGroup,
  UserAddRemoveRole,
  UserAddRemoveSectionType
} from '../../../../../shared/models/admin/members.model';
import { FormControl } from '@angular/forms';
import { UserGroups } from '../../../../../shared/models/admin/group/user-groups.model';
import { USER_GROUPS_DATA_SERVICE, UserGroupsDataService } from '../../../services/groups/groups-data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ObservableResult } from '../../../../../shared/store';
import { SnackbarHelper } from '../../../../../shared/helpers/snackbar-helper';
import { AlumniCsvUploadDialogComponent } from '../alumni-csv-upload-dialog/alumni-csv-upload-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Select } from '@ngxs/store';
import { UserAuthState } from '../../../../../user-auth/store/user-auth.state';
import { Organization, OrganizationRoles } from '../../../../../shared/models/organization';
import { UserDetails } from '../../../../../user-auth/models';
import { ROLES_DATA_SERVICE, RolesDataService } from '../../../services/roles/roles-data.service';
import { LanguageCodeHelper } from '../../../../../shared/helpers/language-code-helper';
import * as moment from 'moment';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MembersBooleanSearchRequest } from '../../../../../shared/models/admin/boolean-filters.model';
import { ActivatedRoute, Router } from '@angular/router';
import { RedirectHelper } from '../../../../resource/store/editor/content/helpers/redirect.helper';

@Component({
  selector: 'ptl-admin-member-buttons',
  templateUrl: './admin-member-buttons.component.html',
  styleUrls: ['./admin-member-buttons.component.scss'],
})
export class AdminMemberButtonsComponent implements OnInit, OnDestroy {

  @Input() isPlaylistPage: boolean;
  @Input() isCardPage: boolean;
  @Input() isAssessment: boolean;
  @Input() totalMembersCount: number;
  @Input() allMembersCount: number;
  @Input() exportName: string;
  @Output() groupAddRemove = new EventEmitter<UserAddRemoveGroup>();
  @Output() roleAddRemove = new EventEmitter<UserAddRemoveRole>();
  @Output() openMessage = new EventEmitter<void>();
  @Input() exportEnabled: boolean;

  // for boolean export
  @Input() playlistUid: string;
  @Input() cardUid: string;
  @Input() booleanSearchRequest: MembersBooleanSearchRequest;

  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;
  @Select(UserAuthState.userDetailsData)
  userDetailsData$: Observable<UserDetails>;
  @Select(UserAuthState.userHasSuperAdminRole)
  private isUserSuperAdmin$: Observable<boolean>;
  @Select(UserAuthState.userHasAdminRole)
  private isUserAdmin$: Observable<boolean>;

  selectedGroupUid: string;
  selectedRoleUid: string;
  groupSectionActive: boolean;
  roleSectionActive: boolean;
  showSearchResult: boolean;
  exportStarted: boolean;
  groups: UserGroups[];
  roles: AdminMembersRoles[];
  searchString = new FormControl('');
  selectedGroupName: string;
  selectedRoleName: string;
  searchInProgress: boolean;
  canViewExports: boolean;
  showDistanceTravelled = true;
  showFirstIntervalCalendar = false;
  showSecondIntervalCalendar = false;
  firstInterval: DateInterval = { startDate: null, endDate: null };
  activeFirstInterval: DateInterval = { startDate: null, endDate: null };
  secondInterval: DateInterval = { startDate: null, endDate: null };
  activeSecondInterval: DateInterval = { startDate: null, endDate: null };
  isFirstIntervalEarliest = false;
  isFirstIntervalLatest = true;
  firstIntervalMode = DistanceTravelMode.LATEST;
  isSecondIntervalEarliest = false;
  isSecondIntervalLatest = true;
  secondIntervalMode = DistanceTravelMode.LATEST;
  includeDistanceTravel = false;

  private searchInputSubject$ = new Subject<UserAddRemoveSectionType>();
  private searchInputSubscription: Subscription;
  private subscriptionEnd$ = new EventEmitter<void>();

  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ngZone: NgZone,
    private dialog: MatDialog,
    @Inject(MEMBERS_DATA_SERVICE) private membersDataService: MembersDataService,
    @Inject(USER_GROUPS_DATA_SERVICE) public userGroupsService: UserGroupsDataService,
    @Inject(ROLES_DATA_SERVICE) private rolesDataService: RolesDataService,
  ) {
    this.searchInputSubscription = this.searchInputSubject$
      .pipe(debounceTime(500))
      .subscribe((type: UserAddRemoveSectionType) => this.fireSearch(type));
  }

  ngOnInit() {
    combineLatest([this.isUserAdmin$, this.isUserSuperAdmin$]).pipe(
      takeUntil(this.subscriptionEnd$)
    ).subscribe(data => {
      this.canViewExports = this.checkIfUserCanViewExports(data[0], data[1]);
    });
  }

  ngOnDestroy() {
    this.subscriptionEnd$.emit();
  }

  private formatDate(date: Date) {
    const dateString = date.toString();
    const timezone = dateString.toString().substr(dateString.indexOf('GMT') + 3, 5);
    return moment(date).utcOffset(timezone, true).format('YYYY-MM-DD');
  }

  downloadCsv(event: MouseEvent) {
    event.stopPropagation();
    const request = this.getExportRequestData();
    this.exportStarted = true;
    let membersObservable: ObservableResult<string>;
    if (this.isPlaylistPage) {
      membersObservable = this.membersDataService.booleanExportPlaylistMembers(request, this.playlistUid);
    } else if (this.isCardPage) {
      if (this.isAssessment) {
        if (this.includeDistanceTravel) {
          if (this.firstInterval.startDate && this.firstInterval.endDate) {
            request.distanceTravelled = this.initializeIntervalsIfEmpty(request);
            request.distanceTravelled.intervals.push({
              startDate: this.formatDate(this.firstInterval.startDate),
              endDate: this.formatDate(this.firstInterval.endDate),
              mode: DistanceTravelMode[this.firstIntervalMode],
            });
          }
          if (this.secondInterval.startDate && this.secondInterval.endDate) {
            request.distanceTravelled = this.initializeIntervalsIfEmpty(request);
            request.distanceTravelled.intervals.push({
              startDate: this.formatDate(this.secondInterval.startDate),
              endDate: this.formatDate(this.secondInterval.endDate),
              mode: DistanceTravelMode[this.secondIntervalMode],
            });
          }
        }
      }
      membersObservable = this.membersDataService.booleanExportCardMembers(request, this.playlistUid, this.cardUid);
    } else {
      membersObservable = this.membersDataService.booleanExportMembers(request);
    }

    membersObservable.subscribe(({ isSuccess, value }) => {
      if (isSuccess) {
        const exportName = this.exportName ? this.exportName : 'members';
        const dateFormat = this.getExportedDateFormat();
        const exportedFileName = exportName + '_export_' + dateFormat + '.csv';
        const csvContent = 'data:application/octet-stream;base64,' + btoa(unescape(encodeURIComponent(value)));
        const link = document.createElement('a');
        link.id = 'csvButton';
        link.setAttribute('href', csvContent);
        link.setAttribute('target', '_blank');
        link.setAttribute('download', exportedFileName);
        document.body.appendChild(link); // Required for FF
        link.click();
        link.parentNode.removeChild(link);
      }
      this.exportStarted = false;
    });
  }

  openAdvancedExport() {
    RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, '/admin/advanced-export');
  }

  openSection(sectionType: UserAddRemoveSectionType) {
    this.hideSection();
    if (sectionType === 'GROUP') {
      this.groupSectionActive = true;
    } else {
      this.roleSectionActive = true;
    }
  }

  onCreateNewGroup() {
    if (this.searchString.value) {
      this.userGroupsService.createUserGroup({ title: this.searchString.value })
        .subscribe(({ isSuccess, value, error }) => {
          if (isSuccess) {
            this.groupAddRemove.emit({ userGroupUid: value._id, type: 'ADD' });
          } else {
            SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
          }
        });
    }
  }

  onMemberAddToGroup() {
    this.groupAddRemove.emit({ userGroupUid: this.selectedGroupUid, type: 'ADD' });
  }

  onMemberRemoveFromGroup() {
    this.groupAddRemove.emit({ userGroupUid: this.selectedGroupUid, type: 'REMOVE' });
  }

  onGroupSelect(groupUid: string, groupTitle: string) {
    this.selectedGroupUid = groupUid;
    this.showSearchResult = false;
    this.selectedGroupName = groupTitle;
  }

  onMemberAddToRole() {
    this.roleAddRemove.emit({ roleUid: this.selectedRoleUid, type: 'ADD' });
  }

  onMemberRemoveFromRole() {
    this.roleAddRemove.emit({ roleUid: this.selectedRoleUid, type: 'REMOVE' });
  }

  onRoleSelect(roleUid: string, roleName: string) {
    this.selectedRoleUid = roleUid;
    this.showSearchResult = false;
    this.selectedRoleName = roleName;
  }

  onInputSubmit(type: UserAddRemoveSectionType) {
    this.fireSearch(type);
  }

  onInputChange(type: UserAddRemoveSectionType) {
    this.searchInputSubject$.next(type);
  }

  hideSection() {
    this.selectedGroupUid = undefined;
    this.selectedRoleUid = undefined;
    this.groupSectionActive = false;
    this.roleSectionActive = false;
    this.clearSearch();
    this.showSearchResult = false;
    this.selectedGroupName = undefined;
    this.selectedRoleName = undefined;
  }

  openAlumniUploadCsv() {
    this.dialog.open(AlumniCsvUploadDialogComponent, {
      maxWidth: '31.25rem',
      width: '100%',
      disableClose: true,
      maxHeight: '80vh',
      position: {
        top: '10vh',
      },
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      panelClass: 'ptl-mat-dialog',
      backdropClass: 'dialog-backdrop',
    });
  }

  openMessageEditor() {
    this.openMessage.emit();
  }

  onDistanceTravelToggleChange(event: MatSlideToggleChange) {
    this.includeDistanceTravel = event.checked;
  }

  @HostListener('document:click', ['$event'])
  documentOnClickListener(event: Event) {
    this.showSearchResult = false;
    const targetElement = event.target as Element;
    if (!targetElement.closest('.f_filter-item') && !targetElement.closest('.f_filter-input')
      && !targetElement.closest('.mat-calendar-body-cell-content') && !targetElement.closest('.mat-calendar')
      && !targetElement.closest('.mat-calendar-body-cell')) {
      this.showFirstIntervalCalendar = false;
      this.showSecondIntervalCalendar = false;
    }
  }

  onFirstIntervalApply() {
    this.activeFirstInterval.startDate = this.firstInterval.startDate;
    this.activeFirstInterval.endDate = this.firstInterval.endDate;
    this.showFirstIntervalCalendar = false;
  }

  onFirstIntervalClear() {
    this.showSecondIntervalCalendar = false;
    this.firstInterval = { startDate: null, endDate: null };
    this.activeFirstInterval = { startDate: null, endDate: null };
  }

  onSecondIntervalApply() {
    this.activeSecondInterval.startDate = this.secondInterval.startDate;
    this.activeSecondInterval.endDate = this.secondInterval.endDate;
    this.showSecondIntervalCalendar = false;
  }

  onSecondIntervalClear() {
    this.showSecondIntervalCalendar = false;
    this.secondInterval = { startDate: null, endDate: null };
    this.activeSecondInterval = { startDate: null, endDate: null };
  }

  toggleFirstIntervalCalendar() {
    this.showFirstIntervalCalendar = !this.showFirstIntervalCalendar;
    this.showSecondIntervalCalendar = false;
  }

  toggleSecondIntervalCalendar() {
    this.showSecondIntervalCalendar = !this.showSecondIntervalCalendar;
    this.showFirstIntervalCalendar = false;
  }

  toggleFirstIntervalMode(mode: string) {
    this.firstIntervalMode = DistanceTravelMode[mode];
    switch (mode) {
      case 'EARLIEST' : {
        this.isFirstIntervalEarliest = true;
        this.isFirstIntervalLatest = false;
        break;
      }
      case 'LATEST' : {
        this.isFirstIntervalLatest = true;
        this.isFirstIntervalEarliest = false;
        break;
      }
    }
  }

  toggleSecondIntervalMode(mode: string) {
    this.secondIntervalMode = DistanceTravelMode[mode];
    switch (mode) {
      case 'EARLIEST' : {
        this.isSecondIntervalEarliest = true;
        this.isSecondIntervalLatest = false;
        break;
      }
      case 'LATEST' : {
        this.isSecondIntervalLatest = true;
        this.isSecondIntervalEarliest = false;
        break;
      }
    }
  }

  private checkIfUserCanViewExports(isAdmin: boolean, isSuperAdmin: boolean): boolean {
    return (isAdmin || isSuperAdmin);
  }

  private getExportedDateFormat(): string {
    const date = new Date();
    return date.getFullYear() + '_' + (date.getMonth() + 1) + '_' + date.getDate() + '_' + date.getHours() + '_' +
      date.getMinutes();
  }

  private fireSearch(type: UserAddRemoveSectionType) {
    this.showSearchResult = false;
    this.searchInProgress = true;
    const searchString = this.searchString.value;
    if (!searchString) {
      this.searchInProgress = false;
      return;
    }
    if (type === 'GROUP') {
      this.userGroupsService.searchUserGroups(0, 10, searchString).subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          this.groups = value.content;
        }
        this.searchInProgress = false;
        this.showSearchResult = true;
      });
    } else {
      this.rolesDataService.loadRolesByPageWithSearch(searchString, 0, 10).subscribe(({ value }) => {
        if (value) {
          this.roles = this.rolesSuccessAction(value.content);
        }
        this.searchInProgress = false;
        this.showSearchResult = true;
      });
    }
  }

  private getExportRequestData(): MembersBooleanSearchRequest {
    return { ...this.booleanSearchRequest };
  }

  private clearSearch() {
    this.searchString.setValue('');
  }

  private rolesSuccessAction(value: OrganizationRoles[]) {
    value.map(role => {
      role.name = [LanguageCodeHelper.getDataByLanguageCode(role.name)];
      return role;
    });
    return value;
  }

  private initializeIntervalsIfEmpty(request: MembersBooleanSearchRequest): DistanceTravelRequest {
    if (!request.distanceTravelled) {
      return request.distanceTravelled = { intervals: [] };
    } else {
      return request.distanceTravelled;
    }
  }

}
