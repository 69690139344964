/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import {
  SubmitPlaylistReviewDialogComponent
} from 'src/app/page-modules/playlist/shared/view/playlist-action-bar/submit-dialog/submit-dialog.component';
import {
  RefreshUserPlaylistSubmissionSummary
} from 'src/app/page-modules/playlist/store/view/playlist-view.state.actions';
import { LEARNER_VIEW_DATA_SERVICE, LearnerViewDataService } from 'src/app/page-modules/resource/services/data.service';
import { DialogService } from 'src/app/shared/helpers/dialog/dialog.service';
import { TranslationService } from 'src/app/shared/services/translation/translation.service';
import { LearnerFormAnswer } from '../../../../../../page-modules/resource/models';
import {
  RefreshStateAfterFormSubmission,
  SaveFormAnswer
} from '../../../../../../page-modules/resource/store/learner-view.actions';
import {
  FormSavedEvent
} from '../../../../../../shared/components/resource-preview/form-preview/form-preview-event.model';
import { ContentHelper } from '../../../../../../shared/helpers/content-helper';
import { LocalTimeHelper } from '../../../../../../shared/helpers/local-time-helper';
import {
  CheckboxFormContent,
  Form,
  TextBoxFormContent,
  UserPlaylistSubmissionSummary
} from '../../../../../../shared/models';
import { LanguageCodeHelper } from '../../../../../../shared/helpers/language-code-helper';


@Component({
  selector: 'ptl-form-preview-checkbox-new',
  templateUrl: './form-checkbox-new.component.html',
  styleUrls: ['./form-checkbox-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormPreviewCheckboxNewComponent implements OnInit, OnChanges {

  /** Receives the checkbox type object */
  @Input() formData: Form<TextBoxFormContent>;
  @Input() learnerFormAnswer: LearnerFormAnswer;
  @Input() userPlaylistSubmissionSummary: UserPlaylistSubmissionSummary;
  @Input() isProjectResource: boolean;
  @Input() playlistUri: string;
  @Input() playlistUid: string;
  @Input() playlistTitle: string;
  @Input() resourceUri: string;
  @Input() resourceUid: string;
  @Input() groupUid: string;
  @Input() publisherUri: string;
  @Input() packageUri: string;
  @Input() pageUri: string;
  @Input() languageCode: string;

  existingUserAnswer: boolean;
  submitted = false;
  submittedOn: Date = null;
  canEdit = false;
  canNotEditReason: string;
  userAnswer = 'false';
  isChecked = false;
  requiresResubmission = false;
  submissionInProgress = false;
  validationInProgress = false;
  validationProcessed = false;

  constructor(
    private store: Store,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private translationService: TranslationService,
    private cd: ChangeDetectorRef,
    @Inject(LEARNER_VIEW_DATA_SERVICE) private dataService: LearnerViewDataService,
  ) {
  }

  ngOnInit() {
    if (this.formData && (this.formData.content as CheckboxFormContent).userAnswer) {
      const content = this.formData.content as CheckboxFormContent;
      this.userAnswer = content.userAnswer;
      this.submitted = content.submitted;
      if (content.userAnswer.length > 0) {
        this.existingUserAnswer = true;
      }
      if (this.userAnswer === 'true') {
        this.isChecked = true;
      }
    }
    this.submittedOn = this.learnerFormAnswer?.submittedOn;
    this.checkSubmissionMode();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.playlistUid && this.resourceUid && this.formData?.uid) {
      this.checkSubmissionMode();
    } else {
      if (changes.learnerFormAnswer || changes.userPlaylistSubmissionSummary) {
        this.checkSubmissionMode();
      }
    }
  }

  onCheckboxChange() {
    const event: FormSavedEvent = {
      formUid: this.formData.uid,
      answer: this.isChecked ? 'true' : 'false',
      submitted: this.submitted,
      submittedOn: this.submittedOn
    };
    this.existingUserAnswer = true;
    this.store.dispatch(new SaveFormAnswer(this.playlistUid, event));
  }


  submit() {
    if (ContentHelper.isProgressiveSubmissionMode(this.userPlaylistSubmissionSummary)) {
      const dialogMessage =
        this.userPlaylistSubmissionSummary?.userReviewSummary?.reviewType &&
        this.userPlaylistSubmissionSummary.userReviewSummary.reviewType !== 'AUTOMATIC' ?
          this.translationService.getTranslation('dialog.title.formSubmissionReviewEnabled') :
          this.translationService.getTranslation('dialog.title.formSubmission')
      this.dialogService.showConfirmDialog(
        dialogMessage,
        this.translationService
      ).then(confirmed => {
          if (confirmed) {
            this.processSubmit();
          }
        }
      );
    } else {
      this.processSubmit();
    }
  }

  processSubmit() {
    this.existingUserAnswer = true;
    if (ContentHelper.shouldDisplayReviewersDialogOnFormSubmission(this.userPlaylistSubmissionSummary)) {
      this.openReviewersDialog();
    } else {
      this.submitCheckbox();
    }
  }

  submitCheckbox(): void {
    this.submissionInProgress = true;
    this.dataService.submitForm(this.playlistUid, this.resourceUid, this.formData.uid).subscribe(({ isSuccess }) => {
      if (isSuccess) {
        this.refreshStateAndFinishSubmission();
      }
    });
  }

  private refreshStateAndFinishSubmission() {
    this.dataService.getCardWithProgress(
      this.isProjectResource,
      this.playlistUri,
      this.resourceUri,
      this.groupUid,
      this.publisherUri,
      this.packageUri,
      this.pageUri,
      this.languageCode
    ).subscribe(
      ({ isSuccess, value }) => {
        if (isSuccess) {
          this.store.dispatch(new RefreshStateAfterFormSubmission(value));
          this.store.dispatch(new RefreshUserPlaylistSubmissionSummary(this.playlistUid));
          this.finishSubmission();
        }
      });
  }

  private openReviewersDialog() {
    const dialogRef = this.dialog.open(SubmitPlaylistReviewDialogComponent, {
      width: '90vw',
      minWidth: '15.625rem',
      maxWidth: '46.875rem',
      maxHeight: '33.75rem',
      restoreFocus: true,
      position: {
        top: '10vh',
      },
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      panelClass: 'ptl-mat-dialog',
      backdropClass: 'dialog-backdrop',
      data: {
        isProjectResource: this.isProjectResource,
        playlistUid: this.playlistUid,
        playlistTitle: this.playlistTitle,
        userPlaylistSubmissionSummary: this.userPlaylistSubmissionSummary,
        mode: 'FORM',
        resourceUid: this.resourceUid,
        formUid: this.formData.uid,
        playlistUri: this.playlistUri,
        resourceUri: this.resourceUri,
        groupUid: this.groupUid,
        publisherUri: this.publisherUri,
        packageUri: this.packageUri,
        pageUri: this.pageUri,
        languageCode: this.languageCode
      }
    });
    dialogRef.afterClosed().subscribe((data => {
      if (data) {
        this.finishSubmission();
      }
    }))
  }

  private finishSubmission() {
    this.submitted = true;
    this.submissionInProgress = false;
    this.checkSubmissionMode();
  }

  getLocalDateTime(date: Date): string {
    return LocalTimeHelper.getLocalDateTime(date).toString();
  }

  get isSubmissionDisabled(): boolean {
    return !this.existingUserAnswer || this.submissionInProgress;
  }

  private checkSubmissionMode(): void {
    if (!this.learnerFormAnswer) {
      if (!this.playlistUid || !this.resourceUid || !this.formData?.uid) {
        return;
      }
      if (!this.validationInProgress && !this.validationProcessed) {
        this.validationInProgress = true;
        this.dataService.validateFormUpdate(this.playlistUid, this.resourceUid, this.formData?.uid)
          .subscribe(({ isSuccess, value }) => {
            if (isSuccess) {
              this.canEdit = value.canBeUpdated;
              this.canNotEditReason = ContentHelper.formCanNotBeEditedReason(this.translationService, value);
              this.requiresResubmission = false;
              this.validationProcessed = true;
            }
            this.checkSubmissionLocked();
            this.cd.detectChanges();
            this.validationInProgress = false;
          });
      }
    } else {
      this.canEdit = this.learnerFormAnswer?.updatePermission?.canBeUpdated;
      this.canNotEditReason = ContentHelper.formCanNotBeEditedReason(this.translationService, this.learnerFormAnswer?.updatePermission);
      this.requiresResubmission = this.learnerFormAnswer?.requiresResubmission;
      this.checkSubmissionLocked();
      this.cd.detectChanges();
    }
  }

  private checkSubmissionLocked() {
    if (this.userPlaylistSubmissionSummary?.submissionsLocked) {
      this.canEdit = false;
      this.canNotEditReason = this.translationService.getTranslation('formUpdateDisabledReasons.submissionClosed')
      this.requiresResubmission = false;
    }
  }

}
